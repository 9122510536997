import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import { REACT_TO_ORDER, GET_ORDERS_SUCCESS } from "../../arsVariables";

import { Actions } from "../../actions/_index";

import { Notify } from "../../../reuse/notify";
import { store } from "../../../index";
import { errorHandler } from "../../../reuse/utilities";
function* reactToOrder(payload) {
  try {
    const {
      data: { code, description },
    } = yield call(Services.reactToOrderService, payload);
    yield put(Actions.uiStop());
    yield put(Actions.miniUiStop());
    Notify({
      message: description,
      className: code === 1 ? "success" : "error",
      duration: 2,
    });
    if (code === 1) {
      if (!window.location.pathname.includes("pending")) {
        yield put({
          type: GET_ORDERS_SUCCESS,
          payloadReturned: store
            .getState()
            .getOrders_reducer.orders.filter(
              (item) => item.id !== payload.payload.orderId
            ),
        });
      }
      if (window.location.pathname.includes("pending") === true) {
        yield put(
          Actions.getOrderItems({
            pageIndex: 1,
            pageSize: 10,
            status: 1,
          })
        );
      }
    }
  } catch (err) {
    yield put(Actions.miniUiStop());
    yield put(Actions.uiStop());
    let message = errorHandler(err);
    Notify({
      message,
      className: "error",
      duration: 2,
    });
  }
}

export default function* reactToOrderSaga() {
  yield takeLatest(REACT_TO_ORDER, reactToOrder);
}
