import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Actions } from '../../../redux-flow/actions/_index';
import BaseComponent from '../../../reuse/baseComponent';
import { formatMoney } from '../../../reuse/currencyHelper';
import ContractMenu from './ContractMenuContainer';
import { Select, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;

class AllContractList extends BaseComponent {
    // productFilterOption: "", statusFilterOption: ""
    state = {
        SearchKeyword: "",
        loading: false,
        productFilterOptions: [{ value: "AGO", label: "AGO" },
        { value: "DPK", label: 'DPK' },
        { value: "PMS", label: 'PMS' }],
        statusFilterOptions: [{ value: 1, label: "Pending" },
        { value: 2, label: "Approved" },
        { value: 4, label: "Expired" }],
        pageObject: { vendorName: "" },
        pageIndex: 1, pageSize: 10,
    };

    fetch = ({ pageIndex, pageSize, vendorName = "", productName = "", contractStatus = "", SearchKeyword }) => {
        const { dispatch } = this.props;
        dispatch(Actions.uiStart());
        dispatch(
            Actions.getContractList({
                pageIndex,
                pageSize,
                productName,
                contractStatus,
                vendorName
            })
        );
    };

    componentDidMount() {
        const { pageSize, pageIndex, SearchKeyword } = this.state;
        this.fetch({ pageSize, SearchKeyword, pageIndex });
    }

    handleSelectChange = (value) => {
        let obj = {
            ...this.state.pageObject,
            productFilterOption: value
        }
        this.setState({ pageObject: obj })
    }

    handleFilterChange = (value) => {
        let obj = {
            ...this.state.pageObject,
            statusFilterOption: value
        }
        this.setState({ pageObject: obj })
    }

    handleSearchText = ({ target: { value } }) => {
        let obj = {
            ...this.state.pageObject,
            vendorName: value
        }
        this.setState({ pageObject: obj })
    }

    handleSearch = () => {
        const { pageSize, pageIndex, pageObject: { vendorName, productFilterOption, statusFilterOption } } = this.state
        this.fetch({ pageIndex, pageSize, productName: productFilterOption, contractStatus: statusFilterOption, vendorName })
    }

    handleClearselect = (open) => {
        if (open) {
            let obj = {
                ...this.state.pageObject,
            }
            delete obj.productFilterOption;
            this.setState({ pageObject: obj })
        }
    }

    handleClearFilter = (open) => {
        if (open) {
            let obj = {
                ...this.state.pageObject,
            }
            delete obj.statusFilterOption;
            this.setState({ pageObject: obj })
        }
    }

    render() {
        const { loading, toggleModal } = this.context;
        const { contractList, totalCount } = this.props;
        const { pageObject: { vendorName, productFilterOption, statusFilterOption }, productFilterOptions, statusFilterOptions } = this.state;
        return (
            <ContractMenu
                toggleModal={toggleModal}
                SearchKeyword={this.state.SearchKeyword}
                loading={loading}
            >
                <div className="content" id="content-body">
                    <div
                        className={`container-fluid container-limited`}>
                        <div className="row my-4">
                            <form
                                className="mt-md-0 col-lg-6 ml-0 ml-md-auto"
                                onSubmit={this.handleSearch}
                            >
                                <Input
                                    prefix={<SearchOutlined style={{ color: '#7F91A8', fontSize: '15px' }} />}
                                    allowClear
                                    size="large"
                                    value={vendorName}
                                    onChange={this.handleSearchText}
                                    // onBlur={this.handleSearch}
                                    className="filter-input"
                                    style={{ width: "30%" }}
                                    placeholder="Vendor name..."
                                />

                                <Select
                                    size="large"
                                    className="select-height"
                                    placeholder="Select Product"
                                    style={{ width: "25%", height: "37px", margin: 5 }}
                                    onChange={this.handleSelectChange}
                                    onDropdownVisibleChange={this.handleClearselect}
                                    value={productFilterOption}
                                >
                                    {productFilterOptions.map((filter, index) => {
                                        return (<Option key={`${index}-${filter.value}`} value={filter.value}>{filter.label}</Option>)
                                    })}
                                </Select>

                                <Select
                                    size="large"
                                    className="select-height"
                                    placeholder="Select Status"
                                    style={{ width: "25%", height: "37px", margin: 5, marginLeft: 0 }}
                                    onChange={this.handleFilterChange}
                                    onDropdownVisibleChange={this.handleClearFilter}
                                    value={statusFilterOption}
                                >
                                    {statusFilterOptions.map((filter, index) => {
                                        return (<Option key={`${index}-${filter.value}`} value={filter.value}>{filter.label}</Option>)
                                    })}
                                </Select>

                                <Button style={{ height: '80%' }} onClick={this.handleSearch} htmlType="button" type="primary">
                                    Search
                                    <SearchOutlined className="ml-1" />
                                </Button>
                            </form>
                            <div className="col-12">
                                <div
                                    className="trail menu-link pt-0"
                                    style={{ marginBottom: 0, paddingBottom: 4 }}
                                >
                                    <span
                                        style={{
                                            color: "#000000",
                                            borderBottomColor: "#00425F",
                                            borderBottomWidth: 3,
                                            borderBottomStyle: "solid",
                                        }}
                                    >
                                        Contract List
                                    </span>
                                </div>
                                {/* Contract ID, Vendor name, Product, Price, Unit, Status, */}
                                <div className="table-container pb-4">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Contract ID
                                                </th>
                                                <th>
                                                    Vendor Name
                                                </th>
                                                <th>
                                                    Product
                                                </th>
                                                <th>
                                                    Price
                                                </th>
                                                <th>
                                                    Unit
                                                </th>
                                                <th>
                                                    Status
                                                </th>
                                                <th>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(contractList && contractList.length > 0) ? contractList.map((contract, index) => {
                                                return (<tr key={`${index}-${contract.index}`}>
                                                    <td>{contract.id}</td>
                                                    <td>{contract.company}</td>
                                                    <td>{contract.productCode}</td>
                                                    <td >{formatMoney(contract.unitPrice)}</td>
                                                    <td>{contract.unitType === 1 ? "Litre" : "Ton"}</td>
                                                    <td>{contract.status === 1 ? (
                                                        <span className="isw-status--box-pending">
                                                            {contract.statusValue}
                                                        </span>
                                                    ) : contract.status === 2 ? (
                                                        <span className="isw-status--box-success">
                                                            {contract.statusValue}
                                                        </span>
                                                    ) : contract.status === 4 ? (
                                                        <span className="isw-status--box-failed">
                                                            {contract.statusValue}
                                                        </span>
                                                    ) : (
                                                        <span className="isw-status--box-info">
                                                            {contract.statusValue}
                                                        </span>
                                                    )}
                                                    </td>
                                                    <td>
                                                        <Link style={{ color: '#00425f' }} to={`/customer/contract/details/${contract.id}`}>View</Link>
                                                    </td>
                                                </tr>)
                                            }) :
                                                <tr><td colSpan="12">{this.renderTableAbnormality("No Contracts available yet.")}</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                    {this.renderPagination(
                                        totalCount
                                        // this.handlePageChange
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContractMenu>
        )
    }

}
const mapStateToProps = ({ getContractList_reducer }) => {
    const {
        totalCount,
        contractList
    } = getContractList_reducer;
    return {
        totalCount,
        contractList
    };
};

export default connect(mapStateToProps)(AllContractList);