import React from "react";
import FormLayout from "../reuse/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import Static from "../reuse/Static";
import { Actions } from "../redux-flow/actions/_index";
import { NotifyClose } from "../reuse/notify";
import { Icon, message } from "antd";
import { history } from "../reuse/history";
import { url } from "../App";
import WithRouter from "../reuse/WithRouter";
const antIcon = (
    <Icon type="loading" style={{ fontSize: 16, marginLeft: `${10}px` }} spin />
);
class EnterOTP extends FormLayout {
    state = {
        data: { code: "" },
        errors: {},
        styles: {}
    };

    schema = {
        code: Joi.number()
            .required()
            .label("OTP code")
    };

    componentDidMount() {
        const { location: { state } } = history;
        if (state) {
            this.setState({ email: state.email });
        } else if (state === undefined) {
            message.error("No email found");
            setTimeout(() => {
                history.push({ pathname: `${url}signup` });
            }, 1500);
        }
    }

    componentWillUnmount() {
        NotifyClose();
    }

    submitOTP = e => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;
        const { email: fromProps, dispatch } = this.props;
        const { code } = this.state.data;
        let email;
        // is user doesn't verify and attempts to login
        if (fromProps) {
            email = fromProps;
        } else {
            email = this.state.email;
        }
        dispatch(Actions.verifyProfile({ code, email }));
    };

    reSendOTP = () => {
        let email;
        const { email: fromProps, dispatch } = this.props;
        if (fromProps) {
            email = fromProps;
        } else {
            email = this.state.email;
        }
        const description = `request to resend OTP to ${email}`;
        dispatch(
            Actions.resendOTP({
                email,
                description
            })
        );
    };

    render() {
        const { loading, isLoading } = this.props;
        let email;
        // is user doesn't verify and attempt to login... getting their email back
        if (this.props.email) {
            email = this.props.email;
        } else {
            email = this.state.email;
        }
        // let new_number = null;
        // if (phone) {
        //     // code to display the phone number like this 0907***1234
        //     const phone_number = phone.split("");
        //     phone_number.splice(3, 6, "*".repeat(6));
        //     new_number = phone_number.join("");
        // }

        return (
            <div className="isw-login">
                <div className="container-fluid p-0">
                    <div className="row no-gutters">
                        <div className="col-lg-6">
                            <div className="isw-login--middle">
                                <div
                                    className="isw-login--middle-form"
                                    style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                    <form
                                        onSubmit={this.submitOTP}
                                        className="row"
                                        style={{
                                            maxWidth: `${30}rem`,
                                            margin: `${0} auto`
                                        }}>
                                        <div className="col-12">
                                            <header>
                                                <h1 className="text-primary">
                                                    Almost Done!
                                                </h1>
                                                <p>One more information</p>
                                            </header>
                                        </div>

                                        <div className="col-12">
                                            <div
                                                className="progress"
                                                style={{
                                                    height: `${0.3}rem`,
                                                    marginBottom: `${1}rem`
                                                }}>
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                    aria-valuenow="100"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 mb-5">
                                            <div className="isw-login--infoBox rounded">
                                                <p className="mb-0 small">
                                                    An OTP has been sent to{" "}
                                                    <span
                                                        style={{
                                                            fontWeight: 700
                                                        }}>
                                                        {email ? email : null}
                                                    </span>
                                                    . Enter the code to verify
                                                    your profile.
                                                </p>
                                            </div>

                                            {/* {phone ? (
                                                <div className="my-4 w-100 text-center">
                                                    <h3>Or</h3>
                                                </div>
                                            ) : null} */}
                                        </div>

                                        {/* {phone ? (
                                            <div className="col-12">
                                                <p>
                                                    Enter the code sent to{" "}
                                                    <span className="font-weight-bold">
                                                        {new_number}
                                                    </span>{" "}
                                                    below
                                                </p>
                                            </div>
                                        ) : null} */}

                                        <div className="form-field mb-2 col-12">
                                            {this.renderInput(
                                                "code",
                                                "OTP code"
                                            )}
                                        </div>

                                        <div
                                            className="mb-4 text-center"
                                            style={{ paddingLeft: `${15}px` }}>
                                            <p>
                                                Didn't receive the OTP?{" "}
                                                {isLoading ? (
                                                    antIcon
                                                ) : (
                                                    <span
                                                        className="text-primary font-weight-bold csr"
                                                        onClick={
                                                            this.reSendOTP
                                                        }>
                                                        Resend OTP
                                                    </span>
                                                )}
                                            </p>
                                        </div>

                                        <div className="col-12">
                                            {this.renderButton(
                                                "Verify Profile",
                                                loading,
                                                this.submitOTP,

                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <Static />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ signup_reducer }) => {
    const { email, phone } = signup_reducer;

    return {
        email,
        phone
    };
};

export default WithRouter(connect(mapStateToProps)(EnterOTP));
