import {
    GET_AUDIT_FAILURE,
    GET_AUDIT_SUCCESS,
} from "../arsVariables";
import { initialState } from "../initialState";

const getAuditLogs_reducer = (state = initialState, action) => {
    const { type, payloadReturned, totalCount } = action;
    switch (type) {
        case GET_AUDIT_SUCCESS:
            return {
                ...state,
                audits: payloadReturned,
                total: totalCount
            };
        case GET_AUDIT_FAILURE:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default getAuditLogs_reducer;
