import { GET_USER_FAILURE, GET_USER_SUCCESS } from "../arsVariables";
import { initialState } from "../initialState";

const getUser_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_SUCCESS:
            return {
                ...state,
                user: payload
            };
        case GET_USER_FAILURE:
            return {
                ...state
            };
        default:
            return state;
    }
};

export default getUser_reducer;
