import {
    GET_LOCATION_FAILURE,
    GET_LOCATION_SUCCESS
} from "../../redux-flow/arsVariables";
import { initialState } from "../initialState";

const getLocation_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_LOCATION_SUCCESS:
            return {
                ...state,
                location: payload
            };
        case GET_LOCATION_FAILURE:
            return {
                ...state
            };
        default:
            return state;
    }
};

export default getLocation_reducer;
