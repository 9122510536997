import { Skeleton } from 'antd';
import React from 'react';

export const LineSkeleton =({isShow, line = 0}) =>{
    return <Skeleton loading={isShow} paragraph={{
        rows: line,
      }} active shape='round' />;
}

export default { LineSkeleton };
