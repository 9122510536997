import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import "./signup.css";
import Static from "../../reuse/Static";
import SignUpForm from "../../reuse/signUpForm";
import { EmailIconLogo, FooterLogoIcon } from "../../reuse/svgIcons";
import { useSelector } from "react-redux";
export const url = "/customer/";

const hideEmail = (email = "hiddenEmail@gmail.com") => {
  const [username, domain] = email?.split("@");

  if (username?.length <= 2) {
    return `${username[0]}*${"@"}${domain}`;
  }

  const hiddenUsername =
    username[0] +
    "*".repeat(username?.length - 2) +
    username[username?.length - 1];
  return `${hiddenUsername}@${domain}`;
};

const EmailSentOnboard = ({ email }) => {
  const emaili = useSelector((state) => state.signup_onboard_reducer);
  const location = useLocation();
  const emails = location?.state?.email;
  // const goBack = () => this.setState({ page: 1 });
  const hiddenEmail = useMemo(() => hideEmail(email), [email]);
  return (
    <div className="isw-login">
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div className="col-lg-8">
            <div
              className="isw-login--middle"
              style={{
                overflowY: "auto",
                displaY: "flex",
                // justifyContent: "center",
              }}
            >
              <div className="isw-login--middle-form">
                <div
                  className="email-sent w-100 row form"
                  style={{
                    maxWidth: `${50}rem`,

                    margin: `${0} auto`,
                  }}
                >
                  <div className="col-12 ">
                    <main className="email-view-wrapper">
                      <EmailIconLogo classname={""} />
                      <h1 className="text-Email-large">
                        Registration Successful!
                      </h1>
                      <h3 className="text-Email-small">Verify email address</h3>
                      <p className="text-Email-faint">
                        A verification link has been sent to the provided email
                        address. Kindly
                        <br /> click on the link sent to {hiddenEmail} to finish
                        setting up <br /> your account.
                      </p>
                    </main>
                  </div>
                  {/* <SignUpForm /> */}
                </div>
                )
              </div>
              <div
                className="footer-wrapperTwo"
                style={{
                  maxWidth: `${50}rem`,

                  margin: `${0} auto`,
                }}
              >
                <p className="footer-text">
                  Powered by <FooterLogoIcon classname={"pb-1 pl-1"} />
                </p>
              </div>
            </div>
          </div>

          <Static />
        </div>
      </div>
    </div>
  );
};

export default EmailSentOnboard;
