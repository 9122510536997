import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import { CREATE_USER } from "../../redux-flow/arsVariables";

import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
function* createUser(payload) {
    try {
        yield put(Actions.miniUiStart());
        const { data } = yield call(Services.createUserService, payload);
        const { code, description } = data;
        yield put(Actions.miniUiStop());
        if (code === 1) {
            yield put(Actions.uiStart());
            yield put(Actions.toggleRightSidebar(false));
            yield put(Actions.getUsers({ pageIndex: 1, pageSize: 10 }));
        }
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
    }
}

export default function* createUserSaga() {
    yield takeLatest(CREATE_USER, createUser);
}
