import React, { Fragment } from "react";
import { Spin, message, Select } from "antd";
import Sidebar from "../reuse/sidebar";
import TrackerMap from "../reuse/trackerMap";
import { database } from "../reuse/firebaseConfig";
import BaseComponent from "../reuse/baseComponent";
import { NotifyClose } from "../reuse/notify";
import InfiniteScroll from "react-infinite-scroller";
import OtpModal from "../reuse/OtpModal";
import Services from "../redux-flow/services/services";
import MenuBar from "../reuse/MenuBar";

const { Option } = Select;
class Delivery extends BaseComponent {
    state = {
        beginTracking: false,
        error: "",
        loading: false,
        loadingList: false,
        hasMore: true,
        pageIndex: 1,
        destination: null,
        activeOrigin: null,
        allDeliveries: [],
        totalCount: null,
        active: null,
        otpModal: false,
        status: null
    };

    handleInfiniteOnLoad = () => {
        const { pageIndex, totalCount, allDeliveries } = this.state;
        this.setState({
            loadingList: true
        });

        if (allDeliveries.length === totalCount) {
            message.warning("Loaded all!");

            return this.setState({
                hasMore: false,
                loadingList: false
            });
        }
        this.fetch({ pageIndex });
    };

    fetch = ({ pageIndex = 1 }) =>
        this.axiosCall({ pageIndex, pageSize: 10, status: 2 });

    axiosCall = payload => {
        const { allDeliveries } = this.state;
        if (allDeliveries.length === 0) {
            this.setState({ loading: true });
        }
        Services.getDeliveriesService({ payload })
            .then(({ data: { payload, totalCount } }) => {
                this.setState({
                    allDeliveries: allDeliveries.concat(payload),
                    totalCount,
                    loadingList: false,
                    loading: false,
                    hasMore: allDeliveries.length >= totalCount ? false : true
                });
            })
            .catch(({ message: error }) => {
                message.error(error);
                this.setState({
                    error,
                    loadingList: false,
                    loading: false
                });
            });
    };

    componentDidMount() {
        this.fetch({ pageIndex: 1 });
        this.interval = setInterval(() => {
            if (this.state.active) {
                this.handleTracking(this.state.active);
            }
        }, 1000 * 10);
    }

    componentWillUnmount() {
        NotifyClose();
        clearInterval(this.interval);
    }

    handleTracking = id => {
        this.setState({ active: id });
        database
            .ref()
            .child(id)
            .limitToFirst(1)
            .on("value", snap => {
                if (!snap.val()) return;
                const obj = snap.val()[Object.keys(snap.val())];
                this.setState({
                    destination: obj,
                    beginTracking: true,
                    activeOrigin: this.state.allDeliveries.filter(
                        item => item.id === id
                    )
                });
            });
    };

    openModal = () => this.setState({ otpModal: !this.state.otpModal });

    pickStatus = status => {
        this.setState(
            { status, pageIndex: 1, allDeliveries: [], totalCount: null },
            () => this.axiosCall({ pageIndex: 1, pageSize: 10, status })
        );
    };

    render() {
        const {
            allDeliveries,
            loading,
            activeOrigin,
            destination,
            beginTracking,
            loadingList,
            hasMore,
            active,
            otpModal,
            status,
            totalCount
        } = this.state;

        const statusCodes = [
            "Pending",
            "In Transit",
            "Delivering",
            "Delivered",
            "Cancelled"
        ];

        return (
            <Fragment>
                <OtpModal
                    visible={otpModal}
                    openModal={this.openModal}
                    scheduleId={active}
                />
                <Sidebar toggleModal={this.context.toggleModal} />
                <div className="isw-mainLayout1">
                    <Spin tip="Please wait..." spinning={loading}>
                        <div className="isw-content--wrapper">
                            <MenuBar title='Delivery' />
                            <div>
                                <div className="content" id="content-body">
                                    <div className="container-fluid container-limited">
                                        <div className="row mb-4 mt-3">
                                            <div className="col-12">
                                                <div className="isw-card">
                                                    <div className="row no-gutters">
                                                        <div className="col-lg-4 h-100">
                                                            <div>
                                                                <header className="p-3">
                                                                    <h3 className="mb-0">
                                                                        Shipment
                                                                        Information
                                                                    </h3>
                                                                    <Select
                                                                        style={{
                                                                            width: 200,
                                                                            marginTop:
                                                                                "10px"
                                                                        }}
                                                                        className="delivery-dropdown-select"
                                                                        placeholder="Select order status"
                                                                        optionFilterProp="children"
                                                                        onChange={val =>
                                                                            this.pickStatus(
                                                                                Number(
                                                                                    val
                                                                                )
                                                                            )
                                                                        }>
                                                                        <Option value="0">
                                                                            Pending
                                                                        </Option>
                                                                        <Option value="1">
                                                                            In
                                                                            Transit
                                                                        </Option>

                                                                        <Option value="3">
                                                                            Delivered
                                                                        </Option>
                                                                        <Option value="4">
                                                                            Cancelled
                                                                        </Option>
                                                                    </Select>
                                                                </header>
                                                                <div className="isw-divider" />
                                                                <div
                                                                    className="p-3"
                                                                    style={{
                                                                        overflowY:
                                                                            "overlay",
                                                                        height: `${35}rem`
                                                                    }}>
                                                                    <InfiniteScroll
                                                                        initialLoad={
                                                                            false
                                                                        }
                                                                        pageStart={
                                                                            1
                                                                        }
                                                                        loadMore={
                                                                            this.handleInfiniteOnLoad
                                                                        }
                                                                        hasMore={
                                                                            !loadingList &&
                                                                            hasMore
                                                                        }
                                                                        useWindow={
                                                                            false
                                                                        }>
                                                                        {totalCount >
                                                                            0 ? (
                                                                            <div className="">
                                                                                {allDeliveries.map(
                                                                                    delivery => {
                                                                                        const {
                                                                                            driverName,
                                                                                            id,
                                                                                            trackingNO,
                                                                                            deliverStatusDetail,
                                                                                            verificationCode,
                                                                                            quantityRequested,
                                                                                            quantityDeilvered,
                                                                                            company,
                                                                                            unit,
                                                                                            product
                                                                                        } = delivery;

                                                                                        return (
                                                                                            <button
                                                                                                className={`card  delivery-card w-100 text-left isw-hoverClick mb-3 ${active ===
                                                                                                    id
                                                                                                    ? "activeCard"
                                                                                                    : ""
                                                                                                    }`}
                                                                                                key={
                                                                                                    id
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    this.handleTracking(
                                                                                                        id
                                                                                                    )
                                                                                                }>
                                                                                                <h6 className="mb-1">
                                                                                                    Driver:{" "}
                                                                                                    {
                                                                                                        driverName
                                                                                                    }
                                                                                                </h6>
                                                                                                <div>
                                                                                                    <span className="isw-p2">
                                                                                                        Delivery
                                                                                                        Company:{" "}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {
                                                                                                            company
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span className="isw-p2">
                                                                                                        Ref:{" "}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {
                                                                                                            trackingNO
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span className="isw-p2">
                                                                                                        Product:{" "}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {
                                                                                                            product
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span className="isw-p2">
                                                                                                        Quantity
                                                                                                        Requested:{" "}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {`${quantityRequested} ${Number(
                                                                                                            quantityRequested
                                                                                                        ) !==
                                                                                                            1
                                                                                                            ? `${unit}s`
                                                                                                            : unit
                                                                                                            }`}
                                                                                                    </span>
                                                                                                </div>

                                                                                                {(deliverStatusDetail === statusCodes[3]) && <div>
                                                                                                    <span className="isw-p2">
                                                                                                        Quantity Delivered:{" "}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {`${quantityDeilvered} ${Number(quantityDeilvered) !== 1
                                                                                                            ? `${unit}s`
                                                                                                            : unit
                                                                                                            }`}
                                                                                                    </span>
                                                                                                </div>}
                                                                                                <div>
                                                                                                    <span className="isw-p2">
                                                                                                        Delivery
                                                                                                        Status:{" "}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {
                                                                                                            deliverStatusDetail
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span className="isw-p2">
                                                                                                        Verification
                                                                                                        Code:{" "}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {
                                                                                                            verificationCode
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                                {/* {status !==
                                                                                                    2 ? null : (
                                                                                                    <span
                                                                                                        onClick={
                                                                                                            this
                                                                                                                .openModal
                                                                                                        }
                                                                                                        role="button"
                                                                                                        className="isw-p2 csr complete-btn">
                                                                                                        Finish
                                                                                                        Delivery
                                                                                                    </span>
                                                                                                )} */}
                                                                                            </button>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                                {loadingList ? (
                                                                                    <Spin
                                                                                        tip="fetching more..."
                                                                                        spinning
                                                                                    />
                                                                                ) : null}
                                                                            </div>
                                                                        ) : (
                                                                            <span>{`None ${statusCodes[
                                                                                status
                                                                            ] ||
                                                                                ""}`}</span>
                                                                        )}
                                                                    </InfiniteScroll>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-8">
                                                            <div className="bg-iswLight1 h-100">
                                                                <TrackerMap
                                                                    origin={
                                                                        activeOrigin
                                                                    }
                                                                    destination={
                                                                        destination
                                                                    }
                                                                    isSingle={
                                                                        false
                                                                    }
                                                                    locations={
                                                                        allDeliveries
                                                                    }
                                                                    trackOrder={
                                                                        beginTracking
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </Fragment>
        );
    }
}

export default Delivery;
