import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import {
    GET_PRODUCT_or_CONTRACT_DETAIL,
    GET_PRODUCT_or_CONTRACT_DETAIL_FAILURE,
    GET_PRODUCT_or_CONTRACT_DETAIL_SUCCESS
} from "../../arsVariables";
import { Notify } from "../../../reuse/notify";
import { Actions } from "../../actions/_index";
import { message } from "antd";
import { history } from "../../../reuse/history";
import { errorHandler } from "../../../reuse/utilities";

function* getProdnContDetailSaga(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: { code, description, payload: dataPayload }
        } = yield call(Services.getVendorWithContractDetailService, payload);
        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_PRODUCT_or_CONTRACT_DETAIL_SUCCESS,
                payload: dataPayload
            });
        } else if (code !== 1) {
            message.error(description);
            setInterval(() => {
                history.push("/customer/contracts");
            }, 1500);
            return yield put({
                type: GET_PRODUCT_or_CONTRACT_DETAIL_FAILURE
            });
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_PRODUCT_or_CONTRACT_DETAIL_FAILURE
        });
    }
}

export default function* getProdnContDetailSagaSaga() {
    yield takeLatest(GET_PRODUCT_or_CONTRACT_DETAIL, getProdnContDetailSaga);
}
