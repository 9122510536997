import { initialState } from "../../initialState";
import {
    GET_PRODUCT_or_CONTRACT_DETAIL_SUCCESS,
    GET_PRODUCT_or_CONTRACT_DETAIL_FAILURE
} from "../../arsVariables";

const getProdnContDetail_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PRODUCT_or_CONTRACT_DETAIL_SUCCESS:
            return {
                ...state,
               vendorDetail: payload
            };
        case GET_PRODUCT_or_CONTRACT_DETAIL_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getProdnContDetail_reducer;
