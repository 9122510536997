import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Spin, Radio, DatePicker } from "antd";

import MenuBar from "../reuse/MenuBar";
import Sidebar from "../reuse/sidebar";

import { calcDate, calcTime, convertRole } from '../reuse/utilities';
import moment from 'moment';

import FilterIcon from '../assets/images/filter.svg';
import { ExportSVG } from '../reuse/svgs';
import BaseComponent from '../reuse/baseComponent';
import { Actions } from "../redux-flow/actions/_index";

const { auditLogActions } = Actions

class Audit extends BaseComponent {

    state = {
        pageConfig: {
            pageIndex: 1,
            pageSize: 10,
            action: '',
            fromDate: moment(new Date(moment().subtract(365, 'days').format('LLLL'))),
            toDate: moment(new Date(moment().subtract(0, 'days').format('LLLL'))),
            format: '',
            filterBy: ''
        },
        pageIndex: 1,
        pageSize: 10,
        SearchKeyword: '',
        totalItems: 0,
        auditLogs: [],
    }

    componentDidMount() {
        this.fetch(this.state.pageConfig);
    }

    fetch = (pageConfig) => {
        const { pageIndex, pageSize, action, fromDate, toDate, filterBy } = pageConfig;
        this.props.dispatch(
            auditLogActions.getAuditLog({ pageIndex, pageSize, action, fromDate: moment(fromDate).format('YYYY-MM-DD'), toDate: moment(toDate).format('YYYY-MM-DD'), filterBy })
        );
    };

    handleTableChange = (page) => {
        const { pageConfig } = this.state;
        this.setState({ pageConfig: { ...pageConfig, pageIndex: page }, pageIndex: page }, () => {
            this.fetch(this.state.pageConfig);
        });
    };

    downloadAudit = (pageConfig) => {
        const { action, fromDate, toDate, filterBy, format } = pageConfig;
        this.props.dispatch(
            auditLogActions.downloadAuditLog({ action, fromDate: moment(fromDate).format('YYYY-MM-DD'), toDate: moment(toDate).format('YYYY-MM-DD'), filterBy, format })
        );
    };

    setAction = (action) => {
        let pageConfig = {
            ...this.state.pageConfig,
            action
        }
        this.setState({ pageConfig }, () => {
            this.fetch(pageConfig);
        })
    }

    onCalendarChange = (e) => {
        const { pageConfig } = this.state;
        let _pageConfig = {
            ...pageConfig,
            fromDate: moment(e[0]),
            toDate: e[1] ? moment(e[1]) : pageConfig.toDate,
        }
        if (e[1]) {
            this.setState({ pageConfig: _pageConfig }, () => {
                this.fetch(_pageConfig);
            })
        }
    }

    handleExportExcel = () => {
        let pageConfig = {
            ...this.state.pageConfig,
            format: 'xlsx'
        }
        this.downloadAudit(pageConfig);
    }

    handleSearch = ({ target: { value } }) => {
        const { pageConfig } = this.state;
        let _pageConfig = {
            ...pageConfig,
            filterBy: value
        }
        this.setState({ pageConfig: _pageConfig, SearchKeyword: value });
    };

    getBySearch = (e) => {
        e.preventDefault();
        const { pageConfig } = this.state;
        this.fetch(pageConfig);
    }

    render() {
        const { pageConfig: { fromDate, toDate }, SearchKeyword } = this.state;
        const { total, audits } = this.props;
        const { loading, status, toggleModal } = this.context;
        return (<Fragment>
            <Sidebar
                search={SearchKeyword}
                status={status}
                toggleModal={toggleModal}
                resetSearch={this.getBySearch}
            />
            <div className="isw-mainLayout1">
                <Spin spinning={loading} tip="Please wait">
                    <div className="isw-content--wrapper">
                        <MenuBar title="Audit Trail"
                            currentText={SearchKeyword}
                            onChangeHandler={this.handleSearch}
                            onSubmitHandler={this.getBySearch}
                        />
                        <div className="content" id="content-body">
                            <div className="container-fluid container-limited">

                                <div className="row mr-0">
                                    <div className="col-8 pr-0 mt-1">
                                        <span className="filter-tags-desc pr-2">Filters:</span>
                                        <Radio.Group style={{
                                            flexDirection: 'row',
                                            display: 'inline-block'
                                        }} defaultValue="" buttonStyle="solid" onChange={(e) => this.setAction(e.target.value)}>
                                            <Radio.Button value="">ALL</Radio.Button>
                                            <Radio.Button value={1}>CREATE</Radio.Button>
                                            <Radio.Button value={2}>DELETE</Radio.Button>
                                            <Radio.Button value={3}>EDIT</Radio.Button>
                                            <Radio.Button value={4}>APPROVE</Radio.Button>
                                            <Radio.Button value={5}>REJECT</Radio.Button>
                                            <Radio.Button value={6}>DISABLE</Radio.Button>
                                            <Radio.Button value={7}>ACTIVATE</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    <div className="col-md-4 pr-1 mt-md-1 mt-2" style={{
                                        paddingTop: 24
                                    }}>
                                        <DatePicker.RangePicker
                                            defaultValue={[fromDate, toDate]}
                                            onCalendarChange={this.onCalendarChange}
                                            format={"DD-MMMM-YYYY"}
                                            allowClear={false}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <div
                                            className="contract-export-btn"
                                            style={{ float: "right" }}>
                                            <span onClick={this.handleExportExcel} className="text-white">
                                                Export CSV <ExportSVG />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div
                                            className="trail menu-link pt-0"
                                            style={{ marginBottom: 0, paddingBottom: 4 }}
                                        >
                                            <span
                                                style={{
                                                    color: "#000000",
                                                    borderBottomColor: "#00425F",
                                                    borderBottomWidth: 3,
                                                    borderBottomStyle: "solid",
                                                }}
                                            >
                                                Audit Trail
                                            </span>
                                        </div>
                                        <div className="table-container pb-4">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Name
                                                        </th>
                                                        <th>
                                                            Role Name
                                                        </th>
                                                        <th>
                                                            Email Address
                                                        </th>
                                                        <th>
                                                            Description
                                                        </th>
                                                        <th>
                                                            Action
                                                            <img className='ml-1 mb-1' src={FilterIcon} alt="filter icon" />
                                                        </th>
                                                        <th>
                                                            Date
                                                            <img className='ml-1 mb-1' src={FilterIcon} alt="filter icon" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(audits && audits.length > 0) ? audits.map((audit, index) => {
                                                        return (<tr key={`${index}-${audit.date}`}>
                                                            <td>{audit.userFullName}</td>
                                                            <td>{convertRole(audit.roleName)}</td>
                                                            <td>{audit.userName}</td>
                                                            <td style={{
                                                                width: '30%'
                                                            }}>{audit.message}</td>
                                                            <td>{audit.actionName}</td>
                                                            <td>{calcDate(audit.date)} <br></br>
                                                                {calcTime(audit.date)}</td>
                                                        </tr>)
                                                    }) :
                                                        <tr><td colSpan="12">{this.renderTableAbnormality("No Audit Trail available yet.")}</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                            {this.renderPagination(
                                                total,
                                                // pageConfig.pageIndex,
                                                // pageConfig.pageSize,
                                                // this.handlePageChange
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </Fragment>);
    }
}

const mapStateToProps = ({ getAuditLogs_reducer }) => {
    const { audits, total } = getAuditLogs_reducer;
    return {
        audits,
        total
    };
};

export default connect(mapStateToProps)(Audit);