import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import {
    GET_CONTRACT_LIST,
    GET_CONTRACT_LIST_FAILURE,
    GET_CONTRACT_LIST_SUCCESS,
    GET_VENDOR_CONTRACTS,
    GET_VENDOR_CONTRACTS_FAILURE,
    GET_VENDOR_CONTRACTS_SUCCESS,
    GET_CONTRACT_DETAILS,
    GET_CONTRACT_DETAILS_SUCCESS,
    GET_CONTRACT_DETAILS_FAILURE,
    GET_VENDORS_PRODUCT,
    GET_VENDORS_PRODUCT_SUCCESS,
    GET_VENDORS_PRODUCT_FAILURE,
    GET_CONTRACT_HISTORY,
    GET_CONTRACT_HISTORY_SUCCESS,
    GET_CONTRACT_HISTORY_FAILURE,
} from "../../arsVariables";
import { Notify } from "../../../reuse/notify";
import { Actions } from "../../actions/_index";
import { errorHandler } from "../../../reuse/utilities";

function* getVendorContracts(payload) {
    try {
        const {
            data: {
                code,
                description,
                totalCount: totalContracts,
                payload: dataPayload
            }
        } = yield call(Services.getVendorWithContractsService, payload);
        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_VENDOR_CONTRACTS_SUCCESS,
                vendorContracts: dataPayload,
                totalContracts
            });
        } else if (code !== 1) {
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_VENDOR_CONTRACTS_FAILURE
            });
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_VENDOR_CONTRACTS_FAILURE
        });
    }
}

export default function* getVendorContractsSaga() {
    yield takeLatest(GET_VENDOR_CONTRACTS, getVendorContracts);
}


function* getContractList(payload) {

    try {
        const {
            data: {
                code,
                description,
                totalCount,
                payload: dataPayload
            }
        } = yield call(Services.getContractList, payload);
        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_CONTRACT_LIST_SUCCESS,
                contractList: dataPayload,
                totalCount
            });
        } else if (code !== 1) {
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_CONTRACT_LIST_FAILURE
            });
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_CONTRACT_LIST_FAILURE
        });
    }
}

export function* getContractListSaga() {
    yield takeLatest(GET_CONTRACT_LIST, getContractList);
}

function* getContractDetails(payload) {

    try {
        yield put(Actions.uiStart());
        const {
            data: {
                code,
                description,
                payload: payloadData
            }
        } = yield call(Services.getProdnContDetailService, payload);
        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_CONTRACT_DETAILS_SUCCESS,
                contractDetail: payloadData,
            });
        } else if (code !== 1) {
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_CONTRACT_DETAILS_FAILURE
            });
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_CONTRACT_DETAILS_FAILURE
        });
    }
}

export function* getContractDetailSaga() {
    yield takeLatest(GET_CONTRACT_DETAILS, getContractDetails);
}

function* getVendorProducts(payload) {

    try {
        yield put(Actions.uiStart());
        const {
            data: {
                code,
                description,
                totalCount,
                payload: payloadData
            }
        } = yield call(Services.getVendorPrdouctsService, payload);
        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_VENDORS_PRODUCT_SUCCESS,
                vendorProducts: payloadData,
                totalCount,
            });
        } else if (code !== 1) {
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_VENDORS_PRODUCT_FAILURE
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_CONTRACT_DETAILS_FAILURE
        });
    }
}

export function* getVendorProductsSaga() {
    yield takeLatest(GET_VENDORS_PRODUCT, getVendorProducts);
}

function* getContractHistory(payload) {

    try {
        yield put(Actions.uiStart());
        const {
            data: {
                code,
                description,
                totalCount,
                payload: payloadData
            }
        } = yield call(Services.getContractHistoryService, payload);
        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_CONTRACT_HISTORY_SUCCESS,
                contractHistory: payloadData,
                totalCount,
            });
        } else if (code !== 1) {
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_CONTRACT_HISTORY_FAILURE
            });
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_CONTRACT_HISTORY_FAILURE
        });
    }
}

export function* getContractHistorySaga() {
    yield takeLatest(GET_CONTRACT_HISTORY, getContractHistory);
}