import {
  GET_REPORT_LOGS_FAILURE,
  GET_REPORT_LOGS_SUCCESS,
  // GET_REPORT_LOGS_PDF_DOWNLOAD,
  // GET_REPORT_LOGS_EXCEL_DOWNLOAD,
} from "../arsVariables";

const initialState = {
  reports: [],
  errors: null,
  noOfPages: 0,
  sizeOfCurrentPage: 0,
  total: 0,
};

const getReports_reducer = (state = initialState, action) => {
  const { type, payloadReturned, total, noOfPages, sizeOfCurrentPage } = action;
  switch (type) {
    case GET_REPORT_LOGS_SUCCESS:
      return {
        ...state,
        reports: payloadReturned,
        total,
        noOfPages,
        sizeOfCurrentPage,
      };
    case GET_REPORT_LOGS_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default getReports_reducer;
