import { initialState } from "../../initialState";
import {
    GET_ORDERS_FAILURE,
    GET_ORDERS_SUCCESS,

} from "../../arsVariables";

const getOrders_reducer = (state = initialState, action) => {
    const { type,  payloadReturned, totalCount } = action;
    switch (type) {
        case GET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: payloadReturned,
                total: totalCount
            };
        case GET_ORDERS_FAILURE:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default getOrders_reducer;
