import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const BackButton = ({ backUrl, backFunction }) => {
    return (
        backUrl ? <Link
            to={backUrl}
            style={{ backgroundColor: "#00425F", width: '100px' }}
            className="isw-btn isw-btn--raised  text-white float-md-right"
        >
            <span>Back</span>
        </Link> :
            <button style={{ backgroundColor: "#00425F", width: '100px' }}
                className="isw-btn isw-btn--raised  text-white float-md-right"
                onClick={backFunction}>
                Back
            </button>

    );
}

BackButton.propTypes = {
    backUrl: PropTypes.string,
    backFunction: PropTypes.func
}