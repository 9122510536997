import { baseurl } from "./authServices";
import { getFunc, postFunc } from "./httpService";

// get roles
export const rolesService = () => {
    return getFunc(`${baseurl}Roles/GetRoles`);
};

// get system permissions
export const permissionsService = () => {
    return getFunc(`${baseurl}Roles/GetPermissions`);
};

// get user permissions
export const getUserPermissionsService = ({ payload }) => {
    return getFunc(
        `${baseurl}Roles/GetUserPermissions`,

        payload
    );
};

// update user permissions
export const updateUserPermissionsService = ({ payload }) => {
    return postFunc(`${baseurl}Roles/UpdateUserPermissions`, payload);
};

// batch update user permissions
export const batchUpdateUserPermissionsService = ({ payload }) => {
    return postFunc(`${baseurl}Roles/BatchUpdateUserPermissions`, payload);
};
