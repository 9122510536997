import {
  SIGNUP_ONBOARD_SUCCESS,
  SIGNUP_ONBOARD_FAILURE,
} from "../../../redux-flow/arsVariables";
import { initialState } from "../../initialState";
const { localStorage } = window;

const signup_onboard_reducer = (state = initialState, action) => {
  // console.log("")
  const { type, email } = action;

  switch (type) {
    case SIGNUP_ONBOARD_SUCCESS:
      return {
        ...state,
        email: email,
      };
    case SIGNUP_ONBOARD_FAILURE:
      return {
        ...state,
        signUpError: true,
      };
    default:
      return state;
  }
};

export default signup_onboard_reducer;
