import { initialState } from "../../initialState";
import {
    GET_VENDOR_CONTRACTS_FAILURE,
    GET_VENDOR_CONTRACTS_SUCCESS,
   
} from "../../arsVariables";

const getVendorContracts_reducer = (state = initialState, action) => {
    const { type, vendorContracts, totalContracts } = action;
    switch (type) {
        case GET_VENDOR_CONTRACTS_SUCCESS:
            return {
                ...state,
                vendorContracts: vendorContracts,
                totalContracts: totalContracts
            };
        case GET_VENDOR_CONTRACTS_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getVendorContracts_reducer;
