import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import Sidebar from "../reuse/sidebar";
import { Spin, Icon } from "antd";
import { formatMoney } from "../reuse/currencyHelper";
import Graph from "../reuse/graph";
import DatePicker, {
    currentDate,
    pastDate,
    currentDate2
} from "../reuse/datePicker";
import BaseComponent from "../reuse/baseComponent";
import MenuBar from "../reuse/MenuBar";
const antIcon = <Icon type="loading" style={{ fontSize: 16 }} spin />;

class DashboardChecker extends BaseComponent {
    state = {
        actType: null,
        pageIndex: 1,
        pageSize: 10,
        activeBtnId: null,
        orderId: null,
        to: pastDate,
        from: currentDate2
    };

    componentDidMount() {
        const { dispatch } = this.props;

        const { pageIndex, pageSize, from, to } = this.state;

        dispatch(Actions.uiStart());
        dispatch(
            Actions.getOrders({
                pageIndex,
                pageSize,
                status: 1
            })
        );

        dispatch(
            Actions.getOrderItems({
                pageIndex,
                pageSize,
                status: 2
            })
        );
        this.fetchByDate({
            from,
            to
        });
    }

    fetchByDate = ({ from, to }) => {
        const { dispatch } = this.props;

        dispatch(Actions.uiStart());
        dispatch(
            Actions.getCustomerOrderStats({
                from,
                to
            })
        );
        dispatch(Actions.getCustomerProfileStats());
    };

    _reactToOrder = (e, orderId, type) => {
        e.preventDefault();
        const { dispatch } = this.props;
        const payloadToSend = {
            orderId: orderId,
            approve: type === "YES" ? true : false,
            note:
                type === "YES"
                    ? `Approved on ${currentDate}`
                    : `Declined on ${currentDate}`
        };
        this.setState({
            actType: type,
            activeBtnId: orderId
        });
        dispatch(Actions.uiStart());
        dispatch(Actions.reactToOrder(payloadToSend));
    };

    dateExtractor = ({ past, today }) => {
        this.setState(
            {
                from: past,
                to: today
            },
            () =>
                this.fetchByDate({
                    from: past,
                    to: today
                })
        );
    };

    render() {
        const { actType, activeBtnId, past, today } = this.state;

        const {

            orderItems = [],

            orderStatistics,
            orders,
            profileStatistics
        } = this.props;
        const { loading, isLoading, status, toggleModal } = this.context;
        const {
            orderCount,
            orderQuantity,
            cancelledOrderCount,
            cancelledOrderQuantity,
            orderAmount,
            cancelledOrderAmount
        } = orderStatistics;

        const { activeLocationCount, activeProfileCount } = profileStatistics;
        return (
            <Fragment>
                <Sidebar
                    search={this.state.SearchKeyword}
                    status={status}
                    toggleModal={toggleModal}
                />
                <div className="isw-mainLayout1">
                    <Spin tip="Please wait..." spinning={loading}>
                        <div className="isw-content--wrapper">
                            <MenuBar />
                            <div>
                                <form className="content" id="content-body">
                                    <div className="container-fluid container-limited">
                                        {/* <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb bg-transparent px-0">
                                                <li className="breadcrumb-item">
                                                    <h1>Dashboard</h1>
                                                </li>
                                            </ol>
                                        </nav> */}

                                        <DatePicker
                                            dateExtractor={this.dateExtractor}
                                        />

                                        <div className="row">
                                            <div
                                                className="col-12 mb-4"
                                                style={{
                                                    minHeight: `${386}px`
                                                }}>
                                                <div className="card h-100 shadow-sm border-0 graph-container">
                                                    <div className="isw-card-header p-3">
                                                        <h3 className="isw-card-h3">
                                                            Total Product
                                                            Purchased
                                                        </h3>
                                                        <div className="isw-card-p">
                                                            By location
                                                        </div>
                                                    </div>

                                                    <Graph
                                                        from={past}
                                                        to={today}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-4 mb-3">
                                                <div className="row">
                                                    {this.renderDashboardCard1({
                                                        header:
                                                            "Orders Fulfilled",

                                                        count: orderCount,
                                                        amount: orderAmount,
                                                        quantity: orderQuantity
                                                    })}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="row">
                                                    {this.renderDashboardCard1({
                                                        header:
                                                            "Cancelled Orders",

                                                        count: cancelledOrderCount,
                                                        amount: cancelledOrderAmount,
                                                        quantity: cancelledOrderQuantity
                                                    })}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mb-3">
                                                <div className="row mb-4">
                                                    {this.renderDashboardCard2({
                                                        icon:
                                                            "location_searching",
                                                        amount: activeLocationCount,
                                                        label:
                                                            "Active Locations"
                                                    })}
                                                    {this.renderDashboardCard2({
                                                        icon: "people",
                                                        amount: activeProfileCount,
                                                        label: "Active Users"
                                                    })}
                                                </div>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="isw-card h-100 shadow-sm border-0">
                                                    <div
                                                        className="isw-card-header p-3"
                                                        style={{ zIndex: 10 }}>
                                                        <h3 className="isw-card-h3">
                                                            Order{" "}
                                                            <Link
                                                                to={{
                                                                    pathname:
                                                                        "/customer/dashboard/awaiting-fulfillment"
                                                                }}
                                                                className="ml-4"
                                                                style={{
                                                                    color:
                                                                        "#00425f",
                                                                    cursor:
                                                                        "pointer"
                                                                }}>
                                                                View all
                                                            </Link>
                                                        </h3>
                                                        <div className="isw-card-p">
                                                            Orders awaiting
                                                            fulfilment
                                                        </div>
                                                    </div>
                                                    <div className="card-body isw-card-body-height">
                                                        <div className="isw-card-body-overflow">
                                                            {orderItems.length ===
                                                                0 ? (
                                                                <span className="nothing-dashboard">
                                                                    No order(s)
                                                                    pending on
                                                                    vendor
                                                                </span>
                                                            ) : (
                                                                <ul>
                                                                    {orderItems.map(
                                                                        order => {
                                                                            const {
                                                                                locationAddress,
                                                                                sum,
                                                                                orderId,
                                                                                id,
                                                                                productName,

                                                                                status
                                                                            } = order;
                                                                            return (
                                                                                <li
                                                                                    className="isw-card-admin-list kam"
                                                                                    key={
                                                                                        id
                                                                                    }>
                                                                                    <p className="isw-p mb-2">
                                                                                        {`Location: ${locationAddress}`}
                                                                                    </p>
                                                                                    <p className="isw-p mb-2">
                                                                                        {`Order ID: ${orderId}`}
                                                                                    </p>
                                                                                    <p className="isw-p mb-2">
                                                                                        {`Product Name: ${productName}`}
                                                                                    </p>
                                                                                    <p className="isw-p mb-2">
                                                                                        {`Amount: `}
                                                                                        {formatMoney(
                                                                                            sum
                                                                                        )}
                                                                                    </p>
                                                                                    <div className="d-inline">
                                                                                        <div
                                                                                            className={`isw-status--box-${status ===
                                                                                                0
                                                                                                ? "pending"
                                                                                                : status ===
                                                                                                    1 ||
                                                                                                    status ===
                                                                                                    2 ||
                                                                                                    status ===
                                                                                                    3
                                                                                                    ? "info"
                                                                                                    : status ===
                                                                                                        6
                                                                                                        ? "success"
                                                                                                        : status ===
                                                                                                            4 ||
                                                                                                            status ===
                                                                                                            5
                                                                                                            ? "failed"
                                                                                                            : null
                                                                                                }`}>
                                                                                            <span>
                                                                                                {status ===
                                                                                                    0
                                                                                                    ? "Pending"
                                                                                                    : status ===
                                                                                                        1 ||
                                                                                                        2
                                                                                                        ? "Admin Approved"
                                                                                                        : status ===
                                                                                                            3
                                                                                                            ? "Vendor Approved"
                                                                                                            : status ===
                                                                                                                4
                                                                                                                ? "Admin Cancelled"
                                                                                                                : status ===
                                                                                                                    5
                                                                                                                    ? "Vendor Cancelled"
                                                                                                                    : "Complete"}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <div className="isw-card h-100 shadow-sm border-0">
                                                    <div
                                                        className="isw-card-header p-3"
                                                        style={{ zIndex: 10 }}>
                                                        <h3 className="isw-card-h3">
                                                            Pending Requests{" "}
                                                            <Link
                                                                to={{
                                                                    pathname:
                                                                        "/customer/dashboard/pending-requests"
                                                                }}
                                                                className="ml-4"
                                                                style={{
                                                                    color:
                                                                        "#00425f",
                                                                    cursor:
                                                                        "pointer"
                                                                }}>
                                                                View all
                                                            </Link>
                                                        </h3>
                                                        <div className="isw-card-p">
                                                            Created by admin
                                                        </div>
                                                    </div>
                                                    <div className="card-body isw-card-body-height">
                                                        <div className="isw-card-body-overflow">
                                                            {orders.length ===
                                                                0 ? (
                                                                <span className="nothing-dashboard">
                                                                    No order(s)
                                                                    created by
                                                                    admin
                                                                </span>
                                                            ) : (
                                                                <ul>
                                                                    {orders
                                                                        ? orders.map(
                                                                            order => {
                                                                                const {
                                                                                    orderNo,
                                                                                    id: orderId,
                                                                                    itemCount,
                                                                                    sum
                                                                                } = order;
                                                                                return (
                                                                                    <li
                                                                                        className="isw-card-admin-list kam"
                                                                                        key={
                                                                                            orderNo
                                                                                        }>
                                                                                        <span>
                                                                                            {`Order_${orderNo} - ${itemCount} item(s)`}
                                                                                        </span>

                                                                                        <p className="isw-p mb-2 d-flx">
                                                                                            {`Amount - `}
                                                                                            <span
                                                                                                style={{
                                                                                                    width: `${4}px`
                                                                                                }}
                                                                                            />
                                                                                            {formatMoney(
                                                                                                sum
                                                                                            )}
                                                                                        </p>
                                                                                        <div className="d-inline">
                                                                                            {isLoading &&
                                                                                                actType ===
                                                                                                "YES" &&
                                                                                                activeBtnId ===
                                                                                                orderId ? (
                                                                                                <button
                                                                                                    style={{
                                                                                                        marginRight: `${20}px`
                                                                                                    }}
                                                                                                    className="isw-btn isw-btn--raised bg-success text-white "
                                                                                                    disabled>
                                                                                                    {
                                                                                                        antIcon
                                                                                                    }
                                                                                                </button>
                                                                                            ) : (
                                                                                                <button
                                                                                                    style={{
                                                                                                        marginRight: `${20}px`
                                                                                                    }}
                                                                                                    className="isw-btn isw-btn--raised bg-success text-white"
                                                                                                    onClick={e =>
                                                                                                        this._reactToOrder(
                                                                                                            e,
                                                                                                            orderId,
                                                                                                            "YES"
                                                                                                        )
                                                                                                    }>
                                                                                                    <span>
                                                                                                        Approve
                                                                                                    </span>
                                                                                                </button>
                                                                                            )}
                                                                                            {isLoading &&
                                                                                                actType ===
                                                                                                "NO" &&
                                                                                                activeBtnId ===
                                                                                                orderId ? (
                                                                                                <button
                                                                                                    className="isw-btn isw-btn--raised bg-danger text-white dot"
                                                                                                    disabled>
                                                                                                    {
                                                                                                        antIcon
                                                                                                    }
                                                                                                </button>
                                                                                            ) : (
                                                                                                <button
                                                                                                    className="isw-btn isw-btn--raised bg-danger text-white"
                                                                                                    onClick={e =>
                                                                                                        this._reactToOrder(
                                                                                                            e,
                                                                                                            orderId,
                                                                                                            "NO"
                                                                                                        )
                                                                                                    }>
                                                                                                    <span>
                                                                                                        Decline
                                                                                                    </span>
                                                                                                </button>
                                                                                            )}
                                                                                        </div>
                                                                                    </li>
                                                                                );
                                                                            }
                                                                        )
                                                                        : null}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Spin>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({
    getOrders_reducer,
    getOrderItems_reducer,
    getVendorContracts_reducer,
    getCustomerOrderStats_reducer,
    getCustomerProfileStats_reducer
}) => {
    const { orders } = getOrders_reducer;
    const { orderItems, total: totalOrderItems } = getOrderItems_reducer;
    const { vendorContracts } = getVendorContracts_reducer;
    const { orderStatistics } = getCustomerOrderStats_reducer;

    const { profileStatistics } = getCustomerProfileStats_reducer;
    return {
        orderItems,
        totalOrderItems,
        orders,

        vendorContracts,

        // stats
        orderStatistics,
        profileStatistics
    };
};

export default connect(mapStateToProps)(DashboardChecker);
