import { baseurl, qs, reportUrl } from "./authServices";
import { getFunc, delFunc, postFunc, getFuncForDownload } from "./httpService";

// create customer user
export const createUserService = ({ payload }) => {
  return postFunc(`${baseurl}userprofiling/createcustomeruser`, payload);
};

// get basic profile info of customer user
export const getBasicInfoService = () => {
  return getFunc(`${baseurl}Auth/GetBasicProfile`);
};

// edit basic profile info of customer user
export const editBasicInfoService = ({ payload }) => {
  return postFunc(`${baseurl}userprofiling/editprofile`, payload);
};

// create location
export const createLocationService = ({ payload }) => {
  return postFunc(`${baseurl}customer/createlocation`, payload);
};

// get location
export const getLocationService = ({ payload }) => {
  return getFunc(
    `${baseurl}Customer/GetLocation?${qs.stringify({
      locationId: payload.id
    })}`
  );
};
// get locations
export const getLocationsService = ({ payload }) => {
  return getFunc(
    `${baseurl}Customer/${payload.userId ? "GetUserLocations" : "GetLocations"
    }?${qs.stringify(payload)}`
  );
};

// get pending locations
export const getPendingBulkUploadsService = ({ payload }) => {
  return getFunc(
    `${baseurl}requests/getcontents?${qs.stringify(payload)}`
  );
};

// bulk upload location
export const bulkUploadLocationService = ({ payload }) => {
  return postFunc(`${baseurl}customer/uploadlocations`, payload);
};

// approving and reject bulk
export const verifyPendingBulkUploadsService = ({ payload }) => {
  return postFunc(`${baseurl}requests/${payload.type}/${payload.id}`);
};

// get user locations
export const getUserLocationsService = ({ payload }) => {
  return getFunc(
    `${baseurl}Customer/GetUserLocations?${qs.stringify(payload)}`
  );
};

// get user
export const getUserService = ({ payload }) => {
  return getFunc(
    `${baseurl}userprofiling/getprofile?${qs.stringify(payload)}`
  );
};

// get users
export const getUsersService = ({ payload }) => {
  return getFunc(`${baseurl}customer/getusers?${qs.stringify(payload)}`);
};

// get customers
export const getCustomersService = ({ payload }) => {
  return getFunc(`${baseurl}customer/getcustomers?${qs.stringify(payload)}`);
};

// delete user from location
export const deleteUserFromLocationService = ({ payload }) => {
  return delFunc(
    `${baseurl}Customer/DeleteLocationUser?${qs.stringify(payload)}`
  );
};
// add user to locations
export const addUserToLocationsService = ({ payload }) => {
  return postFunc(`${baseurl}Customer/AddUserToLocations`, payload);
};

// add users to a location
export const addUsersToLocationService = ({ payload }) => {
  return postFunc(`${baseurl}Customer/AddUsersToLocation`, payload);
};

// get users in a location
export const getUsersInLocationService = ({ payload }) => {
  return getFunc(
    `${baseurl}Customer/GetUsersInLocation?${qs.stringify(payload)}`
  );
};

// change user role
export const changeUserRoleService = ({ payload }) => {
  return postFunc(`${baseurl}Customer/ChangeUserRoleByAdmin`, payload);
};

// bulk upload users
export const bulkUploadService = ({ payload }) => {
  return postFunc(`${baseurl}UserProfiling/UploadCustomer`, payload);
};

// get report logs
export const getReportsServiceHO = ({ payload }) => {
  return getFunc(`${reportUrl}v1/report/customers/order/head-office?${qs.stringify(payload)}`, payload);
};

// get report downloads PDF ADMIN
export const getReportsDownloadPDFServiceHO = ({ payload }) => {
  return getFuncForDownload(`${reportUrl}v1/report/customers/head_office/order/generate/pdf?${qs.stringify(payload)}`);
};

// get report downloads Excel ADMIN
export const getReportsDownloadExcelServiceHO = ({ payload }) => {
  return getFuncForDownload(`${reportUrl}v1/report/customers/head_office/order/generate/excel?${qs.stringify(payload)}`);
};

// get report logs USER
// https://uber4diesel-report-service.k8.isw.la/uber4diesel/api/v1/report/customers/user/order/generate/pdf
export const getReportsServiceUSER = ({ payload }) => {
  return getFunc(`${reportUrl}v1/report/customers/order/user?${qs.stringify(payload)}`, payload);
};

// get report downloads PDF USER
export const getReportsDownloadPDFServiceUSER = ({ payload }) => {
  return getFuncForDownload(`${reportUrl}v1/report/customers/user/order/generate/pdf?${qs.stringify(payload)}`);
};

// get report downloads Excel USER
export const getReportsDownloadExcelServiceUSER = ({ payload }) => {
  return getFuncForDownload(`${reportUrl}v1/report/customers/user/order/generate/excel?${qs.stringify(payload)}`);
};

//audit

export const getAuditLogService = ({ payload }) => {
  return getFunc(`${baseurl}customer/getauditlogs?${qs.stringify(payload)}`);
}

export const downloadAuditLogService = ({ payload }) => {
  return getFuncForDownload(`${baseurl}customer/getauditlogs?${qs.stringify(payload)}`);
}