import { initialState } from "../initialState";
import {
    GET_CONTRACT_LIST_SUCCESS,
    GET_CONTRACT_LIST_FAILURE,
    GET_CONTRACT_DETAILS_SUCCESS,
    GET_CONTRACT_DETAILS_FAILURE,
    GET_CONTRACT_HISTORY_SUCCESS,
    GET_CONTRACT_HISTORY_FAILURE
} from "../arsVariables";

const getContractList_reducer = (state = initialState, action) => {
    const { type, contractList, totalCount } = action;
    switch (type) {
        case GET_CONTRACT_LIST_SUCCESS:
            return {
                ...state,
                contractList,
                totalCount
            };
        case GET_CONTRACT_LIST_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export const getContractDetail_reducer = (state = initialState, action) => {
    const { type, contractDetail } = action;

    switch (type) {
        case GET_CONTRACT_DETAILS_SUCCESS:
            return {
                ...state,
                contractDetail
            };
        case GET_CONTRACT_DETAILS_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
}

export const getContractHistory_reducer = (state = initialState, action) => {
    const { type, contractHistory, totalCount } = action;
    switch (type) {
        case GET_CONTRACT_HISTORY_SUCCESS:
            return {
                ...state,
                contractHistory,
                totalCount
            };
        case GET_CONTRACT_HISTORY_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
}

export default getContractList_reducer;
