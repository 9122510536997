import { getFunc, postFunc } from "./httpService";

export const qs = require("qs");
export const baseurl = window.env.BASE_AUTH_URL;
const client_id = window.env.CUSTOMER_CLIENT_ID;
const client_secret = window.env.CUSTOMER_CLIENT_KEY;

// Reports
export const reportUrl = window.env.BASE_REPORT_URL;

// Signup User
export const signupService = (payload) => {
  const { customerAdmin, customerInfo } = payload;

  const objectToSend = { customerAdmin, customerInfo };

  return postFunc(`${baseurl}userprofiling/createcustomer`, objectToSend);
};

// Login User
export const loginService = ({ payload }) => {
  const { email, password, code_verifier } = payload;

  const objectToSend = qs.stringify({
    username: email,
    password,
    code_verifier,
    grant_type: "password",
    client_id,
    client_secret,
    scope: "openid profile email roles offline_access",
  });
  return postFunc(`${baseurl}Auth/Token`, objectToSend);
};

// send OTP
export const OTPService = (payload) => {
  return postFunc(`${baseurl}Auth/SendOTP`, payload);
};

// verify profile
export const verifyProfileService = ({ payload }) => {
  return postFunc(`${baseurl}Auth/VerifyProfile`, payload);
};

// refresh Token
export const refreshTokenService = (token) => {
  const objectToSend = qs.stringify({
    grant_type: "refresh_token",
    client_id,
    client_secret,
    refresh_token: token,
  });

  return postFunc(`${baseurl}auth/token`, objectToSend);
};

// reset password
export const resetPasswordService = ({ payload }) => {
  return postFunc(`${baseurl}Auth/ResetPasssword`, payload);
};

// change password
export const changePasswordService = ({ payload }) => {
  const { currentPW, password, rtPassword } = payload;
  const objectToSend = {
    currentPassword: currentPW,
    newPassword: password,
    confirmPassword: rtPassword,
  };
  return postFunc(`${baseurl}Auth/ChangePassword`, objectToSend);
};

// forgot password
export const forgotPasswordService = ({ payload }) => {
  return getFunc(`${baseurl}Auth/ForgotPassword?${qs.stringify(payload)}`);
};
