import {
    GET_PERMISSIONS_FAILURE,
    GET_PERMISSIONS_SUCCESS
} from "../../../../redux-flow/arsVariables";
import { initialState } from "../../../initialState";

const permissions_reducer = (state = initialState, action) => {
    const { type,  payloadReturned } = action;
    switch (type) {
        case GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: payloadReturned
            };
        case GET_PERMISSIONS_FAILURE:
            return {
                ...state,
            };


        default:
            return state;
    }
};

export default permissions_reducer;
