import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../services/services";
import {
    GET_ORDER_HISTORY_FAILURE,
    GET_ORDER_HISTORY_SUCCESS,
    GET_ORDER_HISTORY
} from "../arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* getOrderHistory(payload) {
    try {
        const { error, data, status } = yield call(
            Services.getOrderHistoryService,
            payload
        );
        const {
            code,
            description,
            payload: payloadReturned,
            totalCount
        } = data;
        if (error === false && status === 200 && code === 1) {
            return yield put({
                type: GET_ORDER_HISTORY_SUCCESS,
                message: description,
                payload: payloadReturned,
                total: totalCount
            });
        } else if (error === false && status === 200) {
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_ORDER_HISTORY_FAILURE
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_ORDER_HISTORY_FAILURE
        });
    }
}

export default function* getOrderHistorySaga() {
    yield takeLatest(GET_ORDER_HISTORY, getOrderHistory);
}
