import {
  GET_REPORT_LOGS,
  GET_REPORT_LOGS_PDF_DOWNLOAD,
  GET_REPORT_LOGS_EXCEL_DOWNLOAD,
} from "../arsVariables";

const getReports = (payload) => ({
  type: GET_REPORT_LOGS,
  payload,
});

const getReportsPDFDownloads = (payload) => ({
  type: GET_REPORT_LOGS_PDF_DOWNLOAD,
  payload,
});

const getReportsExcelDownloads = (payload) => ({
  type: GET_REPORT_LOGS_EXCEL_DOWNLOAD,
  payload,
});

const getReportsActions = {
  getReports,
  getReportsPDFDownloads,
  getReportsExcelDownloads,
};

export default getReportsActions;
