import {/* call, */ put, takeLatest } from "redux-saga/effects";
// import Services from "../../redux-flow/services/services";
import { encryptAndStore } from "../../redux-flow/services/localStorageHelper";
import createGuest from "cross-domain-storage/guest";
import CryptoJS from "crypto-js";

import {
  LOGIN_SUCCESS,
  // LOGIN_FAILURE,
  LOGIN_USER,
  ENCRYPT_USER,
  CUSTOMER_ADMIN,
  CUSTOMER_USER,
  // EMAIL_NOT_CONFIRMED
} from "../arsVariables";

import jwtDecode from "jwt-decode";
// import { Notify } from "../../reuse/notify";
import { history } from "../../reuse/history";
import { Actions } from "../actions/_index";
import { url, FRONT_SSO_URL } from "../../helper";

function* loginUser(payload) {
  // const email = payload.payload.email;
  // try {
  yield put(Actions.uiStart());
  console.log('FRONT_SSO_URL', FRONT_SSO_URL);
  createGuest(FRONT_SSO_URL).get(ENCRYPT_USER, (error, value) => {
    if (value) {
      console.log('Inside value', value);
      // const returnedData = yield call(Services.loginService, payload);
      // const { error, status, data } = returnedData;
      // const { access_token, code, description, ...rest } = data;
      const bytes = CryptoJS.AES.decrypt(value.toString(), "smartware.spa");
      const response = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // console.log(response); 

      if (!response) {
        history.push(`${url}denied`); //no access
      } else if (
        response.modules.includes("QUICKTELLER_FUEL") &&
        [CUSTOMER_ADMIN, CUSTOMER_USER].includes(response.type)
      ) {
        const {
          access_token,
          type: role,
          userEmail: email,
          firstName,
          lastName,
          id,
          sub,
          /*  code, description, */ ...rest
        } = response;

        window.localStorage.setItem("customerId", response.userTypeId)

        const decoded = {
          ...jwtDecode(response.access_token),
          role,
          given_name: `${firstName} ${lastName}`,
          name: email,
          sub: sub || id,
          // permissions: []// TODO
        };

        // const userRole = decoded.role;
        // yield put(Actions.uiStop());
        // if (
        // error === false &&
        // status === 200 &&
        //     (userRole === CUSTOMER_ADMIN || userRole === CUSTOMER_USER)
        // ) {

        setTimeout(() => {
          localStorage.removeItem("email");
          localStorage.removeItem("phone");
        }, 3990);

        encryptAndStore(
          ENCRYPT_USER,
          { access_token, url, decoded, ...rest },
          true
        );

        put({
          type: LOGIN_SUCCESS,
          role,
          decodedData: decoded,
        });

        setTimeout(() => {
          history.push("/customer/dashboard");
        }, 1000);
        // } else if (error === false && status === 200) {
        // Notify({
        //     message:
        //         userRole !== CUSTOMER_ADMIN || userRole !== CUSTOMER_USER
        //             ? `UNAUTHORIZED CLIENT`
        //             : description,
        //     className: "error"
        // });
        // return yield put({
        //     type: LOGIN_FAILURE
        // });
        // }
      } else {
        // history.push(`${url}denied`); //no access
        window.location.href = `${FRONT_SSO_URL}?d=isw`;//sso-page
      }
    } else {
      window.location.href = `${FRONT_SSO_URL}/signin?r=isw-customer`;
    }
  });

  // } catch (err) {
  //     Notify({
  //         message: err.response.response
  //             ? err.response.response.data.error_description
  //             : err.response.message,
  //         className: "error"
  //     });
  //     if (
  //         err.response.response &&
  //         err.response.response.data.state === EMAIL_NOT_CONFIRMED
  //     ) {
  //         setTimeout(() => {
  //             history.push({
  //                 pathname: `${url}verify-profile`,
  //                 state: { email }
  //             });
  //         }, 1500);
  //     }
  //     yield put(Actions.uiStop());
  //     return yield put({ type: LOGIN_FAILURE });
  // }
}

export default function* loginUserSaga() {
  yield takeLatest(LOGIN_USER, loginUser);
}
