import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./signup.css";
import { useDispatch, useSelector } from "react-redux";

import { RightSideLogo } from "../../reuse/svgIcons";
import { Actions } from "../../redux-flow/actions/_index";
import { FRONT_SSO_URL } from "../../App";
export const url = "/customer/";

const VeifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");
  const token = queryParams.get("token");
  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      userId,
      token,
    };
    dispatch(Actions.verifyUserOnboard(payload));
  }, [userId, token]);
  return (
    <div className="isw-login">
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div
            className="isw-login--middle"
            style={{
              overflowY: "auto",
              displaY: "flex",
              // justifyContent: "center",
            }}
          >
            <div className="email-verify">
              <header className="verify-header">
                <RightSideLogo />
              </header>
              <main className="email-verify-wrapper">
                <h1 className="text-Email-large">
                  Your email has been verified successfully!
                </h1>
                <h3 className="text-Email-small">Login to continue</h3>
                <p className="text-Email-faint verify">
                  You now have access to your newly created account, kindly
                  login with <br /> your credentials to continue.
                </p>
                <button
                  className="verify-button"
                  onClick={() =>
                    (window.location.href =
                      FRONT_SSO_URL + "/quick-energy/signin")
                  }
                >
                  Login to your account
                </button>
              </main>
              <footer className="verify-footer">
                <p>Having issues?</p>
                <a href="">Contact Support</a>
              </footer>
            </div>
            )
          </div>
        </div>
      </div>
    </div>
  );
};

export default VeifyEmail;
