/*
this houses every variable used in
- Actions

- Reducers

- Sagas

thus `${ars}Variables` 😄
*/
// testing variables
// import faker from "faker";
// import qs from "qs";
// export { faker };
// export const genericPassword = "Ezemmuo1";
// export const baseurlForTest =
//     process.env.BASE_AUTH_URL || "https://feulapp.azurewebsites.net/api/";
// export const orderUrl =
//     process.env.BASE_ORDER_URL || "https://feulorder.azurewebsites.net/api/";
// export const queryString = args =>
//     qs.stringify({ pageIndex: 1, pageSize: 10, ...args });
// GENERIC variables

export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const RESET = "RESET";
export const API_KEY = "AIzaSyDeuD95hbs39ZsZPLS0vICN4tbezVCK0Kg";
export const EXPIRY = "EXPIRY";
export const ENCRYPT_USER = "USER";
export const SUPER_ADMIN = "SUPER_ADMIN";
export const VENDOR_ADMIN = "VENDOR_ADMIN";
export const CUSTOMER_ADMIN = "CUSTOMER_ADMIN";
export const CUSTOMER_USER = "CUSTOMER_USER";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const COC = "CREATE_ORDER_COMPONENT";
export const EMAIL_NOT_CONFIRMED = "EMAIL_NOT_CONFIRMED";
export const NOT_ACTIVATED = "NOT_ACTIVATED";

// login variables
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_USER = "LOGIN_USER";

// logout variables
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";

// storage variables
export const READ_SUCCESS = "READ_SUCCESS";
export const READ_FAILURE = "READ_FAILURE";
export const READ_STORAGE = "READ_STORAGE";
export const READ_EXPIRED = "READ_EXPIRED";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

// signup variables
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_USER = "SIGNUP_USER";

//Onboard
// signup variables
export const SIGNUP_ONBOARD_SUCCESS = "SIGNUP_ONBOARD_SUCCESS";
export const SIGNUP_ONBOARD_FAILURE = "SIGNUP_ONBOARD_FAILURE";
export const SIGNUP_ONBOARD_USER = "SIGNUP_ONBOARD_USER";

// signup variables
export const VERIFYEMAIL_ONBOARD_SUCCESS = "VERIFYEMAIL_ONBOARD_SUCCESS";
export const VERIFYEMAIL_ONBOARD_FAILURE = "VERIFYEMAIL_ONBOARD_FAILURE";
export const VERIFYEMAIL_ONBOARD_USER = "VERIFYEMAIL_ONBOARD_USER";

// reset variables
export const PW_RESET = "PW_RESET";
export const PW_SUCCESS = "PW_RESET_SUCCESS";
export const PW_FAILURE = "PW_RESET_FAILURE";

// forgotPassword variables
export const FORGOT_RESET = "FORGOT_RESET";
export const FORGOT_SUCCESS = "FORGOT_RESET_SUCCESS";
export const FORGOT_FAILURE = "FORGOT_RESET_FAILURE";

// OTP variables
export const RESEND_OTP = "RESEND_OTP";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";

// verifyProfile varibales
export const VERIFY_PROFILE = "VERIFY_PROFILE";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILURE = "VERIFY_FAILURE";

// change password variables
export const CHANGE_FAILURE = "CHANGE_FAILURE";
export const CHANGE_SUCCESS = "CHANGE_SUCCESS";
export const CHANGE_PW = "CHANGE_PW";

// create customer user variables
export const CREATE_USER = "CREATE_CUSTOMER_USER";

// bulk upload customer user variables
export const BULK_UPLOAD = "BULK_UPLOAD";

// create customer location variables
export const CREATE_LOC_USER = "CREATE_LOCATION_USER";

// edit profile basic info variables
export const GET_BASIC_INFO = "GET_BASIC_INFO";
export const GET_BASIC_SUCCESS = "GET_BASIC_SUCCESS";
export const GET_BASIC_FAILURE = "GET_BASIC_FAILURE";

// edit profile basic info variables
export const EDIT_BASIC_INFO = "EDIT_BASIC_INFO";
export const EDIT_BASIC_SUCCESS = "EDIT_BASIC_SUCCESS";
export const EDIT_BASIC_FAILURE = "EDIT_BASIC_FAILURE";

// get location variables
export const GET_LOCATION = "GET_LOCATION";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_FAILURE = "GET_LOCATION_FAILURE";

// get locations variables
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_FAILURE = "GET_LOCATIONS_FAILURE";

export const GET_PENDING_UPLOADS = "GET_PENDING_UPLOADS";
export const GET_PENDING_UPLOADS_SUCCESS = "GET_PENDING_UPLOADS_SUCCESS";
export const GET_PENDING_UPLOADS_FAILURE = "GET_PENDING_UPLOADS_FAILURE";

// get users variables
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

// get users created by the CUSTOMER ADMIN variables
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

// get users in location
export const GET_USERS_IN_LOCATION = "GET_USERS_IN_LOCATION";
export const GET_USERS_IN_LOCATION_SUCCESS = "GET_USERS_IN_LOCATION_SUCCESS";
export const GET_USERS_IN_LOCATION_FAILURE = "GET_USERS_IN_LOCATION_FAILURE";

// get users created by the CUSTOMER ADMIN variables
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

// add user to locations variables
export const ADD_USER_2_LOCATIONS = "ADD_USER_2_LOCATIONS";

// add users to location variables
export const ADD_USERS_2_LOCATION = "ADD_USERS_2_LOCATION";

// toggle user status variables
export const TOGGLE_USER_STATUS = "TOGGLE_USER_STATUS";
export const TOGGLE_USER_STATUS_SUCCESS = "TOGGLE_USER_STATUS_SUCCESS";
export const TOGGLE_USER_STATUS_FAILURE = "TOGGLE_USER_STATUS_FAILURE";

// DELETE user variables
export const TOGGLE_LOCATION_STATUS = "TOGGLE_LOCATION_STATUS";
export const TOGGLE_LOCATION_STATUS_SUCCESS = "TOGGLE_LOCATION_STATUS_SUCCESS";
export const TOGGLE_LOCATION_STATUS_FAILURE = "TOGGLE_LOCATION_STATUS_FAILURE";

/**
 *
 * PERMISSIONs and ROLES variables BELOW
 *
 * */

// GET permissions
// get permissions
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAILURE = "GET_PERMISSIONS_FAILURE";

// get remove user permissions
export const GET_RM_USER_PERMISSIONS = "GET_REMOVE_USER_PERMISSIONS";
export const GET_RM_USER_PERMISSIONS_SUCCESS =
  "GET_REMOVE_USER_PERMISSIONS_SUCCESS";
export const GET_RM_USER_PERMISSIONS_FAILURE =
  "GET_REMOVE_USER_PERMISSIONS_FAILURE";

// get roles
export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

// get user permissions
export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const GET_USER_PERMISSIONS_SUCCESS = "GET_USER_PERMISSIONS_SUCCESS";
export const GET_USER_PERMISSIONS_FAILURE = "GET_USER_PERMISSIONS_FAILURE";

// POST permissions
// batch update user permissions
export const BATCH_UU_PERMISSIONS = "BATCH_UPDATE_USER_PERMISSIONS";
export const BATCH_UU_PERMISSIONS_SUCCESS =
  "BATCH_UPDATE_USER_PERMISSIONS_SUCCESS";
export const BATCH_UU_PERMISSIONS_FAILURE =
  "BATCH_UPDATE_USER_PERMISSIONS_FAILURE";

// change user role
export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE";

// remove user permission
export const RM_USER_PERMISSION = "REMOVE_USER_PERMISSION";
export const RM_USER_PERMISSION_SUCCESS = "REMOVE_USER_PERMISSION_SUCCESS";
export const RM_USER_PERMISSION_FAILURE = "REMOVE_USER_PERMISSION_FAILURE";

// remove user permissions
export const RM_USER_PERMISSIONS = "REMOVE_USER_PERMISSIONS";
export const RM_USER_PERMISSIONS_SUCCESS = "REMOVE_USER_PERMISSIONS_SUCCESS";
export const RM_USER_PERMISSIONS_FAILURE = "REMOVE_USER_PERMISSIONS_FAILURE";

// update user permissions
export const UU_PERMISSIONS = "UPDATE_USER_PERMISSIONS";
export const UU_PERMISSIONS_SUCCESS = "UPDATE_USER_PERMISSIONS_SUCCESS";
export const UU_PERMISSIONS_FAILURE = "UPDATE_USER_PERMISSIONS_FAILURE";

// delete user from location
export const DELETE_USER_FROM_LOCATION = "DELETE_USER_FROM_LOCATION";
export const DELETE_USER_FROM_LOCATION_FAILURE =
  "DELETE_USER_FROM_LOCATION_FAILURE";
export const DELETE_USER_FROM_LOCATION_SUCCESS =
  "DELETE_USER_FROM_LOCATION_SUCCESS";
/**
 *
 *
 * ORDER MANAGEMENT variables below
 *
 */

// get pending order variables
export const GET_PRODUCT_or_CONTRACT_DETAIL = "GET_PRODUCT_OR_CONTRACT_DETAIL";
export const GET_PRODUCT_or_CONTRACT_DETAIL_SUCCESS =
  "GET_PRODUCT_OR_CONTRACT_DETAIL_SUCCESS";
export const GET_PRODUCT_or_CONTRACT_DETAIL_FAILURE =
  "GET_PRODUCT_OR_CONTRACT_DETAIL_FAILURE";

// get VENDORS n their PRODUCTS variables
export const GET_VENDOR_WITH_PRODS = "GET_VENDOR_WITH_PRODUCTS";
export const GET_VENDOR_WITH_PRODS_SUCCESS = "GET_VENDOR_WITH_PRODUCTS_SUCCESS";
export const GET_VENDOR_WITH_PRODS_FAILURE = "GET_VENDOR_WITH_PRODUCTS_FAILURE";

// Vendor's Products
export const GET_VENDORS_PRODUCT = "GET_VENDORS_PRODUCT";
export const GET_VENDORS_PRODUCT_SUCCESS = "GET_VENDORS_PRODUCT_SUCCESS";
export const GET_VENDORS_PRODUCT_FAILURE = "GET_VENDORS_PRODUCT_FAILURE";

// get VENDORS n their contracts variables
export const GET_VENDOR_CONTRACTS = "GET_VENDOR_CONTRACTS";
export const GET_VENDOR_CONTRACTS_SUCCESS = "GET_VENDOR_CONTRACTS_SUCCESS";
export const GET_VENDOR_CONTRACTS_FAILURE = "GET_VENDOR_CONTRACTS_FAILURE";

export const GET_CONTRACT_LIST = "GET_CONTRACT_LIST";
export const GET_CONTRACT_LIST_SUCCESS = "GET_CONTRACT_LIST_SUCCESS";
export const GET_CONTRACT_LIST_FAILURE = "GET_CONTRACT_LIST_FAILURE";

export const GET_CONTRACT_DETAILS = "GET_CONTRACT_DETAILS";
export const GET_CONTRACT_DETAILS_SUCCESS = "GET_CONTRACT_DETAILS_SUCCESS";
export const GET_CONTRACT_DETAILS_FAILURE = "GET_CONTRACT_DETAILS_FAILURE";

export const GET_CONTRACT_HISTORY = "GET_CONTRACT_HISTORY";
export const GET_CONTRACT_HISTORY_SUCCESS = "GET_CONTRACT_HISTORY_SUCCESS";
export const GET_CONTRACT_HISTORY_FAILURE = "GET_CONTRACT_HISTORY_FAILURE";

// create order variables
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";

// get pending order variables
export const GET_PENDING_ORDER = "GET_PENDING_ORDER";
export const GET_PENDING_ORDER_SUCCESS = "GET_PENDING_ORDER_SUCCESS";
export const GET_PENDING_ORDER_FAILURE = "GET_PENDING_ORDER_FAILURE";

// get analytics order
export const GET_BRANCH_ORDER = "GET_BRANCH_ORDER";
export const GET_BRANCH_ORDER_SUCCESS = "GET_BRANCH_ORDER_SUCCESS";
export const GET_BRANCH_ORDER_FAILURE = "GET_BRANCH_ORDER_FAILURE";

export const GET_BRANCH_FULFILLED_ORDER = "GET_BRANCH_FULFILLED_ORDER";
export const GET_BRANCH_FULFILLED_ORDER_SUCCESS =
  "GET_BRANCH_FULFILLED_ORDER_SUCCESS";
export const GET_BRANCH_FULFILLED_ORDER_FAILURE =
  "GET_BRANCH_FULFILLED_ORDER_FAILURE";

export const GET_BRANCH_RECENT_ORDER = "GET_BRANCH_RECENT_ORDER";
export const GET_BRANCH_RECENT_ORDER_SUCCESS =
  "GET_BRANCH_RECENT_ORDER_SUCCESS";
export const GET_BRANCH_RECENT_ORDER_FAILURE =
  "GET_BRANCH_RECENT_ORDER_FAILURE";

export const GET_CUSTOMER_ORDER_DELIVERIES = "GET_CUSTOMER_ORDER_DELIVERIES";
export const GET_CUSTOMER_ORDER_DELIVERIES_SUCCESS =
  "GET_CUSTOMER_ORDER_DELIVERIES_SUCCESS";
export const GET_CUSTOMER_ORDER_DELIVERIES_FAILURE =
  "GET_CUSTOMER_ORDER_DELIVERIES_FAILURE";

// get order items variables
export const GET_ORDER_ITEMS = "GET_ORDER_ITEMS";
export const GET_ORDER_ITEMS_SUCCESS = "GET_ORDER_ITEMS_SUCCESS";
export const GET_ORDER_ITEMS_FAILURE = "GET_ORDER_ITEMS_FAILURE";

// get order history variables
export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY";
export const GET_ORDER_HISTORY_SUCCESS = "GET_ORDER_HISTORY_SUCCESS";
export const GET_ORDER_HISTORY_FAILURE = "GET_ORDER_HISTORY_FAILURE";

// get order items variables
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";

// get one order item variables
export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

// approve/decline orders variables
export const REACT_TO_ORDER = "REACT_TO_ORDER";
export const REACT_TO_ORDER_SUCCESS = "REACT_TO_ORDER_SUCCESS";
export const REACT_TO_ORDER_FAILURE = "REACT_TO_ORDER_FAILURE";

// get delivery info
export const GET_DELIVERY_INFO = "GET_DELIVERY_INFO";
export const GET_DELIVERY_INFO_FAILURE = "GET_DELIVERY_INFO_FAILURE";
export const GET_DELIVERY_INFO_SUCCESS = "GET_DELIVERY_INFO_SUCCESS";

// verify/decline orders variables
export const VERIFY_ORDER = "VERIFY_ORDER";
export const VERIFY_ORDER_SUCCESS = "VERIFY_ORDER_SUCCESS";
export const VERIFY_ORDER_FAILURE = "VERIFY_ORDER_FAILURE";

export const VERIFY_PENDING_UPLOADS = "VERIFY_PENDING_UPLOADS";
export const VERIFY_PENDING_UPLOADS_SUCCESS = "VERIFY_PENDING_UPLOADS_SUCCESS";
export const VERIFY_PENDING_UPLOADS_FAILURE = "VERIFY_PENDING_UPLOADS_FAILURE";

// verify/decline orders variables
export const REQUEST_CONTRACT = "REQUEST_CONTRACT";
export const REQUEST_CONTRACT_SUCCESS = "REQUEST_CONTRACT_SUCCESS";
export const REQUEST_CONTRACT_FAILURE = "REQUEST_CONTRACT_FAILURE";

// statistics
export const GET_CUSTOMER_ORDER_STATS = "GET_CUSTOMER_ORDER_STATISTICS";
export const GET_CUSTOMER_ORDER_STATS_FAILURE =
  "GET_CUSTOMER_ORDER_STATISTICS_FAILURE";
export const GET_CUSTOMER_ORDER_STATS_SUCCESS =
  "GET_CUSTOMER_ORDER_STATISTICS_SUCCESS";

export const GET_CUSTOMER_PROFILE_STATS = "GET_CUSTOMER_PROFILE_STATISTICS";
export const GET_CUSTOMER_PROFILE_STATS_FAILURE =
  "GET_CUSTOMER_PROFILE_STATISTICS_FAILURE";
export const GET_CUSTOMER_PROFILE_STATS_SUCCESS =
  "GET_CUSTOMER_PROFILE_STATISTICS_SUCCESS";

export const GET_USER_LOCATIONS_FAILURE = "GET_USER_LOCATIONS_FAILURE";
export const GET_USER_LOCATIONS_SUCCESS = "GET_USER_LOCATIONS_SUCCESS";
export const GET_USER_LOCATIONS = "GET_USER_LOCATIONS";

export const SUBMIT_VERIFICATION_CODE_FAILURE =
  "SUBMIT_VERIFICATION_CODE_FAILURE";
export const SUBMIT_VERIFICATION_CODE_SUCCESS =
  "SUBMIT_VERIFICATION_CODE_SUCCESS";
export const SUBMIT_VERIFICATION_CODE = "SUBMIT_VERIFICATION_CODE";

// Reports
export const GET_REPORT_LOGS_FAILURE = "GET_REPORT_LOGS_FAILURE";
export const GET_REPORT_LOGS_SUCCESS = "GET_REPORT_LOGS_SUCCESS";
export const GET_REPORT_LOGS = "GET_REPORT_LOGS";
export const GET_REPORT_LOGS_PDF_DOWNLOAD = "GET_REPORT_LOGS_PDF_DOWNLOAD";
export const GET_REPORT_LOGS_EXCEL_DOWNLOAD = "GET_REPORT_LOGS_EXCEL_DOWNLOAD";
export const GET_REPORT_LOGS_DOWNLOAD_SUCCESS =
  "GET_REPORT_LOGS_DOWNLOAD_SUCCESS";
export const GET_REPORT_LOGS_DOWNLOAD_FAILURE =
  "GET_REPORT_LOGS_DOWNLOAD_FAILURE";

//Audits

export const GET_AUDIT = "GET_AUDITS";
export const GET_AUDIT_SUCCESS = "GET_AUDIT_SUCCESS";
export const GET_AUDIT_FAILURE = "GET_AUDIT_FAILURE";
export const DOWNLOAD_AUDIT_LOG = "DOWNLOAD_AUDIT_LOG";
export const DOWNLOAD_AUDIT_LOG_SUCCESS = "DOWNLOAD_AUDIT_LOG_SUCCESS";
export const DOWNLOAD_AUDIT_LOG_FAILURE = "DOWNLOAD_AUDIT_LOG_FAILURE";
