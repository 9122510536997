import { all } from "redux-saga/effects";
import logoutSaga from "./logoutSaga";
import loginSaga from "./loginSaga";
import storageSaga from "./storageSaga";

// sagas for CUSTOMER admin
import signupSaga from "./signupSaga";
import resetPasswordSaga from "./pwReset_saga";
import resendOTPSaga from "./resendOTP_saga";
import verifyProfileSaga from "./verifyProfile_saga";
import forgotPasswordSaga from "./forgotPW_saga";
import changePasswordSaga from "./changePW_saga";

/**
 *
 * saga for CUSTOMER user and user permission below
 *
 */
// user
import bulkUploadSaga from "./bulkUpload_saga";
import createUserSaga from "./createUser_saga";
import createLocationSaga from "./createLocation_saga";
import editBasicInfoSaga from "./editBasicInfo_saga";
import getBasicInfoSaga from "./getBasicInfo_saga";
import getLocationsSaga from "./getLocations_saga";
import getLocationSaga from "./getLocation_saga";
import getUsersSaga from "./getUsers_saga";
import getCustomersSaga from "./getCustomers_saga";
import addUserToLocationsSaga from "./addUserToLocations_saga";
import addUsersToLocationSaga from "./addUsersToLocation_saga";

// user permissions
import permissions_saga from "./user_permissions/GET/permissions_saga";
import userPermissions_saga from "./user_permissions/GET/userPermissions_saga";

import batchUpdateUserPermissions_saga from "./user_permissions/POST/batchUpdateUserPermissions_saga";

import removeUserPermission_saga from "./user_permissions/POST/removeUserPermission_saga";
import removeUserPermissions_saga from "./user_permissions/POST/removeUserPermissions_saga";
import updateUserPermissions_saga from "./user_permissions/POST/updateUserPermissions_saga";
import deleteUserFromLocationSaga from "./deleteUserFromLocation_saga";
import changeUserRoleSaga from "./user_permissions/POST/changeUserRole_saga";
// orders
import getVendorWithProductsSaga from "./orders/getVendorWithProds_saga";
import createOrderSaga from "./orders/createOrder_saga";
import getPendingOrderSaga from "./orders/getPendingOrder_saga";
import getOrderItemsSaga from "./orders/getOrderItems_saga";
import getOrdersSaga from "./orders/getOrders_saga";
import getOrderSaga from "./orders/getOrder_saga";
import reactToOrderSaga from "./orders/reactToOrder_saga";
import verifyOrderSaga from "./orders/verifyOrder_saga";
import requestContractSaga from "./orders/requestContract_saga";
import getOrderHistorySaga from "./getOrderHistory_saga";
import getUsersInLocationSaga from "./getUsersInLocation_saga";
import getVendorContractsSaga, {
  getContractListSaga,
  getContractDetailSaga,
  getVendorProductsSaga,
  getContractHistorySaga,
} from "./orders/getVendorContracts_saga";
import getProdnContDetailSaga from "./orders/getProdnContDetail_saga";

// STATUSES
import toggleUserStatusSaga from "./status/toggleUserStatus_saga";
import toggleLocationStatusSaga from "./status/toggleLocationStatus_saga";
import getUserLocationsSaga from "./getUserLocations_saga";
// STATISTICS
import getCustomerOrderStatsSaga from "./statistics/getCustomerOrderStats_saga";
import getCustomerProfileStatsSaga from "./statistics/getCustomerProfileStats_saga";
import getUserSaga from "./getUser_saga";

import {
  verifyPendingBulkUploadsSaga,
  getPendingBulkUploadSaga,
} from "./getPendingBulkUpload_saga";

import getReportsSaga, {
  getReportsExcelDownloadSaga,
  getReportsPDFDownloadSaga,
} from "./getReports_saga";

import { getAuditLogsSaga, downloadAuditLogSaga } from "./auditLog_saga";
import getBranchOrderSaga from "./orders/getBranchOrder_saga";
import getBranchFulfilledOrderSaga from "./orders/getBranchFulfilledOrder_saga";
import getBranchOrderDeliverySaga from "./orders/getCustomerOrderDeliveries_saga";
import getBranchRecentOrderSaga from "./orders/getRecentBranchOrders";
import signupOnboardUser8Saga from "./onboard/signupOnboard";
import signupOnboardUserSaga from "./onboard/signupOnboard";
import verifyEmailOnboardUserSaga from "./onboard/verify_email_sagas";
// signupOnboardUserSaga;
// signupOnboardUser8Saga;
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    signupSaga(),
    resetPasswordSaga(),
    resendOTPSaga(),
    verifyProfileSaga(),
    storageSaga(),
    logoutSaga(),
    loginSaga(),
    getUserSaga(),
    forgotPasswordSaga(),
    changePasswordSaga(),
    getUsersInLocationSaga(),
    createUserSaga(),
    editBasicInfoSaga(),
    getBasicInfoSaga(),
    createLocationSaga(),
    bulkUploadSaga(),
    getLocationsSaga(),
    getLocationSaga(),
    getUserLocationsSaga(),
    getUsersSaga(),
    getCustomersSaga(),
    addUserToLocationsSaga(),
    addUsersToLocationSaga(),
    deleteUserFromLocationSaga(),
    // GET
    permissions_saga(),
    userPermissions_saga(),

    // POST
    batchUpdateUserPermissions_saga(),
    changeUserRoleSaga(),
    removeUserPermission_saga(),
    removeUserPermissions_saga(),
    updateUserPermissions_saga(),

    // orders
    getVendorWithProductsSaga(),
    getProdnContDetailSaga(),
    createOrderSaga(),
    getVendorContractsSaga(),
    getPendingOrderSaga(),
    getOrderItemsSaga(),
    reactToOrderSaga(),
    getOrderSaga(),
    getOrdersSaga(),
    verifyOrderSaga(),
    requestContractSaga(),
    getOrderHistorySaga(),

    // Analytics
    getBranchOrderSaga(),
    getBranchFulfilledOrderSaga(),
    getBranchOrderDeliverySaga(),
    getBranchRecentOrderSaga(),

    // STATUS
    toggleUserStatusSaga(),
    toggleLocationStatusSaga(),

    // STATISTICS
    getCustomerOrderStatsSaga(),
    getCustomerProfileStatsSaga(),

    // REPORT
    getReportsSaga(),
    getReportsExcelDownloadSaga(),
    getReportsPDFDownloadSaga(),

    getPendingBulkUploadSaga(),
    verifyPendingBulkUploadsSaga(),
    // audit
    getAuditLogsSaga(),
    downloadAuditLogSaga(),
    // contracts
    getContractListSaga(),
    getContractDetailSaga(),
    getContractHistorySaga(),
    // vendor products
    getVendorProductsSaga(),

    //Onboarding
    signupOnboardUserSaga(),
    verifyEmailOnboardUserSaga(),
  ]);
}
