import { initialState } from "../../initialState";
import {
  GET_CUSTOMER_ORDER_DELIVERIES_FAILURE,
  GET_CUSTOMER_ORDER_DELIVERIES_SUCCESS,
} from "../../arsVariables";

const getBranchOrderDeliveries_reducer = (state = initialState, action) => {
  const { type, payloadReturned, totalCount } = action;
  switch (type) {
    case GET_CUSTOMER_ORDER_DELIVERIES_SUCCESS:
      return {
        ...state,
        branchDeliveryOrder: payloadReturned,
        total: totalCount,
      };
    case GET_CUSTOMER_ORDER_DELIVERIES_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default getBranchOrderDeliveries_reducer;
