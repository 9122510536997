import {
    GET_ORDER_HISTORY_SUCCESS,
    GET_ORDER_HISTORY_FAILURE
} from "../arsVariables";
import { initialState } from "../initialState";

const getOrderHistory_reducer = (state = initialState, action) => {
    const { type, payload, total } = action;
    switch (type) {
        case GET_ORDER_HISTORY_SUCCESS:
            return {
                ...state,
                total,
                orderHistory: payload
            };
        case GET_ORDER_HISTORY_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getOrderHistory_reducer;
