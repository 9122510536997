import React from "react";
import { connect } from "react-redux";
import { history } from "../../../reuse/history";
// import Sidebar from "../../../reuse/sidebar";
import { CUSTOMER_USER } from "../../../redux-flow/arsVariables";
import { Actions } from "../../../redux-flow/actions/_index";
// import { Spin } from "antd";
import { Link } from "react-router-dom";

import BaseComponent from "../../../reuse/baseComponent";
// import MenuBar from "../../../reuse/MenuBar";
import ContractMenu from "./ContractMenuContainer";

///// File name is ContractList, but menu bar title is Vendor List
/////
class ContractList extends BaseComponent {
    state = {
        SearchKeyword: "",
        pageIndex: 1,
        pageSize: 12
    };

    fetch = ({ pageIndex, pageSize, SearchKeyword }) => {
        const { dispatch } = this.props;
        dispatch(Actions.uiStart());
        dispatch(
            Actions.getVendorContracts({
                pageIndex,
                pageSize,
                SearchKeyword
            })
        );
    };

    componentDidMount() {
        if (this.context.decodedData.role === CUSTOMER_USER) {
            return history.push({ pathname: "/#/403" });
        }
        const { pageSize, SearchKeyword, pageIndex } = this.state;

        this.fetch({ pageSize, SearchKeyword, pageIndex });
    }

    render() {
        const { SearchKeyword } = this.state;
        const { vendorContracts, total } = this.props;

        return (
            <ContractMenu
                SearchKeyword={SearchKeyword}
                loading={this.context.loading}
            >
                <div>
                    <div className="content" id="content-body">
                        <div
                            className={`container-fluid container-limited`}>
                            {/* <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb bg-transparent px-0">
                                                <li className="breadcrumb-item">
                                                    <span
                                                        style={{
                                                            color: "#00425f"
                                                        }}>
                                                        Contract List
                                                    </span>
                                                </li>
                                            </ol>
                                        </nav> */}
                            <div className="row my-4">
                                <div className="col-lg-6 col-12 isw-valign--middle mr-auto">
                                    {this.renderPageTracker(total)}
                                </div>

                                {/* <form
                                                className="ml-auto col-6"
                                                onSubmit={this.getBySearch}>
                                                <input
                                                    value={SearchKeyword}
                                                    onChange={this.handleSearch}
                                                    onBlur={this.handleSearch}
                                                    className="isw-input-search"
                                                    placeholder="Search"
                                                />
                                            </form> */}
                            </div>
                            {total === 0 ? (
                                this.renderTableAbnormality(
                                    "No match for search found"
                                )
                            ) : !total ? (
                                this.renderTableAbnormality()
                            ) : (
                                <div className="row my-5">
                                    {vendorContracts.map(vendor => {
                                        const {
                                            companyName,
                                            id
                                        } = vendor;
                                        return (
                                            <div
                                                key={id}
                                                className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                <Link
                                                    to={{
                                                        pathname: `/customer/contracts/${id}`
                                                    }}>
                                                    <span className="card isw-hoverClick h-100">
                                                        <div className="card-body">
                                                            <h5
                                                                className="card-title"
                                                                style={{
                                                                    color:
                                                                        "rgba(0,0,0,0.7)"
                                                                }}>
                                                                {
                                                                    companyName
                                                                }
                                                            </h5>
                                                            <p className="card-text">
                                                                <small className="text-muted">
                                                                    {vendorContracts.filter(
                                                                        vendor =>
                                                                            vendor.id ===
                                                                            id
                                                                    )[0]
                                                                        .status ===
                                                                        1 ? (
                                                                        <span className="isw-status--box-pending">
                                                                            Pending
                                                                        </span>
                                                                    ) : vendorContracts.filter(
                                                                        vendor =>
                                                                            vendor.id ===
                                                                            id
                                                                    )[0]
                                                                        .status ===
                                                                        2 ? (
                                                                        <span className="isw-status--box-success">
                                                                            In
                                                                            Contract
                                                                        </span>
                                                                    ) : vendorContracts.filter(
                                                                        vendor =>
                                                                            vendor.id ===
                                                                            id
                                                                    )[0]
                                                                        .status ===
                                                                        4 ? (
                                                                        <span className="isw-status--box-failed">
                                                                            Expired
                                                                        </span>
                                                                    ) : (
                                                                        <span className="isw-status--box-info">
                                                                            No
                                                                            Contract
                                                                        </span>
                                                                    )}
                                                                </small>
                                                            </p>
                                                        </div>
                                                    </span>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            {this.renderPagination(total)}
                        </div>
                    </div>
                </div>
            </ContractMenu>

        );
    }
}

const mapStateToProps = ({ getVendorContracts_reducer }) => {
    const {
        totalContracts: total,
        vendorContracts
    } = getVendorContracts_reducer;
    return {
        vendorContracts,
        total
    };
};

export default connect(mapStateToProps)(ContractList);
