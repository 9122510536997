import { initialState } from "../../initialState";
import {
    GET_VENDORS_PRODUCT_FAILURE,
    GET_VENDORS_PRODUCT_SUCCESS,
    GET_VENDOR_WITH_PRODS_FAILURE,
    GET_VENDOR_WITH_PRODS_SUCCESS
} from "../../arsVariables";

const getVendorWithProducts_reducer = (state = initialState, action) => {
    const { type, vendorInfo, totalVendors } = action;
    switch (type) {
        case GET_VENDOR_WITH_PRODS_SUCCESS:
            return {
                ...state,
                vendorInfo: vendorInfo,
                totalVendors: totalVendors
            };
        case GET_VENDOR_WITH_PRODS_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export const getVendorsProduct_reducer = (state = initialState, action) => {
    const { type, vendorProducts, totalCount } = action;
    switch (type) {
        case GET_VENDORS_PRODUCT_SUCCESS:
            return {
                ...state,
                vendorProducts,
                totalCount
            };
        case GET_VENDORS_PRODUCT_FAILURE:
            return {
                ...state
            };
        default:
            return state;
    }
}

export default getVendorWithProducts_reducer;
