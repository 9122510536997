import React from "react";
import PropTypes from "prop-types";
import { CUSTOMER_ADMIN, CUSTOMER_USER } from "../../redux-flow/arsVariables";
export const RightView = ({
    userPermissions,
    permissions,
    updatePermission,
    pickRole,
    role
}) => {
    const filteredPermisson = permissions.filter(permission => {
        if (role === "8") return permission.category === CUSTOMER_ADMIN;
        else if (role === "9") return permission.category === CUSTOMER_USER;
        else return permission;
    });
    return (
        <div className="col-7">
            <div>
                <div className="form-select">
                    <select
                        className="select-text"
                        onChange={({ target: { value } }) => pickRole(value)}>
                        <option value="1">All</option>
                        <option value="8">Admin</option>
                        <option value="9">User</option>
                    </select>
                    <span className="select-highlight" />
                    <span className="select-bar" />
                    <label className="select-label">Select Role</label>
                </div>

                <div
                    style={{
                        minHeight: `${500}px`
                    }}
                    className="tab-content isw-permission-list"
                    id="nav-tabContent">
                    <div
                        style={{
                            minHeight: `${500}px`,
                            display: "flex",
                            flexDirection: "column"
                        }}
                        className="tab-pane"
                        id="list-home"
                        role="tabpanel"
                        aria-labelledby="list-home-list">
                        {userPermissions.length <= 0 ? (
                            <span
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                Select a user to update their permissions
                            </span>
                        ) : filteredPermisson.length === 0 && role === "8" ? (
                            <span className="text-c">No admin roles</span>
                        ) : filteredPermisson.length === 0 && role === "9" ? (
                            <span className="text-c">No user roles</span>
                        ) : (
                            filteredPermisson.map(permission => {
                                const {
                                    description,
                                    category,
                                    name
                                } = permission;

                                return (
                                    <div
                                        key={name.toString()}
                                        className="isw-permission-item-right"
                                        onClick={() => updatePermission(name)}>
                                        <div className="checkbox w-100 p-2">
                                            <label
                                                style={{
                                                    display: "flex",
                                                    marginBottom: 0
                                                }}>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        userPermissions
                                                            ? userPermissions[0].permissions.filter(
                                                                  perm => {
                                                                      return (
                                                                          perm ===
                                                                          name
                                                                      );
                                                                  }
                                                              )[0]
                                                                ? true
                                                                : false
                                                            : null
                                                    }
                                                    onChange={() =>
                                                        updatePermission(name)
                                                    }
                                                />
                                                <i
                                                    className="helper"
                                                    style={{
                                                        marginTop: `${0.2}rem`
                                                    }}
                                                />
                                                <div>
                                                    <h6 className="mb-0">
                                                        {description}
                                                    </h6>
                                                    <small>{category}</small>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

RightView.propTypes = {
    userPermissions: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    updatePermission: PropTypes.func.isRequired,
    pickRole: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired
};
