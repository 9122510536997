import React, { useState } from "react";
import { Select, Tooltip as DataTip } from "antd";
import { ResponsiveContainer, PieChart, Pie, Cell, Label } from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import { GetAssetAnalyticsStart } from "../../../../store/actions";
import { useMemo } from "react";
// import { formatString } from "../../../../utils/utils";
import { LineSkeleton } from "./dashUtil";
import { formatString } from "../../utils/utils";

const { Option } = Select;

export const AssetUtilisationChart = ({ isMobile }) => {
  const id = localStorage.getItem("vendorId");
  const dispatch = useDispatch();
  const [assetType, setAssetType] = useState("drivers");
  const { assetPayload, assetLoading } = useSelector(
    (state) => state.analytics
  ) || { assetPayload: null, assetLoading: false };

  useEffect(() => {
    // dispatch(GetAssetAnalyticsStart(id));
  }, []);

  const payload = useMemo(() => {
    var obj = null;
    if (assetPayload) {
      for (const key in assetPayload) {
        obj = {
          ...obj,
          [key]: [
            { name: "inactive", value: assetPayload[key]["inactive"] },
            { name: "active", value: assetPayload[key]["active"] },
          ],
        };
      }
    }
    return obj;
  }, [assetPayload]);

  const pieColors = ["#EBF8FE", "#236BCC"];

  return (
    <div className="chart-section line-chart">
      <div className="d-flex line-chart-header">
        <h4 className="chart-title mb-0">
          <DataTip title="Total number of active vs. inactive Trucks/Drivers">
            Asset Utilization
          </DataTip>
        </h4>
        <Select
          value={assetType}
          style={{
            height: 40,
            width: 114,
            backgroundColor: "#F3F5F6",
            borderRadius: 4,
          }}
          onChange={(e) => setAssetType(e)}
          defaultValue=""
        >
          <Option value="trucks">Trucks</Option>
          <Option value="drivers">Drivers</Option>
        </Select>
      </div>
      {payload ? (
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={payload[assetType]}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={isMobile ? 80 : 100}
              outerRadius={isMobile ? 81 : 101}
              fill="#82ca9d"
              label=""
            >
              {payload[assetType].map((entry, index) => (
                <Cell
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={20}
                  className="cell-class"
                  key={`cell-${index}`}
                  stroke={pieColors[index]}
                ></Cell>
              ))}
              <Label className="pie-label" position="center">
                {assetPayload[assetType].total}
              </Label>
              <Label className="pie-sub-label" position="centerBottom">
                {formatString(assetType)}
              </Label>
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <></>
      )}
      <LineSkeleton isShow={assetLoading} line={4} />
      <div className="d-flex pie-chart-base">
        <div>
          <div className="pie-dots"></div>
          <LineSkeleton isShow={assetLoading} />
          {payload && <label>{assetPayload[assetType].active} Active</label>}
        </div>
        <div>
          <div
            style={{
              backgroundColor: "#EBF8FE",
            }}
            className="pie-dots"
          ></div>
          <LineSkeleton isShow={assetLoading} />
          {payload && (
            <label>{assetPayload[assetType].inactive} Inactive</label>
          )}
        </div>
      </div>
    </div>
  );
};
export default AssetUtilisationChart;
