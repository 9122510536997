import React, { Fragment } from 'react';
import BaseComponent from '../../reuse/baseComponent';
import { connect } from 'react-redux';
import { Actions } from '../../redux-flow/actions/_index';
import MenuBar from '../../reuse/MenuBar';
import Sidebar from '../../reuse/sidebar';
import Brief from '../../assets/images/brief.jpg';
import { Spin, Button, Modal } from 'antd';
import { formatMoney } from '../../reuse/currencyHelper';
import { calcDate, dateRangeOptFormatter } from '../../reuse/utilities';
import { BackButton } from '../../reuse/backButton';
import { history } from '../../reuse/history';
import { CloseOutlined } from "@ant-design/icons";
import WithRouter from '../../reuse/WithRouter';



class ContractDetail extends BaseComponent {
    state = {
        pageIndex: 1,
        pageSize: 12,
        currentContract: {},
        showModal: false
    };

    componentDidMount() {
        const { pageIndex, pageSize } = this.state;
        const {
            params,
            dispatch,
        } = this.props;
        dispatch(Actions.getContractDetails({ contractId: params.id }));
        this.fetch({ contractId: params.id, pageIndex, pageSize })
    }

    fetch = ({ pageIndex, pageSize, contractId }) => {
        const { dispatch } = this.props;
        dispatch(Actions.getContractHistory({ contractId, pageIndex, pageSize }))
    }

    toggelDetailModal = (currentContract) => {
        this.setState({ showModal: !this.state.showModal, currentContract })
    }

    renderModal = () => {
        const { showModal, currentContract } = this.state;
        return (
            <Modal
                closeIcon={<CloseOutlined style={{ display: 'inline-block', fontSize: 25, marginRight: 70, marginTop: 30, color: '#000000' }} />}
                className="report-modal"
                //  width={!hideElement ? '50%' : '100%'}
                centered
                title="History details"
                visible={showModal}
                onOk={this.toggelDetailModal}
                onCancel={this.toggelDetailModal}
                footer={[
                    <Button style={{
                        width: 130, color: '#5F738C', borderColor: '#7F91A8',
                    }} key="back" size="large" onClick={this.toggelDetailModal}>
                        Cancel
                    </Button>,
                ]}
            >
                <div className="row mr-5 ml-5 pb-0">
                    {/* First row */}
                    <div className="col-12 detail-row mt-3">
                        <div className="row full-width ft-14">
                            <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                                <label className="d-block column-header">Date of Change</label>
                                <label className="column-body ">{calcDate(currentContract.dateOfChange)}</label>
                            </div>
                            <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                                <label className="d-block ft-14 column-header">Price</label>
                                <label className="column-body">{formatMoney(currentContract.price)}</label>
                            </div>
                            <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                                <label className="d-block ft-14 column-header">Contract start date</label>
                                <label className="column-body">{calcDate(currentContract.contractStartDate)}</label>
                            </div>
                        </div>
                    </div>
                    {/* second row */}
                    <div className="col-12 detail-row mt-4 pb-3">
                        <div className="row full-width">
                            <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                                <label className="d-block ft-14 column-header">Contract end date</label>
                                <label className="column-body">{calcDate(currentContract.contractEndDate)}</label>
                            </div>
                            <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                                <label className="d-block ft-14 column-header">Contract limit</label>
                                <label className="column-body">{formatMoney(currentContract.contractLimit)}</label>
                            </div>
                            <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                                <label className="d-block ft-14 column-header">Contract Cycle</label>
                                <label className="column-body">{currentContract.contractCycle}</label>
                            </div>
                        </div>
                    </div>
                    {/* third row */}
                    <div className="col-12 detail-row mt-4 pb-3">
                        <div className="row full-width">
                            <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                                <label className="d-block ft-14 column-header">Contract range</label>
                                <label className="column-body">{currentContract.contractRange}</label>
                            </div>
                            <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                                <label className="d-block ft-14 column-header">Contract Usage</label>
                                <label className="column-body">{formatMoney(currentContract.contractUsage)}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>)
    }

    render() {
        const { loading, status, toggleModal } = this.context;
        const { contractDetail: contractInfo, contractHistory, totalCount } = this.props;
        const { pageIndex, pageSize } = this.state;
        return (<Fragment>
            <Sidebar
                status={status}
                toggleModal={toggleModal}
            />
            <div className="isw-mainLayout1">
                <div className="isw-content--wrapper">
                    <MenuBar title="Contract Details" />
                    <div>
                        <div className="content" id="content-body">
                            <div className={`container-fluid container-limited`}>
                                {this.renderModal()}
                                <Spin
                                    tip="Please wait..."
                                    spinning={loading}
                                    className="isw-content--wrapper">
                                    <div className="row">
                                        <div className="col-4 isw-customer">
                                            <div className="card card-body">
                                                <div className="isw-user-profile">
                                                    <div className="isw-user-image">
                                                        <img alt="" src={Brief} />
                                                    </div>
                                                    {contractInfo && <div className="isw-user-name">{contractInfo.company}</div>}
                                                    {contractInfo && <div>{contractInfo.companyEmail}</div>}
                                                </div>
                                                <div className="isw-divider isw-divider-horizontal isw-divider-dash" />
                                                <div>
                                                    <div className="mb-2">
                                                        <div className="isw-user-section-title">Details</div>
                                                        <div className="mb-2">
                                                            <div className="text-muted">Contract ID:</div>
                                                            {contractInfo && contractInfo.id && contractInfo.id.toUpperCase()}
                                                        </div>
                                                        <div className="mb-2">
                                                            <div className="text-muted">Price:</div>
                                                            {contractInfo && formatMoney(contractInfo.unitPrice)}
                                                        </div>

                                                        <div className="mb-2">
                                                            <div className="text-muted">Payment Status:</div>
                                                            {contractInfo && contractInfo.paymentStatusValue}
                                                        </div>

                                                        <div className="mb-2">
                                                            <div className="text-muted">Product Code:</div>
                                                            {contractInfo && contractInfo.productCode}
                                                        </div>

                                                        <div className="mb-2">
                                                            <div className="text-muted">Contract Type:</div>{" "}
                                                            {contractInfo && contractInfo.isVap ? "Vap" : "NotVap"}
                                                        </div>

                                                        <div className="mb-2">
                                                            <div className="text-muted">Contract Limit:</div>
                                                            {contractInfo && formatMoney(contractInfo.contractLimit)}
                                                        </div>

                                                        <div className="mb-2">
                                                            <div className="text-muted">Contract Cycle:</div>
                                                            {contractInfo && (contractInfo.contractCycle === "0" ? "NA " : contractInfo.contractCycle + " days")}
                                                        </div>
                                                        <div className="mb-2">
                                                            <div className="text-muted">Contract Usage:</div>
                                                            {contractInfo && formatMoney(contractInfo.contractUsage)}
                                                        </div>
                                                        <div className="mb-2">
                                                            <div className="text-muted">Contract Range:</div>
                                                            {contractInfo && dateRangeOptFormatter(contractInfo.contractDateRange)}
                                                        </div>

                                                        <div className="mb-2">
                                                            <div className="text-muted">Cycle Starts:</div>
                                                            {contractInfo && calcDate(contractInfo.startDate)}
                                                        </div>

                                                        <div className="mb-2">
                                                            <div className="text-muted">Cycle Ends:</div>
                                                            {contractInfo && calcDate(contractInfo.endDate)}
                                                        </div>
                                                        <div className="mb-2">
                                                            <div className="text-muted">Date Created:</div>
                                                            {contractInfo && calcDate(contractInfo.dateCreated)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="isw-divider isw-divider-horizontal isw-divider-dash" />
                                                <div>
                                                    <div className="isw-user-section-title">Admin</div>
                                                    {contractInfo && <Fragment>
                                                        <div>{contractInfo.company}</div>
                                                        <div>{contractInfo.companyEmail}</div>
                                                        <div>{contractInfo.phone}</div>
                                                    </Fragment>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-8">
                                            <BackButton backFunction={history.goBack} />
                                            <div
                                                className="trail menu-link pt-0"
                                                style={{ marginBottom: 0, paddingBottom: 4 }}
                                            >
                                                <span
                                                    style={{
                                                        color: "#000000",
                                                        borderBottomColor: "#00425F",
                                                        borderBottomWidth: 3,
                                                        borderBottomStyle: "solid",
                                                    }}
                                                >
                                                    Contract History
                                                </span>
                                            </div>
                                            <div className="table-container pb-4">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Date of Change
                                                            </th>
                                                            <th>
                                                                Price
                                                            </th>
                                                            <th>
                                                                Contract Limit
                                                            </th>
                                                            <th>
                                                                Contract Cycle
                                                            </th>
                                                            <th>
                                                                Contract Usage
                                                            </th>
                                                            <th>
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(contractHistory && contractHistory.length > 0) ? contractHistory.map((contract, index) => {
                                                            return (<tr key={`${index}-${contract.date}`}>
                                                                <td>{calcDate(contract.dateOfChange)}</td>
                                                                <td>{formatMoney(contract.price)}</td>
                                                                <td>{formatMoney(contract.contractLimit)}</td>
                                                                <td>{contract.contractCycle}</td>
                                                                <td>{formatMoney(contract.contractUsage)}</td>
                                                                <th>
                                                                    <Button type="link"
                                                                        onClick={() => this.toggelDetailModal(contract)}
                                                                        style={{
                                                                            color: '#096DD9',
                                                                            fontWeight: '500'
                                                                        }}>View</Button>
                                                                </th>

                                                            </tr>)
                                                        }) :
                                                            <tr><td colSpan="12">{this.renderTableAbnormality("No history available yet.")}</td></tr>
                                                        }
                                                    </tbody>
                                                </table>
                                                {this.renderPagination(
                                                    totalCount,
                                                    pageIndex,
                                                    pageSize,
                                                    // this.handlePageChange
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        )
    }
}

const mapStateToProps = ({ getContractDetail_reducer, getContractHistory_reducer }) => {
    const {
        contractDetail
    } = getContractDetail_reducer;
    const { contractHistory, totalCount } = getContractHistory_reducer;
    return {
        contractDetail,
        contractHistory,
        totalCount
    };
};

export default WithRouter(connect(mapStateToProps)(ContractDetail));
