import { call, put, takeLatest } from "redux-saga/effects";
// import Services from "../../redux-flow/services/services";
import {
  VERIFYEMAIL_ONBOARD_SUCCESS,
  VERIFYEMAIL_ONBOARD_USER,
  VERIFYEMAIL_ONBOARD_FAILURE,
} from "../../../redux-flow/arsVariables";
import { Actions } from "../../actions/_index";
import { Notify } from "../../../reuse/notify";
import { history } from "../../../reuse/history";
import { url } from "../../../helper";
import Services from "../../services/services";
const { localStorage } = window;

function* verifyOnboardUser({ payload }) {
  try {
    const resp = yield call(Services.onboardVerifyUserService, payload);
    console.log(resp, "resp......");
    if (!resp?.data) {
      yield put(Actions.uiStop());
      Notify({
        message: resp?.response?.data,
        className: "error",
      });
      return yield put({
        type: VERIFYEMAIL_ONBOARD_FAILURE,
      });
    }
    const { error, data, status } = resp;
    const { code, description } = data;
    yield put(Actions.uiStop());
    Notify({
      message: description,
      className: code === 1 ? "success" : "error",
    });
    if (error === false && status === 200 && code === 1) {
      return yield put({ type: VERIFYEMAIL_ONBOARD_SUCCESS });
    }
  } catch (err) {
    Notify({
      message: err.response.response
        ? err.response.response.data.error_description
        : err.response.message,
      className: "error",
    });

    yield put(Actions.uiStop());
  }
}

export default function* verifyEmailOnboardUserSaga() {
  yield takeLatest(VERIFYEMAIL_ONBOARD_USER, verifyOnboardUser);
}
