import { GET_VENDOR_CONTRACTS, GET_CONTRACT_LIST, GET_CONTRACT_DETAILS, GET_CONTRACT_HISTORY } from "../../arsVariables";

const getVendorContracts = payload => ({
    type: GET_VENDOR_CONTRACTS,
    payload
});

export const getContractList = payload => ({
    type: GET_CONTRACT_LIST,
    payload
});

export const getContractDetails = payload => ({
    type: GET_CONTRACT_DETAILS,
    payload
})

export const getContractHistory = payload => ({
    type: GET_CONTRACT_HISTORY,
    payload
})

export default getVendorContracts;
