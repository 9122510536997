import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Spin, Select, DatePicker, Button, Input, Modal, Checkbox } from "antd";
import { DownloadOutlined, SearchOutlined, CloseOutlined, PrinterOutlined, CalendarOutlined, FileOutlined } from "@ant-design/icons";
import Sidebar from "../reuse/sidebar";
import MenuBar from "../reuse/MenuBar";
import { formatMoney } from "../reuse/currencyHelper";
import BaseComponent from "../reuse/baseComponent";
import { Notify } from "../reuse/notify";
import { Actions } from "../redux-flow/actions/_index";
import ReactToPrint from "react-to-print";


import "./Pages.css";
import { calcDate } from "../reuse/utilities";

const { getReportsActions } = Actions;

const { Option } = Select;
const { RangePicker } = DatePicker;

const pageStyle = `
@media {
 body {
   zoom: 250%;
 }
 @page {
  size: A4 landscape;
  }
  .ant-modal-centered.ant-modal{
    position:fixed;
     top:0;
  }
}
`;
class Reports extends BaseComponent {
  state = {
    pageIndex: 1,
    pageSize: 10,
    searchKeyword: "",
    moduleType: [],
    actionType: [],
    searchCategory: "",
    deliveryStartDate: null,
    deliveryEndDate: null,
    customerId: window.localStorage.getItem("customerId"),
    selectedOrder: {},
    showDownloadModal: false,
    downloadOption: 'pdf',
    filterOptions: [{ value: "driverName", label: "Driver's Name" },
    { value: "deliveryCompany", label: 'Delivery Company' },
    { value: "productName", label: 'Product Name' },
    { value: "locationName", label: 'Location Name' },
    { value: "vendor", label: 'Vendor Name' }],

    pageObject: {
      page: 1,
      pageSize: 10,
      customerId: window.localStorage.getItem("customerId"),
    },
    hideElement: false,
  };

  componentDidMount() {
    const { pageObject } = this.state;
    this.fetch(pageObject);
  }

  fetch = (pageObject) => {
    let tempObject = {
      ...pageObject
    };

    if (this.context.decodedData.role !== "CUSTOMER_ADMIN") {
      delete tempObject.customerId;
    }

    this.props.dispatch(
      getReportsActions.getReports({
        ...tempObject,
      })
    );
  };

  handleClick = (e) => {
    e.preventDefault();
    const { pageObject } = this.state;
    this.setState({ pageIndex: Number(e.target.id), pageObject: { ...pageObject, page: Number(e.target.id) } });
  };

  handleSearch = ({ target: { value } }) => {
    this.setState({ searchKeyword: value });
    const { pageObject, filterOptions } = this.state;
    let _pageConfig = pageObject;

    if (value === '') {
      filterOptions.forEach(item => {
        if (pageObject.hasOwnProperty(item.value)) {
          //delete filterOption so placeholder can show
          let _state = this.state;
          delete _state.filterOption;
          this.setState(_state);
          delete _pageConfig[item.value];
          this.setState({ pageObject: _pageConfig, searchKeyword: '' }, () => {
            this.fetch(this.state.pageObject);
          })
          return;
        }
      });
    }
  };

  handleTableChange = (page, pageSize) => {
    const { pageObject } = this.state;
    this.setState({ pageObject: { ...pageObject, page }, pageIndex: page }, () => {
      this.fetch(this.state.pageObject);
    });
  };

  getBySearch = (e, empty) => {
    e.preventDefault();
    const { searchKeyword, pageObject } = this.state;
    this.setState({
      pageIndex: 1,
      pageObject: { ...pageObject, page: 1 },
      searchKeyword: empty ? "" : searchKeyword,
    });
  };

  handleSelectChange = (value) => {
    if (value === "reset") {
      this.setState({
        searchKeyword: "",
      });
      return;
    }
    const { searchKeyword, pageObject } = this.state;
    if (searchKeyword === "") {
      Notify({
        message: "No filter parameter",
        className: "error",
      });
      return;
    }

    this.setState({ pageObject: { ...pageObject, [value]: searchKeyword, page: 1 }, filterOption: value, pageIndex: 1 }, () => {
      this.props.dispatch(
        getReportsActions.getReports(this.state.pageObject)
      );
    })
  };

  handleClearselect = (open) => {
    if (open) {
      let _state = this.state;
      delete _state.filterOption;
      this.setState(_state);
    }
  }

  handleDateChange = (date, dateString) => {
    // refetch when date is cleared
    const { pageObject } = this.state;
    let _pageObject = pageObject;
    if (date === null) {
      this.setState({
        deliveryStartDate: null,
        deliveryEndDate: null,
      });
      return;
    }

    if (!dateString[0] || !dateString[1]) {
      delete _pageObject.deliveryStartDate;
      delete _pageObject.deliveryEndDate;
      // return this.fetch({ pageIndex, pageSize });
    }

    if (dateString[0] || dateString[1]) {
      _pageObject = {
        ..._pageObject,
        deliveryStartDate: dateString[0],
        deliveryEndDate: dateString[1],
        page: 1,
      }
    }
    this.setState({ pageObject: _pageObject }, () => {
      this.props.dispatch(
        getReportsActions.getReports(this.state.pageObject)
      );
    })
  };

  handlePDFDownload = () => {
    const { pageObject } = this.state;

    const tempObject = {
      ...pageObject,
      page: 1,
    };

    if (this.props.decodedData.role !== "CUSTOMER_ADMIN") {
      delete tempObject.customerId;
    }

    this.props.dispatch(
      getReportsActions.getReportsPDFDownloads({
        ...tempObject,
      })
    );
    this.toggleDownloadModal();
  };

  handleExcelDownload = () => {
    const { pageObject } = this.state;
    const tempObject = {
      ...pageObject,
      page: 1,
    };

    if (this.context.decodedData.role !== "CUSTOMER_ADMIN") {
      delete tempObject.customerId;
    }

    this.props.dispatch(
      getReportsActions.getReportsExcelDownloads({
        ...tempObject,
      })
    );
    this.toggleDownloadModal();
  };

  openRowdetails = (order) => {
    this.setState({ selectedOrder: order }, () => {
      this.setState({ showModal: !this.state.showModal })
    })
  }

  toggelModal = () => {
    this.setState({ showModal: !this.state.showModal });
  }

  toggleDownloadModal = () => {
    this.setState({ showDownloadModal: !this.state.showDownloadModal });
  }

  handleDownload = () => {
    const { downloadOption } = this.state;
    if (downloadOption === 'pdf') {
      this.handlePDFDownload();
    }

    if (downloadOption === 'xlsx') {
      this.handleExcelDownload();
    }
  }

  toggleDownloadOptions = (e, val) => {
    if (e.target.checked) {
      this.setState({ downloadOption: val });
    }
  }

  handleOnBeforeGetContent = () => {
    this.setState({ hideElement: true });
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 100);
    });
  };

  handleAfterPrint = () => {
    this.setState({ hideElement: false });
  }

  renderModal = () => {
    const { showModal, selectedOrder, hideElement } = this.state;
    return (
      <Modal
        closeIcon={<CloseOutlined style={{ display: hideElement ? 'none' : 'inline-block', fontSize: 25, marginRight: 70, marginTop: 30, color: '#000000' }} />}
        className="report-modal"
        width={!hideElement ? '50%' : '100%'}
        centered
        title="Order Details"
        visible={showModal}
        onOk={this.toggelModal}
        onCancel={this.toggelModal}
        footer={[
          <Button style={{
            width: 130, color: '#5F738C', borderColor: '#7F91A8',
            display: hideElement ? 'none' : 'inline-block'
          }} key="back" size="large" onClick={this.toggelModal}>
            Cancel
          </Button>,
          <Fragment>
            <ReactToPrint
              trigger={() => <Button style={{ width: 130, display: hideElement ? 'none' : 'inline-block' }} key="submit" type="primary" className="report-btn" size="large">
                <PrinterOutlined style={{ fontSize: 20, verticalAlign: 'bottom' }} />
                Print
              </Button>
              }
              content={() => this.componentRef}
              onBeforeGetContent={this.handleOnBeforeGetContent}
              onAfterPrint={this.handleAfterPrint}
              pageStyle={pageStyle}
            />
          </Fragment>,
        ]}
        ref={(el) => (this.componentRef = el)}
      >
        <div className="row mr-5 ml-5 pb-0">
          {/* First row */}
          <div className="col-12 detail-row mt-3">
            <div className="row full-width ft-14">
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                <label className="d-block column-header">Vendor</label>
                <label className="column-body ">{selectedOrder.vendor}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Location</label>
                <label className="column-body">{selectedOrder.locationName}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Product</label>
                <label className="column-body">{selectedOrder.product}</label>
              </div>
            </div>
          </div>
          {/* second row */}
          <div className="col-12 detail-row mt-4 pb-3">
            <div className="row full-width">
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                <label className="d-block ft-14 column-header">Quantity Requested</label>
                <label className="column-body">{selectedOrder.quantityRequested}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Amount</label>
                <label className="column-body">{formatMoney(selectedOrder.orderAmount)}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Driver's Name</label>
                <label className="column-body">{selectedOrder.driverName}</label>
              </div>
            </div>
          </div>
          {/* third row */}
          <div className="col-12 detail-row mt-4 pb-3">
            <div className="row full-width">
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                <label className="d-block ft-14 column-header">Delivery Company</label>
                <label className="column-body">{selectedOrder.deliveryCompany}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Quantity Delivered</label>
                <label className="column-body">{selectedOrder.quantityDelivered}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Order ID</label>
                <label className="column-body">{selectedOrder.orderId}</label>
              </div>
            </div>
          </div>
          {/* fourth row */}
          <div className="col-12 detail-row mt-4 pb-3">
            <div className="row full-width">
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                <label className="d-block ft-14 column-header">Delivery Date</label>
                {selectedOrder.deliveryDate && <label className="column-body">{calcDate(new Date(selectedOrder.deliveryDate))}</label>}
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Payment Status</label>
                <label className="column-body">{selectedOrder.paymentStatus}</label>
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">
                <label className="d-block ft-14 column-header">Payment Reference</label>
                <label className="column-body">{selectedOrder.paymentReference}</label>
              </div>
            </div>
          </div>
          {/* fifth row */}
          <div className="col-12 detail-row no-border mt-4 pb-3">
            <div className="row full-width">
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0 text-left">
                <label className="d-block ft-14 column-header">Payment Date</label>
                {selectedOrder.paymentDate && <label className="column-body">{calcDate(new Date(
                  selectedOrder.paymentDate
                ))}</label>}
              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">

              </div>
              <div className="col-4 col-lg-4 col-sm-6 p-0 m-0">

              </div>
            </div>
          </div>
        </div>
      </Modal>)
  }

  renderDownloadModal = () => {
    const { showDownloadModal, downloadOption, pageObject: { deliveryStartDate, deliveryEndDate } } = this.state;
    return (
      <Modal
        closeIcon={<CloseOutlined style={{ fontSize: 20, marginRight: 70, marginTop: 30, color: '#000000' }} />}
        className="report-modal download"
        // width={'50%'}
        centered
        title="Download reports"
        visible={showDownloadModal}
        onOk={this.toggleDownloadModal}
        onCancel={this.toggleDownloadModal}

        footer={[
          <Button style={{ width: 130, color: '#5F738C', borderColor: '#7F91A8' }} key="back" size="large" onClick={this.toggleDownloadModal}>
            Cancel
          </Button>,
          <Button onClick={this.handleDownload} style={{ width: 130 }} key="submit" type="primary" className="report-btn" size="large">
            <DownloadOutlined style={{ fontSize: 20, verticalAlign: 'bottom' }} />
            Download
          </Button>
        ]}>
        <div>
          {(deliveryEndDate && deliveryStartDate) &&
            <div style={{
              width: 292
            }}>
              <div className="pb-0 mb-0"><label className="pb-0 mb-0 ft-12" style={{
                color: '#7F91A8'
              }}>From</label><label className="pb-0 mb-0 ft-12" style={{
                color: '#7F91A8',
                marginLeft: '50%'
              }}>To</label></div>
              <div className="d-flex date-display">
                <span className="pt-2">
                  <CalendarOutlined style={{ fontSize: 20, verticalAlign: 'bottom' }} />
                </span>
                <span className="center-date pt-2">
                  {calcDate(deliveryStartDate)}
                </span>
                <span className="pt-2">
                  {calcDate(deliveryEndDate)}
                </span>
              </div>
            </div>
          }
          <div className="mt-3">
            <label className="ft-16 ft-w600 d-block">Choose your preferred file format</label>
            <span><Checkbox checked={downloadOption === 'xlsx' ? true : false} onChange={(e) => this.toggleDownloadOptions(e, 'xlsx')}>xlsx</Checkbox></span>
            <span style={{
              display: 'inline-block',
              marginLeft: 55,
            }}><Checkbox checked={downloadOption === 'pdf' ? true : false} onChange={(e) => this.toggleDownloadOptions(e, 'pdf')}>pdf</Checkbox></span>
          </div>
          <div className="mt-3 mb-4 p-3" style={{
            height: 58,
            background: '#F2F3F5',
            borderRadius: 6,
            color: '#096DD9',
            width: '100%'
          }}>
            <FileOutlined style={{
              color: '#096DD9', fontSize: 30
            }} />
            <label className="ft-14 pl-5">report.{downloadOption}</label>
          </div>
        </div>
      </Modal>)
  }

  render() {
    const { searchKeyword, filterOption, filterOptions } = this.state;

    const {
      total,
      reports,
      // loading,
      // noOfPages,
      // sizeOfCurrentPage,
    } = this.props;
    const { loading, status, toggleModal } = this.context;

    return (
      <>
        <Sidebar
          resetSearch={this.getBySearch}
          search={this.state.searchKeyword}
          status={status}
          toggleModal={toggleModal}
        />
        {this.renderModal()}
        {this.renderDownloadModal()}
        <div className="isw-mainLayout1">
          <Spin tip="Please wait..." spinning={loading}>
            <div className="isw-content--wrapper">
              <MenuBar title="Reports" />
              <div className="content" id="content-body">
                <div className="container-fluid container-limited">
                  <div className="row mb-4 mt-3">
                    <div className="col-lg-12">
                      <div className="row mb-3">
                        <div className="col-lg-4 isw-valign--middle">
                          {this.renderPageTracker(total)}
                        </div>

                        <div className="col-lg-3 ml-0 d-flex justify-content-end">
                          <RangePicker
                            size='large'
                            className=""
                            placeholder="Delivery Date"
                            onChange={this.handleDateChange}
                          />
                        </div>

                        <form
                          className="mt-md-0 col-lg-5 ml-0 ml-md-auto"
                          onSubmit={this.getBySearch}
                        >
                          <Input
                            prefix={<SearchOutlined style={{ color: '#7F91A8', fontSize: '15px' }} />}
                            allowClear
                            size="large"
                            value={searchKeyword}
                            onChange={this.handleSearch}
                            onBlur={this.handleSearch}
                            className="filter-input"
                            placeholder="Filter..."
                          />
                          <Select
                            size="large"
                            className="select-height"
                            placeholder="Select Category"
                            style={{ width: "50%", height: "37px" }}
                            onChange={this.handleSelectChange}
                            onDropdownVisibleChange={this.handleClearselect}
                            value={filterOption}
                          >
                            {filterOptions.map((filter, index) => {
                              return (<Option key={`${index}-${filter.value}`} value={filter.value}>{filter.label}</Option>)
                            })}
                          </Select>
                        </form>
                      </div>
                      <div className="row mb-4">
                        <div className="col-12 mt-1">
                          <Button className="report-btn download-btn" onClick={this.toggleDownloadModal} type="primary" size="large">
                            <DownloadOutlined style={{ fontSize: 20, verticalAlign: 'bottom' }} />
                            Download Report
                          </Button>
                          {/* <Button
                            onClick={this.handleExcelDownload}
                            className="mr-1"
                          >
                            Download Reports as Excel
                          </Button>
                          <Button onClick={this.handlePDFDownload}>
                            Download Reports as PDF
                          </Button> */}
                        </div>
                      </div>
                      {/* <Spin spinning={this.state.loading} tip="Please wait"> */}
                      <div className="row mb-4 mt-3">
                        <div className="col-12">
                          <div className="table-container pb-4">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="pl-3">Vendor</th>
                                  <th>Location</th>
                                  <th>Product</th>
                                  <th>Quantity Requested</th>
                                  <th>Amount</th>
                                  <th>Delivery Company</th>
                                  <th>Quantity Delivered</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {reports.length !== 0
                                  ? reports.map((order, index) => {
                                    return (<tr key={index}>
                                      <td className="pl-3">{order.vendor}</td>
                                      <td>{order.locationName}</td>
                                      <td>{order.product}</td>
                                      <td>{order.quantityRequested}</td>
                                      <td>{formatMoney(order.orderAmount)}</td>
                                      <td>{order.deliveryCompany}</td>
                                      <td>{order.quantityDelivered}</td>
                                      <th><Button type="link"
                                        onClick={() => { this.openRowdetails(order) }}
                                        style={{
                                          color: '#096DD9',
                                          fontWeight: '500'
                                        }}>View</Button></th>
                                    </tr>)
                                  }) : (<tr><td style={{ textAlign: 'center' }} colSpan="8">{this.renderTableAbnormality(`No reports`)}</td></tr>)}
                              </tbody>
                            </table>
                            {this.renderPagination(total)}
                          </div>
                        </div>
                      </div>

                      {/* 
                      <div className="isw-table mb-3">
                        <ul className="isw-table--head d-none d-md-block">
                          <li className="isw-table--headLi">
                            <div className="row">
                              <div className="col p-0 text-center">
                                <span className="isw-table--headItem">
                                  Vendor Name
                                </span>
                              </div>
                              <div className="col p-0 text-center">
                                <span className="isw-table--headItem">
                                  Location
                                </span>
                              </div>
                              <div className="col p-0 text-center">
                                <span className="isw-table--headItem">
                                  Delivery company
                                </span>
                              </div>
                              <div className="col p-0 text-center">
                                <span className="isw-table--headItem">
                                  Driver Name
                                </span>
                              </div>
                              <div className="col p-0 text-center">
                                <span className="isw-table--headItem">
                                  Product
                                </span>
                              </div>
                              <div className="col p-0 text-center">
                                <span className="isw-table--headItem">
                                  Amount
                                </span>
                              </div>
                              <div className="col p-0 text-center">
                                <span className="isw-table--headItem">
                                  Order ID
                                </span>
                              </div>
                              <div className="col p-0 text-center">
                                <span className="isw-table--headItem">
                                  Quantity Requested
                                </span>
                              </div>
                              <div className="col p-0 text-center">
                                <span className="isw-table--headItem">
                                  Quantity Delivered
                                </span>
                              </div>
                              <div className="col p-0 text-center">
                                <span className="isw-table--headItem">
                                  Delivery Date
                                </span>
                              </div>
                              <div className="col p-0 text-center">
                                <span className="isw-table--headItem">
                                  Payment Date / Reference
                                </span>
                              </div>
                              <div className="col p-0 text-center">
                                <span className=" isw-table--headItem">
                                  Payment Status
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>

                        {total === 0 ? (
                          this.renderTableAbnormality(`No reports`)
                        ) : (
                          <ul className="isw-table--body">
                            {reports.map((report, index) => {
                              const {
                                driverName,
                                vendor,
                                locationName,
                                deliveryCompany,
                                product,
                                quantityRequested,
                                quantityDelivered,
                                deliveryDate,
                                amount,
                                orderId,
                                paymentDate,
                                paymentReference,
                                paymentStatus,
                                paymentMode
                              } = report;

                              return (
                                <li className="isw-table--bodyLi" key={index}>
                                  <span className="row isw-table--bodyA">
                                    <div className="col p-0 isw-valign--middle add-border">
                                      <span className="isw-p2">{vendor}</span>
                                    </div>
                                    <div className="col p-0 isw-valign--middle add-border">
                                      <span className="isw-p2">
                                        {locationName}
                                      </span>
                                    </div>
                                    <div className="col p-0 isw-valign--middle add-border">
                                      <span className="isw-p2">
                                        {deliveryCompany}
                                      </span>
                                    </div>

                                    <div className="col p-0 isw-valign--middle add-border">
                                      <span className="isw-p2">
                                        {driverName}
                                      </span>
                                    </div>

                                    <div className="col p-0 isw-valign--middle add-border">
                                      <span className="isw-p2">{product}</span>
                                    </div>

                                    <div className="col p-0 isw-valign--middle add-border">
                                      <span className="isw-p2">{amount}</span>
                                    </div>
                                    <div className="col p-0 isw-valign--middle add-border">
                                      <span className="isw-p2">{orderId}</span>
                                    </div>
                                    <div className="col p-0 isw-valign--middle add-border">
                                      <span className="text-center isw-p2">
                                        {quantityRequested}
                                      </span>
                                    </div>
                                    <div className="col p-0 isw-valign--middle add-border">
                                      <span className="text-center isw-p2">
                                        {quantityDelivered}
                                      </span>
                                    </div>
                                    <div className="col p-0 isw-valign--middle add-border">
                                      <span className="isw-p2">
                                        {new Date(
                                          deliveryDate
                                        ).toLocaleDateString()}
                                      </span>
                                    </div>
                                    <div className="col p-0 isw-valign--middle add-border">
                                      <span className="isw-p2">
                                        {paymentReference}<br />
                                        {paymentDate && new Date(
                                          paymentDate
                                        ).toLocaleDateString()}
                                      </span>
                                    </div>
                                    <div className="col p-0 isw-valign--middle no-border">
                                      {paymentMode === "4" && <span className="text-center isw-p2">
                                        {paymentStatus === "1" ? "Paid" : (paymentStatus === "2" ? "Pending" : (paymentStatus === "3" ? "Failed" : ""))}
                                      </span>}
                                    </div>
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                      {this.renderPagination(total)} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ getReports_reducer, storage_reducer }) => {
  const { reports, total, noOfPages, sizeOfCurrentPage } = getReports_reducer;
  const { decodedData } = storage_reducer;
  return {
    reports,
    total,
    noOfPages,
    sizeOfCurrentPage,
    decodedData,
  };
};

export default connect(mapStateToProps)(Reports);
