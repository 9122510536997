import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import {
    GET_VENDOR_WITH_PRODS,
    GET_VENDOR_WITH_PRODS_FAILURE,
    GET_VENDOR_WITH_PRODS_SUCCESS
} from "../../arsVariables";
import { Notify } from "../../../reuse/notify";
import { Actions } from "../../actions/_index";
import { errorHandler } from "../../../reuse/utilities";

function* getVendorWithProducts(payload) {
    try {
        const {
            data: {
                code,
                description,
                totalCount: totalVendors,
                payload: dataPayload
            }
        } = yield call(Services.getVendorWithProductsService, payload);
        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_VENDOR_WITH_PRODS_SUCCESS,
                vendorInfo: dataPayload,
                totalVendors
            });
        } else if (code !== 1) {
            Notify({ message: description, className: "error" });

            return yield put({
                type: GET_VENDOR_WITH_PRODS_FAILURE
            });
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_VENDOR_WITH_PRODS_FAILURE
        });
    }
}

export default function* getVendorWithProductsSaga() {
    yield takeLatest(GET_VENDOR_WITH_PRODS, getVendorWithProducts);
}
