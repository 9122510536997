import React from "react";
import { Fragment } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { GetProRevAnalyticsStart } from "../../../../store/actions";
// import ProductPurchasedChart from "./productsPurchasedChart";
// import RevenueChart from "./revenueChart";
import BranchOrderRequestChart from ".";
import BranchOrderChart from "./branchOrderChart.jsx";

export const OrdersChart = ({ period, isMobile, dataPeriodText }) => {
  const id = localStorage.getItem("vendorId");
  const dispatch = useDispatch();
  const { proRevPayload, proRevLoading } = useSelector(
    (state) => state.analytics
  ) || { proRevPayload: null, proRevLoading: false };
  const { branchOrders, isLoading } = useSelector(
    (state) => state?.getBranchOrder_reducer
  );
  console.log(period, dataPeriodText, branchOrders, "how far");
  useEffect(() => {
    // dispatch(GetProRevAnalyticsStart({ id, period }));
  }, [period]);

  let totalAmount = 0;
  const revenueData = useMemo(() => {
    if (proRevPayload && proRevPayload.length >= 1) {
      return proRevPayload.map((value) => {
        totalAmount += value.total;
        return {
          intervals: value.label,
          amount: value.total,
        };
      });
    }
  }, [proRevPayload]);

  const productPurchasedData = useMemo(() => {
    if (proRevPayload && proRevPayload.length >= 1) {
      return proRevPayload.map((value) => {
        return {
          name: value.label,
          ago: value.products.AGO,
          dpk: value.products.DPK,
          pms: value.products.PMS,
        };
      });
    }
  }, [proRevPayload]);
  let RevenueObject = { list: revenueData, total: totalAmount };
  return (
    <div className="row">
      <div className="col-lg-7 col-sm-12 mobile-margin">
        <BranchOrderChart
          data={branchOrders ? branchOrders : []}
          dataLoading={proRevLoading}
          dataPeriodText={dataPeriodText}
        />
      </div>
      <div className="col-lg-5 col-sm-12">
        <BranchOrderRequestChart
          data={RevenueObject}
          dataLoading={proRevLoading}
          dataPeriodText={dataPeriodText}
        />
      </div>
    </div>
  );
};

export default OrdersChart;
