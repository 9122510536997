import {
  VERIFYEMAIL_ONBOARD_SUCCESS,
  VERIFYEMAIL_ONBOARD_FAILURE,
} from "../../../redux-flow/arsVariables";
import { initialState } from "../../initialState";
const { localStorage } = window;

const verify_onboard_reducer = (state = initialState, action) => {
  // console.log("")
  const { type } = action;

  switch (type) {
    case VERIFYEMAIL_ONBOARD_SUCCESS:
      return {
        ...state,
        email: localStorage.getItem("email"),
      };
    case VERIFYEMAIL_ONBOARD_FAILURE:
      return {
        ...state,
        veirfyEmail: true,
      };
    default:
      return state;
  }
};

export default verify_onboard_reducer;
