import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import {
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS,
    GET_USERS
} from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* getUsers(payload) {
    try {
        const { error, data, status } = yield call(
            Services.getUsersService,
            payload
        );
        const {
            code,
            description,
            payload: payloadReturned,
            totalCount
        } = data;
        yield put(Actions.uiStop());
        yield put(Actions.miniUiStop());

        payloadReturned.forEach((item, index) =>
            localStorage.setItem(item.id, item.permissions)
        );
        if (error === false && status === 200 && code === 1) {
            return yield put({
                type: GET_USERS_SUCCESS,
                payloadReturned,
                totalCount
            });
        } else if (error === false && status === 200) {
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_USERS_FAILURE
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_USERS_FAILURE
        });
    }
}

export default function* getUsersSaga() {
    yield takeLatest(GET_USERS, getUsers);
}
