import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import { REQUEST_CONTRACT } from "../../arsVariables";
import { Actions } from "../../actions/_index";
import { Notify } from "../../../reuse/notify";
import { message } from "antd";
import { errorHandler } from "../../../reuse/utilities";
function* requestContract(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: { code, description }
        } = yield call(Services.requestContractService, payload);
        yield put(Actions.uiStop());
        if (code === 1) {
            message.success(description, 3);
            return yield put(
                Actions.getProdnContDetail({
                    vendorId: payload.payload.vendorId
                })
            );
        } else {
            message.error(description);
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
    }
}

export default function* requestContractSaga() {
    yield takeLatest(REQUEST_CONTRACT, requestContract);
}
