import {
    GET_ROLES_FAILURE,
    GET_ROLES_SUCCESS
} from "../../../../redux-flow/arsVariables";
import { initialState } from "../../../initialState";

const roles_reducer = (state = initialState, action) => {
    const { type,  payloadReturned } = action;
    switch (type) {
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: payloadReturned
            };
        case GET_ROLES_FAILURE:
            return {
                ...state,
            };


        default:
            return state;
    }
};

export default roles_reducer;
