import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import Sidebar from "../reuse/sidebar";
import { history } from "../reuse/history";
import DatePicker, {
  currentDate,
  pastDate,
  currentDate2,
} from "../reuse/datePicker";
import { Spin, Icon, Skeleton } from "antd";
import { formatMoney } from "../reuse/currencyHelper";
import BaseComponent from "../reuse/baseComponent";
import BranchOrderRequestChart from "../components/branchOrderRequest";
import { formatString } from "../utils/utils";
import { Select } from "antd";
import AssetUtilisationChart from "../components/branchOrderRequest/assetUtilisationChart";
import { CustomerAndTransactionTable } from "../components/branchOrderRequest/customerAndTransaction";
import moment from "moment";
import OrdersChart from "../components/branchOrderRequest/branchAndFulfilledOrder";
const { Option } = Select;

const antIcon = <Icon type="loading" style={{ fontSize: 16 }} spin />;

class DashboardAdmin extends BaseComponent {
  state = {
    actType: null,
    pageIndex: 1,
    pageSize: 10,
    activeBtnId: null,
    orderId: null,
    from: pastDate,
    to: currentDate2,
    count: 10,
    dataPeriod: "week",
    // RevenueData: this.WeeklyRevenueData,
    dataPeriodText: "this week",
    selectedOrderStatus: "yes-order",
    startDate: moment().subtract(29, "days"),
    endDate: moment(),
    pageConfig: {
      page: 1,
      pageSize: 20,
    },
    orderGraphData: [],
    orderByDriverGraphData: [],
    orderByFleetGraphData: [],
    screenWidth: window.screen.width,
    RevenueData: this.WeeklyRevenueData,
    selectedOrderStatus: "yes-order",
    orderData: this.weeklyOrder,
    period: "year",
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const customerId = localStorage.getItem("customerId");
    console.log("customerId", customerId);
    const { pageIndex, pageSize, from, to, count, period } = this.state;

    dispatch(
      Actions.getPendingOrder({
        pageIndex,
        pageSize,
      })
    );

    // Analytics Open
    dispatch(
      Actions.getBranchOrders({
        customerId,
        count,
      })
    );
    dispatch(
      Actions.getBranchFulfilledOrders({
        customerId,
        count,
      })
    );
    dispatch(
      Actions.getRecentOrders({
        vendorId: "70CB524D-E0D3-4A56-1E18-08D919271C72",
        period,
      })
    );
    dispatch(
      Actions.getBranchDeliveryOrders({
        customerId,
      })
    );
    // Analytics Closed

    dispatch(
      Actions.getVendorContracts({
        pageIndex,
        pageSize,
      })
    );

    this.fetchByDate({
      from,
      to,
    });
  }

  fetchByDate = ({ from, to }) => {
    const { dispatch } = this.props;

    dispatch(
      Actions.getCustomerOrderStats({
        from,
        to,
      })
    );
    dispatch(Actions.getCustomerProfileStats());
  };

  _reactToOrder = (e, orderId, type) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const payloadToSend = {
      orderId: [orderId],
      refNo: orderId,
      approve: type === "YES" ? true : false,
      note:
        type === "YES"
          ? `Verified on ${currentDate}`
          : `Declined on ${currentDate}`,
    };

    this.setState({
      actType: type,
      activeBtnId: orderId,
    });
    dispatch(Actions.uiStart());
    dispatch(Actions.miniUiStart());
    dispatch(Actions.verifyOrder(payloadToSend));
  };

  dateExtractor = ({ past, today }) => {
    this.setState(
      {
        from: past,
        to: today,
      },
      () =>
        this.fetchByDate({
          from: past,
          to: today,
        })
    );
  };

  ReturnSkeleton = () => {
    let { analytics } = this.props;
    const { loading } = analytics || {};
    return loading ? <Skeleton loading={loading} active /> : <></>;
  };

  changeDataPeriod = (period) => {
    this.setState({ dataPeriod: period });
    switch (period) {
      case "week":
        this.setState({ dataPeriodText: "this week" });
        break;
      case "month":
        this.setState({ dataPeriodText: "this month" });
        break;
      case "year":
        this.setState({ dataPeriodText: "this year" });
        break;
      default:
        this.setState({ dataPeriodText: "this week" });
        break;
    }
  };

  render() {
    const {
      vendorContracts,
      pendingOrders,
      orderStatistics,
      profileStatistics,
      branchDeliveryOrder,
      recentBranchOrders,
    } = this.props;

    console.log("props:::", recentBranchOrders);
    const { decodedData, loading, status, toggleModal, isLoading } =
      this.context;
    const { pendingOnVendor = [], pendingOnAdmin = [] } = pendingOrders;

    const { CRA_01, CRA_02 } = decodedData;

    const {
      orderCount = "-",
      orderQuantity = "-",
      orderAmount = "-",
      cancelledOrderCount = "-",
      cancelledOrderQuantity = "-",
      cancelledOrderAmount = "-",
    } = orderStatistics;

    const { activeLocationCount = "-", activeProfileCount = "-" } =
      profileStatistics;
    const { actType, activeBtnId, from, to } = this.state;
    let { dataPeriod, dataPeriodText } = this.state;
    return (
      <Fragment>
        <Sidebar
          search={this.state.SearchKeyword}
          status={status}
          toggleModal={toggleModal}
        />
        <div className="isw-mainLayout1">
          {/* <Spin tip="Please wait..." spinning={false}> */}
          <div className="isw-content--wrapper">
            {/* <MenuBar /> */}
            <div>
              <div className="content" id="content-body">
                <div className="container-fluid container-limited">
                  <div className="row">
                    <div className="col-12">
                      <label className="welcome-txt">
                        Hello {localStorage.getItem("firstName") || "Vendor"}!
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="row">
                        <div className="col-lg-6 col-sm-12 col-md-6">
                          <div className="card dash-card">
                            <div>
                              {branchDeliveryOrder && (
                                <h3 className="card-numb">
                                  {branchDeliveryOrder?.orders?.branched?.num}
                                </h3>
                              )}
                              <this.ReturnSkeleton />
                              <span className="d-block sub-numb">
                                Orders From Branch
                              </span>
                              {branchDeliveryOrder?.orders && (
                                <label className="hint">
                                  {branchDeliveryOrder?.orders?.branched.vol}{" "}
                                  Litres
                                </label>
                              )}
                              <this.ReturnSkeleton />
                            </div>
                            <div className="card-base">
                              <button
                                className="p-0"
                                onClick={() =>
                                  history.push("/vendor/pendingorders")
                                }
                              >
                                View Requests
                              </button>
                              <button
                                className="p-0"
                                onClick={() =>
                                  history.push("/vendor/pendingorders")
                                }
                              >
                                {" "}
                                <i className="material-icons">
                                  keyboard_arrow_right
                                </i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 col-md-6">
                          <div className="card dash-card">
                            <div>
                              {branchDeliveryOrder?.orders &&
                                branchDeliveryOrder?.orders?.fulfilled && (
                                  <h3 className="card-numb">
                                    {branchDeliveryOrder?.orders?.fulfilled.num}
                                  </h3>
                                )}
                              <this.ReturnSkeleton />
                              <span className="d-block sub-numb">
                                All Fulfilled Orders
                              </span>
                              <this.ReturnSkeleton />
                              {branchDeliveryOrder?.orders &&
                                branchDeliveryOrder?.orders?.fulfilled && (
                                  <label className="hint">
                                    {branchDeliveryOrder?.orders?.fulfilled.vol}{" "}
                                    Litres
                                  </label>
                                )}
                            </div>
                            <div className="card-base">
                              <button
                                className="p-0"
                                onClick={() =>
                                  history.push("/vendor/scheduledelivery")
                                }
                              >
                                View Requests
                              </button>
                              <button
                                className="p-0"
                                onClick={() =>
                                  history.push("/vendor/scheduledelivery")
                                }
                              >
                                {" "}
                                <i className="material-icons">
                                  keyboard_arrow_right
                                </i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 col-md-4">
                          <div className="card delivery-card">
                            <header className="card-title-new">
                              Number Of vendors
                            </header>
                            <div className="d-flex " style={{ columnGap: 16 }}>
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ columnGap: 4 }}
                              >
                                <span className="sub-circle sub-circle-active"></span>{" "}
                                <this.ReturnSkeleton />
                                {branchDeliveryOrder?.locations && (
                                  <span className="hint-active">
                                    {branchDeliveryOrder?.locations.active}
                                  </span>
                                )}{" "}
                                <span className="sub-active">active</span>{" "}
                              </div>{" "}
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ columnGap: 4 }}
                              >
                                <span className="sub-circle sub-circle-inactive"></span>{" "}
                                <this.ReturnSkeleton />{" "}
                                {branchDeliveryOrder?.locations && (
                                  <span className="hint-inactive">
                                    {branchDeliveryOrder?.locations.inactive}
                                  </span>
                                )}
                                <span className="sub-inactive">inactive</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div
                                className="d-flex"
                                style={{ columnGap: 24, alignItems: "center" }}
                              >
                                <this.ReturnSkeleton />
                                {branchDeliveryOrder?.locations && (
                                  <h3 className="card-numb">
                                    {branchDeliveryOrder?.locations.total}
                                  </h3>
                                )}
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  onClick={() =>
                                    history.push("/vendor/pendingdelivery")
                                  }
                                  className="dash-link"
                                >
                                  {this.state.screenWidth <= 500
                                    ? `View`
                                    : "View All"}
                                </a>
                                <a
                                  onClick={() =>
                                    history.push("/vendor/pendingdelivery")
                                  }
                                  className="dash-link icon"
                                >
                                  {" "}
                                  <i className="material-icons">
                                    keyboard_arrow_right
                                  </i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 col-md-4">
                          <div className="card delivery-card">
                            <header className="card-title-new">
                              Number of Staff
                            </header>
                            <div className="d-flex " style={{ columnGap: 16 }}>
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ columnGap: 4 }}
                              >
                                <span className="sub-circle sub-circle-active"></span>{" "}
                                <this.ReturnSkeleton />
                                {branchDeliveryOrder?.staffs && (
                                  <span className="hint-active">
                                    {branchDeliveryOrder?.staffs.active}
                                  </span>
                                )}{" "}
                                <span className="sub-active">active</span>{" "}
                              </div>{" "}
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ columnGap: 4 }}
                              >
                                <span className="sub-circle sub-circle-inactive"></span>{" "}
                                <this.ReturnSkeleton />{" "}
                                {branchDeliveryOrder?.staffs && (
                                  <span className="hint-inactive">
                                    {branchDeliveryOrder?.staffs.inactive}
                                  </span>
                                )}
                                <span className="sub-inactive">inactive</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div
                                className="d-flex"
                                style={{ columnGap: 24, alignItems: "center" }}
                              >
                                <this.ReturnSkeleton />
                                {branchDeliveryOrder?.staffs && (
                                  <h3 className="card-numb">
                                    {branchDeliveryOrder?.staffs.total}
                                  </h3>
                                )}
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  onClick={() =>
                                    history.push("/vendor/pendingdelivery")
                                  }
                                  className="dash-link"
                                >
                                  {this.state.screenWidth <= 500
                                    ? `View`
                                    : "View All"}
                                </a>
                                <a
                                  onClick={() =>
                                    history.push("/vendor/pendingdelivery")
                                  }
                                  className="dash-link icon"
                                >
                                  {" "}
                                  <i className="material-icons">
                                    keyboard_arrow_right
                                  </i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 col-md-4">
                          <div className="card delivery-card">
                            <header className="card-title-new">
                              Number of Vendors
                            </header>
                            <div className="d-flex " style={{ columnGap: 16 }}>
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ columnGap: 4 }}
                              >
                                <span className="sub-circle sub-circle-active"></span>{" "}
                                <this.ReturnSkeleton />
                                {branchDeliveryOrder?.vendors && (
                                  <span className="hint-active">
                                    {branchDeliveryOrder?.vendors.incontract}
                                  </span>
                                )}{" "}
                                <span className="sub-active">In-Contract</span>{" "}
                              </div>{" "}
                              <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ columnGap: 4 }}
                              >
                                <span className="sub-circle sub-circle-inactive"></span>{" "}
                                <this.ReturnSkeleton />{" "}
                                {branchDeliveryOrder?.vendors && (
                                  <span className="hint-inactive">
                                    {branchDeliveryOrder?.vendors.nocontract}
                                  </span>
                                )}
                                <span className="sub-inactive">
                                  Out-Of-Contract
                                </span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div
                                className="d-flex"
                                style={{ columnGap: 24, alignItems: "center" }}
                              >
                                <this.ReturnSkeleton />
                                {branchDeliveryOrder?.vendors && (
                                  <h3 className="card-numb">
                                    {branchDeliveryOrder?.vendors.total}
                                  </h3>
                                )}
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  onClick={() =>
                                    history.push("/vendor/pendingdelivery")
                                  }
                                  className="dash-link"
                                >
                                  {this.state.screenWidth <= 500
                                    ? `View`
                                    : "View All"}
                                </a>
                                <a
                                  onClick={() =>
                                    history.push("/vendor/pendingdelivery")
                                  }
                                  className="dash-link icon"
                                >
                                  {" "}
                                  <i className="material-icons">
                                    keyboard_arrow_right
                                  </i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-sm-12">
                        <div className="card dash-right-card">
                          <div style={{ minHeight: "50%" }}>
                            <div className="d-flex dash-card-section">
                              <img
                                src={PendingCusIcon}
                                alt="Pending Contracts"
                              />
                              <div>
                                <span className="hint d-block">
                                  PENDING CONTRACTS
                                </span>
                                <span>Customer agreements</span>
                              </div>
                            </div>
                            <this.ReturnSkeleton />
                            <div className="d-flex justify-content-between">
                              {!anayliticLoading && (
                                <h3 className="card-numb">
                                  {pending_contracts}
                                </h3>
                              )}
                              <div className="d-flex align-items-center">
                                <button
                                  onClick={() =>
                                    history.push("/vendor/customers")
                                  }
                                  className="dash-link"
                                >
                                  View All
                                </button>
                                <button
                                  onClick={() =>
                                    history.push("/vendor/customers")
                                  }
                                  className="dash-link icon"
                                >
                                  {" "}
                                  <i className="material-icons">
                                    keyboard_arrow_right
                                  </i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="line-break"></div>
                          <div>
                            <div className="d-flex dash-card-section">
                              <img src={TotalCusIcon} alt="Total Customers" />
                              <div>
                                <span className="hint d-block">
                                  TOTAL CUSTOMERS
                                </span>
                                <span>Unique corporate customers served</span>
                              </div>
                            </div>
                            <this.ReturnSkeleton />
                            <div className="d-flex justify-content-between">
                              {!anayliticLoading && (
                                <h3 className="card-numb">{total_customers}</h3>
                              )}
                              <div className="d-flex align-items-center"></div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                  </div>
                  <div className="row card graph-section">
                    <div className="col-12 graph-btn-section pl-0 pr-0  d-desktop">
                      <label className="filter-label">
                        Data Analysis of All Orders {dataPeriodText}
                      </label>
                      <div>
                        <button
                          onClick={() => this.changeDataPeriod("week")}
                          className={`${
                            dataPeriod === "week" ? "active" : ""
                          } filter-btn first`}
                        >
                          {" "}
                          This Week
                        </button>
                        <button
                          onClick={() => this.changeDataPeriod("month")}
                          className={`${
                            dataPeriod === "month" ? "active" : ""
                          } filter-btn`}
                        >
                          {" "}
                          This Month
                        </button>
                        <button
                          onClick={() => this.changeDataPeriod("year")}
                          className={`${
                            dataPeriod === "year" ? "active" : ""
                          } filter-btn last`}
                        >
                          This Year
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-12 graph-btn-section pl-0 pr-0 d-fl-mobile">
                      <label className="filter-label">
                        {formatString(dataPeriodText)}
                      </label>
                      <Select
                        value={dataPeriod}
                        style={{
                          height: 36,
                          width: 113,
                          backgroundColor: "#F3F5F6",
                          borderRadius: 4,
                        }}
                        onChange={this.changeDataPeriod}
                        defaultValue="week"
                      >
                        <Option value="week">This Week</Option>
                        <Option value="month">This Month</Option>
                        <Option value="year">This Year</Option>
                      </Select>
                    </div> */}
                    {/* <div className="col-sm-12 col-lg-7"> */}
                    <OrdersChart
                      isMobile={this.state?.screenWidth <= 500}
                      period={dataPeriod}
                      dataPeriodText={dataPeriodText}
                    />
                    {/* </div> */}
                    <div className="graph-divider"></div>
                    <div className="row">
                      <div className="col-sm-12 col-lg-7">
                        {/* <BranchOrderRequestChart
                          isMobile={this.state?.screenWidth <= 500}
                          period={dataPeriod}
                          dataPeriodText={dataPeriodText}
                        /> */}
                      </div>
                      {this.state.screenWidth <= 500 && (
                        <div className="col-12 pl-0 pr-0 graph-divider d-mobile"></div>
                      )}
                      {/* Asset utilization chart */}
                      {/* <div className="col-sm-12 col-lg-5">
                        <AssetUtilisationChart
                          isMobile={this.state.screenWidth <= 500}
                        />
                      </div> */}
                    </div>
                    <div className="graph-divider"></div>
                    {/* <div className="row">
                      <CustomerAndTransactionTable
                        period={this.state.period}
                        recent={recentBranchOrders}
                      />
                    </div> */}
                    {/* <div className="row">
                      <CustomerAndTransactionTable period={dataPeriod} />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </Spin> */}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  getPendingOrder_reducer,
  getVendorContracts_reducer,
  getCustomerOrderStats_reducer,
  getCustomerProfileStats_reducer,
  getBranchOrder_reducer,
  getBranchFulfilledOrder_reducer,
  getBranchOrderDeliveries_reducer,
  getBranchRecentOrders_reducer,
}) => {
  const { pendingOrders } = getPendingOrder_reducer;
  const { vendorContracts } = getVendorContracts_reducer;
  const { orderStatistics } = getCustomerOrderStats_reducer;
  const { profileStatistics } = getCustomerProfileStats_reducer;
  const { branchOrders } = getBranchOrder_reducer;
  const { fulfilledOrders } = getBranchFulfilledOrder_reducer;
  const { branchDeliveryOrder } = getBranchOrderDeliveries_reducer;
  const { recentBranchOrders } = getBranchRecentOrders_reducer;
  return {
    pendingOrders,
    vendorContracts,
    orderStatistics,
    profileStatistics,
    branchOrders,
    fulfilledOrders,
    branchDeliveryOrder,
    recentBranchOrders,
  };
};
export default connect(mapStateToProps)(DashboardAdmin);
