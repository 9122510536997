import React, { Fragment, Component } from "react";
import { Outlet } from 'react-router-dom';
import { connect } from "react-redux";
import { history } from "./history";

class AuthLayout extends Component {
    componentDidMount() {
        const { fakeAuth, location } = this.props;

        if (
            fakeAuth.isAuthenticated &&
            !location.pathname.includes("resetpassword")
        ) {
            history.push({ pathname: "/customer/dashboard" });
        }
    }

    render() {
        // component and props passed are accessed
        // const { children, loading, isLoading, fakeAuth } = this.props;

        return (
            <Fragment>
                <div>
                    <Outlet context={this.props} />
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ ui_reducer }) => {
    const { loading, isLoading } = ui_reducer;

    return {
        loading,
        isLoading
    };
};

export default connect(mapStateToProps)(AuthLayout);
