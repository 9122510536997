import React, { Fragment, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import Sidebar from "../reuse/sidebar";
import { Spin } from "antd";
import { CUSTOMER_ADMIN } from "../redux-flow/arsVariables";

import Icon from "@material-ui/core/Icon";
import BaseComponent from "../reuse/baseComponent";
import MenuBar from "../reuse/MenuBar";
const CreateUser = lazy(() => import("../components/Create_User"));

class Staff extends BaseComponent {
  state = {
    pageIndex: 1,
    pageSize: 10,
    SearchKeyword: "",
  };

  componentDidMount() {
    this.fetch({ ...this.state });
  }

  fetch = ({ pageIndex, pageSize, SearchKeyword = "" }) => {
    const { dispatch } = this.props;
    dispatch(Actions.uiStart());
    dispatch(
      Actions.getUsers({
        pageIndex,
        pageSize,
        SearchKeyword,
      })
    );
  };

  _toggleRightSidebar = (type) => {
    const { dispatch, toggleRightSidebar } = this.props;
    if (toggleRightSidebar === type) {
      dispatch(Actions.toggleRightSidebar(false));
    } else {
      dispatch(Actions.toggleRightSidebar(type));
    }
  };

  changeUserRole = (e, userId, role) => {
    e.preventDefault();

    this.props.dispatch(
      Actions.changeUserRole({
        userId,
        newRole: role.includes("USER") ? 8 : 9,
        pageIndex: this.state.pageIndex,
      })
    );
  };

  render() {
    const { pageIndex, pageSize, SearchKeyword } = this.state;

    const {
      users,
      usersLength,
      // decodedData: { role, CRA_01 },
      // toggleRightSidebar,
      // userId,
      // loading,
    } = this.props;

    const { userId, loading, decodedData: { role, CRA_01 }, toggleModal, status, toggleRightSidebar } = this.context;

    /* const searchBox = () => (
            <form className="col-5" onSubmit={this.getBySearch}>
                <input
                    value={SearchKeyword}
                    onChange={this.handleSearch}
                    onBlur={this.handleSearch}
                    className="isw-input-search"
                    placeholder="Search"
                />
            </form>
        ); */

    return (
      <Fragment>
        <Sidebar
          resetSearch={this.getBySearch}
          search={this.state.SearchKeyword}
          status={status}
          toggleModal={toggleModal}
        />

        <div className="isw-mainLayout1">
          <Spin tip="Please wait..." spinning={loading}>
            <div className="isw-content--wrapper">
              <MenuBar
                title="Staff"
                currentText={SearchKeyword}
                onChangeHandler={this.handleSearch}
                onSubmitHandler={this.getBySearch}
              />
              <div>
                <div className="content" id="content-body">
                  <div className={`container-fluid container-limited`}>
                    <div className="row my-4">
                      <div className="col-lg-9 isw-valign--middle mr-auto">
                        {this.renderPageTracker(usersLength)}
                      </div>

                      {!CRA_01 ? (
                        <>{/* searchBox() */}</>
                      ) : (
                        <Fragment>
                          {/* {searchBox()} */}
                          <div className="col-lg-3 text-right">
                            <span
                              data-toggle="modal"
                              data-target="#exampleModal"
                              className="isw-btn isw-btn--raised bg-primary text-white w-100"
                            >
                              <Icon>group_add</Icon>
                              <span>Add Staff</span>
                            </span>
                          </div>
                        </Fragment>
                      )}
                    </div>
                    <div id="exampleModal" className="modal fade" role="dialog">
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div className="isw-card-header p-0">
                              <h3 className="isw-card-h3">Create User</h3>
                              <div className="isw-card-p">
                                Select create user type
                              </div>
                            </div>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body p-5">
                            <div className="d-flex justify-content-between">
                              <span
                                className="d-flex flex-column justify-content-center py-3 px-5 border isw-hoverClick"
                                data-dismiss="modal"
                                onClick={() =>
                                  this._toggleRightSidebar("single")
                                }
                              >
                                <i
                                  className="material-icons text-center"
                                  style={{
                                    fontSize: `${3}em`,
                                  }}
                                >
                                  person_outline
                                </i>
                                <h6 className="mb-0">Single User</h6>
                              </span>

                              <span
                                className="d-flex flex-column justify-content-center py-3 px-5 border isw-hoverClick"
                                data-dismiss="modal"
                                onClick={() => this._toggleRightSidebar("bulk")}
                              >
                                <i
                                  className="material-icons text-center"
                                  style={{
                                    fontSize: `${3}em`,
                                  }}
                                >
                                  people_outline
                                </i>
                                <h6 className="mb-0">Bulk Users</h6>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-12"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className="row mb-4 mt-3">
                          <div className="col-12">
                            <div className="isw-table">
                              <ul className="isw-table--head">
                                <li className="isw-table--headLi">
                                  {role === CUSTOMER_ADMIN ? (
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <span className="isw-table--headItem">
                                          Name
                                        </span>
                                      </div>
                                      <div className="col-lg-3">
                                        <span className="isw-table--headItem">
                                          Phone Number
                                        </span>
                                      </div>
                                      <div className="col-lg-2">
                                        <span className="isw-table--headItem">
                                          Status
                                        </span>
                                      </div>
                                      <div className="col-lg-2">
                                        <span className="isw-table--headItem">
                                          Role
                                        </span>
                                      </div>
                                      <div className="col-lg-1">
                                        <span className="isw-table--headItem">
                                          Action
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <span className="isw-table--headItem">
                                          Name
                                        </span>
                                      </div>
                                      <div className="col-lg-3">
                                        <span className="isw-table--headItem">
                                          Phone Number
                                        </span>
                                      </div>
                                      <div className="col-lg-3">
                                        <span className="isw-table--headItem">
                                          Status
                                        </span>
                                      </div>
                                      <div className="col-lg-2">
                                        <span className="isw-table--headItem">
                                          Role
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </li>
                              </ul>
                              {usersLength === 0 ? (
                                this.renderTableAbnormality(
                                  `No user(s) created`
                                )
                              ) : !usersLength ? (
                                this.renderTableAbnormality()
                              ) : (
                                <ul className="isw-table--body">
                                  {users.map((user) => {
                                    const {
                                      activated,
                                      firstName,
                                      lastName,
                                      phoneNumber,
                                      id,
                                      role: userRole,
                                    } = user;
                                    return (
                                      <li
                                        className="isw-table--bodyLi"
                                        key={id.toString()}
                                      >
                                        {role === CUSTOMER_ADMIN ? (
                                          <div className="row isw-table--bodyA">
                                            <div className="col-lg-4 isw-valign--middle">
                                              <h3 className="isw-subtitle">
                                                {`${firstName} ${lastName}`}
                                              </h3>
                                            </div>
                                            <div className="col-lg-3 isw-valign--middle">
                                              <span className="isw-p2">
                                                {phoneNumber}
                                              </span>
                                            </div>

                                            <div className="col-lg-2 isw-valign--middle">
                                              <span className="isw-p2">
                                                {activated
                                                  ? "Active"
                                                  : "Inactive"}
                                              </span>
                                            </div>

                                            <div className="col-lg-2 isw-valign--middle">
                                              <span className="isw-p2">
                                                {userRole.split(" ")[1]}
                                              </span>
                                            </div>
                                            <div className="col-lg-1 isw-valign--middle text-center">
                                              {this.renderTableDropdown({
                                                activated,
                                                id,
                                                component: "users",
                                                changeUserRole: this
                                                  .changeUserRole,
                                                role: userRole.split(" ")[1],
                                              })}
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="row isw-table--bodyA">
                                            <div className="col-lg-4 isw-valign--middle">
                                              <h3 className="isw-subtitle">
                                                {`${firstName} ${lastName}`}
                                              </h3>
                                            </div>
                                            <div className="col-lg-3 isw-valign--middle">
                                              <span className="isw-p2">
                                                {phoneNumber}
                                              </span>
                                            </div>

                                            <div className="col-lg-3 isw-valign--middle">
                                              <span className="isw-p2">
                                                {activated
                                                  ? "Active"
                                                  : "Inactive"}
                                              </span>
                                            </div>

                                            <div className="col-lg-2 isw-valign--middle">
                                              <span className="isw-p2">
                                                {userRole}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                        {this.renderPagination(usersLength)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
        {!CRA_01 ? null : (
          <Suspense fallback={<Spin spinning={true} tip="Loading..." />}>
            <CreateUser
              fetch={this.fetch}
              toggleRightSidebar={toggleRightSidebar}
              pageIndex={pageIndex}
              pageSize={pageSize}
              userId={userId}
            />
          </Suspense>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ getUsers_reducer }) => {
  const { users, usersLength } = getUsers_reducer;
  return {
    users,
    usersLength,
  };
};
export default connect(mapStateToProps)(Staff);
