import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import {
    GET_CUSTOMER_ORDER_STATS,
    GET_CUSTOMER_ORDER_STATS_FAILURE,
    GET_CUSTOMER_ORDER_STATS_SUCCESS
} from "../../arsVariables";
import { Notify } from "../../../reuse/notify";
import { Actions } from "../../actions/_index";
import { errorHandler } from "../../../reuse/utilities";

function* getCustomerOrderStats(payload) {
    try {
        const {
            data: { code, description, totalCount, payload: dataPayload }
        } = yield call(Services.getCustomerOrderStatsService, payload);

        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_CUSTOMER_ORDER_STATS_SUCCESS,
                payloadReturned: dataPayload,
                totalCount
            });
        } else if (code !== 1) {
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_CUSTOMER_ORDER_STATS_FAILURE
            });
        }
    } catch (err) {
        yield put(Actions.uiStop());
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
    }
}

export default function* getCustomerOrderStatsSaga() {
    yield takeLatest(GET_CUSTOMER_ORDER_STATS, getCustomerOrderStats);
}
