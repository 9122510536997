import React, {useState} from "react";
import OtpInput from "react-otp-input";
import {Modal, message} from "antd";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Services from "../redux-flow/services/services";
import {Actions} from "../redux-flow/actions/_index";
import {TextField} from "@material-ui/core";
import {validateDigit} from "./regexes";

import NumberFormat from "react-number-format";

const NumberFormatCustom = props => {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
};

const OtpModal = ({visible, openModal, scheduleId, dispatch, isLoading}) => {
    const [code, setCode] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [error, setError] = useState(null);

    const submitCode = () => {
        if (quantity && (!code || code.length < 4))
            return setError("Please enter a valid verification code!");
        else if (!quantity && code)
            return setError("Please enter quantity received!");

        else if (!quantity && (!code || code.length < 4))
            return setError(
                "Please enter a valid verification code and quantity received!"
            );

        dispatch(Actions.miniUiStart());
        Services.submitVerificationCodeService({
            payload: {
                scheduleId,
                quantity,
                code
            }
        })
            .then(res => {
                const {data} = res;
                const {code, description} = data;
                dispatch(Actions.miniUiStop());
                if (code !== 1) {
                    message.error(description);
                } else {
                    openModal();
                    setCode(null);
                    setQuantity(null);
                    message.success(description);
                }
            })
            .catch(e => {
                dispatch(Actions.miniUiStop());
            });
    };

    return (
        <Modal
            title="Enter Verification Code"
            visible={visible}
            onOk={submitCode}
            onCancel={() => {
                setCode(null);
                setError(null);
                setQuantity(null);
                return openModal();
            }}
            confirmLoading={isLoading}
            cancelText="Cancel"
            okText="Submit"
            className="otp-modal"
            centered>
            <OtpInput
                onChange={otp => {
                    setError(null);
                    return setCode(otp);
                }}
                numInputs={4}
                containerStyle={{
                    alignItems: "center",
                    justifyContent: "center"
                }}
                inputStyle={{
                    border: "1px solid #e8e8e8",
                    width: "36px",
                    height: "36px",
                    margin: "0 10px",
                    fontSize: "18px"
                }}
                value={code}
                focusStyle={{color: "#00425f"}}
                shouldAutoFocus
            />

            <div className="form-field mt-3">
                <div className="form-field__control">
                    <TextField
                        id="cssTarget"
                        error={error && error.indexOf("quantity") !== -1}
                        type="text"
                        className="form-field__input"
                        label="Enter Quantity Received"
                        value={quantity}
                        onChange={({target: {value}}) => {
                            setError(null);
                            return validateDigit(value)
                                ? setQuantity(Number(value))
                                : null;
                        }}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                    />
                </div>
            </div>
            {error && (
                <small style={{color: "#ee312a", marginTop: "10px"}}>
                    {error}
                </small>
            )}
        </Modal>
    );
};

OtpModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    scheduleId: PropTypes.string.isRequired
};

const mapStateToProps = ({ui_reducer}) => {
    const {isLoading} = ui_reducer;

    return {
        isLoading
    };
};

export default connect(mapStateToProps)(OtpModal);
