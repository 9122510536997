import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export class ErrorHandler extends Component {
    state = {
        error: false,
        info: ""
    };

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error: true };
    }
    componentDidCatch(error, info) {
        this.setState({ error, info });
    }

    render() {
        const { error } = this.state;

        if (error) {
            return (
                <div className="container-404">
                    <section>
                        <h1>Whoops!!!, an error occurred</h1>
                        <span>
                            <NavLink to="/customer/users">
                                Click here to go back to dashboard
                            </NavLink>
                        </span>
                    </section>
                </div>
            );
        } else if (!error) {
            return this.props.children;
        }
    }
}

export default ErrorHandler;
