import {call, put, takeLatest} from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import {VERIFY_PROFILE} from "../../redux-flow/arsVariables";
import {Actions} from "../actions/_index";
import {Notify} from "../../reuse/notify";
import {history} from "../../reuse/history";
import { url} from "../../helper";

function* verifyProfile(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: {code, description}
        } = yield call(Services.verifyProfileService, payload);

        yield put(Actions.uiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        if (code === 1)
            setTimeout(() => {
                history.push({pathname: url});
            }, 1500);
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
    }
}

export default function* verifyProfileSaga() {
    yield takeLatest(VERIFY_PROFILE, verifyProfile);
}
