import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../../../redux-flow/services/services";

import {
    GET_PERMISSIONS_FAILURE,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS
} from "../../../../redux-flow/arsVariables";
import { Actions } from "../../../actions/_index";
import { Notify } from "../../../../reuse/notify";
import { errorHandler } from "../../../../reuse/utilities";

function* permissions(payload) {
    try {
        const {
            data: { code, description, payload: payloadReturned }
        } = yield call(Services.permissionsService, payload);
        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_PERMISSIONS_SUCCESS,
                payloadReturned
            });
        } else if (code !== 1) {
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_PERMISSIONS_FAILURE
            });
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_PERMISSIONS_FAILURE
        });
    }
}

export default function* permissionsSaga() {
    yield takeLatest(GET_PERMISSIONS, permissions);
}
