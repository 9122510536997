import {
    GET_USER_LOCATIONS_FAILURE,
    GET_USER_LOCATIONS_SUCCESS
} from "../arsVariables";
import { initialState } from "../initialState";

const getUserLocations_reducer = (state = initialState, action) => {
    const { type, payload, totalCount } = action;

    switch (type) {
        case GET_USER_LOCATIONS_SUCCESS:
            return {
                ...state,
                userLocations: payload,
                userLocationsCount: totalCount
            };
        case GET_USER_LOCATIONS_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getUserLocations_reducer;
