/* global  */

import React, { Component } from "react";
import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    Marker,
    InfoWindow,
    DirectionsRenderer
} from "react-google-maps";
import { withProps, compose } from "recompose";
import { genericProps } from "./map";

class MapWithADirectionsRenderer extends Component {
    state = {
        directions: []
    };
    componentDidUpdate() {
        this.drawDirection();
    }

    componentDidMount() {
        this.drawDirection();
    }

    drawDirection = () => {
        const { google } = window;
        const { places } = this.props;
        let waypoints = places.map(({ longitude, latitude }) => {
            return {
                location: {
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude)
                }
            };
        });

        const directionsService = new google.maps.DirectionsService();
        directionsService.route(
            {
                destination: waypoints.shift().location,
                origin: waypoints.pop().location,
                travelMode: google.maps.TravelMode.DRIVING,
                waypoints: waypoints,
                provideRouteAlternatives: false
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.setState({ directions: result });
                } else {
                }
            }
        );
    };
    render() {
        const { directions } = this.state;

        return directions && <DirectionsRenderer directions={directions} />;
    }
}

const MultipleLocationMap = compose(
    withProps({
        ...genericProps,
        containerElement: <div style={{ height: `635px` }} />
    }),
    withScriptjs,
    withGoogleMap
)(
    ({
        onMapMounted,
        handleMapChanged,
        handleMapFullyLoaded,
        zoom,
        locations,
        clickTooltip,
        closeWindow,
        trackOrder,
        destination,
        origin
    }) => {
        return (
            <GoogleMap
                ref={onMapMounted}
                onZoomChanged={handleMapChanged}
                onDragEnd={handleMapChanged}
                onBoundsChanged={handleMapFullyLoaded}
                defaultCenter={{ lat: 6.5244, lng: 3.3792 }}
                defaultZoom={zoom}>
                {locations.map(
                    ({
                        latitude,
                        longitude,
                        company,
                        product,
                        location,
                        id,
                        selected
                    }) => (
                        <Marker
                            markerWithLabel={window.MarkerWithLabel}
                            onClick={clickTooltip.bind(this)}
                            icon={{
                                url:
                                    "http://icons.iconarchive.com/icons/paomedia/small-n-flat/24/map-marker-icon.png"
                            }}
                            hoverIcon={{
                                url:
                                    "http://icons.iconarchive.com/icons/paomedia/small-n-flat/24/map-marker-icon.png"
                            }}
                            position={{
                                lat: parseFloat(latitude),
                                lng: parseFloat(longitude)
                            }}
                            labelClass="map-price-container"
                            labelContent={`<div class="map-price-marker"><span>600</span></div>`}
                            key={`marker${id}`}>
                            {selected && (
                                <InfoWindow onCloseClick={closeWindow}>
                                    <div>
                                        <h3>Customer: {company}</h3>
                                        <span>Product: {product}</span>
                                        <span> Location: {location}</span>
                                    </div>
                                </InfoWindow>
                            )}
                        </Marker>
                    )
                )}
                {trackOrder && destination !== null ? (
                    <MapWithADirectionsRenderer
                        places={[origin[0], destination]}
                    />
                ) : (
                    ""
                )}
            </GoogleMap>
        );
    }
);

class TrackerMap extends Component {
    xMapBounds = { min: null, max: null };
    yMapBounds = { min: null, max: null };

    mapFullyLoaded = false;
    zoom = 13;
    state = {
        lat: 50.0515918,
        lng: 19.9357531,
        showTooltip: false
    };
    map = null;

    handleMapChanged() {
        this.getMapBounds();
        this.setMapCenterPoint();
    }

    closeWindow() {
        this.setState({ showTooltip: false });
    }

    clickTooltip() {
        this.setState({ showTooltip: !this.state.showTooltip });
    }

    handleMapMounted(map) {
        this.map = map;
    }

    handleMapFullyLoaded() {
        if (this.mapFullyLoaded) return;

        this.mapFullyLoaded = true;
        this.handleMapChanged();
    }

    setMapCenterPoint() {
        this.setState({
            lat: this.map.getCenter().lat(),
            lng: this.map.getCenter().lng()
        });
    }

    static defaultProps = {
        locations: []
    };

    getMapBounds() {
        var mapBounds = this.map.getBounds();

        var xMapBounds = mapBounds.ga;
        var yMapBounds = mapBounds.ma;

        if (xMapBounds) {
            this.xMapBounds.min = xMapBounds.ga;
            this.xMapBounds.max = xMapBounds.ma;
        }

        if (yMapBounds) {
            this.yMapBounds.min = yMapBounds.ga;
            this.yMapBounds.max = yMapBounds.ma;
        }
    }

    render() {
        const { lat, lng, showTooltip } = this.state;
        let {
            width,
            height,
            order,
            locations,
            isSingle,
            trackOrder,
            destination,
            origin
        } = this.props;

        return (
            <div style={{ width, height }}>
                <MultipleLocationMap
                    onMapMounted={this.handleMapMounted.bind(this)}
                    handleMapChanged={this.handleMapChanged.bind(this)}
                    handleMapFullyLoaded={this.handleMapFullyLoaded.bind(this)}
                    center={{
                        lat: lat,
                        lng: lng
                    }}
                    isSingle={isSingle}
                    locations={locations}
                    showTooltip={showTooltip}
                    closeWindow={this.closeWindow.bind(this)}
                    clickTooltip={this.clickTooltip.bind(this)}
                    order={order}
                    trackOrder={trackOrder}
                    zoom={this.zoom}
                    destination={destination}
                    origin={origin}
                />
            </div>
        );
    }
}

export default TrackerMap;
