import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import { VERIFY_ORDER, GET_PENDING_ORDER_SUCCESS } from "../../arsVariables";
import { Actions } from "../../actions/_index";
import { Notify } from "../../../reuse/notify";
import { store } from "../../../index";
import { errorHandler } from "../../../reuse/utilities";

function* verifyOrder(payload) {
  try {
    const {
      data: { code, description },
    } = yield call(Services.verifyOrderService, payload);

    yield put(Actions.uiStop());
    yield put(Actions.miniUiStop());
    Notify({
      message: description,
      className: code === 1 ? "success" : "error",
      duration: 2,
    });
    if (code === 1) {
      if (!window.location.pathname.includes("pending")) {
        const storePath =
          store.getState().getPendingOrder_reducer.pendingOrders;
        yield put({
          type: GET_PENDING_ORDER_SUCCESS,
          payloadReturned: {
            pendingOnVendor: storePath.pendingOnVendor,
            pendingOnAdmin: storePath.pendingOnAdmin.filter(
              (item) => item.id !== payload.payload.orderId[0]
            ),
          },
        });
      }
      if (window.location.pathname.includes("pending") === true) {
        yield put(
          Actions.getOrderItems({
            pageIndex: 1,
            pageSize: 10,
            status: 0,
          })
        );
      }
    }
  } catch (err) {
    yield put(Actions.miniUiStop());
    yield put(Actions.uiStop());
    let message = errorHandler(err);
    Notify({
      duration: 2,
      message,
      className: "error",
    });
  }
}

export default function* verifyOrderSaga() {
  yield takeLatest(VERIFY_ORDER, verifyOrder);
}
