import React from "react";
import { Link } from "react-router-dom";
import { Icon403 } from "./svgs";
import { url } from "../App";

const Page403 = () => (
    <div className="isw-error">
        <div className="isw-error-wrapper">
            <h4>Forbidden</h4>
            <div className="isw-image" style={{ margin: `${3}rem ${1}rem` }}>
                <Icon403 />
            </div>
            <div className="err-text-wrapper">
                <p className="mb-2">
                    Sorry, you don't have access to this page.
                </p>
                <p>
                    Either check the URL,{" "}
                    <Link to={`${url}dashboard`} className="text-danger csr">
                        go home
                    </Link>{" "}
                    , or feel free to{" "}
                    <Link to="" className="text-danger csr">
                        report this issue
                    </Link>{" "}
                    .
                </p>
            </div>
        </div>
    </div>
);

export default Page403;
