import React, { Component } from "react";
import qs from "qs";
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import SearchBox from "react-google-maps/lib/components/places/SearchBox";
import { COC } from "../redux-flow/arsVariables";

const mapKey = window.env.MAP_API_KEY
  ? window.env.MAP_API_KEY
  : "AIzaSyDfvCJoSwZyhs4WzLHnxmsTfgXo9do8WzU"; //new
// : "AIzaSyA_WObUiYD7YpoYufR84re1LZHAJeAGXkY";//old
export const genericProps = {
  googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${mapKey}&v=3.exp&libraries=geometry,drawing,places`,
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `350px` }} />,
  mapElement: <div style={{ height: `100%` }} />,
};

const MyMapComponent = compose(
  withProps({
    ...genericProps,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        bounds: null,
        center: {
          lat: 6.4286,
          lng: 3.4276,
        },
        markers: [],
        onMapMounted: (ref) => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          });
        },
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();

          let params = {
            key: mapKey,
            address: places[0].formatted_address,
          };
          let queryString = qs.stringify(params);

          return fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?${queryString}`
          )
            .then((res) => res.json())
            .then(({ results, status }) => {
              if (status !== "OK") {
                console.log(`Geocode error: ${status}`);
                return;
              }
              const { lat, lng } = results[0].geometry.location;
              const address = results[0].formatted_address;
              this.props.reCenterMap(lat, lng, address);
            });
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { isMarkerShown, lat, lng, zoom, handleClickedMap, component } = props;
  return (
    <GoogleMap
      center={{ lat: lat, lng: lng }}
      zoom={zoom}
      onClick={handleClickedMap}
    >
      {component === COC ? null : (
        <SearchBox
          ref={props.onSearchBoxMounted}
          bounds={props.bounds}
          controlPosition={1}
          onPlacesChanged={props.onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Search..."
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              marginTop: `15px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
            }}
          />
        </SearchBox>
      )}
      {isMarkerShown && <Marker position={{ lat: lat, lng: lng }} />}
    </GoogleMap>
  );
});

class MapComponent extends Component {
  constructor(props) {
    super(props);
    const { lat, lng, zoom } = this.props;
    this.state = {
      zoom: zoom,
      currentLocation: {
        lat: lat,
        lng: lng,
      },
    };
  }

  geoCoder = ({ lat, lng, reCenterMap }) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        currentLocation: {
          lat,
          lng,
        },
      };
    });

    let params = {
      key: mapKey,
      latlng: `${lat},${lng}`,
    };
    let queryString = qs.stringify(params);

    return fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?${queryString}`
    )
      .then((res) => res.json())
      .then(({ results, status }) => {
        if (status !== "OK") {
          console.log(`Geocode error: ${status}`);
          return;
        }
        const address = results[0].formatted_address;
        reCenterMap(lat, lng, address);
      });
  };

  componentDidMount() {
    const { component, reCenterMap } = this.props;
    if (component === COC) {
      return;
    } else {
      const { geolocation } = navigator;
      if (navigator && geolocation) {
        geolocation.getCurrentPosition(({ coords }) => {
          const { latitude: lat, longitude: lng } = coords;
          this.geoCoder({ lat, lng, reCenterMap });
        });
      }
    }
  }

  handleClickedMap = ({ latLng }) => {
    const { reCenterMap, component } = this.props;
    if (component === COC) {
      return;
    } else {
      let lat = latLng.lat();
      let lng = latLng.lng();
      this.geoCoder({ lat, lng, reCenterMap });
    }
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };
  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    const { zoom } = this.state;
    const { lat, lng, reCenterMap, component } = this.props;

    return (
      <MyMapComponent
        component={component}
        reCenterMap={reCenterMap}
        isMarkerShown
        lat={lat}
        lng={lng}
        zoom={zoom}
        handleClickedMap={this.handleClickedMap}
      />
    );
  }
}

MapComponent.defaultProps = {
  zoom: 18,
  initialCenter: {
    lat: 6.4286,
    lng: 3.4276,
  },
  centerAroundCurrentLocation: true,
  visible: true,
};

export default MapComponent;
