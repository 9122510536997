import React from "react";

export const ExportSVG = () => {
    return (
      <svg width="12" height="12" viewBox="0 0 12 12" style={{marginLeft: 8, marginRight: 0, position: "relative", bottom: 1}} fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.32133 6.73945L10.2986 2.7622L11.0943 3.55795C11.1971 3.6607 11.3501 3.69445 11.4873 3.6457C11.5398 3.6262 11.5863 3.5962 11.6246 3.55795C11.6853 3.4972 11.7251 3.4162 11.7326 3.3262L11.9981 0.409451C12.0078 0.298451 11.9681 0.188951 11.8893 0.110201C11.8106 0.0314505 11.7011 -0.00829947 11.5901 0.00145053L8.67333 0.266951C8.52858 0.280451 8.40408 0.376451 8.35458 0.512951C8.30508 0.649451 8.33883 0.803201 8.44158 0.905951L9.23733 1.7017L5.26008 5.6782C5.11383 5.82445 5.11383 6.0622 5.26008 6.20845L5.79033 6.7387C5.93733 6.8857 6.17433 6.8857 6.32133 6.73945Z" fill="#2196F3"/>
  <path d="M0.75 12H11.25C11.664 12 12 11.664 12 11.25V5.25H10.5V10.5H1.5V1.5H6.75V0H0.75C0.33525 0 0 0.33525 0 0.75V11.25C0 11.664 0.33525 12 0.75 12Z" fill="white"/>
  </svg>
    )
  }

export const IconCheckSquare = () => {
    return (
        <svg
            width="81px"
            height="73px"
            viewBox="0 0 81 73"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            style={{
                width: `${4}rem`,
                margin: `${0} auto`
            }}>
            <title>check-square</title>
            <desc>Created with Sketch.</desc>
            <g
                id="Customer"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round">
                <g
                    id="CO---Create-Order-Form-Complete"
                    transform="translate(-630.000000, -254.000000)"
                    stroke="#2DB004"
                    strokeWidth="2">
                    <g
                        id="Group-5"
                        transform="translate(354.000000, 255.000000)">
                        <g
                            id="Group-3"
                            transform="translate(198.000000, 0.000000)">
                            <g
                                id="check-square"
                                transform="translate(79.000000, 0.000000)">
                                <polyline
                                    id="Shape"
                                    points="24 31.2727273 35.7857143 43 79 0"
                                />
                                <path
                                    d="M71,35.5 L71,63.1111111 C71,67.4680241 67.4680241,71 63.1111111,71 L7.88888889,71 C3.53197586,71 0,67.4680241 0,63.1111111 L0,7.88888889 C0,3.53197586 3.53197586,0 7.88888889,0 L51.2777778,0"
                                    id="Shape"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const IconLogo = ({ height, width, color }) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            className={color}
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            height={height ? height : "64px"}
            width={width ? width : "160px"}
            viewBox="0 200 850 200"
            space="preserve">
            <g>
                <path
                    className="st0"
                    d="M316.31,295.07l2.3-13.1c0,0-17.72-5.76-32.14,4.92v52.41h15.31v-42.58
		C307.88,291.8,316.31,295.07,316.31,295.07"
                />
                <path
                    className="st0"
                    d="M184.27,267.03l15.31-2.88v17.04h15.15v13.63h-15.15v20.33c0,3.46,0.56,6.21,1.7,8.24
		c1.13,2.06,3.4,3.09,6.81,3.09c1.67,0,3.34-0.16,5.11-0.5c1.72-0.35,3.32-0.79,4.76-1.37l2.17,12.77c-1.85,0.79-3.9,1.49-6.16,2.07
		c-2.26,0.58-5.05,0.88-8.33,0.88c-4.18,0-7.63-0.61-10.37-1.82c-2.73-1.21-4.93-2.9-6.56-5.07c-1.64-2.14-2.79-4.78-3.43-7.86
		c-0.66-3.07-0.99-6.47-0.99-10.22V267.03z"
                />
                <path
                    className="st0"
                    d="M341.28,327.56c2.8,0,4.78-0.31,5.96-0.89c1.17-0.59,1.74-1.73,1.74-3.41c0-1.32-0.74-2.48-2.25-3.46
		c-1.51-1-3.79-2.11-6.88-3.37c-2.39-0.96-4.57-1.94-6.53-2.96c-1.94-1.04-3.6-2.24-4.97-3.68c-1.37-1.43-2.42-3.14-3.19-5.14
		c-0.76-1.96-1.13-4.33-1.13-7.13c0-5.42,1.89-9.71,5.65-12.86c3.77-3.14,8.92-4.74,15.51-4.74c3.29,0,6.44,0.31,9.46,0.95
		c3.01,0.62,5.41,1.32,7.18,2.03l-2.66,12.76c-1.78-0.66-3.72-1.24-5.81-1.74c-2.09-0.52-4.44-0.79-7.03-0.79
		c-4.79,0-7.19,1.44-7.19,4.3c0,0.66,0.1,1.24,0.31,1.76c0.21,0.52,0.62,1.01,1.22,1.49c0.63,0.47,1.46,1,2.53,1.54
		c1.06,0.57,2.42,1.16,4.06,1.84c3.34,1.33,6.12,2.63,8.31,3.93c2.2,1.31,3.92,2.68,5.19,4.21c1.27,1.49,2.17,3.14,2.67,4.98
		c0.5,1.83,0.77,3.94,0.77,6.35c0,5.71-2.01,10.03-6,12.95c-4.01,2.91-9.68,4.38-17.01,4.38c-4.79,0-8.77-0.43-11.96-1.32
		c-3.19-0.87-5.4-1.62-6.63-2.2l2.57-13.3c2.59,1.1,5.29,1.95,8.01,2.59C335.91,327.25,338.59,327.56,341.28,327.56"
                />
                <path
                    className="st0"
                    d="M411.36,306.11c-1.37,5.57-2.82,11.12-4.36,16.6c-1.54,5.5-3.18,11.04-4.87,16.61h-11.92
		c-1.24-3.08-2.58-6.74-4.02-10.99c-1.45-4.26-2.94-8.89-4.49-13.93c-1.54-5.02-3.11-10.33-4.73-15.95
		c-1.62-5.6-3.19-11.25-4.69-16.98h16.13c0.54,2.63,1.14,5.52,1.8,8.63c0.65,3.12,1.34,6.33,2.06,9.63c0.72,3.29,1.49,6.6,2.28,9.94
		c0.79,3.34,1.61,6.54,2.43,9.61c0.89-3.22,1.77-6.51,2.62-9.88c0.84-3.38,1.67-6.69,2.47-9.95c0.78-3.25,1.55-6.41,2.25-9.46
		c0.71-3.04,1.35-5.89,1.91-8.52h11.08c0.57,2.63,1.16,5.48,1.86,8.52c0.68,3.04,1.4,6.21,2.16,9.46c0.74,3.26,1.54,6.58,2.35,9.95
		c0.83,3.37,1.68,6.66,2.56,9.88c0.83-3.07,1.66-6.27,2.49-9.61c0.83-3.34,1.61-6.65,2.33-9.94c0.71-3.3,1.4-6.51,2.07-9.63
		c0.66-3.11,1.26-6,1.8-8.63h15.92c-1.51,5.72-3.07,11.38-4.69,16.98c-1.63,5.62-3.19,10.93-4.74,15.95
		c-1.53,5.03-3.05,9.66-4.52,13.93c-1.48,4.25-2.85,7.91-4.07,10.99h-11.92c-1.72-5.57-3.4-11.1-5.05-16.61
		C414.22,317.23,412.73,311.68,411.36,306.11"
                />
                <rect
                    x="461.67"
                    y="281.47"
                    className="st0"
                    width="15.28"
                    height="57.83"
                />
                <path
                    className="st0"
                    d="M489.11,267.05l15.3-2.9v17.35h15.14v13.61H504.4v20.35c0,3.44,0.57,6.22,1.69,8.24
		c1.13,2.05,3.41,3.08,6.82,3.08c1.66,0,3.35-0.15,5.09-0.49c1.75-0.33,3.34-0.81,4.79-1.38l2.15,12.76
		c-1.85,0.81-3.91,1.5-6.16,2.1c-2.27,0.58-5.02,0.88-8.32,0.88c-4.18,0-7.64-0.6-10.37-1.82c-2.74-1.19-4.93-2.87-6.57-5.05
		c-1.64-2.14-2.78-4.79-3.44-7.85c-0.66-3.09-0.97-6.5-0.97-10.23V267.05z"
                />
                <g>
                    <defs>
                        <path
                            id="SVGID_1_"
                            d="M530.35,310.41c0-4.18,0.63-8.13,1.9-11.83c1.27-3.71,3.09-6.93,5.49-9.68c2.4-2.75,5.3-4.92,8.74-6.54
				c3.41-1.6,7.32-2.42,11.7-2.42c2.87,0,5.51,0.29,7.91,0.83c2.4,0.56,4.74,1.34,6.98,2.36l-3.17,13.08
				c-1.45-0.58-3.02-1.08-4.74-1.54c-1.71-0.43-3.64-0.66-5.75-0.66c-4.52,0-7.89,1.51-10.1,4.52c-2.25,3.01-3.34,6.96-3.34,11.88
				c0,5.19,1.04,9.23,3.14,12.09c2.07,2.85,5.72,4.29,10.92,4.29c1.85,0,3.83-0.19,5.96-0.55c2.12-0.37,4.07-0.95,5.86-1.76
				l2.14,13.42c-1.78,0.81-4,1.49-6.67,2.08c-2.68,0.58-5.62,0.88-8.83,0.88c-4.93,0-9.19-0.8-12.74-2.37
				c-3.56-1.57-6.49-3.72-8.78-6.43c-2.31-2.72-3.97-5.92-5.03-9.62C530.88,318.74,530.35,314.72,530.35,310.41"
                        />
                    </defs>
                    <use
                        href="#SVGID_1_"
                        style={{
                            overflow: "visible",
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                            fill: "#fff"
                        }}
                    />
                    <clipPath id="SVGID_2_">
                        <use href="#SVGID_1_" style={{ overflow: "visible" }} />
                    </clipPath>
                    <g className="st1">
                        <defs>
                            <rect
                                id="SVGID_3_"
                                x="-645.81"
                                y="-2553.66"
                                width="2156.75"
                                height="3055.39"
                            />
                        </defs>
                        <use
                            href="#SVGID_3_"
                            style={{ overflow: "visible", fill: "#fff" }}
                        />
                        <clipPath id="SVGID_4_">
                            <use
                                href="#SVGID_3_"
                                style={{ overflow: "visible" }}
                            />
                        </clipPath>
                        <rect
                            x="504.67"
                            y="254.27"
                            className="st2"
                            width="94.99"
                            height="112.27"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M583.88,339.3v-72.27l15.3-2.88v17.56c1.02-0.36,2.33-0.71,3.96-1.05c1.6-0.33,3.17-0.5,4.67-0.5
		c4.37,0,8.01,0.65,10.93,1.92c2.9,1.28,5.25,3.1,6.98,5.45c1.76,2.34,2.98,5.13,3.71,8.34c0.71,3.24,1.06,6.83,1.06,10.79v32.63
		h-15.3v-30.66c0-5.27-0.63-9.01-1.89-11.22c-1.27-2.18-3.62-3.28-7.03-3.28c-1.39,0-2.66,0.13-3.86,0.38
		c-1.19,0.26-2.27,0.54-3.22,0.83v43.95H583.88z"
                />
                <path
                    className="st0"
                    d="M122.54,339.32v-52.4c0,0,7.69-6.58,24.51-6.58c15.31,0,23.71,8.19,23.71,26.21v32.76h-15.29v-32.76
		c0-11.48-5.37-12.27-9.95-12.27c-4.58,0-7.65,2.44-7.65,2.44v42.6H122.54z"
                />
                <polygon
                    className="st0"
                    points="93.73,339.32 93.73,267.03 109.04,264.15 109.04,339.32 	"
                />
                <path
                    className="st0"
                    d="M250.78,291.62c-9.95,0-10.72,11.46-10.72,11.46h21.41C261.48,303.08,260.72,291.62,250.78,291.62
		 M274.49,314.55h-34.43c0,0,0.77,12.28,16.06,12.28c7.65,0,15.31-2.45,15.31-2.45l1.53,13.12c0,0-7.65,3.25-18.35,3.25
		c-15.31,0-29.08-8.18-29.08-31.11c0-18.03,10.71-29.48,26.01-29.48C274.49,280.17,276.03,304.71,274.49,314.55"
                />
                <g>
                    <path
                        className="st3"
                        d="M661.68,382.64c-6.25-1.67,49.88-71.75,40.52-100.13c-4.67-15-51.43-13.33-51.43-18.35
			c0-5.01,90.45-18.33,98.98-5.84C763.04,275.88,669.48,385.98,661.68,382.64"
                    />
                    <path
                        className="st3"
                        d="M770.79,220.79c0,11.99-9.06,21.69-20.26,21.69c-11.19,0-20.26-9.7-20.26-21.69
			c0-11.99,9.08-21.68,20.26-21.68C761.72,199.1,770.79,208.8,770.79,220.79"
                    />
                </g>
                <g>
                    <path
                        className="st4"
                        d="M364.18,391.81v-3.02h1.54c2.37,2.38,5.9,4.49,12.25,4.49c6.86,0,11.61-2.82,11.61-9.62
			c0-6.61-5.2-7.89-11.68-9.62c-8.02-2.12-12.63-4.68-12.63-11.99c0-6.48,4.75-11.41,13.53-11.41c5.64,0,9.24,1.47,11.99,3.52v2.89
			h-1.6c-2.37-2.18-5.32-3.66-10.32-3.66c-7.31,0-10.33,3.66-10.33,8.4c0,6.16,4.23,7.57,10.33,9.17
			c9.04,2.38,14.04,4.62,14.04,12.44c0,8.72-6.16,12.63-14.88,12.63C371.36,396.04,367.13,394.12,364.18,391.81z"
                    />
                    <path
                        className="st4"
                        d="M403.25,367.44c3.21-2.57,7.76-5.45,13.34-5.45c3.59,0,6.99,1.54,7.95,5.51c3.4-2.89,8.08-5.51,13.47-5.51
			c4.55,0,8.27,2.25,8.27,8.21v25.59h-3.21v-24.76c0-4.93-2.38-6.34-6.22-6.34c-4.74,0-8.59,2.63-12.05,5.38v25.72h-3.21v-24.76
			c0-4.93-2.57-6.34-6.16-6.34c-4.88,0-8.73,2.56-12.19,5.45v25.65h-3.21v-33.41h1.47c1.42,0,1.73,0.39,1.73,3.34V367.44z"
                    />
                    <path
                        className="st4"
                        d="M474.66,391.23c-2.83,2.95-5.9,4.87-11.42,4.87c-6.22,0-10.78-3.4-10.78-9.68c0-6.74,5.58-9.81,13.4-9.81
			c3.21,0,6.03,0.32,8.79,1.02v-6.03c0-5.2-3.34-7.05-8.21-7.05c-4.94,0-8.28,1.28-10.39,2.95h-1.35v-2.63
			c2.69-1.47,6.41-2.88,11.86-2.88c6.8,0,11.29,2.5,11.29,9.43v24.37h-0.9c-1.86,0-2.31-0.58-2.31-3.27V391.23z M474.66,388.16
			v-8.21c-2.57-0.58-5.39-1.03-8.6-1.03c-5.9,0-10.32,2.11-10.32,7.25c0,5.32,3.46,7.44,7.95,7.44
			C469.08,393.61,472.03,391.3,474.66,388.16z"
                    />
                    <path
                        className="st4"
                        d="M490.06,368.15c2.56-3.08,6.22-6.03,10.97-6.03c0.84,0,1.41,0.13,1.54,0.19v2.63c0,0-0.84-0.07-1.41-0.07
			c-4.88,0-8.4,3.14-11.1,6.29v24.63h-3.21v-33.41h1.47c1.42,0,1.73,0.39,1.73,3.66V368.15z"
                    />
                    <path
                        className="st4"
                        d="M505.66,362.37h4.42v-9.29h3.21v9.29h8.91v2.44h-8.91v23.35c0,4.04,1.86,5.26,5.77,5.26h3.85v2.24
			c-1.02,0.19-2.95,0.39-4.93,0.39c-5.13,0-7.89-2.18-7.89-7.5v-23.73h-4.42V362.37z"
                    />
                    <path
                        className="st4"
                        d="M529.31,395.79V350.9h26.75v2.89h-23.35v18.6h17.32v2.83h-17.32v20.58H529.31z"
                    />
                    <path
                        className="st4"
                        d="M581.98,390.92c-3.72,2.82-8.72,5.26-13.92,5.26c-5.13,0-9.04-2.12-9.04-8.73v-25.08h3.27v24.76
			c0,5.13,2.69,6.41,6.22,6.41c5.07,0,9.68-2.56,13.47-5.32v-25.85h3.21v33.41h-1.48c-1.41,0-1.73-0.45-1.73-3.59V390.92z"
                    />
                    <path
                        className="st4"
                        d="M608.04,393.42c5.32,0,8.59-1.67,10.39-3.47h1.41v2.38c-2.37,1.86-6.03,3.72-12.12,3.72
			c-10.07,0-15.64-6.48-15.64-16.87c0-10.52,6.28-17.19,14.81-17.19c8.21,0,13.72,4.75,13.72,15.64c0,0.64-0.07,1.42-0.07,1.54
			h-25.2C595.34,388.54,599.7,393.42,608.04,393.42z M595.4,376.74h22c-0.19-8.91-4.3-12.31-10.52-12.31
			C600.73,364.43,596.17,368.53,595.4,376.74z"
                    />
                    <path
                        className="st4"
                        d="M627.29,395.79V350.9h1.15c1.86,0,2.05,0.39,2.05,3.08v41.81H627.29z"
                    />
                </g>
            </g>
        </svg>
    );
};
export const IconLogoWhite = ({ height, width }) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            fill="transparent"
            height="32px"
            width="150px"
            viewBox="0 200 850 200"
            space="preserve">
            <g>
                <path
                    className="st0"
                    d="M316.31,295.07l2.3-13.1c0,0-17.72-5.76-32.14,4.92v52.41h15.31v-42.58 C307.88,291.8,316.31,295.07,316.31,295.07"
                />
                <path
                    className="st0"
                    d="M184.27,267.03l15.31-2.88v17.04h15.15v13.63h-15.15v20.33c0,3.46,0.56,6.21,1.7,8.24 c1.13,2.06,3.4,3.09,6.81,3.09c1.67,0,3.34-0.16,5.11-0.5c1.72-0.35,3.32-0.79,4.76-1.37l2.17,12.77c-1.85,0.79-3.9,1.49-6.16,2.07 c-2.26,0.58-5.05,0.88-8.33,0.88c-4.18,0-7.63-0.61-10.37-1.82c-2.73-1.21-4.93-2.9-6.56-5.07c-1.64-2.14-2.79-4.78-3.43-7.86 c-0.66-3.07-0.99-6.47-0.99-10.22V267.03z"
                />
                <path
                    className="st0"
                    d="M341.28,327.56c2.8,0,4.78-0.31,5.96-0.89c1.17-0.59,1.74-1.73,1.74-3.41c0-1.32-0.74-2.48-2.25-3.46 c-1.51-1-3.79-2.11-6.88-3.37c-2.39-0.96-4.57-1.94-6.53-2.96c-1.94-1.04-3.6-2.24-4.97-3.68c-1.37-1.43-2.42-3.14-3.19-5.14 c-0.76-1.96-1.13-4.33-1.13-7.13c0-5.42,1.89-9.71,5.65-12.86c3.77-3.14,8.92-4.74,15.51-4.74c3.29,0,6.44,0.31,9.46,0.95 c3.01,0.62,5.41,1.32,7.18,2.03l-2.66,12.76c-1.78-0.66-3.72-1.24-5.81-1.74c-2.09-0.52-4.44-0.79-7.03-0.79 c-4.79,0-7.19,1.44-7.19,4.3c0,0.66,0.1,1.24,0.31,1.76c0.21,0.52,0.62,1.01,1.22,1.49c0.63,0.47,1.46,1,2.53,1.54 c1.06,0.57,2.42,1.16,4.06,1.84c3.34,1.33,6.12,2.63,8.31,3.93c2.2,1.31,3.92,2.68,5.19,4.21c1.27,1.49,2.17,3.14,2.67,4.98 c0.5,1.83,0.77,3.94,0.77,6.35c0,5.71-2.01,10.03-6,12.95c-4.01,2.91-9.68,4.38-17.01,4.38c-4.79,0-8.77-0.43-11.96-1.32 c-3.19-0.87-5.4-1.62-6.63-2.2l2.57-13.3c2.59,1.1,5.29,1.95,8.01,2.59C335.91,327.25,338.59,327.56,341.28,327.56"
                />
                <path
                    className="st0"
                    d="M411.36,306.11c-1.37,5.57-2.82,11.12-4.36,16.6c-1.54,5.5-3.18,11.04-4.87,16.61h-11.92 c-1.24-3.08-2.58-6.74-4.02-10.99c-1.45-4.26-2.94-8.89-4.49-13.93c-1.54-5.02-3.11-10.33-4.73-15.95 c-1.62-5.6-3.19-11.25-4.69-16.98h16.13c0.54,2.63,1.14,5.52,1.8,8.63c0.65,3.12,1.34,6.33,2.06,9.63c0.72,3.29,1.49,6.6,2.28,9.94 c0.79,3.34,1.61,6.54,2.43,9.61c0.89-3.22,1.77-6.51,2.62-9.88c0.84-3.38,1.67-6.69,2.47-9.95c0.78-3.25,1.55-6.41,2.25-9.46 c0.71-3.04,1.35-5.89,1.91-8.52h11.08c0.57,2.63,1.16,5.48,1.86,8.52c0.68,3.04,1.4,6.21,2.16,9.46c0.74,3.26,1.54,6.58,2.35,9.95 c0.83,3.37,1.68,6.66,2.56,9.88c0.83-3.07,1.66-6.27,2.49-9.61c0.83-3.34,1.61-6.65,2.33-9.94c0.71-3.3,1.4-6.51,2.07-9.63 c0.66-3.11,1.26-6,1.8-8.63h15.92c-1.51,5.72-3.07,11.38-4.69,16.98c-1.63,5.62-3.19,10.93-4.74,15.95 c-1.53,5.03-3.05,9.66-4.52,13.93c-1.48,4.25-2.85,7.91-4.07,10.99h-11.92c-1.72-5.57-3.4-11.1-5.05-16.61 C414.22,317.23,412.73,311.68,411.36,306.11"
                />
                <rect
                    x="461.67"
                    y="281.47"
                    className="st0"
                    width="15.28"
                    height="57.83"
                />
                <path
                    className="st0"
                    d="M489.11,267.05l15.3-2.9v17.35h15.14v13.61H504.4v20.35c0,3.44,0.57,6.22,1.69,8.24 c1.13,2.05,3.41,3.08,6.82,3.08c1.66,0,3.35-0.15,5.09-0.49c1.75-0.33,3.34-0.81,4.79-1.38l2.15,12.76 c-1.85,0.81-3.91,1.5-6.16,2.1c-2.27,0.58-5.02,0.88-8.32,0.88c-4.18,0-7.64-0.6-10.37-1.82c-2.74-1.19-4.93-2.87-6.57-5.05 c-1.64-2.14-2.78-4.79-3.44-7.85c-0.66-3.09-0.97-6.5-0.97-10.23V267.05z"
                />
                <g>
                    <defs>
                        <path
                            id="SVGID_1_"
                            d="M530.35,310.41c0-4.18,0.63-8.13,1.9-11.83c1.27-3.71,3.09-6.93,5.49-9.68c2.4-2.75,5.3-4.92,8.74-6.54 c3.41-1.6,7.32-2.42,11.7-2.42c2.87,0,5.51,0.29,7.91,0.83c2.4,0.56,4.74,1.34,6.98,2.36l-3.17,13.08 c-1.45-0.58-3.02-1.08-4.74-1.54c-1.71-0.43-3.64-0.66-5.75-0.66c-4.52,0-7.89,1.51-10.1,4.52c-2.25,3.01-3.34,6.96-3.34,11.88 c0,5.19,1.04,9.23,3.14,12.09c2.07,2.85,5.72,4.29,10.92,4.29c1.85,0,3.83-0.19,5.96-0.55c2.12-0.37,4.07-0.95,5.86-1.76 l2.14,13.42c-1.78,0.81-4,1.49-6.67,2.08c-2.68,0.58-5.62,0.88-8.83,0.88c-4.93,0-9.19-0.8-12.74-2.37 c-3.56-1.57-6.49-3.72-8.78-6.43c-2.31-2.72-3.97-5.92-5.03-9.62C530.88,318.74,530.35,314.72,530.35,310.41"
                        />
                    </defs>
                    <use
                        href="#SVGID_1_"
                        style={{
                            overflow: "visible",
                            fillRule: "evenodd",
                            clipRule: "evenodd"
                            // fill: "#fff"
                        }}
                    />
                    <clippath id="SVGID_2_">
                        <use href="#SVGID_1_" style={{ overflow: "visible" }} />
                    </clippath>
                    <g className="st1">
                        <defs>
                            <rect
                                id="SVGID_3_"
                                x="-645.81"
                                y="-2553.66"
                                width="2156.75"
                                height="3055.39"
                            />
                        </defs>
                        <use
                            href="#SVGID_3_"
                            style={{
                                overflow: "visible"

                                // fill: "#fff"
                            }}
                        />
                        <clippath id="SVGID_4_">
                            <use
                                href="#SVGID_3_"
                                style={{ overflow: "visible" }}
                            />
                        </clippath>
                        <rect
                            x="504.67"
                            y="254.27"
                            className="st2"
                            width="94.99"
                            height="112.27"
                        />
                    </g>
                </g>
                <path
                    className="st0"
                    d="M583.88,339.3v-72.27l15.3-2.88v17.56c1.02-0.36,2.33-0.71,3.96-1.05c1.6-0.33,3.17-0.5,4.67-0.5 c4.37,0,8.01,0.65,10.93,1.92c2.9,1.28,5.25,3.1,6.98,5.45c1.76,2.34,2.98,5.13,3.71,8.34c0.71,3.24,1.06,6.83,1.06,10.79v32.63 h-15.3v-30.66c0-5.27-0.63-9.01-1.89-11.22c-1.27-2.18-3.62-3.28-7.03-3.28c-1.39,0-2.66,0.13-3.86,0.38 c-1.19,0.26-2.27,0.54-3.22,0.83v43.95H583.88z"
                />
                <path
                    className="st0"
                    d="M122.54,339.32v-52.4c0,0,7.69-6.58,24.51-6.58c15.31,0,23.71,8.19,23.71,26.21v32.76h-15.29v-32.76 c0-11.48-5.37-12.27-9.95-12.27c-4.58,0-7.65,2.44-7.65,2.44v42.6H122.54z"
                />
                <polygon
                    className="st0"
                    points="93.73,339.32 93.73,267.03 109.04,264.15 109.04,339.32 	"
                />
                <path
                    className="st0"
                    d="M250.78,291.62c-9.95,0-10.72,11.46-10.72,11.46h21.41C261.48,303.08,260.72,291.62,250.78,291.62 M274.49,314.55h-34.43c0,0,0.77,12.28,16.06,12.28c7.65,0,15.31-2.45,15.31-2.45l1.53,13.12c0,0-7.65,3.25-18.35,3.25 c-15.31,0-29.08-8.18-29.08-31.11c0-18.03,10.71-29.48,26.01-29.48C274.49,280.17,276.03,304.71,274.49,314.55"
                />
                <g>
                    <path
                        className="st3"
                        d="M661.68,382.64c-6.25-1.67,49.88-71.75,40.52-100.13c-4.67-15-51.43-13.33-51.43-18.35 c0-5.01,90.45-18.33,98.98-5.84C763.04,275.88,669.48,385.98,661.68,382.64"
                    />
                    <path
                        className="st3"
                        d="M770.79,220.79c0,11.99-9.06,21.69-20.26,21.69c-11.19,0-20.26-9.7-20.26-21.69 c0-11.99,9.08-21.68,20.26-21.68C761.72,199.1,770.79,208.8,770.79,220.79"
                    />
                </g>
                <g>
                    <path
                        className="st4"
                        d="M364.18,391.81v-3.02h1.54c2.37,2.38,5.9,4.49,12.25,4.49c6.86,0,11.61-2.82,11.61-9.62 c0-6.61-5.2-7.89-11.68-9.62c-8.02-2.12-12.63-4.68-12.63-11.99c0-6.48,4.75-11.41,13.53-11.41c5.64,0,9.24,1.47,11.99,3.52v2.89 h-1.6c-2.37-2.18-5.32-3.66-10.32-3.66c-7.31,0-10.33,3.66-10.33,8.4c0,6.16,4.23,7.57,10.33,9.17 c9.04,2.38,14.04,4.62,14.04,12.44c0,8.72-6.16,12.63-14.88,12.63C371.36,396.04,367.13,394.12,364.18,391.81z"
                    />
                    <path
                        className="st4"
                        d="M403.25,367.44c3.21-2.57,7.76-5.45,13.34-5.45c3.59,0,6.99,1.54,7.95,5.51c3.4-2.89,8.08-5.51,13.47-5.51 c4.55,0,8.27,2.25,8.27,8.21v25.59h-3.21v-24.76c0-4.93-2.38-6.34-6.22-6.34c-4.74,0-8.59,2.63-12.05,5.38v25.72h-3.21v-24.76 c0-4.93-2.57-6.34-6.16-6.34c-4.88,0-8.73,2.56-12.19,5.45v25.65h-3.21v-33.41h1.47c1.42,0,1.73,0.39,1.73,3.34V367.44z"
                    />
                    <path
                        className="st4"
                        d="M474.66,391.23c-2.83,2.95-5.9,4.87-11.42,4.87c-6.22,0-10.78-3.4-10.78-9.68c0-6.74,5.58-9.81,13.4-9.81 c3.21,0,6.03,0.32,8.79,1.02v-6.03c0-5.2-3.34-7.05-8.21-7.05c-4.94,0-8.28,1.28-10.39,2.95h-1.35v-2.63 c2.69-1.47,6.41-2.88,11.86-2.88c6.8,0,11.29,2.5,11.29,9.43v24.37h-0.9c-1.86,0-2.31-0.58-2.31-3.27V391.23z M474.66,388.16 v-8.21c-2.57-0.58-5.39-1.03-8.6-1.03c-5.9,0-10.32,2.11-10.32,7.25c0,5.32,3.46,7.44,7.95,7.44 C469.08,393.61,472.03,391.3,474.66,388.16z"
                    />
                    <path
                        className="st4"
                        d="M490.06,368.15c2.56-3.08,6.22-6.03,10.97-6.03c0.84,0,1.41,0.13,1.54,0.19v2.63c0,0-0.84-0.07-1.41-0.07 c-4.88,0-8.4,3.14-11.1,6.29v24.63h-3.21v-33.41h1.47c1.42,0,1.73,0.39,1.73,3.66V368.15z"
                    />
                    <path
                        className="st4"
                        d="M505.66,362.37h4.42v-9.29h3.21v9.29h8.91v2.44h-8.91v23.35c0,4.04,1.86,5.26,5.77,5.26h3.85v2.24 c-1.02,0.19-2.95,0.39-4.93,0.39c-5.13,0-7.89-2.18-7.89-7.5v-23.73h-4.42V362.37z"
                    />
                    <path
                        className="st4"
                        d="M529.31,395.79V350.9h26.75v2.89h-23.35v18.6h17.32v2.83h-17.32v20.58H529.31z"
                    />
                    <path
                        className="st4"
                        d="M581.98,390.92c-3.72,2.82-8.72,5.26-13.92,5.26c-5.13,0-9.04-2.12-9.04-8.73v-25.08h3.27v24.76 c0,5.13,2.69,6.41,6.22,6.41c5.07,0,9.68-2.56,13.47-5.32v-25.85h3.21v33.41h-1.48c-1.41,0-1.73-0.45-1.73-3.59V390.92z"
                    />
                    <path
                        className="st4"
                        d="M608.04,393.42c5.32,0,8.59-1.67,10.39-3.47h1.41v2.38c-2.37,1.86-6.03,3.72-12.12,3.72 c-10.07,0-15.64-6.48-15.64-16.87c0-10.52,6.28-17.19,14.81-17.19c8.21,0,13.72,4.75,13.72,15.64c0,0.64-0.07,1.42-0.07,1.54 h-25.2C595.34,388.54,599.7,393.42,608.04,393.42z M595.4,376.74h22c-0.19-8.91-4.3-12.31-10.52-12.31 C600.73,364.43,596.17,368.53,595.4,376.74z"
                    />
                    <path
                        className="st4"
                        d="M627.29,395.79V350.9h1.15c1.86,0,2.05,0.39,2.05,3.08v41.81H627.29z"
                    />
                </g>
            </g>
        </svg>
    );
};

export const IconFolder = () => {
    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 475.082 475.082"
            width="24px"
            height="24px"
            style={{
                enableBackground: "new 0 0 475.082 475.082",
                marginLeft: `${30}px`
            }}
            // xml:space="preserve"
        >
            <g>
                <path
                    d="M456.239,128.475c-12.56-12.562-27.597-18.842-45.11-18.842h-191.86v-9.136c0-17.511-6.283-32.548-18.843-45.107
		c-12.562-12.562-27.6-18.846-45.111-18.846H63.953c-17.515,0-32.551,6.283-45.111,18.846C6.28,67.949,0,82.986,0,100.497v274.088
		c0,17.508,6.28,32.545,18.842,45.104c12.562,12.565,27.6,18.849,45.111,18.849h347.175c17.514,0,32.551-6.283,45.11-18.849
		c12.566-12.56,18.843-27.597,18.843-45.104V173.59C475.082,156.078,468.805,141.042,456.239,128.475z M438.536,374.585
		c0,7.611-2.662,14.093-7.99,19.417c-5.328,5.325-11.8,7.987-19.417,7.987H63.953c-7.614,0-14.084-2.662-19.414-7.987
		c-5.33-5.324-7.993-11.806-7.993-19.417V100.501c0-7.611,2.663-14.084,7.993-19.414c5.326-5.327,11.799-7.993,19.414-7.993h91.365
		c7.614,0,14.087,2.663,19.417,7.993c5.327,5.33,7.993,11.803,7.993,19.414v18.274c0,7.616,2.664,14.083,7.994,19.414
		c5.327,5.327,11.798,7.994,19.414,7.994h200.993c7.617,0,14.089,2.666,19.417,7.993c5.328,5.326,7.99,11.799,7.99,19.414V374.585
		L438.536,374.585z"
                />
            </g>
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
        </svg>
    );
};

export const IconAddContact = ({ place }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 531.818 531.818"
            style={{
                enableBackground: "new 0 0 531.818 531.818",
                margin: place === "sidebar" ? `0 0 0 ${30}px` : `0 ${5}px 0 0`
            }}
            // xml:space="preserve"
            width="24px"
            height="24px">
            <g>
                <g>
                    <g>
                        <circle
                            cx="262.294"
                            cy="149.655"
                            r="149.655"
                            fill={place === "sidebar" ? "#000" : "#FFFFFF"}
                        />
                        <path
                            d="M338.097,427.767c0-51.082,41.41-92.49,92.49-92.49c1.323,0,2.635,0.045,3.943,0.1     c-21.247-41.729-45.704-75.837-45.704-75.837s-57.806,62.998-138.735,57.806c-80.929-5.191-115.612-57.806-115.612-57.806     S7.304,375.151,53.549,467.641s300.593,57.807,300.593,57.807s19.505-1.461,43-11.451     C362.599,500.588,338.097,467.05,338.097,427.767z"
                            fill={place === "sidebar" ? "#000" : "#FFFFFF"}
                        />
                        <path
                            d="M476.832,416.206h-34.684v-34.685c0-6.384-5.177-11.562-11.563-11.562c-6.384,0-11.56,5.177-11.56,11.562v34.685h-34.684     c-6.386,0-11.562,5.176-11.562,11.562s5.176,11.562,11.562,11.562h34.684v34.684c0,6.386,5.176,11.563,11.56,11.563     c6.386,0,11.563-5.177,11.563-11.563V439.33h34.684c6.386,0,11.562-5.176,11.562-11.562S483.218,416.206,476.832,416.206z"
                            fill={place === "sidebar" ? "#000" : "#FFFFFF"}
                        />
                    </g>
                </g>
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
            </g>
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
        </svg>
    );
};

export const IconFilter = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            width="24px"
            height="20px"
            viewBox="0 0 459 459"
            style={{
                enableBackground: "new 0 0 459 459",
                marginRight: `${5}px`
            }}
            // xml:space="preserve"
        >
            <g>
                <g id="filter">
                    <path
                        d="M178.5,382.5h102v-51h-102V382.5z M0,76.5v51h459v-51H0z M76.5,255h306v-51h-306V255z"
                        fill="#000"
                    />
                </g>
            </g>
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
        </svg>
    );
};

export const Icon500 = () => {
    return (
        <svg
            width="862px"
            height="295px"
            viewBox="0 0 862 295"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{ maxWidth: `${30}rem` }}
            // xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <title>Group</title>
            <desc>Created with Sketch.</desc>
            <defs>
                <polygon
                    id="path-1"
                    points="0 291.9375 320.325854 291.9375 320.325854 0 0 0"
                />
            </defs>
            <g
                id="Error-Pages"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd">
                <g id="500" transform="translate(-358.000000, -403.000000)">
                    <g id="Group" transform="translate(358.000000, 403.000000)">
                        <path
                            d="M207.8,61.4 L89,61.4 L89,106.6 L114.2,106.6 C178.2,106.6 220.2,141 220.2,191 C220.2,249.8 176.2,287.8 108.6,287.8 C71.8,287.8 29.8,273.8 0.6,253 L29,199 C49.8,221.4 75,233.4 100.2,233.4 C127.8,233.4 143.8,219.8 143.8,195.8 C143.8,173.8 127.8,161 100.2,161 C85.8,161 41.4,161.8 24.2,163 L24.2,5 L207.8,5 L207.8,61.4 Z"
                            id="5-copy"
                            fill="#00425F"
                        />
                        <path
                            d="M737.8,0.6 C817,0.6 861.4,51.4 861.4,142.6 C861.4,234.2 817,285 737.8,285 C657.8,285 613.4,234.2 613.4,142.6 C613.4,51.4 657.8,0.6 737.8,0.6 Z M737.8,52.6 C701.8,52.6 686.6,78.6 686.6,142.6 C686.6,206.6 701.8,233 737.8,233 C773,233 788.6,206.6 788.6,142.6 C788.6,78.6 773,52.6 737.8,52.6 Z"
                            id="0-copy"
                            fill="#00425F"
                        />
                        <g
                            id="face"
                            transform="translate(259.000000, 3.000000)">
                            <path
                                d="M201.573299,225.610207 C203.626737,223.178416 204.653457,219.769707 204.653457,215.388018 L204.653457,198.080545 L177.372813,198.080545 L177.372813,215.388018 C177.372813,219.769707 178.483364,223.178416 180.704466,225.610207 C182.925569,228.041999 186.445623,229.257894 191.26463,229.257894 C196.083637,229.257894 199.51986,228.041999 201.573299,225.610207 L201.573299,225.610207 Z M168.195874,240.267483 C163.669837,234.886554 161.40726,227.204265 161.40726,217.217114 L161.40726,198.080545 L100,198.080545 L100,179 L220.745198,179 L220.745198,218.464523 C220.745198,227.561855 218.356432,234.816086 213.579341,240.224152 C208.801809,245.634406 201.405635,248.338439 191.390378,248.338439 C180.452971,248.338439 172.72147,245.646661 168.195874,240.267483 L168.195874,240.267483 Z"
                                id="Fill-3"
                                fill="#2B3E51"
                            />
                            <mask id="mask-2" fill="white">
                                <use href="#path-1" />
                            </mask>
                            <g id="Clip-6" />
                            <path
                                d="M89.1264772,43.7687406 L290.322881,43.7687406 L290.322881,29.3250562 L89.1264772,29.3250562 L89.1264772,43.7687406 Z M29.5617523,262.612444 L290.322881,262.612444 L290.322881,73.0937969 L29.5617523,73.0937969 L29.5617523,262.612444 Z M36.8264427,28.9814716 C40.8627249,28.9814716 44.1348138,32.2273614 44.1348138,36.2313258 C44.1348138,40.2352902 40.8627249,43.48118 36.8264427,43.48118 C32.7901604,43.48118 29.5180715,40.2352902 29.5180715,36.2313258 C29.5180715,32.2273614 32.7901604,28.9814716 36.8264427,28.9814716 L36.8264427,28.9814716 Z M66.8294152,28.9814716 C70.8656974,28.9814716 74.1377863,32.2273614 74.1377863,36.2313258 C74.1377863,40.2352902 70.8656974,43.48118 66.8294152,43.48118 C62.7931329,43.48118 59.5210441,40.2352902 59.5210441,36.2313258 C59.5210441,32.2273614 62.7931329,28.9814716 66.8294152,28.9814716 L66.8294152,28.9814716 Z M14.8964759,0 C6.71845974,0 0,6.35828495 0,14.4734472 L0,277.384831 C0,285.497368 6.71845974,291.9375 14.8964759,291.9375 L305.332751,291.9375 C313.516503,291.9375 320.325854,285.497368 320.325854,277.384831 L320.325854,14.4734472 C320.325854,6.35828495 313.516503,0 305.332751,0 L14.8964759,0 Z"
                                id="Fill-5"
                                fill="#00425F"
                                mask="url(#mask-2)"
                            />
                            <polygon
                                id="Fill-1-Copy"
                                fill="#2B3E51"
                                mask="url(#mask-2)"
                                points="125.223272 139.580238 106.856158 121.353622 125.219742 103.134883 112.971028 91 94.6061205 109.21655 76.2438601 91 64 103.134883 82.3662314 121.361938 64 139.580238 76.2438601 151.725188 94.6061205 133.507763 112.977647 151.725188"
                            />
                            <polygon
                                id="Fill-2-Copy"
                                fill="#2B3E51"
                                mask="url(#mask-2)"
                                points="257.223272 139.580238 238.856158 121.353622 257.219742 103.134883 244.971028 91 226.606121 109.21655 208.24386 91 196 103.134883 214.366231 121.361938 196 139.580238 208.24386 151.725188 226.606121 133.507763 244.977647 151.725188"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const Icon404 = () => {
    return (
        <svg
            width="798px"
            height="299px"
            viewBox="0 0 798 299"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{ maxWidth: `${30}rem` }}
            // xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <title>Group</title>
            <desc>Created with Sketch.</desc>
            <g
                id="Error-Pages"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd">
                <g
                    id="404"
                    transform="translate(-398.000000, -396.000000)"
                    fill="#00425F">
                    <g id="Group" transform="translate(398.000000, 396.000000)">
                        <g id="Group-2">
                            <polygon
                                id="4"
                                points="74.4 178.4 133.6 178.4 133.6 118.4 199.6 118.4 199.6 178.4 237.6 178.4 237.6 232.4 199.6 232.4 199.6 294 133.6 294 133.6 232.4 0.8 232.4 0.8 184.8 89.2 14 157.6 14"
                            />
                            <polygon
                                id="4-"
                                points="634.4 178.4 693.6 178.4 693.6 118.4 759.6 118.4 759.6 178.4 797.6 178.4 797.6 232.4 759.6 232.4 759.6 294 693.6 294 693.6 232.4 560.8 232.4 560.8 184.8 649.2 14 717.6 14"
                            />
                            <path
                                d="M459.291287,137.329794 C445.264878,137.329794 433.884183,125.993421 433.884183,111.983724 C433.884183,97.990563 445.264878,86.6372076 459.291287,86.6372076 C473.318593,86.6372076 484.698391,97.990563 484.698391,111.983724 C484.698391,125.993421 473.318593,137.329794 459.291287,137.329794 M315.317101,111.983724 C315.317101,97.990563 326.697795,86.6372076 340.724205,86.6372076 C354.75151,86.6372076 366.131309,97.990563 366.131309,111.983724 C366.131309,125.993421 354.75151,137.329794 340.724205,137.329794 C326.697795,137.329794 315.317101,125.993421 315.317101,111.983724 M399.999683,1.13686838e-13 C272.963266,1.13686838e-13 273,118.282256 273,118.282256 L273,298.737372 L304.773215,256.538463 L336.49133,298.737372 L368.213028,256.538463 L399.936519,298.737372 L431.660457,256.538463 L463.385291,298.737372 L495.134764,256.538463 L526.999365,298.737372 L526.999365,118.282256 C526.999365,118.282256 527.035651,1.13686838e-13 399.999683,1.13686838e-13"
                                id="Ghost"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const Icon403 = () => {
    return (
        <svg
            width="784px"
            height="355px"
            viewBox="0 0 784 355"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{ maxWidth: `${30}rem` }}
            // xmlns:xlink="http://www.w3.org/1999/xlink"
        >
            <title>403</title>
            <desc>Created with Sketch.</desc>
            <defs>
                <polygon
                    id="path-1"
                    points="254.932248 354.444609 254.932248 0 0 0 1.37252235e-14 354.444609 254.932248 354.444609"
                />
            </defs>
            <g
                id="Error-Pages"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd">
                <g id="403" transform="translate(-401.000000, -355.000000)">
                    <g
                        transform="translate(401.000000, 355.000000)"
                        id="Group-4">
                        <g>
                            <g
                                id="Group-2"
                                transform="translate(0.000000, 53.000000)"
                                fill="#00425F">
                                <polygon
                                    id="4"
                                    points="74.4 164.4 133.6 164.4 133.6 104.4 199.6 104.4 199.6 164.4 237.6 164.4 237.6 218.4 199.6 218.4 199.6 280 133.6 280 133.6 218.4 0.8 218.4 0.8 170.8 89.2 0 157.6 0"
                                />
                                <path
                                    d="M701,113.6 L714.2,115.6 C755,122 783.8,148.8 783.8,192 C783.8,245.6 741.8,283.2 676.6,283.2 C638.2,283.2 597.8,269.6 572.2,249.2 L601,195.2 C621.8,217.6 645.8,229.2 671,229.2 C699,229.2 714.2,215.6 714.2,192.8 C714.2,169.2 698.6,157.6 670.6,157.6 L625.8,157.6 L625.8,119.6 L688.2,54.8 L586.2,54.8 L586.2,0.4 L771,0.4 L771,40.8 L701,113.6 Z"
                                    id="3"
                                />
                            </g>
                            <g
                                id="Page-1"
                                transform="translate(269.000000, 0.000000)">
                                <g
                                    id="Group-3"
                                    transform="translate(0.000000, 0.099481)">
                                    <mask id="mask-2" fill="white">
                                        <use href="#path-1" />
                                    </mask>
                                    <g id="Clip-2" />
                                    <path
                                        d="M183.35609,112.487272 C166.457327,104.228838 147.54583,99.4463415 127.459552,99.4463415 C107.389704,99.4463415 88.469991,104.228838 71.5794439,112.487272 L71.5794439,102.3635 C71.6698117,72.8960912 96.6441614,48.9753921 127.459552,48.9753921 C158.283157,48.9753921 183.265722,72.8960912 183.35609,102.3635 L183.35609,112.487272 Z M127.459552,286.506044 C94.5903498,286.506044 67.9400897,259.848968 67.9400897,226.963143 C67.9400897,194.0691 94.5903498,167.420241 127.459552,167.420241 C160.345184,167.420241 186.995444,194.0691 186.995444,226.963143 C186.995444,259.848968 160.345184,286.506044 127.459552,286.506044 L127.459552,286.506044 Z M229.813309,151.174545 L229.813309,98.1644353 L229.607928,98.1644353 C227.365166,43.56016 182.567426,0 127.459552,0 C71.7355336,0 26.4941704,44.5873285 25.2290224,100.054425 L25.114009,100.054425 L25.114009,151.174545 C9.4064574,172.391737 0,198.522902 0,226.963143 C0,297.377595 57.0631031,354.447074 127.459552,354.447074 C197.864215,354.447074 254.935534,297.377595 254.935534,226.963143 C254.935534,198.522902 245.520861,172.391737 229.813309,151.174545 L229.813309,151.174545 Z"
                                        id="Fill-1"
                                        fill="#00425F"
                                        mask="url(#mask-2)"
                                    />
                                </g>
                                <path
                                    d="M127.707767,198 C112.961399,198 101,209.972676 101,224.722815 C101,239.489388 112.961399,251.445629 127.707767,251.445629 C142.46235,251.445629 154.423749,239.489388 154.423749,224.722815 C154.423749,209.972676 142.46235,198 127.707767,198"
                                    id="Fill-4"
                                    fill="#00425F"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const IconLocation = () => {
    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            width="24px"
            height="24px"
            style={{ enableBackground: `new 0 0 60 60`, marginLeft: `${30}px` }}
            space="preserve">
            <g>
                <path
                    d="M30,26c3.86,0,7-3.141,7-7s-3.14-7-7-7s-7,3.141-7,7S26.14,26,30,26z M30,14c2.757,0,5,2.243,5,5s-2.243,5-5,5
		s-5-2.243-5-5S27.243,14,30,14z"
                />
                <path
                    d="M29.823,54.757L45.164,32.6c5.754-7.671,4.922-20.28-1.781-26.982C39.761,1.995,34.945,0,29.823,0
		s-9.938,1.995-13.56,5.617c-6.703,6.702-7.535,19.311-1.804,26.952L29.823,54.757z M17.677,7.031C20.922,3.787,25.235,2,29.823,2
		s8.901,1.787,12.146,5.031c6.05,6.049,6.795,17.437,1.573,24.399L29.823,51.243L16.082,31.4
		C10.882,24.468,11.628,13.08,17.677,7.031z"
                />
                <path
                    d="M42.117,43.007c-0.55-0.067-1.046,0.327-1.11,0.876s0.328,1.046,0.876,1.11C52.399,46.231,58,49.567,58,51.5
		c0,2.714-10.652,6.5-28,6.5S2,54.214,2,51.5c0-1.933,5.601-5.269,16.117-6.507c0.548-0.064,0.94-0.562,0.876-1.11
		c-0.065-0.549-0.561-0.945-1.11-0.876C7.354,44.247,0,47.739,0,51.5C0,55.724,10.305,60,30,60s30-4.276,30-8.5
		C60,47.739,52.646,44.247,42.117,43.007z"
                />
            </g>
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
        </svg>
    );
};

export const IconGear = () => {
    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="24px"
            height="24px"
            viewBox="0 0 932.179 932.179"
            style={{
                enableBackground: `new 0 0 932.179 932.179`,
                marginLeft: `${30}px`
            }}
            // xml:space="preserve"
        >
            <g>
                <path
                    d="M61.2,341.538c4.9,16.8,11.7,33,20.3,48.2l-24.5,30.9c-8,10.1-7.1,24.5,1.9,33.6l42.2,42.2c9.1,9.1,23.5,9.899,33.6,1.899
		l30.7-24.3c15.8,9.101,32.6,16.2,50.1,21.2l4.6,39.5c1.5,12.8,12.3,22.4,25.1,22.4h59.7c12.8,0,23.6-9.601,25.1-22.4l4.4-38.1
		c18.8-4.9,36.8-12.2,53.7-21.7l29.7,23.5c10.1,8,24.5,7.1,33.6-1.9l42.2-42.2c9.1-9.1,9.9-23.5,1.9-33.6l-23.1-29.3
		c9.6-16.601,17.1-34.3,22.1-52.8l35.6-4.1c12.801-1.5,22.4-12.3,22.4-25.1v-59.7c0-12.8-9.6-23.6-22.4-25.1l-35.1-4.1
		c-4.801-18.3-12-35.8-21.199-52.2l21.6-27.3c8-10.1,7.1-24.5-1.9-33.6l-42.1-42.1c-9.1-9.1-23.5-9.9-33.6-1.9l-26.5,21
		c-17.2-10.1-35.601-17.8-54.9-23l-4-34.3c-1.5-12.8-12.3-22.4-25.1-22.4h-59.7c-12.8,0-23.6,9.6-25.1,22.4l-4,34.3
		c-19.8,5.3-38.7,13.3-56.3,23.8l-27.5-21.8c-10.1-8-24.5-7.1-33.6,1.9l-42.2,42.2c-9.1,9.1-9.9,23.5-1.9,33.6l23,29.1
		c-9.2,16.6-16.2,34.3-20.8,52.7l-36.8,4.2c-12.8,1.5-22.4,12.3-22.4,25.1v59.7c0,12.8,9.6,23.6,22.4,25.1L61.2,341.538z
		 M277.5,180.038c54.4,0,98.7,44.3,98.7,98.7s-44.3,98.7-98.7,98.7c-54.399,0-98.7-44.3-98.7-98.7S223.1,180.038,277.5,180.038z"
                />
                <path
                    d="M867.699,356.238l-31.5-26.6c-9.699-8.2-24-7.8-33.199,0.9l-17.4,16.3c-14.699-7.1-30.299-12.1-46.4-15l-4.898-24
		c-2.5-12.4-14-21-26.602-20l-41.1,3.5c-12.6,1.1-22.5,11.4-22.9,24.1l-0.799,24.4c-15.801,5.7-30.701,13.5-44.301,23.3
		l-20.799-13.8c-10.602-7-24.701-5-32.9,4.7l-26.6,31.7c-8.201,9.7-7.801,24,0.898,33.2l18.201,19.399
		c-6.301,14.2-10.801,29.101-13.4,44.4l-26,5.3c-12.4,2.5-21,14-20,26.601l3.5,41.1c1.1,12.6,11.4,22.5,24.1,22.9l28.1,0.899
		c5.102,13.4,11.801,26.101,19.9,38l-15.699,23.7c-7,10.6-5,24.7,4.699,32.9l31.5,26.6c9.701,8.2,24,7.8,33.201-0.9l20.6-19.3
		c13.5,6.3,27.699,11,42.299,13.8l5.701,28.2c2.5,12.4,14,21,26.6,20l41.1-3.5c12.6-1.1,22.5-11.399,22.9-24.1l0.9-27.601
		c15-5.3,29.199-12.5,42.299-21.399l22.701,15c10.6,7,24.699,5,32.9-4.7l26.6-31.5c8.199-9.7,7.799-24-0.9-33.2l-18.301-19.399
		c6.701-14.2,11.602-29.2,14.4-44.601l25-5.1c12.4-2.5,21-14,20-26.601l-3.5-41.1c-1.1-12.6-11.4-22.5-24.1-22.9l-25.1-0.8
		c-5.201-14.6-12.201-28.399-20.9-41.2l13.699-20.6C879.4,378.638,877.4,364.438,867.699,356.238z M712.801,593.837
		c-44.4,3.801-83.602-29.3-87.301-73.699c-3.801-44.4,29.301-83.601,73.699-87.301c44.4-3.8,83.602,29.301,87.301,73.7
		C790.301,550.938,757.199,590.138,712.801,593.837z"
                />
                <path
                    d="M205,704.438c-12.6,1.3-22.3,11.899-22.4,24.6l-0.3,25.3c-0.2,12.7,9.2,23.5,21.8,25.101l18.6,2.399
		c3.1,11.301,7.5,22.101,13.2,32.301l-12,14.8c-8,9.899-7.4,24.1,1.5,33.2l17.7,18.1c8.9,9.1,23.1,10.1,33.2,2.3l14.899-11.5
		c10.5,6.2,21.601,11.101,33.2,14.5l2,19.2c1.3,12.6,11.9,22.3,24.6,22.4l25.301,0.3c12.699,0.2,23.5-9.2,25.1-21.8l2.3-18.2
		c12.601-3.101,24.601-7.8,36-14l14,11.3c9.9,8,24.101,7.4,33.201-1.5l18.1-17.7c9.1-8.899,10.1-23.1,2.301-33.2L496.6,818.438
		c6.6-11,11.701-22.7,15.201-35l16.6-1.7c12.6-1.3,22.299-11.9,22.4-24.6l0.299-25.301c0.201-12.699-9.199-23.5-21.799-25.1
		l-16.201-2.1c-3.1-12.2-7.699-24-13.699-35l10.1-12.4c8-9.9,7.4-24.1-1.5-33.2l-17.699-18.1c-8.9-9.101-23.102-10.101-33.201-2.3
		l-12.101,9.3c-11.399-6.9-23.6-12.2-36.399-15.8l-1.601-15.7c-1.3-12.601-11.899-22.3-24.6-22.4l-25.3-0.3
		c-12.7-0.2-23.5,9.2-25.101,21.8l-2,15.601c-13.199,3.399-25.899,8.6-37.699,15.399l-12.5-10.2c-9.9-8-24.101-7.399-33.201,1.5
		l-18.2,17.801c-9.1,8.899-10.1,23.1-2.3,33.199l10.7,13.801c-6.2,11-11.1,22.699-14.3,35L205,704.438z M368.3,675.837
		c36.3,0.4,65.399,30.301,65,66.601c-0.4,36.3-30.301,65.399-66.601,65c-36.3-0.4-65.399-30.3-65-66.601
		C302.1,704.538,332,675.438,368.3,675.837z"
                />
            </g>
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
        </svg>
    );
};

export const IconStaffs = () => {
    return (
        <svg
            width="24px"
            height="24px"
            style={{ marginLeft: `${30}px` }}
            viewBox="0 -66 426 426"
            xmlns="http://www.w3.org/2000/svg">
            <path d="m212.699219 146.101562c22.699219 0 41.101562-18.402343 41.101562-41.101562s-18.402343-41.101562-41.101562-41.101562-41.097657 18.402343-41.097657 41.101562c.097657 22.699219 18.5 41.101562 41.097657 41.101562zm0 0" />
            <path d="m360.699219 28.5c-5.800781-1.601562-11.800781-1.800781-17.699219-1.101562-7.5 1-14 5-14 5-12.199219 7-20 19.800781-20.601562 33.902343 12.601562-1.800781 39.203124-8.902343 52.300781-37.800781zm0 0" />
            <path d="m356.800781 58.101562c-6.902343 6.597657-14.902343 11.898438-23.699219 15.699219-7.300781 3.097657-15 5.300781-22.902343 6.398438 5.300781 17.199219 21.199219 29 39.300781 29 18.398438.199219 34.699219-12 39.601562-29.800781-15.203124-4-26-13.796876-32.300781-21.296876zm0 0" />
            <path d="m366.101562 47.300781c4.5 5.699219 12.699219 14.097657 24.398438 17.898438-.800781-11.898438-6.800781-22.800781-16.300781-29.898438l-.800781-.601562c-2.097657 4.300781-4.597657 8.699219-7.296876 12.601562zm0 0" />
            <path d="m136.699219 223.398438v65.601562c0 2.199219 1.800781 4 4 4h67.300781v-51.398438l-24.5-79.800781c-27.601562 7.699219-46.800781 32.898438-46.800781 61.597657zm0 0" />
            <path d="m148.800781 165.300781c-6.800781-19.5-22.601562-34.601562-42.300781-40.601562l-24.5 88.800781v42.101562h40.699219v-32.203124c0-22.199219 9.5-43.398438 26.101562-58.097657zm-29.800781 20.699219h-13c-3.898438 0-7-3.101562-7-7s3.101562-7 7-7h13c3.898438 0 7 3.101562 7 7s-3.101562 7-7 7zm0 0" />
            <path d="m379.800781 124.5-11.601562 49.800781c-1.597657 6.898438-6.5 12.597657-13.199219 15.097657v66.203124h67c2.199219 0 4-1.800781 4-4v-65.601562c0-28.601562-18.898438-53.601562-46.199219-61.5zm18.199219 64.5h-17c-3.898438 0-7-3.101562-7-7s3.101562-7 7-7h17c3.898438 0 7 3.101562 7 7s-3.101562 7-7 7zm0 0" />
            <path d="m246.101562 163.101562-24.101562 78.5v51.398438h62.800781c2.199219 0 4-1.800781 4-4v-65.601562c0-27.097657-17.199219-51.296876-42.699219-60.296876zm18.898438 65.898438h-17c-3.898438 0-7-3.101562-7-7s3.101562-7 7-7h17c3.898438 0 7 3.101562 7 7s-3.101562 7-7 7zm0 0" />
            <path d="m0 185.898438v65.703124c0 2.199219 1.800781 4 4 4h64v-42.101562l-24.300781-88.199219c-26.097657 8.699219-43.699219 33.097657-43.699219 60.597657zm0 0" />
            <path d="m88.199219 122h-24.300781c-2.199219 0-4.296876.101562-6.398438.300781l17.601562 63.800781 17.597657-64c-1.597657 0-3-.101562-4.5-.101562zm0 0" />
            <path d="m224.898438 159.5h-24.296876c-1.101562 0-2.101562 0-3.101562.101562l17.5 57.097657 17.398438-56.800781c-2.5-.296876-5-.398438-7.5-.398438zm0 0" />
            <path d="m327.800781 174.199219-11.300781-48.5c-18.5 6.601562-33.101562 21.199219-39.601562 39.699219 16.5 14.703124 25.902343 35.800781 25.902343 57.902343v32.199219h38.199219v-66.199219c-6.601562-2.5-11.601562-8.199219-13.199219-15.101562zm0 0" />
            <path d="m362.101562 122h-24.300781c-2.601562 0-5.101562.199219-7.699219.5l11.296876 48.601562c.703124 3.097657 3.300781 5.398438 6.5 5.5h.5c3-.300781 5.402343-2.5 6-5.5l11.402343-48.902343c-1.101562-.097657-2.402343-.199219-3.699219-.199219zm0 0" />
            <path d="m76 109.199219c22.601562 0 40.601562-18.097657 41.101562-41.199219 0-22.699219-18.5-41.101562-41.203124-41-22.699219 0-41.097657 18.5-41 41.199219.101562 22.601562 18.5 41 41.101562 41zm0 0" />
            <path d="m343 23c0-5 4-9 9-9s9 4 9 9c0 0 0 3.199219-.300781 5.5 4.101562 1.300781 9.199219 3.699219 12.601562 6.101562 0 0 .398438.296876.800781.597657l.898438.699219v-12.898438c0-12.699219-10.300781-23-23-23s-23 10.300781-23 23v9.398438c4.199219-2.398438 9-4.097657 14-4.898438zm0 0" />
            <path d="m343 27.5c-5 .800781-9.800781 2.5-14 4.898438 4.300781-2.5 9.101562-4.199219 14-4.898438zm0 0" />
        </svg>
    );
};

export const IconFuelPump = () => {
    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="24px"
            height="24px"
            viewBox="0 0 79.536 79.536"
            style={{
                marginLeft: `${30}px`,
                enableBackground: `new 0 0 79.536 79.536`
            }}
            space="preserve">
            <g>
                <path
                    style={{ fill: "#010002" }}
                    d="M39.545,0H4.72C2.706,0,1.072,1.634,1.072,3.648v74.427h42.116V3.648
		C43.188,1.634,41.562,0,39.545,0z M37.945,27.827H6.323V7.115h31.623V27.827z M59.926,79.536c-8.647,0-13.888-5.95-14.385-16.321
		c-0.207-4.091,0.818-8.591,1.9-13.36c0.813-3.583,1.662-7.28,2.061-11.083c0.399-3.814-0.201-5.111-0.595-5.546
		c-0.73-0.813-2.879-0.813-4.956-0.813h-1.022v-5.056h1.022c3.035,0,6.479,0,8.715,2.486c1.75,1.937,2.341,4.947,1.869,9.46
		c-0.43,4.091-1.305,7.954-2.153,11.682c-1,4.417-1.952,8.596-1.792,11.982c0.367,7.643,3.511,11.512,9.336,11.512
		c6.4,0,9.647-4.045,9.647-12.02c0-6.597-2.051-11.733-3.413-15.13l-0.533-1.367c-1.413-3.702-1.6-7.021-1.745-12.052
		c-0.145-4.883-0.088-10.105,1.264-15.266c1.362-5.19,4.163-7.72,8.798-11.909l1.118-1.018l3.402,3.744l-1.134,1.023
		c-4.334,3.922-6.312,5.711-7.291,9.447c-1.191,4.536-1.228,9.328-1.104,13.831c0.141,4.908,0.332,7.539,1.419,10.397l0.508,1.284
		c1.419,3.531,3.77,9.435,3.77,17.005C74.627,73.151,69.128,79.536,59.926,79.536z M66.435,34.769l-0.129-5.059
		c0,0,3.92-0.101,6.142-0.127c0.051-0.896,0.051-2.296,0.051-3.764v-0.754c0-6.05-0.947-6.851-2.034-7.785
		c-0.399-0.334-0.793-0.684-1.176-1.103l3.718-3.418c0.238,0.259,0.497,0.466,0.74,0.673c2.16,1.843,3.807,3.866,3.807,11.627v0.753
		c0,2.835,0.005,4.401-0.249,5.592c-0.435,2.048-1.843,3.226-3.862,3.226C71.8,34.631,66.441,34.769,66.435,34.769z"
                />
            </g>
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
        </svg>
    );
};

export const IconEllipse = () => {
    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="24px"
            height="24px"
            viewBox="0 0 401.991 401.991"
            style={{ enableBackground: `new ${0} ${0} ${401.991} ${401.991}` }}
            space="preserve">
            <g>
                <g>
                    <path
                        d="M228.406,292.362h-54.816c-7.616,0-14.084,2.662-19.414,7.99c-5.33,5.329-7.994,11.797-7.994,19.411v54.826
			c0,7.611,2.664,14.086,7.994,19.41c5.327,5.332,11.798,7.991,19.414,7.991h54.816c7.614,0,14.082-2.663,19.41-7.991
			s7.994-11.799,7.994-19.41v-54.826c0-7.614-2.666-14.082-7.994-19.411C242.495,295.024,236.02,292.362,228.406,292.362z"
                    />
                    <path
                        d="M228.406,146.181h-54.816c-7.616,0-14.084,2.664-19.414,7.994s-7.994,11.798-7.994,19.414v54.819
			c0,7.618,2.664,14.086,7.994,19.411c5.327,5.332,11.798,7.994,19.414,7.994h54.816c7.614,0,14.082-2.662,19.41-7.994
			c5.328-5.325,7.994-11.793,7.994-19.411v-54.819c0-7.616-2.666-14.087-7.994-19.414
			C242.495,148.845,236.02,146.181,228.406,146.181z"
                    />
                    <path
                        d="M247.816,7.993C242.488,2.666,236.02,0,228.406,0h-54.816c-7.616,0-14.084,2.663-19.414,7.993
			c-5.33,5.33-7.994,11.803-7.994,19.414v54.821c0,7.616,2.664,14.084,7.994,19.414c5.327,5.327,11.798,7.994,19.414,7.994h54.816
			c7.614,0,14.082-2.664,19.41-7.994c5.328-5.327,7.994-11.798,7.994-19.414V27.406C255.813,19.795,253.151,13.322,247.816,7.993z"
                    />
                </g>
            </g>
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
        </svg>
    );
};

export const IconMac = () => (
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        style={{ enableBackground: `new 0 0 612 612` }}
        >
        <g>
            <path
                d="M541.322,500.219v-94.372c0-20.277-16.438-36.716-36.715-36.716h-9.598V24.598c0-3.082-1.547-5.958-4.117-7.657
		L467.587,1.537c-6.103-4.033-14.239,0.342-14.239,7.657v110.652l-6.945-18.734c-9.34-25.196-33.373-41.918-60.245-41.918H225.702
		c-27.03,0-51.169,16.916-60.394,42.323l-6.655,18.329V9.194c0-7.314-8.137-11.69-14.24-7.657L121.107,16.94
		c-2.571,1.699-4.118,4.575-4.118,7.657v344.534h-9.597c-20.277,0-36.715,16.438-36.715,36.716v94.372H55.035
		c-5.069,0-9.178,4.109-9.178,9.179v50.743c0,5.069,4.109,9.179,9.178,9.179h39.598v24.322c0,10.139,8.219,18.357,18.358,18.357
		h48.645c10.139,0,18.358-8.219,18.358-18.357V569.32h252.014v24.322c0,10.139,8.22,18.357,18.357,18.357h48.646
		c10.139,0,18.357-8.219,18.357-18.357V569.32h39.598c5.07,0,9.179-4.11,9.179-9.179v-50.742c0-5.07-4.109-9.179-9.179-9.179
		L541.322,500.219L541.322,500.219z M170.814,170.975h270.372v90.44H170.814V170.975z M164.527,474.533H133.17
		c-9.581,0-17.348-7.768-17.348-17.349v-0.438c0-9.581,7.767-17.348,17.348-17.348h31.356c9.581,0,17.348,7.767,17.348,17.348v0.438
		C181.875,466.765,174.108,474.533,164.527,474.533z M368.398,479.648H243.602c-10.139,0-18.358-8.22-18.358-18.357V344.976
		c0-10.138,8.219-18.357,18.358-18.357h124.796c10.138,0,18.357,8.22,18.357,18.357v116.314
		C386.756,471.428,378.536,479.648,368.398,479.648z M478.829,474.533h-31.356c-9.58,0-17.348-7.768-17.348-17.349v-0.438
		c0-9.581,7.768-17.348,17.348-17.348h31.356c9.581,0,17.349,7.767,17.349,17.348v0.438
		C496.178,466.765,488.41,474.533,478.829,474.533z M365.607,393.801H246.099c-5.019,0-9.087-4.068-9.087-9.088v-0.184
		c0-5.019,4.068-9.086,9.087-9.086h119.508c5.019,0,9.087,4.067,9.087,9.086v0.184C374.694,389.733,370.626,393.801,365.607,393.801
		z M365.607,357.085H246.099c-5.019,0-9.087-4.068-9.087-9.087v-0.184c0-5.018,4.068-9.086,9.087-9.086h119.508
		c5.019,0,9.087,4.068,9.087,9.086v0.184C374.694,353.017,370.626,357.085,365.607,357.085z M365.607,467.232H246.099
		c-5.019,0-9.087-4.068-9.087-9.087v-0.184c0-5.019,4.068-9.087,9.087-9.087h119.508c5.019,0,9.087,4.068,9.087,9.087v0.184
		C374.694,463.164,370.626,467.232,365.607,467.232z M365.607,430.516H246.099c-5.019,0-9.087-4.068-9.087-9.086v-0.184
		c0-5.019,4.068-9.087,9.087-9.087h119.508c5.019,0,9.087,4.068,9.087,9.087v0.184C374.694,426.448,370.626,430.516,365.607,430.516
		z"
            />
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
    </svg>
);
