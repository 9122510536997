export const calcDate = (dateObj) => {
  let d = dateObj ? new Date(dateObj) : new Date();
  var options = {
    weekday: undefined,
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return d.toLocaleDateString("en-US", options);
};

export const calcTime = (dateObj) => {
  let d = dateObj ? new Date(dateObj) : '';
  if (d) {
    d = d.toLocaleTimeString("en-US", { hour: 'numeric', minute: 'numeric' });
  }
  return d;
}

export const convertRole = (role) => {
  let roleValuePair = {
    SYS_ADMIN: 'System Admin',
    SUPER_ADMIN: 'Super Admin',
    VENDOR_ADMIN: 'Vendor Admin',
    LOGISTIC: 'Logistics User',
    PROCUREMENT: 'Procurement User',
    FLIT: 'Fleet Manager',
    DRIVER: 'Driver User',
    CUSTOMER_ADMIN: 'Corporate Admin',
    CUSTOMER_USER: 'Corporate User',
    VENDOR_SUPER_ADMIN: 'Vendor Super Admin',
  }

  if (role) {
    return roleValuePair[role];
  }
  else return '';
}

export const convertRequestType = value => {
  if (value === 'ADD_CUSTOMER_LOCATION_ORDERS') {
    return 'Bulk Customer Order';
  }
  else return value;
}

export const dateRangeOptFormatter = (option) => {
  if (option === 0) return "Custom";
  if (option === 1) return "Daily";
  if (option === 2) return "Weekly";
  if (option === 3) return "Monthly";
}

export const errorHandler = (err) => {
  let message = "Something is not right";
  if (err.response) {
    if (err.response.response) {
      let errObj = err.response.response;
      if (errObj.data && errObj.data.error_description) {
        message = errObj.data.error_description;
      }
    }
    if (err.response.message) {
      message = err.response.message
    }
  }
  return message;
}