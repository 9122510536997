import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import {
    GET_LOCATIONS_FAILURE,
    GET_LOCATIONS_SUCCESS,
    GET_LOCATIONS,
    // GET_PENDING_LOCATIONS_SUCCESS,
    // GET_PENDING_LOCATIONS_FAILURE,
    // GET_PENDING_LOCATIONS,
} from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* getLocations(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: { code, description, totalCount, payload: payloadReturned }
        } = yield call(Services.getLocationsService, payload);
        yield put(Actions.uiStop());

        if (code === 1) {
            return yield put({
                type: GET_LOCATIONS_SUCCESS,
                payloadReturned,
                totalCount
            });
        } else if (code !== 1) {
            yield put(Actions.uiStop());
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_LOCATIONS_FAILURE
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_LOCATIONS_FAILURE
        });
    }
}

export default function* getLocationsSaga() {
    yield takeLatest(GET_LOCATIONS, getLocations);
}
