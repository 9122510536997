import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import { FORGOT_RESET } from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify, NotifyClose } from "../../reuse/notify";
import { history } from "../../reuse/history";
import { url } from "../../helper";

function* forgotPassword(payload) {
    yield put(Actions.uiStart());
    try {
        const {
            data: { code, description }
        } = yield call(Services.forgotPasswordService, payload);
        yield put(Actions.uiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        if (code === 1) {
            setTimeout(() => {
                NotifyClose();
                history.push({ pathname: `${url}signin` });
            }, 3000);
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
    }
}

export default function* forgotPasswordSaga() {
    yield takeLatest(FORGOT_RESET, forgotPassword);
}
