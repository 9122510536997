import { baseurl } from "./authServices";
import { postFunc } from "./httpService";

// disable users
export const togglerUserStatusService = ({ payload }) => {
    return postFunc(`${baseurl}Userprofiling/toggleprofileactivation`, payload);
};

// disable locations
export const toggleLocationStatusService = ({ payload }) => {
    return postFunc(`${baseurl}Customer/togglelocationactivation`, payload);
};
