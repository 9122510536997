// generic reducer for entire app
import loginUser from "./login_action";
import onInit from "./init_action";
import logoutUser from "./logout_action";
import reset from "./reset_action";
import toggleRightSidebar from "./toggleRightSidebar_action";

// handling CUSTOMER admin
import resetPW from "./pwReset_action";
import resendOTP from "./resendOTP_action";
import signupUser from "./signup_action";
import verifyProfile from "./verifyProfile_action";
import forgotPW from "./forgotPW_action";
import changePW from "./changePW_action";

/**
 *
 * handling CUSTOMER USER BELOW
 *
 */
// handling user
import bulkUpload from "./bulkUpload_action";
import createUser from "./createUser_action";
import getLocation from "./getLocation_action";
import getLocationsActions from "./getLocations_action";
import createLocation from "./createLocation_action";
import editBasicInfo from "./editBasicInfo_action";
import getBasicInfo from "./getBasicInfo_action";
import getUsers from "./getUsers_action";
import getCustomers from "./getCustomers_action";
import addUserToLocations from "./addUserToLocations_action";
import addUsersToLocation from "./addUsersToLocation_action";
import getUsersInLocation from "./getUsersInLocation_action";

// handling permissions
import permissions from "./user_permissions/GET/permissions_action";
import userPermissions from "./user_permissions/GET/userPermissions_action";
import roles from "./user_permissions/GET/roles_action";
import getRemoveUserPermissions from "./user_permissions/GET/getRemoveUserPermissions_action";
import batchUpdateUserPermissions from "./user_permissions/POST/batchUpdateUserPermissions_action";
import changeUserRole from "./user_permissions/POST/changeUserRole_action";
import removeUserPermission from "./user_permissions/POST/removeUserPermission_action";
import removeUserPermissions from "./user_permissions/POST/removeUserPermissions_action";
import updateUserPermissions from "./user_permissions/POST/updateUserPermissions_action";
import deleteUserFromLocation from "./deleteUserFromLocation_action";

// orders
import getVendorWithProducts, {
  getVendorsProducts,
} from "./orders/getVendorWithProds_action";
import createOrder from "./orders/createOrder_action";
import getPendingOrder from "./orders/getPendingOrders_action";
import getOrderItems from "./orders/getOrderItems_action";
import getOrders from "./orders/getOrders_action";
import getOrder from "./orders/getOrder_action";
import reactToOrder from "./orders/reactToOrder_action";
import verifyOrder from "./orders/verifyOrder_action";
import requestContract from "./orders/requestContract_action";
import getOrderHistory from "./getOrderHistory_action";
import getVendorContracts, {
  getContractList,
  getContractDetails,
  getContractHistory,
} from "./orders/getVendorContracts_action";
import getProdnContDetail from "./orders/getProdnContDetail_action";

// USER status
import toggleUserStatus from "./status/toggleUserStatus_action";
import toggleLocationStatus from "./status/toggleLocationStatus";

// STATISTICS
import getCustomerOrderStats from "./statistics/getCustomerOrderStats_action";
import getCustomerProfileStats from "./statistics/getCustomerProfileStats_action";
import getUserLocations from "./getUserLocations_action";
import getUser from "./getUser_action";
import submitVerificationCode from "./submitVerificationCode_action";

// Reports
import getReportsActions from "./getReports_action";

//Audit
import auditLogActions from "./auditLog_actions";

import getPendingBulkActions from "./getPendingBulkUpload_action";

import getBranchOrders from "./orders/getBranchOrders_action";
import getBranchFulfilledOrders from "./orders/getBranchFulfilledOrders_action";
import getBranchDeliveryOrders from "./orders/getBranchDeliveryOrder_action";
import getRecentOrders from "./orders/getRecentOrders";

//Onboard
import signupUserOnboard from "./onBoard/signUpOnboard";
import verifyUserOnboard from "./onBoard/verify_email_action";

const { getPendingBulkUploads, verifyPendingBulkUploads } =
  getPendingBulkActions;

const uiStop = () => ({
  type: "STOP_LOAD",
});

const uiStart = () => ({
  type: "LOAD",
});
const miniUiStart = () => ({
  type: "mini_LOAD",
});
const miniUiStop = () => ({
  type: "mini_STOP_LOAD",
});

const { getLocations } = getLocationsActions;
export const Actions = {
  submitVerificationCode,
  uiStop,
  uiStart,
  miniUiStop,
  miniUiStart,
  // action to toggle right sidebar
  toggleRightSidebar,
  // action to signin user
  loginUser,
  // action on init of any route
  onInit,
  // action to logout user
  logoutUser,
  // action to signup user
  signupUser,
  // action to reset password
  resetPW,
  // action to resend OTP
  resendOTP,
  // action to verifyprofile
  verifyProfile,
  // action to forget password
  forgotPW,
  // action to change password
  changePW,
  // action to create CUSTOMER user
  createUser,
  // action to create CUSTOMER location
  createLocation,
  // action to edit basic profile info
  editBasicInfo,
  // action to get basic profile info
  getBasicInfo,
  // action to get location
  getLocation,
  // action to get locations
  getLocations,
  // getPendingLocations,

  // action to get  users
  getUser,
  getUsers,
  // action to get created customers
  getCustomers,
  // action to add user to locations
  addUserToLocations,
  // action to add users to location
  addUsersToLocation,
  getUsersInLocation,
  deleteUserFromLocation,
  bulkUpload,
  getPendingBulkUploads,
  verifyPendingBulkUploads,

  // GET
  // action to get permissions
  permissions,
  // action to get user permissions
  userPermissions,
  // action to get remove user permissions
  getRemoveUserPermissions,
  // action to get roles
  roles,
  getUserLocations,

  // POST
  // action to remove user permissions
  batchUpdateUserPermissions,
  // action to batch update user permissions
  changeUserRole,
  // action to change user role permissions
  removeUserPermission,
  // action to remove user permissions
  removeUserPermissions,
  // action to remove users permissions
  updateUserPermissions,
  // action to reset actions after every error response
  reset,

  // orders
  getVendorWithProducts,
  getProdnContDetail,
  createOrder,
  getPendingOrder,
  getOrderItems,
  getOrder,
  getOrders,
  reactToOrder,
  verifyOrder,
  requestContract,
  getOrderHistory,
  getVendorContracts,
  getVendorsProducts,
  // STATUSES
  // toggle location status
  toggleLocationStatus,
  // toggle user status
  toggleUserStatus,

  //  STATISTICS
  getCustomerOrderStats,
  getCustomerProfileStats,

  // REPORTS
  getReportsActions,

  //
  auditLogActions,

  // contracts
  getContractList,
  getContractDetails,
  getContractHistory,

  // Analytics
  getBranchOrders,
  getBranchFulfilledOrders,
  getBranchDeliveryOrders,
  getRecentOrders,

  //Onboard
  signupUserOnboard,
  verifyUserOnboard,
};
