import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import { TOGGLE_LOCATION_STATUS } from "../../arsVariables";
import { Actions } from "../../actions/_index";
import { Notify } from "../../../reuse/notify";
import { errorHandler } from "../../../reuse/utilities";

function* toggleLocationStatus(payload) {
    try {
        const {
            data: { code, description }
        } = yield call(Services.toggleLocationStatusService, payload);
        yield put(Actions.uiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        if (code === 1) {
            yield put(
                Actions.getLocations({
                    pageIndex: payload.payload.pageIndex,
                    pageSize: 10
                })
            );
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
    }
}

export default function* toggleLocationStatusSaga() {
    yield takeLatest(TOGGLE_LOCATION_STATUS, toggleLocationStatus);
}
