import React from "react";
import { TextField, withStyles } from "@material-ui/core";

const styles = {
  root: {
    "& .MuiOutlinedInput-root": {
      height: "40px", // Adjust this value as needed
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px", // Adjust padding to control text field content
    },
    "& .MuiPrivateNotchedOutline-legend-55": {
      lineHeight: "5px",
    },
    "& .MuiFilledInput-root": {
      borderBottom: "none", // Remove the border-bottom
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: "none", // Remove the default underline before focus
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "none", // Remove the default underline after focus
    },
    "& .MuiFilledInput-underline:hover:before": {
      borderBottom: "none", // Remove the hover underline
    },
  },
};

function CustomTextField(props) {
  console.log(props);
  const { classes } = props;

  return (
    <TextField
      //   variant="outlined"
      label="Reduced Height"
      className={classes.root}
      variant="filled"
      //   {...rest}
    />
  );
}
const StyledTextField = withStyles(styles)(CustomTextField);
export default StyledTextField;
