import { GET_LOCATIONS } from "../../redux-flow/arsVariables";

const getLocations = payload => ({
    type: GET_LOCATIONS,
    payload
});



const getLocationsActions = {
    getLocations,
}

export default getLocationsActions;
