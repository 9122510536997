import {
    GET_AUDIT,
    DOWNLOAD_AUDIT_LOG,
} from "../arsVariables";

const getAuditLog = (payload) => ({
    type: GET_AUDIT,
    payload,
});

const downloadAuditLog = (payload) => ({
    type: DOWNLOAD_AUDIT_LOG,
    payload,
});


const auditLogActions = {
    getAuditLog,
    downloadAuditLog,
};

export default auditLogActions;