import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const MenuBar = ({ title, currentText = '', onChangeHandler, onSubmitHandler }) => {
  const [searchText, setSearchText] = useState(currentText);
  const hasSearch = (typeof onChangeHandler === 'function' && typeof onSubmitHandler === 'function');

  const handleBlurOrChange = (e) => {
    if (hasSearch) {
      setSearchText(e.target.value);
      onChangeHandler(e);
    }
  }

  return (
    <div className="menu-bar">
      <div className="menu-link" /* style={{borderBottomWidth: 0.5, borderBottomColor: "#e5e5e5", borderBottomStyle: "solid"}} */>
        {Array.isArray(title) ? title.map(({ title, link }, index) =>
          <NavLink to={link} className="link mr-3">{title || 'Dashboard'}</NavLink>
        ) : <span className="mr-3">{title || 'Dashboard'}</span>}
        {hasSearch && <div className="menu-input-container">
          <input
            style={{
              color: 'white'
            }}
            type="text"
            value={searchText}
            onChange={handleBlurOrChange}
            onBlur={handleBlurOrChange}
            placeholder="Search..."
            className="menu-input"
          />
          <i className="input-icon" onClick={onSubmitHandler}><SearchSvg /></i>
        </div>}
      </div>
    </div>
  )
};

export const SearchSvg = ({ color = "white" }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.80624 7.7457C9.39675 6.93931 9.74996 5.9488 9.74996 4.87502C9.74996 2.18703 7.56294 0 4.87497 0C2.18699 0 0 2.18703 0 4.87502C0 7.56301 2.18701 9.75004 4.87499 9.75004C5.94877 9.75004 6.93936 9.39678 7.74575 8.80627L10.9395 12L12 10.9395C12 10.9394 8.80624 7.7457 8.80624 7.7457ZM4.87499 8.25002C3.01391 8.25002 1.50001 6.73611 1.50001 4.87502C1.50001 3.01392 3.01391 1.50001 4.87499 1.50001C6.73607 1.50001 8.24997 3.01392 8.24997 4.87502C8.24997 6.73611 6.73605 8.25002 4.87499 8.25002Z" fill={color} />
    </svg>
  );
};

export default MenuBar;