import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import { CREATE_LOC_USER } from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* createLocation(payload) {
    try {
        const { data } = yield call(Services.createLocationService, payload);
        const { code, description } = data;
        yield put(Actions.miniUiStop());

        yield put(Actions.uiStart());
        if (code === 1) yield put(Actions.toggleRightSidebar(false));
        yield put(Actions.getLocations({ pageIndex: 1, pageSize: 10 }));
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
    }
}

export default function* createLocationSaga() {
    yield takeLatest(CREATE_LOC_USER, createLocation);
}
