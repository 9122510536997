import React, { Fragment, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "../reuse/sidebar";
import { Actions } from "../redux-flow/actions/_index";
import { CUSTOMER_USER, CUSTOMER_ADMIN } from "../redux-flow/arsVariables";
import { Spin } from "antd";
import Icon from "@material-ui/core/Icon";
import BaseComponent from "../reuse/baseComponent";
import MenuBar from "../reuse/MenuBar";

const CreateLocation = lazy(() => import("../components/Create_Location"));

class Locations extends BaseComponent {
  state = {
    pageIndex: 1,
    pageSize: 10,
    dropdown: false,
    SearchKeyword: "",
  };

  componentDidMount() {
    const { pageIndex, pageSize } = this.state;
    this.fetch({ pageIndex, pageSize });
  }

  fetch = ({ pageIndex, pageSize, SearchKeyword = "" }) => {
    const { dispatch } = this.props;
    const { userId, decodedData } = this.context
    dispatch(
      Actions.getLocations({
        pageIndex,
        pageSize,
        SearchKeyword,
        userId: decodedData.role === CUSTOMER_ADMIN ? undefined : userId,
      })
    );
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      pageIndex: Number(e.target.id),
    });
  };

  handleViewLocation = (e) => {
    //  console.log(this.props)
  }

  _toggleRightSidebar = (type) => {
    const { dispatch } = this.props;
    const { toggleRightSidebar } = this.context
    if (toggleRightSidebar === type) {
      dispatch(Actions.toggleRightSidebar(false));
    } else {
      dispatch(Actions.toggleRightSidebar(type));
    }
  };

  render() {
    const { pageIndex, pageSize, SearchKeyword } = this.state;
    const { decodedData: { role, CRA_01 }, loading, status, toggleModal, toggleRightSidebar } = this.context;

    const {
      locations,
      locationsLength,
    } = this.props;

    /* const searchBox = () => (
            <form className="ml-auto col-5" onSubmit={this.getBySearch}>
                <input
                    value={SearchKeyword}
                    onChange={this.handleSearch}
                    onBlur={this.handleSearch}
                    className="isw-input-search"
                    placeholder="Search"
                />
            </form>
        ); */

    return (
      <Fragment>
        <Sidebar
          resetSearch={this.getBySearch}
          search={this.state.SearchKeyword}
          status={status}
          toggleModal={toggleModal}
        />

        <div className="isw-mainLayout1">
          <Spin tip="Please wait..." spinning={loading}>
            <div className="isw-content--wrapper">
              <MenuBar
                title="Locations"
                currentText={SearchKeyword}
                onChangeHandler={this.handleSearch}
                onSubmitHandler={this.getBySearch}
              />
              <div>
                <div className="content" id="content-body">
                  <div className={`container-fluid container-limited`}>
                    <div className="row my-4">
                      <div className="col-lg-6 col-6 isw-valign--middle mr-auto">
                        {this.renderPageTracker(locationsLength)}
                      </div>
                      {role === CUSTOMER_USER ? (
                        <>{/* searchBox() */}</>
                      ) : (
                        <Fragment>
                          {/* {searchBox()} */}
                          {CRA_01 && (
                            <div className="col-lg-3 text-right">
                              <Link to="/customer/pending-upload-requests" className="text-primary">
                                <button
                                  onClick={this.handleViewLocation}
                                  className="isw-btn isw-btn--raised w-100 text-black"
                                >
                                  <Icon>room</Icon>
                                  <span style={{ fontSize: 10 }}>View Pending Upload</span>
                                </button>
                              </Link>
                            </div>
                          )}
                          {CRA_01 && (
                            <div className="col-lg-3 text-right">
                              <span
                                data-toggle="modal"
                                data-target="#exampleModal"
                                className="isw-btn isw-btn--raised bg-primary text-white w-100"
                              >
                                <Icon>add</Icon>
                                <span>Add Location</span>
                              </span>
                            </div>
                          )}
                        </Fragment>
                      )}
                    </div>

                    <div
                      id="exampleModal"
                      className="modal fade mt-5"
                      role="dialog"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <div className="isw-card-header p-0">
                              <h3 className="isw-card-h3">Create Location</h3>
                              <div className="isw-card-p">Select means</div>
                            </div>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body p-5">
                            <div className="d-flex justify-content-between">
                              <span
                                className="d-flex flex-column justify-content-center py-3 px-5 border isw-hoverClick"
                                data-dismiss="modal"
                                onClick={() =>
                                  this._toggleRightSidebar("single")
                                }
                              >
                                <i
                                  className="material-icons text-center"
                                  style={{
                                    fontSize: `${3}em`,
                                  }}
                                >
                                  add_location
                                </i>
                                <h6 className="mb-0">Single Location</h6>
                              </span>

                              <span
                                className="d-flex flex-column justify-content-center py-3 px-5 border isw-hoverClick"
                                data-dismiss="modal"
                                onClick={() => this._toggleRightSidebar("bulk")}
                              >
                                <i
                                  className="material-icons text-center"
                                  style={{
                                    fontSize: `${3}em`,
                                  }}
                                >
                                  location_city
                                </i>
                                <h6 className="mb-0">Bulk Location</h6>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-12"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className="row mb-4 mt-3">
                          <div className="col-12">
                            <div className="isw-table">
                              <ul className="isw-table--head">
                                <li className="isw-table--headLi">
                                  {role === CUSTOMER_ADMIN ? (
                                    <div className="row">
                                      <div className="col-lg-2">
                                        <span className="isw-table--headItem">
                                          Name
                                        </span>
                                      </div>
                                      <div className="col-lg-5">
                                        <span className="isw-table--headItem">
                                          Address
                                        </span>
                                      </div>
                                      <div className="col-lg-2">
                                        <span className="isw-table--headItem">
                                          Status
                                        </span>
                                      </div>
                                      <div className="col-lg-2 ">
                                        <span className="isw-table--headItem">
                                          Email
                                        </span>
                                      </div>
                                      <div className="col-lg-1 ">
                                        <span className="isw-table--headItem">
                                          Action
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="row">
                                      <div className="col-lg-2">
                                        <span className="isw-table--headItem">
                                          Name
                                        </span>
                                      </div>
                                      <div className="col-lg-5">
                                        <span className="isw-table--headItem">
                                          Address
                                        </span>
                                      </div>
                                      <div className="col-lg-3">
                                        <span className="isw-table--headItem">
                                          Status
                                        </span>
                                      </div>
                                      <div className="col-lg-2 ">
                                        <span className="isw-table--headItem">
                                          Email
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </li>
                              </ul>
                              {locationsLength === 0 ? (
                                role === CUSTOMER_ADMIN ? (
                                  this.renderTableAbnormality(
                                    `No location(s) created`
                                  )
                                ) : (
                                  this.renderTableAbnormality(
                                    `No location(s) assigned`
                                  )
                                )
                              ) : !locationsLength ? (
                                this.renderTableAbnormality()
                              ) : (
                                <ul className="isw-table--body">
                                  {locations.map((location) => {
                                    const {
                                      activated,
                                      address,
                                      emailAddress,
                                      name,
                                      id,
                                    } = location;
                                    return (
                                      <li
                                        className="isw-table--bodyLi"
                                        key={id.toString()}
                                      >
                                        {role === CUSTOMER_ADMIN ? (
                                          <div className="row isw-table--bodyA">
                                            <div className="col-lg-2 isw-valign--middle">
                                              <h3
                                                className="isw-subtitle"
                                                style={{ flexWrap: "nowrap" }}
                                              >
                                                {name}
                                              </h3>
                                            </div>
                                            <div className="col-lg-5 isw-valign--middle">
                                              <span className="isw-p2">
                                                {address}
                                              </span>
                                            </div>

                                            <div className="col-lg-2 isw-valign--middle">
                                              <span className="isw-p2">
                                                {activated
                                                  ? "Active"
                                                  : "Inactive"}
                                              </span>
                                            </div>

                                            <div className="col-lg-2 isw-valign--middle">
                                              <span className="isw-p2">
                                                {emailAddress}
                                              </span>
                                            </div>
                                            <div className="col-lg-1 isw-valign--middle text-center">
                                              {this.renderTableDropdown({
                                                activated,
                                                id,

                                                component: "locations",
                                              })}
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="row isw-table--bodyA">
                                            <div className="col-lg-2 isw-valign--middle">
                                              <h3
                                                className="isw-subtitle"
                                                style={{ flexWrap: "nowrap" }}
                                              >
                                                {name}
                                              </h3>
                                            </div>
                                            <div className="col-lg-5 isw-valign--middle">
                                              <span className="isw-p2">
                                                {address}
                                              </span>
                                            </div>

                                            <div className="col-lg-3 isw-valign--middle">
                                              <span className="isw-p2">
                                                {activated
                                                  ? "Active"
                                                  : "Inactive"}
                                              </span>
                                            </div>

                                            <div className="col-lg-2 isw-valign--middle">
                                              <span className="isw-p2">
                                                {emailAddress}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        {this.renderPagination(locationsLength)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>

        {!CRA_01 ? null : (
          <Suspense fallback={<Spin spinning={true} tip="Loading..." />}>
            <CreateLocation
              fetch={this.fetch}
              toggleRightSidebar={toggleRightSidebar}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Suspense>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ getLocations_reducer }) => {
  const { locations, locationsLength } = getLocations_reducer;
  return {
    locations,
    locationsLength,
  };
};

export default connect(mapStateToProps)(Locations);
