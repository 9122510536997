import {
  decryptAndRead,
  getAuthCsrfToken,
  getOrderCsrfToken,
  setAuthCrfToken,
  setOrderCsrfToken,
} from "./localStorageHelper";
import { history } from "../../reuse/history";
import { ENCRYPT_USER, EXPIRY } from "../arsVariables";
import { store } from "../../index";
import { Actions } from "../actions/_index";
import axios from "axios";
// import { url } from "../../App";
// import { store } from "../../helper";
import { object } from "prop-types";
const url = "/customer/";

axios.interceptors.response.use(
  (headers) => {
    const orderToken = getOrderCsrfToken();
    const authToken = getAuthCsrfToken();
    if (!orderToken || orderToken !== headers.headers["u4do"]) {
      setOrderCsrfToken(headers.headers["u4do"]);
    }
    if (!authToken || authToken !== headers.headers["u4da"]) {
      setAuthCrfToken(headers.headers["u4da"]);
    }

    return headers;
  },
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;
      if (status >= 500) {
        if (!window.location.pathname.includes("dashboard")) {
          setTimeout(() => {
            history.push({ pathname: `${url}#/500` });
          }, 1000);
        }
        return Promise.reject({ error, message: "Server error" });
      } else if (status === 401 && data === "") {
        const decryptedToken = decryptAndRead(ENCRYPT_USER);

        const expiry = localStorage.getItem(EXPIRY);
        if (!decryptedToken) {
          store.dispatch(Actions.logoutUser());
        } else if (decryptedToken) {
          const { access_token, expired } = decryptedToken;
          if (expired && new Date().getTime() < Number(expiry) + 3600000) {
            store.dispatch(Actions.onInit());
          } else if (
            expired &&
            new Date().getTime() > Number(expiry) + 3600000
          ) {
            store.dispatch(Actions.logoutUser());
          }
          return new Promise((resolve) => {
            error.config.headers.Authorization = "Bearer " + access_token;
            resolve(axios(error.config));
          });
        }
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  axios.defaults.withCredentials = true;
  const decryptedToken = decryptAndRead(ENCRYPT_USER);
  if (decryptedToken) {
    const { access_token, expired } = decryptedToken;
    if (!expired) {
      config.headers["Authorization"] = `Bearer ${access_token}`;
    }
  }

  if (config.method.toLowerCase() === "post") {
    const orderCsrfToken = getOrderCsrfToken();
    const authCsrfToken = getAuthCsrfToken();

    if (orderCsrfToken) {
      config.headers["u4do"] = orderCsrfToken;
    }
    if (authCsrfToken) {
      config.headers["u4da"] = authCsrfToken;
    }
  }
  return config;
});

/**
 * WHY? To Download files
  You create an axios instance for downloads separately,
 because you need the "responseType"
 */

const downloadInstance = axios.create({
  responseType: "blob", // VERY IMPORTANT FOR DOWNLOADS else file will be blank
});
// intercept to add auth headers
downloadInstance.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    const decryptedToken = decryptAndRead(ENCRYPT_USER);
    if (decryptedToken) {
      const { access_token, expired } = decryptedToken;
      if (!expired) {
        config.headers.Authorization = `Bearer ${access_token}`;
      }
    }

    const orderCsrfToken = getOrderCsrfToken();
    const authCsrfToken = getAuthCsrfToken();

    if (orderCsrfToken) {
      config.headers["u4do"] = orderCsrfToken;
    }
    if (authCsrfToken) {
      config.headers["u4da"] = authCsrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject({ error });
  }
);

const getFuncForDownload = (path, payload) => {
  return new Promise((resolve, reject) => {
    downloadInstance
      .get(path, payload)
      .then((res) => {
        return resolve({ ...res, error: false });
      })
      .catch((error) => {
        return reject({ error: true, response: error });
      });
  });
};

const getFunc = (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(path, payload)
      .then((res) => {
        return resolve({ ...res, error: false });
      })
      .catch((error) => {
        return reject({ error: true, response: error });
      });
  });
};

const delFunc = (path) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(path)
      .then((res) => {
        return resolve({ ...res, error: false });
      })
      .catch((error) => {
        return reject({ error: true, response: error });
      });
  });
};

const postFunc = (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(path, payload)
      .then((res) => {
        return resolve({ ...res, error: false });
      })
      .catch((error) => {
        return reject({ error: true, response: error });
      });
  });
};
const putFunc = (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(path, payload)
      .then((res) => {
        return resolve({ ...res, error: false });
      })
      .catch((error) => {
        return reject({ error: true, response: error });
      });
  });
};

export { getFunc, delFunc, postFunc, axios, getFuncForDownload, putFunc };
