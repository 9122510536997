import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  BrowserRouter,
  useLocation,
} from "react-router-dom";
import { Actions } from "./redux-flow/actions/_index";
import { SUCCESS, FAILURE, ENCRYPT_USER } from "./redux-flow/arsVariables";
import {
  clear,
  decryptAndRead,
} from "./redux-flow/services/localStorageHelper";
// css
import "bootstrap/dist/js/bootstrap.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./assets/stylesheet/app.css";
import "./scss/kamsi.css";
// auth
import Login from "./Auth/Login/Login";
// import ForgotPW from "./Auth/Forgot/ForgotPW";
// import ResetPW from "./Auth/Reset/ResetPW";
import Signup from "./Auth/Signup/Signup";

// pages
import Locations from "./pages/Locations";
import Staff from "./pages/Staff";
import UserPermission from "./pages/User_Permission";
import DashboardAdmin from "./pages/Dashboard_admin";
import DashboardChecker from "./pages/Dashboard_checker";
import DashboardUser from "./pages/Dashboard_user";
import Delivery from "./pages/Delivery";
import Reports from "./pages/Reports";
import Audit from "./pages/Audit_trail";

// shared
import Layout from "./reuse/layout";
import Page404 from "./reuse/404";
import Page403 from "./reuse/403";
import Page500 from "./reuse/500";
import EnterOTP from "./Auth/EnterOTP";

import { history } from "./reuse/history";
import VendorList from "./components/Orders/VendorList";
import ContractList from "./components/Orders/ContractMenu/ContractList";
import LoadingPage from "./reuse/loading";
import AuthLayout from "./reuse/authLayout";
import { store } from ".";
// import { Notify } from "./reuse/notify";

import IdleTimer from "react-idle-timer";
import AllContractList from "./components/Orders/ContractMenu/AllContractList";
import ContractDetail from "./components/Orders/ContractDetail";
import TermsAndCondition from "./pages/Terms";
import SignupOnboard from "./Auth/SignupOnboard/Signup";
import EmailSentOnboard from "./Auth/SignupOnboard/EmailSent";
import VeifyEmail from "./Auth/SignupOnboard/verifyEmail";

// lazy loaded components
const VendorContract = lazy(() => import("./components/Orders/VendorContract"));
const OrderCreate = lazy(() => import("./components/Orders/Order_Create"));
const ProfileInfo = lazy(() => import("./components/Profile/Profile"));
const GeneralProfile = lazy(() => import("./components/GeneralProfile"));
const VendorProduct = lazy(() => import("./components/Orders/VendorProduct"));
const PendingApprovals = lazy(() => import("./components/Pending_Approvals"));
const PendingRequests = lazy(() => import("./components/Pending_Requests"));
const PendingUploadRequest = lazy(() =>
  import("./components/PendingUploadRequest")
);
// const Audit = lazy(() => import());
/** */
export const url = "/customer/";
export const FRONT_SSO_URL = window.env ? window.env.FRONT_SSO_URL : "";

// variable to hold auth status and also functions to convert it
export const fakeAuth = {
  isAuthenticated: false,
  authenticate() {
    return (this.isAuthenticated = true);
  },
  signout() {
    return (this.isAuthenticated = false);
  },
};

// Private Router function
const PrivateRoute = ({ props }) => {
  const location = useLocation();
  return fakeAuth.isAuthenticated === true ? (
    <Layout {...props} fakeAuth={fakeAuth} />
  ) : (
    <Navigate
      to={{
        pathname: url,
        state: {
          from: location,
          error: location !== url ? true : false,
        },
      }}
    />
  );
};

const AuthRoute = ({ ...rest }) => <AuthLayout {...rest} fakeAuth={fakeAuth} />;

const DashOptionRoute = ({ CRA_01, CRA_02 }) =>
  CRA_02 !== undefined && CRA_01 === undefined ? (
    <DashboardChecker />
  ) : CRA_02 === undefined && CRA_01 === undefined ? (
    <DashboardUser />
  ) : (
    <DashboardAdmin />
  );

class App extends Component {
  state = {
    loaded: false,
    toggling: false,
    pageIndex: null,
    pageSize: null,
    page: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(Actions.onInit());
  }

  static getDerivedStateFromProps = (props, state) => {
    const { storage, dispatch, urlFromStore } = props;
    const { loaded } = state;

    if (urlFromStore && !history.location.pathname.includes(urlFromStore)) {
      // Notify({ message: `UNAUTHORIZED CLIENT`, className: "error" });
      fakeAuth.signout();
      clear();
      dispatch(Actions.reset());
      return {
        loaded: true,
      };
    }

    if (storage === SUCCESS && loaded === false) {
      fakeAuth.authenticate();
      return {
        loaded: true,
      };
    }

    if (storage === FAILURE && loaded === false) {
      fakeAuth.signout();
      clear();
      dispatch(Actions.reset());
      return {
        loaded: true,
      };
    }

    return state;
  };

  _toggleStatus = (userId, activate, page, pageIndex) => {
    const { dispatch } = this.props;
    dispatch(Actions.uiStart());
    if (page === "locations") {
      dispatch(
        Actions.toggleLocationStatus({
          locationId: userId,
          activate,
          pageIndex,
        })
      );
    } else if (page === "users") {
      dispatch(Actions.toggleUserStatus({ userId, activate, pageIndex }));
    }
  };

  idleTimer = null;
  _onAction = (e) => {
    this.idleTimer.reset();
  };

  _onActive = (e) => {
    this.idleTimer.reset();
  };

  _onIdle = (e) => {
    if (this.idleTimer.isIdle() && fakeAuth.isAuthenticated) {
      fakeAuth.signout();
      clear();
      this.props.dispatch(Actions.logoutUser());
    }
  };

  render() {
    const { loaded } = this.state;
    const { decodedData } = this.props;
    const { CRA_01, CRA_02 } = decodedData; // TODO
    const decryptedToken = decryptAndRead(ENCRYPT_USER);

    if (loaded) {
      return (
        <Suspense fallback={<LoadingPage text="Loading..." />}>
          <IdleTimer
            ref={(ref) => (this.idleTimer = ref)}
            onActive={this._onActive}
            onIdle={this._onIdle}
            onAction={this._onAction}
            debounce={10}
            timeout={
              decryptedToken && decryptedToken.expired
                ? 1000 * 30
                : 1000 * 60 * 61
            }
          />
          <BrowserRouter history={history}>
            <Routes>
              <Route
                // if it falls on the localhost:3000/ or www.smartfuel.netlify.com/
                exact
                path="/"
                element={
                  fakeAuth.isAuthenticated ? (
                    <Navigate
                      push
                      to={{
                        pathname:
                          history.location.hash !== ""
                            ? `/${history.location.hash}`
                            : `${url}dashboard`,
                        state: {
                          from: history.location,
                        },
                      }}
                    />
                  ) : (
                    <Navigate
                      to={{
                        pathname: `${url}signin`,
                        state: {
                          from: history.location,
                        },
                      }}
                    />
                  )
                }
              />

              <Route
                // if it falls on the localhost:3000/customer or www.smartfuel.netlify.com/customer
                path={url}
                element={
                  fakeAuth.isAuthenticated ? (
                    <Navigate
                      push
                      to={{
                        pathname:
                          history.location.hash !== ""
                            ? `${url}${history.location.hash}`
                            : `${url}dashboard`,
                        state: {
                          from: history.location,
                        },
                      }}
                    />
                  ) : (
                    <Navigate
                      to={{
                        pathname: `${url}signin`,
                        state: {
                          from: history.location,
                        },
                      }}
                    />
                  )
                }
              />
              <Route
                path={`${url}denied`}
                children={() => {
                  window.location.href = FRONT_SSO_URL + "?d=isw";
                  return <LoadingPage text="Redirecting..." />;
                }}
              />
              <Route element={<AuthRoute />}>
                <Route path={`${url}terms`} element={<TermsAndCondition />} />
                <Route path={`${url}signup`} element={<SignupOnboard />} />
                <Route path={`${url}signin`} element={<Login />} />
                <Route
                  exact
                  path={`${url}verify-profile`}
                  element={<EmailSentOnboard />}
                />
                <Route
                  exact
                  path={`${url}verify-email`}
                  element={<VeifyEmail />}
                />
                {/* <Route
                  exact
                  path={`${url}verify-profile`}
                  element={<EnterOTP />}
                /> */}
              </Route>

              {/* <AuthRoute
                                exact
                                path={`${url}forgotPassword`}
                                component={ForgotPW}
                            />

                            <AuthRoute
                                exact
                                path={`${url}resetPassword`}
                                component={ResetPW}
                            /> */}
              <Route element={<PrivateRoute props={this.props} />}>
                <Route exact path={`${url}delivery`} element={<Delivery />} />

                <Route
                  exact
                  path={`${url}reports`}
                  element={<Reports />}
                  _toggleStatus={this._toggleStatus}
                />

                <Route
                  exact
                  path={`${url}users`}
                  element={<Staff />}
                  _toggleStatus={this._toggleStatus}
                />

                <Route
                  exact
                  path={`${url}users/:id`}
                  element={<GeneralProfile />}
                />

                <Route
                  exact
                  path={`${url}locations`}
                  element={<Locations />}
                  _toggleStatus={this._toggleStatus}
                />

                <Route
                  exact
                  path={`${url}locations/:id`}
                  element={<GeneralProfile />}
                />

                <Route
                  exact
                  path={`${url}pending-upload-requests`}
                  element={<PendingUploadRequest />}
                />

                <Route
                  exact
                  path={`${url}user-permission`}
                  element={<UserPermission />}
                />

                <Route
                  exact
                  path={`${url}profile-info`}
                  element={<ProfileInfo />}
                  store={store}
                />

                {/*  Dashboard */}
                <Route
                  exact
                  path={`${url}dashboard/pending-requests`}
                  element={<PendingRequests />}
                />

                <Route
                  exact
                  path={`${url}dashboard`}
                  element={<DashOptionRoute CRA_01={CRA_01} CRA_02={CRA_02} />}
                />

                <Route
                  exact
                  path={`${url}dashboard/awaiting-fulfillment`}
                  element={<PendingApprovals />}
                />

                {/* orders */}
                <Route exact path={`${url}order`} element={<VendorList />} />

                <Route
                  exact
                  path={`${url}contracts`}
                  element={<ContractList />}
                />
                <Route
                  exact
                  path={`${url}contracts2`}
                  element={<AllContractList />}
                />

                <Route
                  exact
                  path={`${url}order/:id`}
                  element={<VendorProduct />}
                />

                <Route
                  exact
                  path={`${url}contracts/:id`}
                  element={<VendorContract />}
                />

                <Route
                  exact
                  path={`${url}contract/details/:id`}
                  element={<ContractDetail />}
                />

                <Route exact path={`${url}audits`} element={<Audit />} />
                <Route
                  exact
                  path={`${url}order/:id/create-order`}
                  element={<OrderCreate />}
                  _toggleStatus={this._toggleStatus}
                />
              </Route>
              <Route path={`${url}#/500`} element={<Page500 />} />
              <Route path={`${url}#/403`} element={<Page403 />} />
              <Route path={`${url}#/404`} element={<Page404 />} />

              <Route
                path="*"
                element={
                  <Navigate
                    to={{
                      pathname: `${url}#/404`,
                    }}
                    {...this.props}
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </Suspense>
      );
    }
    return <LoadingPage text="Getting all assets..." />;
  }
}

const mapStateToProps = ({ login_reducer, storage_reducer }) => {
  const {
    storage,
    decodedData: decodedFromStorage,
    url: urlFromStore,
  } = storage_reducer;
  const { decodedData: decodedFromLogin } = login_reducer;

  return {
    storage,
    urlFromStore,
    decodedData:
      decodedFromStorage === undefined ? decodedFromLogin : decodedFromStorage,
  };
};
export default connect(mapStateToProps)(App);
