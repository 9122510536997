import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../services/services";
import {
  GET_PENDING_UPLOADS,
  GET_PENDING_UPLOADS_SUCCESS,
  GET_PENDING_UPLOADS_FAILURE,
  VERIFY_PENDING_UPLOADS,
  VERIFY_PENDING_UPLOADS_SUCCESS,
  VERIFY_PENDING_UPLOADS_FAILURE,
} from "../arsVariables";

import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* getPendingBulkUpload(payload) {
  try {
    const { error, data, status } = yield call(
      Services.getPendingBulkUploadsService,
      payload
    );
    const { code, description, totalCount } = data;
    yield put(Actions.uiStop());
    if (error === false && status === 200 && code === 1) {
      return yield put({
        type: GET_PENDING_UPLOADS_SUCCESS,
        payloadReturned: data.payload,
        totalCount,
      });
    } else if (error === false && status === 200) {
      Notify({ message: description, className: "error" });
      return yield put({
        type: GET_PENDING_UPLOADS_FAILURE,
      });
    }
  } catch (err) {
    Notify({
      message: err.response.response
        ? err.response.response.data.error_description
        : err.response.message,
      className: "error",
    });
    yield put(Actions.uiStop());
    return yield put({
      type: GET_PENDING_UPLOADS_FAILURE,
    });
  }
}

function* verifyPendingBulkUploads(payload) {
  try {
    const { data } = yield call(
      Services.verifyPendingBulkUploadsService,
      payload
    );

    yield put(Actions.uiStop());
    yield put(Actions.miniUiStop());
    Notify({
      message: data.description,
      className: data.code === 1 ? "success" : "error",
      duration: 2,
    });

    if (data.code === 1) {
      return yield put({
        type: VERIFY_PENDING_UPLOADS_SUCCESS,
        verifyPayloadReturned: data.payload,
        verifyTotalCount: data.totalCount,
      });
    }
  } catch (err) {
    yield put(Actions.miniUiStop());
    yield put(Actions.uiStop());

    Notify({
      duration: 2,
      message: err.response.response
        ? err.response.response.data.error_description
        : err.response.message,
      className: "error",
    });
    return yield put({
      type: VERIFY_PENDING_UPLOADS_FAILURE,
    });
  }
}

export function* verifyPendingBulkUploadsSaga() {
  yield takeLatest(VERIFY_PENDING_UPLOADS, verifyPendingBulkUploads);
}

export function* getPendingBulkUploadSaga() {
  yield takeLatest(GET_PENDING_UPLOADS, getPendingBulkUpload);
}

