import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import { CREATE_ORDER } from "../../arsVariables";
import { Notify } from "../../../reuse/notify";
import { Actions } from "../../actions/_index";
import { history } from "../../../reuse/history";
import {url} from "../../../helper";
import { errorHandler } from "../../../reuse/utilities";

function* createOrder(payload) {
    try {
        yield put(Actions.miniUiStart());
        const {
            data: { code, description }
        } = yield call(Services.createOrderService, payload);
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        yield put(Actions.miniUiStop());
        if (code === 1) {
            setTimeout(() => {
                history.push({ pathname: `${url}order` });
            }, 1000);
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
    }
}

export default function* createOrderSaga() {
    yield takeLatest(CREATE_ORDER, createOrder);
}
