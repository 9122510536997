import { initialState } from "../../initialState";
import {
  GET_BRANCH_ORDER_FAILURE,
  GET_BRANCH_ORDER_SUCCESS,
} from "../../arsVariables";

const getBranchOrder_reducer = (state = initialState, action) => {
  const { type, payloadReturned, totalCount } = action;
  switch (type) {
    case GET_BRANCH_ORDER_SUCCESS:
      return {
        ...state,
        branchOrders: payloadReturned,
        total: totalCount,
      };
    case GET_BRANCH_ORDER_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default getBranchOrder_reducer;
