import React, { Fragment } from "react";
import { connect } from "react-redux";
import Sidebar from "../../reuse/sidebar";
import { Actions } from "../../redux-flow/actions/_index";
import { Spin } from "antd";
import { Link } from "react-router-dom";

import BaseComponent from "../../reuse/baseComponent";
import MenuBar from "../../reuse/MenuBar";

class VendorList extends BaseComponent {
    state = {
        SearchKeyword: "",
        pageIndex: 1,
        pageSize: 12,
        status: 1
    };

    componentDidMount() {
        const { pageSize, SearchKeyword, pageIndex, status } = this.state;
        this.fetch({ pageIndex, pageSize, SearchKeyword, status });
    }

    fetch = ({ pageIndex, pageSize, SearchKeyword, status }) => {
        const { dispatch } = this.props;
        dispatch(Actions.uiStart());
        dispatch(
            Actions.getVendorWithProducts({
                pageIndex,
                pageSize,
                status,
                SearchKeyword
            })
        );
    };

    render() {
        const { SearchKeyword } = this.state;
        const { loading, toggleModal } = this.context;
        let { vendorInfo, total } = this.props;
        vendorInfo = (vendorInfo && vendorInfo.length > 0) ? vendorInfo.filter((vendor) => vendor.isActive === true) : []
        total = vendorInfo.length;

        return (
            <Fragment>
                <Sidebar
                    resetSearch={this.getBySearch}
                    search={this.state.SearchKeyword}
                    status={this.props.status}
                    toggleModal={toggleModal}
                />

                <div className="isw-mainLayout1">
                    <Spin
                        tip="Please wait..."
                        spinning={loading}
                        className="isw-content--wrapper">
                        <div className="isw-content--wrapper">
                            <MenuBar title="Orders"
                                currentText={SearchKeyword}
                                onChangeHandler={this.handleSearch}
                                onSubmitHandler={this.getBySearch}
                            />
                            <div>
                                <div className="content" id="content-body">
                                    <div
                                        className={`container-fluid container-limited`}>
                                        {/* <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb bg-transparent px-0">
                                                <li className="breadcrumb-item">
                                                    <span
                                                        style={{
                                                            color: "#00425f"
                                                        }}>
                                                        Vendor's List
                                                    </span>
                                                </li>
                                                <li
                                                    className="breadcrumb-item active"
                                                    aria-current="page">
                                                    Available Products
                                                </li>
                                            </ol>
                                        </nav> */}
                                        <div className="row my-4">
                                            <div className="col-lg-6 isw-valign--middle mr-auto">
                                                {this.renderPageTracker(total)}
                                            </div>

                                            {/* <form
                                                className="ml-auto col-6"
                                                onSubmit={this.getBySearch}>
                                                <input
                                                    value={SearchKeyword}
                                                    onChange={this.handleSearch}
                                                    onBlur={this.handleSearch}
                                                    className="isw-input-search"
                                                    placeholder="Search"
                                                />
                                            </form> */}
                                        </div>
                                        {total === 0 ? (
                                            this.renderTableAbnormality(
                                                "No match for search found"
                                            )
                                        ) : !total ? (
                                            this.renderTableAbnormality()
                                        ) : (
                                            <div className="row my-5">
                                                {vendorInfo.map(vendor => {
                                                    const {
                                                        companyName,
                                                        id
                                                    } = vendor;
                                                    return (
                                                        <div
                                                            key={id}
                                                            className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                                                            <Link
                                                                to={{
                                                                    pathname: `/customer/order/${id}`
                                                                }}>
                                                                <span className="card isw-hoverClick h-100">
                                                                    <div className="card-body">
                                                                        <h5
                                                                            className="card-title"
                                                                            style={{
                                                                                color:
                                                                                    "rgba(0,0,0,0.7)"
                                                                            }}>
                                                                            {
                                                                                companyName
                                                                            }
                                                                        </h5>
                                                                        <p className="card-text">
                                                                            {vendorInfo.filter(
                                                                                vendor =>
                                                                                    vendor.id ===
                                                                                    id
                                                                            )[0]
                                                                                .status ===
                                                                                1 ? (
                                                                                <span className="text-orange">
                                                                                    Pending
                                                                                </span>
                                                                            ) : vendorInfo.filter(
                                                                                vendor =>
                                                                                    vendor.id ===
                                                                                    id
                                                                            )[0]
                                                                                .status ===
                                                                                2 ? (
                                                                                <span className="text-success">
                                                                                    In
                                                                                    Contract
                                                                                </span>
                                                                            ) : vendorInfo.filter(
                                                                                vendor =>
                                                                                    vendor.id ===
                                                                                    id
                                                                            )[0]
                                                                                .status ===
                                                                                4 ? (
                                                                                <span className="text-danger">
                                                                                    Expired
                                                                                </span>
                                                                            ) : (
                                                                                <span className="text-info">
                                                                                    No
                                                                                    Contract
                                                                                </span>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        {this.renderPagination(total)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const {
        vendorInfo,
        totalVendors: total,
        status_data,
        status
    } = state.getVendorWithProducts_reducer;

    return {
        vendorInfo,
        total,
        status_data,
        status
    };
};

export default connect(mapStateToProps)(VendorList);
