import { GET_PENDING_UPLOADS, VERIFY_PENDING_UPLOADS } from "../arsVariables";

const getPendingBulkUploads = payload => ({
    type: GET_PENDING_UPLOADS,
    payload
});

const verifyPendingBulkUploads = payload => ({
    type: VERIFY_PENDING_UPLOADS,
    payload
});


const getPendingBulkActions = {
    getPendingBulkUploads,
    verifyPendingBulkUploads
}

export default getPendingBulkActions;
