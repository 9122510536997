import React from "react";
import PropTypes from "prop-types";
export const UserPermissionButtons = ({
    loading,
    updateType,
    editedUsers,
    batchSubmitUpdates,
    submitUpdates
}) => (
    <div className="row">
        <div className="col-12 user-perms">
            <div className="isw-user-permission-action">
                {loading && updateType === "single" ? (
                    <div className="isw-user-permission-btn">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md">
                                    <button
                                        className="dot isw-btn isw-btn--raised bg-primary text-white w-100"
                                        disabled>
                                        <span>
                                            Updating Permissions
                                            <span>.</span>
                                            <span>.</span>
                                            <span>.</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="isw-user-permission-btn">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md">
                                    <button
                                        className={
                                            editedUsers.length === 1
                                                ? "isw-btn isw-btn--raised bg-primary text-white w-100"
                                                : "isw-btn  bg-primary text-white w-100 false"
                                        }
                                        onClick={submitUpdates}
                                        disabled={
                                            editedUsers.length === 1
                                                ? false
                                                : true
                                        }>
                                        <span>Update User Permissions</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="isw-user-permission-action">
                {loading && updateType === "batch" ? (
                    <div className="isw-user-permission-btn">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md">
                                    <button
                                        className="dot isw-btn isw-btn--raised bg-primary text-white w-100"
                                        disabled>
                                        <span>
                                            Batch Updating Permissions
                                            <span>.</span>
                                            <span>.</span>
                                            <span>.</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="isw-user-permission-btn">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md">
                                    <button
                                        className={
                                            editedUsers.length > 1
                                                ? "isw-btn isw-btn--raised bg-primary text-white w-100"
                                                : "isw-btn  bg-primary text-white w-100 false"
                                        }
                                        onClick={batchSubmitUpdates}
                                        disabled={
                                            editedUsers.length > 1
                                                ? false
                                                : true
                                        }>
                                        <span>
                                            Batch Update User Permissions
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
);

UserPermissionButtons.propTypes = {
    loading: PropTypes.bool.isRequired,
    updateType: PropTypes.string.isRequired,
    batchSubmitUpdates: PropTypes.func.isRequired,
    submitUpdates: PropTypes.func.isRequired,
    editedUsers: PropTypes.array.isRequired
};
