import {
    GET_LOCATIONS_FAILURE,
    GET_LOCATIONS_SUCCESS,
   
} from "../../redux-flow/arsVariables";
import { initialState } from "../initialState";

const getLocations_reducer = (state = initialState, action) => {
    const { type, payloadReturned, totalCount } = action;
    switch (type) {
        case GET_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: payloadReturned,
                locationsLength: totalCount
            };
       
        case GET_LOCATIONS_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getLocations_reducer;
