export const initialState = {
  loading: false,
  isLoading: false,
  success: false,
  locations: [],
  locationsLength: null,

  usersLength: null,
  location: [],
  users: [],
  user: [],
  permissions: [],
  role: null,
  decodedData: {},
  basicInfo: {},
  vendorInfo: [],
  pendingOrders: [],
  orderItems: [],
  orders: [],
  vendorContracts: [],
  usersInLocation: [],
  orderStatistics: [],
  profileStatistics: [],
  vendorDetail: [],
  deliveryInfo: [],
  orderHistory: [],
  toggleSidebar: true,
  toggleRightSidebar: true,
  userLocations: [],
  branchOrders: [],
  recentBranchOrders: [],
  fulfilledOrders: [],
  branchDeliveryOrder: [],
  userLocationsCount: null,
  signUpError: false,
  veirfyEmail: false,
  email: null,
};
