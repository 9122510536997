import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import { ADD_USER_2_LOCATIONS } from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* addUserToLocations(payload) {
    try {
        yield put(Actions.miniUiStart());
        const {
            data: { code, description }
        } = yield call(Services.addUserToLocationsService, payload);
        yield put(Actions.miniUiStop());
        if (code === 1) {
            yield put(Actions.miniUiStart());
            yield put(
                Actions.getUserLocations({
                    userId: payload.payload.userId,
                    pageSize: 10,
                    pageIndex: 1
                })
            );
        }
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
    }
}

export default function* addUserToLocationsSaga() {
    yield takeLatest(ADD_USER_2_LOCATIONS, addUserToLocations);
}
