import React, { useState, useEffect } from "react";
import "./signUpForm.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import { ErrorIconLogo } from "./svgIcons";
import { FRONT_SSO_URL } from "../App";

const SignUpForm = () => {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    role: "",
    EmailAddress: "",
    PhoneNo: "",
    password: "",
    retypePassword: "",
    termsAccepted: false,
  });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const signUpError = useSelector(
    (state) => state.signup_onboard_reducer.signUpError
  );

  useEffect(() => {
    if (signUpError) {
      setLoading(false);
    }
  }, [signUpError]);

  const validate = () => {
    const errors = {};
    if (!form.firstName.trim()) errors.firstName = "First Name is required";
    if (!form.lastName.trim()) errors.lastName = "Last Name is required";
    if (!form.role) errors.role = "Account Type is required";
    if (!form.EmailAddress) {
      errors.EmailAddress = "Email Address is required";
    } else if (!/\S+@\S+\.\S+/.test(form.EmailAddress)) {
      errors.EmailAddress = "Email Address is invalid";
    }
    if (!form.PhoneNo) {
      errors.PhoneNo = "Phone number is required";
    } else if (!/^0\d{10}$/.test(form.PhoneNo)) {
      errors.PhoneNo = "Phone number is invalid";
    }
    if (!form.password) {
      errors.password = "Password is required";
    } else if (form.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    if (form.password !== form.retypePassword) {
      errors.retypePassword = "Passwords do not match";
    }
    if (!form.termsAccepted) {
      errors.termsAccepted = "You must accept the terms and conditions";
    }
    return errors;
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setErrors({ ...errors, [name]: validate()[name] });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({
      ...form,
      [name]: type === "checkbox" ? checked : value,
    });
    setLoading(false); // Reset loading state on input change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const payload = {
          FirstName: form?.firstName,
          LastName: form?.lastName,
          EmailAddress: form?.EmailAddress,
          PhoneNo: form?.PhoneNo,
          Password: form?.password,
          role: form?.role,
        };

        await dispatch(Actions.uiStart());
        await dispatch(Actions.signupUserOnboard(payload));

        if (signUpError) {
          setLoading(false);

          setForm({
            firstName: "",
            lastName: "",
            role: "",
            EmailAddress: "",
            PhoneNo: "",
            password: "",
            retypePassword: "",
            termsAccepted: false,
          });
        }
      } catch (error) {
        console.error("Form submission error", error);
        setLoading(false); // Reset loading state if API call fails
      }
    } else {
      setLoading(false); // Reset loading state if there are validation errors
    }
  };
  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={form.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.firstName ? "error" : ""}
            />
            {errors.firstName && (
              <p className="error-message">
                <ErrorIconLogo /> {errors.firstName}
              </p>
            )}
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={form.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.lastName ? "error" : ""}
            />
            {errors.lastName && (
              <p className="error-message">
                <ErrorIconLogo /> {errors.lastName}
              </p>
            )}
          </div>
        </div>

        <div className="form-group full-width">
          <label>Account Type</label>
          <select
            name="role"
            value={form.role}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.role ? "error" : ""}
          >
            <option value="">Select Account Type</option>
            <option value="VENDOR_USER">Vendor</option>
            <option value="CUSTOMER_USER">Customer</option>
          </select>
          {errors.role && (
            <p className="error-message">
              <ErrorIconLogo /> {errors.role}
            </p>
          )}
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              name="EmailAddress"
              value={form.EmailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.EmailAddress ? "error" : ""}
            />
            {errors.EmailAddress && (
              <p className="error-message">
                <ErrorIconLogo /> {errors.EmailAddress}
              </p>
            )}
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              name="PhoneNo"
              value={form.PhoneNo}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.PhoneNo ? "error" : ""}
            />
            {errors.PhoneNo && (
              <p className="error-message">
                <ErrorIconLogo /> {errors.PhoneNo}
              </p>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={form.password}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.password ? "error" : ""}
            />
            {errors.password && (
              <p className="error-message">
                <ErrorIconLogo /> {errors.password}
              </p>
            )}
          </div>
          <div className="form-group">
            <label>Retype Password</label>
            <input
              type="password"
              name="retypePassword"
              value={form.retypePassword}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.retypePassword ? "error" : ""}
            />
            {errors.retypePassword && (
              <p className="error-message">
                <ErrorIconLogo /> {errors.retypePassword}
              </p>
            )}
          </div>
        </div>

        <div className="terms-container">
          <input
            type="checkbox"
            name="termsAccepted"
            checked={form.termsAccepted}
            onChange={handleChange}
            className="terms-checkbox"
          />
          <label className="terms-label">
            I've read and agree to the{" "}
            <a href="/terms" target="_blank">
              Terms & Conditions
            </a>{" "}
            and{" "}
            <a href="/privacy" target="_blank">
              Privacy Policy
            </a>
          </label>
        </div>
        {errors.termsAccepted && (
          <p className="error-message">
            <ErrorIconLogo /> {errors.termsAccepted}
          </p>
        )}

        <button type="submit" disabled={loading}>
          {loading ? <div className="loader"></div> : "Sign up"}
        </button>

        <p className="signin-text">
          Do you have an account already?{" "}
          <a href={FRONT_SSO_URL + "/quick-energy/signin"}>Sign in</a>
        </p>
      </form>
    </div>
  );
};

export default SignUpForm;
