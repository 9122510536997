import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../../../redux-flow/services/services";

import {
    GET_USER_PERMISSIONS,
    GET_USER_PERMISSIONS_FAILURE,
    GET_USER_PERMISSIONS_SUCCESS
} from "../../../../redux-flow/arsVariables";
import { Actions } from "../../../actions/_index";
import { Notify } from "../../../../reuse/notify";
import { errorHandler } from "../../../../reuse/utilities";

function* userPermissions(payload) {
    try {
        const {
            data: { code, description }
        } = yield call(Services.getUserPermissionsService, payload);

        yield put(Actions.uiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });

        return yield put({
            type:
                code === 1
                    ? GET_USER_PERMISSIONS_SUCCESS
                    : GET_USER_PERMISSIONS_FAILURE
        });
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_USER_PERMISSIONS_FAILURE
        });
    }
}

export default function* userPermissionsSaga() {
    yield takeLatest(GET_USER_PERMISSIONS, userPermissions);
}
