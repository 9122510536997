import React from "react";
import { Tooltip as DataTip } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  Brush,
} from "recharts";
// import { formatMoney } from "../../../utils/iswCurrencyHelper";
// import { formatString } from "../../../utils/utils";
import { LineSkeleton } from "./dashUtil";
import { formatMoney } from "../../reuse/currencyHelper";
import { formatString } from "../../utils/utils";

// formatMoney;
// const data = [
//   {
//     name: "SUN",
//     ago: 4000,
//     dpk: 2400,
//     pms: 2400
//   },
//   {
//     name: "MON",
//     ago: 3000,
//     dpk: 1398,
//     pms: 2210
//   },
//   {
//     name: "TUE",
//     ago: 2000,
//     dpk: 9800,
//     pms: 2290
//   },
//   {
//     name: "WED",
//     ago: 2780,
//     dpk: 3908,
//     pms: 2000
//   },
//   {
//     name: "THUR",
//     ago: 1890,
//     dpk: 4800,
//     pms: 2181
//   },
//   {
//     name: "FRI",
//     ago: 2390,
//     dpk: 3800,
//     pms: 2500
//   },
//   {
//     name: "SAT",
//     ago: 3490,
//     dpk: 4300,
//     pms: 2100
//   }
// ];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const {
      payload: { pms, dpk, ago },
    } = payload[0];
    return (
      <div className="chart-custom-tooltip">
        <span
          style={{ color: "#307399" }}
          className="tooltip-label mb-0"
        >{`Pms : ${formatMoney(pms, false, 0)}`}</span>
        <span
          style={{ color: "#18425D" }}
          className="tooltip-label mb-0"
        >{`Ago : ${formatMoney(ago, false, 0)}`}</span>
        <span
          style={{ color: "#FFB800" }}
          className="tooltip-label mb-0"
        >{`Dpk : ${formatMoney(dpk, false, 0)}`}</span>
      </div>
    );
  }

  return null;
};

export const BranchOrderRequestChart = ({
  data,
  dataPeriodText,
  dataLoading,
}) => {
  return (
    <div
      className="chart-section line-chart stacked"
      style={{ overflow: "scroll" }}
    >
      <div className="d-flex line-chart-header">
        <div>
          <h4 className="chart-title mb-0">
            <DataTip title="Total Fulfilled Order Request by a specified duration (i.e. all approved/accepted orders)">
              Fulfilled Order
            </DataTip>
          </h4>
          <label className="chart-label">{formatString(dataPeriodText)}</label>
        </div>
        <div>
          <label>
            Volume <strong>(Ltrs)</strong>
          </label>
        </div>
      </div>
      <LineSkeleton isShow={dataLoading} />
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={[]}>
          <XAxis
            dataKey="name"
            axisLine={false}
            tickMargin={8}
            tick={{ color: "#7E858E", fontSize: 12 }}
            tickLine={false}
          />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Legend iconType="circle" iconSize={14} margin={{ top: 100 }} />
          <Bar dataKey="ago" stackId="a" barSize={1} fill={"#307399"}>
            {/* {data.map((entry, index) => (
              <Cell
                style={{
                  transformStyle: "preserve-3d",
                  transform: "translateZ(0px)",
                }}
                stroke={"#307399"}
                strokeWidth={4}
                strokeLinejoin="round"
                width={1}
                key={`ago-${index}`}
              />
            ))} */}
          </Bar>
          <Bar dataKey="dpk" stackId="a" fill={"#FFB800"}>
            {/* {data.map((entry, index) => (
              <Cell
                style={{ transform: "translateZ(500px) perspective(1px)" }}
                stroke={"#FFB800"}
                strokeWidth={4}
                width={1}
                key={`dpk-${index}`}
              />
            ))} */}
          </Bar>
          <Bar dataKey="pms" stackId="a" fill={"#18425D"}>
            {/* {data.map((entry, index) => (
              <Cell
                style={{
                  transformStyle: "preserve-3d",
                  transform: "translateZ(0px)",
                }}
                stroke={"#18425D"}
                strokeWidth={4}
                strokeLinejoin="round"
                width={1}
                key={`pms-${index}`}
              />
            ))} */}
          </Bar>
          <Brush dataKey="name" height={1} stroke="transparent" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default BranchOrderRequestChart;
