import React, { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PropsContext } from "./PropsContext";

const WithRouter = (WrappedComponent) => {
  return function WithRouterWrapper(props) {
    const params = useParams();
    const location = useLocation();
    const context = useContext(PropsContext);

    return (
      <WrappedComponent
        {...props}
        params={params}
        location={location}
        {...context}
      />
    );
  };
};

export default WithRouter;
