import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import {
    GET_CUSTOMERS_FAILURE,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS
} from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

function* getCustomers(payload) {
    try {
        const {
            data: { code, description, payload: payloadReturned }
        } = yield call(Services.getCustomersService, payload);

        if (code === 1) {
            return yield put({
                type: GET_CUSTOMERS_SUCCESS,
                payloadReturned
            });
        } else if (code !== 1) {
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_CUSTOMERS_FAILURE
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_CUSTOMERS_FAILURE
        });
    }
}

export default function* getCustomersSaga() {
    yield takeLatest(GET_CUSTOMERS, getCustomers);
}
