import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../../redux-flow/services/services";
import { TOGGLE_USER_STATUS } from "../../../redux-flow/arsVariables";
import { Actions } from "../../actions/_index";
import { Notify } from "../../../reuse/notify";
import { errorHandler } from "../../../reuse/utilities";

function* toggleUserStatus(payload) {
    try {
        const {
            data: { code, description }
        } = yield call(Services.togglerUserStatusService, payload);

        yield put(Actions.uiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        if (code === 1) {
            yield put(Actions.uiStart());
            return yield put(
                Actions.getUsers({
                    pageIndex: payload.payload.pageIndex,
                    pageSize: 10
                })
            );
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
    }
}

export default function* toggleUserStatusSaga() {
    yield takeLatest(TOGGLE_USER_STATUS, toggleUserStatus);
}
