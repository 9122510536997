import { Spin } from 'antd';
import React, { Fragment } from 'react';
import BaseComponent from '../../../reuse/baseComponent';
import MenuBar from '../../../reuse/MenuBar';
import Sidebar from "../../../reuse/sidebar";

const menuList = [{ title: "Vendor List", link: '/customer/contracts', isActive: true }, { title: 'Contract List', link: '/customer/contracts2' }];

class ContractMenu extends BaseComponent {

    render() {
        const { loading, status, toggleModal } = this.context;
        const { SearchKeyword } = this.props;
        return (<Fragment>
            <Sidebar
                resetSearch={this.getBySearch}
                search={SearchKeyword}
                status={status}
                toggleModal={toggleModal}
            />

            <div className="isw-mainLayout1">
                <Spin
                    tip="Please wait..."
                    spinning={loading}
                    className="isw-content--wrapper">
                    <div className="isw-content--wrapper">
                        <MenuBar title={menuList}
                            currentText={SearchKeyword}
                            onChangeHandler={this.handleSearch}
                            onSubmitHandler={this.getBySearch}
                        />
                        {this.props.children}
                    </div>
                </Spin>
            </div>
        </Fragment>)
    }

}

export default ContractMenu;