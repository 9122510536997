import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../services/services";
import {
  DELETE_USER_FROM_LOCATION,
  GET_USERS_IN_LOCATION_SUCCESS,
} from "../arsVariables";

import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
import { store } from "../../index";

function* deleteUserFromLocation(payload) {
  try {
    yield put(Actions.miniUiStart());
    const {
      data: { description, code },
    } = yield call(Services.deleteUserFromLocationService, payload);

    yield put(Actions.miniUiStop());
    Notify({
      message: description,
      className: code === 1 ? "success" : "error",
    });

    if (code === 1) {
      const filteredArr = store
        .getState()
        .getUsersInLocation_reducer.usersInLocation.filter(
          (user) => user.id !== payload.payload.id
        );
      return yield put({
        type: GET_USERS_IN_LOCATION_SUCCESS,
        payload: filteredArr,
        totalCount: filteredArr.length,
      });
    }
  } catch (err) {
    Notify({
      message: err.response.response
        ? err.response.response.data.error_description
        : err.response.message,
      className: "error",
    });
    yield put(Actions.miniUiStop());
  }
}

export default function* deleteUserFromLocationSaga() {
  yield takeLatest(DELETE_USER_FROM_LOCATION, deleteUserFromLocation);
}
