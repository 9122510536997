import { GET_VENDOR_WITH_PRODS, GET_VENDORS_PRODUCT } from "../../arsVariables";

const getVendorWithProducts = payload => ({
    type: GET_VENDOR_WITH_PRODS,
    payload
});

export const getVendorsProducts = payload => ({
    type: GET_VENDORS_PRODUCT,
    payload
});
export default getVendorWithProducts;
