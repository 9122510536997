import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import {
  GET_CUSTOMER_ORDER_DELIVERIES_SUCCESS,
  GET_CUSTOMER_ORDER_DELIVERIES,
  GET_CUSTOMER_ORDER_DELIVERIES_FAILURE,
} from "../../arsVariables";

import { Actions } from "../../actions/_index";
import { Notify } from "../../../reuse/notify";
import { errorHandler } from "../../../reuse/utilities";

function* getBranchOrderDelivery(payload) {
  try {
    const { error, data, status } = yield call(
      Services.getCustomerOrderDeliveriesService,
      payload
    );
    const { code, description, totalCount } = data;
    yield put(Actions.uiStop());
    if (error === false && status === 200 && code === 1) {
      return yield put({
        type: GET_CUSTOMER_ORDER_DELIVERIES_SUCCESS,

        payloadReturned: data.payload,
        totalCount,
      });
    } else if (error === false && status === 200) {
      Notify({ message: description, className: "error" });
      return yield put({
        type: GET_CUSTOMER_ORDER_DELIVERIES_FAILURE,
      });
    }
  } catch (err) {
    let message = errorHandler(err);
    Notify({
      message,
      className: "error",
    });
    yield put(Actions.uiStop());
    return yield put({
      type: GET_CUSTOMER_ORDER_DELIVERIES_FAILURE,
    });
  }
}

export default function* getBranchOrderDeliverySaga() {
  yield takeLatest(GET_CUSTOMER_ORDER_DELIVERIES, getBranchOrderDelivery);
}
