import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../services/services";
import { ADD_USERS_2_LOCATION } from "../arsVariables";

import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
function* addUsersToLocation(payload) {
    try {
        yield put(Actions.miniUiStart());
        const {
            data: { code, description }
        } = yield call(Services.addUsersToLocationService, payload);

        yield put(Actions.miniUiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        if (code === 1) {
            yield put(Actions.miniUiStart());
            yield put(
                Actions.getUsersInLocation({
                    locationId: payload.payload.locationId,
                    pageSize: 10,
                    pageIndex: 1
                })
            );
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
    }
}

export default function* addUserToLocationsSaga() {
    yield takeLatest(ADD_USERS_2_LOCATION, addUsersToLocation);
}
