import React from "react";
import { Tooltip as DataTip } from "antd";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
// import { formatString } from "../../../../utils/utils";
// import { formatMoney } from "../../../../utils/iswCurrencyHelper";
import { LineSkeleton } from "./dashUtil";
import { formatString } from "../../utils/utils";
import { formatMoney } from "../../reuse/currencyHelper";

export const BranchOrderChart = ({ data, dataPeriodText, dataLoading }) => {
  const internalFormat = (amount) => {
    return formatMoney(amount, false, 0);
  };
  return (
    <div className="chart-section line-chart">
      <div className="d-flex line-chart-header">
        <div>
          <h4 className="chart-title mb-0">
            <DataTip title="All order request made from product sold within a specified duration">
              Branch Order Request
            </DataTip>
          </h4>
          <label className="chart-label">{formatString(dataPeriodText)}</label>
        </div>
        <LineSkeleton isShow={dataLoading} line={1} />
        {/* {data && (
          <div className="line-chart-summary">
            <p className="dur-text mb-0">
              <strong>Total</strong> - For {dataPeriodText}
            </p>
            <p className="amount mb-0">{formatMoney(data.total)}</p>
          </div>
        )} */}
      </div>
      <ResponsiveContainer width="100%" height={180} padding={{ left: 10 }}>
        <AreaChart data={data ? data : []}>
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#074EE8" stopOpacity={0.15} />
              <stop offset="95%" stopColor="#074EE8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="intervals"
            tick={{ fontSize: 12 }}
            tickLine={false}
            tickMargin={5}
          />
          <YAxis
            tickFormatter={internalFormat}
            axisLine={false}
            tickMargin={14}
            tick={{ color: "#9CA3AF", fontSize: 12 }}
            tickLine={false}
          />
          <CartesianGrid vertical={false} stroke="#F9F9F9" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="amount"
            stroke="#074EE8"
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BranchOrderChart;
