import React, { Fragment } from "react";
import { RightSideLogo, WhiteLogoIcon } from "./svgIcons";

const Static = () => {
  return (
    <Fragment>
      <div className="col-lg-4 ">
        <div className="isw-column--left">
          <div className="isw-column--left-top">
            <RightSideLogo classname="" />
          </div>

          <div className="isw-column--left-middle">
            <p>
              Powering the process of end-
              <br />
              to-end satisfaction between
              <br /> vendors and customers
            </p>
          </div>

          <div className="isw-column--left-base">
            <p>Having issues?</p>
            <a href="">Contact Support</a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Static;
