import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import Sidebar from "../reuse/sidebar";
import { Actions } from "../redux-flow/actions/_index";
import { CUSTOMER_USER } from "../redux-flow/arsVariables";
import { history } from "../reuse/history";
import { Spin, message } from "antd";
import Services from "../redux-flow/services/services";
import { LeftView } from "../components/UserPermissions/LeftView";
import { RightView } from "../components/UserPermissions/RightView";
import { UserPermissionButtons } from "../components/UserPermissions/Buttons";
import MenuBar from "../reuse/MenuBar";
import WithRouter from "../reuse/WithRouter";
class UserPermission extends PureComponent {
    state = {
        editedUsers: [],
        activeUser: null,
        SearchKeyword: "",
        updateType: "",
        loadingList: false,
        hasMore: true,
        users: [],
        totalCount: null,
        userPermissions: [],
        pageIndex: 1,
        error: "",
        pending: false,
        role: "1"
    };

    componentDidUpdate() {
        if (!this.props.loading && this.state.pending) {
            this.setState({ editedUsers: [], pending: false });
        }
    }

    componentDidMount() {
        const {
            dispatch,
            decodedData: { role }
        } = this.props;
        if (role === CUSTOMER_USER) {
            return history.push({ pathname: "/#/403" });
        }
        this.fetchUsers({ pageIndex: 1 });
        dispatch(Actions.permissions());
    }

    handleInfiniteOnLoad = () => {
        const { pageIndex, totalCount, users } = this.state;
        const { dispatch } = this.props;

        if (users.length === totalCount) {
            return this.setState(
                {
                    hasMore: false
                },
                () => {
                    message.warning("Loaded all!");
                    dispatch(Actions.miniUiStop());
                }
            );
        }
        dispatch(Actions.miniUiStart());
        this.fetchUsers({ pageIndex: pageIndex + 1 });
    };

    fetchUsers = ({ pageIndex }) => {
        const { users } = this.state;
        const { dispatch } = this.props;
        this.setState({
            loadingList: true
        });
        Services.getUsersService({ payload: { pageIndex, pageSize: 10 } })
            .then(({ data: { code, payload, totalCount } }) => {
                dispatch(Actions.uiStop());
                dispatch(Actions.miniUiStop());
                if (code === 1) {
                    this.setState({
                        users: users.concat(payload),
                        totalCount,
                        loadingList: false,
                        hasMore: users.length >= totalCount ? false : true
                    });
                }
            })
            .catch(error => {
                dispatch(Actions.miniUiStop());
                dispatch(Actions.uiStop());

            });
    };

    batchSubmitUpdates = e => {
        e.preventDefault();
        this.setState({
            updateType: "batch",
            pending: true
        });
        this.props.dispatch(
            Actions.batchUpdateUserPermissions(this.state.editedUsers)
        );
    };

    submitUpdates = e => {
        e.preventDefault();
        this.setState({
            updateType: "single",
            pending: true
        });
        this.props.dispatch(
            Actions.updateUserPermissions(this.state.editedUsers[0])
        );
    };

    searching = ({ target: { value } }) =>
        this.setState(prevState => {
            return {
                ...prevState,
                SearchKeyword: value
            };
        });

    updatePermission = permissionName => {
        const { editedUsers, activeUser, userPermissions, users } = this.state;
        const usersCopy = [...users];
        const unadulteredPermission = [];
        unadulteredPermission.push(activeUser);

        // get the highlighted user
        const highlightedUser = usersCopy.filter(
            user => user.id === activeUser
        )[0];

        const arrToEdit = [...editedUsers];

        const item = userPermissions[0];

        // get the index of the particular permission in the active users permissions array or return -1 if it doesn't exist yet
        let existingPermIndex;
        existingPermIndex = highlightedUser.permissions.findIndex(perm => {
            return perm === permissionName;
        });

        const indexOfUser = arrToEdit.findIndex(
            item => item.userId === activeUser
        );
        if (indexOfUser === -1) {
            const customPermArr = [];
            customPermArr.push(permissionName);
            item.permissions.push(permissionName);
            arrToEdit.splice(arrToEdit.length + 1, 1, { ...item });
            this.setState(prevState => {
                return {
                    ...prevState,
                    editedUsers: arrToEdit,
                    userPermissions: arrToEdit.filter(
                        user => user.userId === activeUser
                    )
                };
            });
        } else if (indexOfUser !== -1) {
            if (item.permissions[existingPermIndex] === permissionName) {
                item.permissions.splice(existingPermIndex, 1);
            } else if (existingPermIndex === -1) {
                item.permissions.push(permissionName);
            }
            arrToEdit.splice(indexOfUser, 1, { ...item });
            this.setState(prevState => {
                return {
                    ...prevState,
                    editedUsers: arrToEdit
                };
            });
        }
    };

    selectedUser = id => {
        this.setState({
            activeUser: id
        });

        let newUsers = [...this.state.users];
        let userPermArr = [];
        const itemIndex = newUsers.findIndex(user => user.id === id);
        const item = newUsers[itemIndex];
        const index = userPermArr.findIndex(item => item.userId === id);
        if (index === -1) {
            userPermArr.push({
                userId: id,
                permissions: item.permissions
            });
        } else return;

        this.setState(prevState => {
            return {
                ...prevState,
                userPermissions: userPermArr
            };
        });
    };

    pickRole = value => this.setState({ role: value });

    render() {
        const {
            SearchKeyword,
            updateType,
            activeUser,
            editedUsers,
            userPermissions,
            hasMore,
            role,
            users,
            totalCount
        } = this.state;
        const { permissions, loading, isLoading } = this.props;
        const filteredUsers = users.filter(
            ({ firstName, lastName }) =>
                `${lastName} ${firstName}`
                    .toLowerCase()
                    .includes(SearchKeyword.toLowerCase()) ||
                `${firstName} ${lastName}`
                    .toLowerCase()
                    .includes(SearchKeyword.toLowerCase())
        );

        const usersAvailable =
            totalCount === 0 ? (
                <span
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                    No match found
                </span>
            ) : !totalCount ? (
                <span
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                    No data available
                </span>
            ) : (
                filteredUsers.map(({ firstName, lastName, id }) => (
                    <span
                        key={id}
                        className={`list-group-item list-group-item-action ${activeUser === id ? "active" : ""
                            }`}
                        id="list-home-list"
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={() => this.selectedUser(id)}>
                        {firstName} {lastName}
                    </span>
                ))
            );

        return (
            <Fragment>
                <Sidebar
                    search={this.state.SearchKeyword}
                    status={this.props.status}
                    toggleModal={this.props.toggleModal}
                />

                <div className="isw-mainLayout1">
                    <Spin tip="Please wait..." spinning={loading}>
                        <div className="isw-content--wrapper">
                            <MenuBar title='User Permisisons' />
                            <div>
                                <div className="content position-relative" id="content-body">
                                    <div className={`container-fluid container-limited`}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="isw-card">
                                                    <div className="isw-card-header p-3 mb-4">
                                                        <h3 className="isw-card-h3">
                                                            Assign Permission
                                                        </h3>
                                                        <div className="isw-card-p">
                                                            Assign sets of
                                                            permissions to user
                                                        </div>
                                                    </div>
                                                    <div className="card-body isw-permission p-3">
                                                        <div className="row">
                                                            <LeftView
                                                                searching={
                                                                    this
                                                                        .searching
                                                                }
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                hasMore={
                                                                    hasMore
                                                                }
                                                                handleInfiniteOnLoad={
                                                                    this
                                                                        .handleInfiniteOnLoad
                                                                }
                                                                initialLoad={
                                                                    users.length <
                                                                    1 &&
                                                                    isLoading
                                                                }
                                                                usersAvailable={
                                                                    usersAvailable
                                                                }
                                                            />

                                                            <RightView
                                                                userPermissions={
                                                                    userPermissions
                                                                }
                                                                pickRole={
                                                                    this
                                                                        .pickRole
                                                                }
                                                                permissions={
                                                                    permissions
                                                                }
                                                                role={role}
                                                                updatePermission={
                                                                    this
                                                                        .updatePermission
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <UserPermissionButtons
                                            loading={loading}
                                            updateType={updateType}
                                            editedUsers={editedUsers}
                                            batchSubmitUpdates={
                                                this.batchSubmitUpdates
                                            }
                                            submitUpdates={this.submitUpdates}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ permissions_reducer }) => {
    const { permissions } = permissions_reducer;
    return {
        permissions
    };
};

export default WithRouter(connect(mapStateToProps)(UserPermission));
