import firebase from "firebase";

let environment = window.env;
const config = {
  apiKey: environment.FB_API_KEY || "AIzaSyC1xkzXclBwKeBHbzlmDwlaREIO0BdiEm0",
  authDomain: environment.FB_AUTH_DOMAIN || "smartfuel-5eeae.firebaseapp.com",
  databaseURL:
    environment.FB_DATABASE_URL || "https://smartfuel-5eeae.firebaseio.com",
  projectId: environment.FB_PROJECT_ID || "smartfuel-5eeae",
  storageBucket: environment.FB_STORAGE_BUCKET || "smartfuel-5eeae.appspot.com",
  messagingSenderId: environment.FB_MESSAGE_SENDER || "360133791860",
  appId: environment.FB_APP_ID || "1:360133791860:web:add6b096bbac5f9e",
};

// eslint-disable-next-line no-unused-vars
let firebaseInit = firebase.initializeApp(config);

const database = firebase.database();

export { database };
