import { call, put, takeLatest } from "redux-saga/effects";
// import Services from "../../redux-flow/services/services";
import {
  SIGNUP_ONBOARD_SUCCESS,
  SIGNUP_ONBOARD_USER,
  SIGNUP_ONBOARD_FAILURE,
} from "../../../redux-flow/arsVariables";
import { Actions } from "../../actions/_index";
import { Notify } from "../../../reuse/notify";
import { history } from "../../../reuse/history";
import { FRONT_SSO_URL, url } from "../../../helper";
import Services from "../../services/services";
const { localStorage } = window;

function* signupOnboardUser({ payload }) {
  try {
    const resp = yield call(Services.onboardSignupService, payload);
    if (!resp?.data) {
      yield put(Actions.uiStop());
      Notify({
        message: resp?.response?.data?.description,
        className: "error",
      });
      return yield put({
        type: SIGNUP_ONBOARD_FAILURE,
      });
    }
    const { error, data, status } = resp;
    const { code, description } = data;
    yield put(Actions.uiStop());
    Notify({
      message: description,
      className: code === 1 ? "success" : "error",
    });
    if (code === -2) {
      return yield put({
        type: SIGNUP_ONBOARD_FAILURE,
      });
    }
    if (error === false && status === 200 && code === 1) {
      localStorage.setItem("email", payload.EmailAddress);
      setTimeout(() => {
        // const location = {
        //   pathname: `${url}verify-profile`,
        //   state: { email: payload.EmailAddress },
        // };
        window.location.href = FRONT_SSO_URL + "/quick-energy/signin";
        // history.push(location);
      }, 3000);
      return yield put({
        type: SIGNUP_ONBOARD_SUCCESS,
        email: payload.EmailAddress,
      });
    }
  } catch (err) {
    Notify({
      message: err.response.response
        ? err.response.response.data.error_description
        : err.response.message,
      className: "error",
    });

    yield put(Actions.uiStop());
  }
}

export default function* signupOnboardUserSaga() {
  yield takeLatest(SIGNUP_ONBOARD_USER, signupOnboardUser);
}
