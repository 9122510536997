import React from "react";
import energyLogo from "../../assets/images/energyLogo.svg";
import whiteEnergyLogo from "../../assets/images/whiteLogo.svg";
import footerLogo from "../../assets/images/interswitch_logo 1.svg";
import rightSideLogo from "../../assets/images/Group.svg";
import svgBackgroundImage from "../../assets/images/Rectangle 20.svg";
import EmailIcon from "../../assets/images/EmailIcon.svg";
import ErrorIcon from "../../assets/images/ErrorIcon.svg";
export const EnergyLogoIcon = ({ classname }) => (
  <img className={classname} src={energyLogo} alt="Interswitch Energy" />
);

export const WhiteLogoIcon = ({ classname }) => (
  <img className={classname} src={whiteEnergyLogo} alt="Interswitch Energy" />
);

export const FooterLogoIcon = ({ classname }) => (
  <img className={classname} src={footerLogo} alt="Interswitch Energy" />
);

export const RightSideLogo = ({ classname }) => (
  <img className={classname} src={rightSideLogo} alt="Interswitch Energy" />
);
export const EmailIconLogo = ({ classname }) => (
  <img className={classname} src={EmailIcon} alt="Interswitch Energy" />
);
export const ErrorIconLogo = ({ classname }) => (
  <img className={classname} src={ErrorIcon} alt="Interswitch Energy" />
);
