import { call, put, takeLatest } from "redux-saga/effects";
import fileDownload from "js-file-download";

import Services from "../services/services";
import {
    GET_AUDIT,
    GET_AUDIT_SUCCESS,
    GET_AUDIT_FAILURE,
    DOWNLOAD_AUDIT_LOG,
    DOWNLOAD_AUDIT_LOG_SUCCESS,
    DOWNLOAD_AUDIT_LOG_FAILURE,
} from "../arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

const { getAuditLogService, downloadAuditLogService } = Services;

function* getAuditLogs(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: {
                code,
                description,
                payload: payloadReturned,
                totalCount,
                totalRecords
            }
        } = yield call(getAuditLogService, payload);
        yield put(Actions.uiStop());
        if (code !== 1) {
            Notify({
                message: description,
                className: "error"
            });
            return yield put({
                type: GET_AUDIT_FAILURE
            });
        } else if (code === 1) {
            return yield put({
                type: GET_AUDIT_SUCCESS,
                payloadReturned,
                totalCount: totalRecords ? totalRecords : totalCount
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_AUDIT_FAILURE
        });
    }
}

export function* getAuditLogsSaga() {
    yield takeLatest(GET_AUDIT, getAuditLogs);
}

function* downloadAuditLog(payload) {
    try {
        yield put(Actions.uiStart());
        const response = yield call(downloadAuditLogService, payload);
        fileDownload(response.data, 'Auditlog.xlsx');
        Notify({
            message: 'Audit log download successful',
            className: 'success',
        })
        yield put(Actions.uiStop());

        return yield put({
            type: DOWNLOAD_AUDIT_LOG_SUCCESS,
        });
    } catch (err) {
        Notify({
            message: err.response.message,
            className: "error",
        });
        yield put(Actions.uiStop());
        return yield put({
            type: DOWNLOAD_AUDIT_LOG_FAILURE,
        });
    }
}

export function* downloadAuditLogSaga() {
    yield takeLatest(DOWNLOAD_AUDIT_LOG, downloadAuditLog);
}