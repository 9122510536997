import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../../../redux-flow/services/services";
import {
    RM_USER_PERMISSIONS,
    RM_USER_PERMISSIONS_FAILURE,
    RM_USER_PERMISSIONS_SUCCESS
} from "../../../../redux-flow/arsVariables";

import { Actions } from "../../../actions/_index";
import { Notify } from "../../../../reuse/notify";
import { errorHandler } from "../../../../reuse/utilities";
function* removeUserPermissions({ payload }) {
    try {
        const {
            data: { code, description },

        } = yield call(Services.signupService, payload);

        yield put(Actions.uiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        return yield put({
            type:
                code === 1
                    ? RM_USER_PERMISSIONS_SUCCESS
                    : RM_USER_PERMISSIONS_FAILURE
        });
    } catch (err) {
        let message = errorHandler(err)
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: RM_USER_PERMISSIONS_FAILURE
        });
    }
}

export default function* removeUserPermissionsSaga() {
    yield takeLatest(RM_USER_PERMISSIONS, removeUserPermissions);
}
