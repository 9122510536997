import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import { SIGNUP_SUCCESS, SIGNUP_USER } from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
import { history } from "../../reuse/history";
import { url} from "../../helper";
const { localStorage } = window;

function* signupUser({ payload }) {
    const { emailAddress, phoneNo } = payload.customerAdmin;
    try {
        const { error, data, status } = yield call(
            Services.signupService,
            payload
        );

        const { code, description } = data;
        yield put(Actions.uiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        if (error === false && status === 200 && code === 1) {
            localStorage.setItem("email", emailAddress);
            localStorage.setItem("phone", phoneNo);
            setTimeout(() => {
                history.push({
                    pathname: `${url}verify-profile`,
                    state: {
                        email: emailAddress,
                        phone: phoneNo
                    }
                });
            }, 3000);
            return yield put({ type: SIGNUP_SUCCESS });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });

        yield put(Actions.uiStop());
    }
}

export default function* signupUserSaga() {
    yield takeLatest(SIGNUP_USER, signupUser);
}
