import React, { Fragment, Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { CUSTOMER_ADMIN } from "../redux-flow/arsVariables";
import { url } from "../App";
import { Actions } from "../redux-flow/actions/_index";

class Sidebar extends Component {
    assignActive = path => {
        return window.location.pathname === path ||
            window.location.pathname.includes(path)
            ? "isw-sideBarList active"
            : "isw-sideBarList";
    };

    render() {
        const {
            decodedData,
            status,
            toggleModal,
            search,
            resetSearch,
            dispatch
        } = this.props;

        const { role } = decodedData;
        const listGen = (path, icon, label) => {
            return (
                <li
                    className={this.assignActive(`${url}${path}`)}
                    onClick={e => {
                        dispatch(Actions.toggleRightSidebar("remove"));
                        if (search !== undefined && search.length > 0)
                            return resetSearch(e, true);
                    }}>
                    <NavLink
                        activeClassName={""}
                        to={`${url}${path}`}
                        className="isw-sideBarNavLink">
                        <span className="isw-sideBarIcon">
                            <i className="material-icons isw-sideBarIconFont">
                                {icon}
                            </i>
                        </span>
                        <span className="isw-sideBarNavLabel">{label}</span>
                    </NavLink>
                </li>
            );
        };

        return (
            <Fragment>
                <aside
                    className={`isw-sideBarQuick123 ${status === "open" ? "openModal" : ""
                        }`}
                    id="side-nav"
                    onClick={() => toggleModal("close")}>
                    <div className="isw-sideBarNav">
                        <ul className="isw-ul">
                            {listGen("dashboard", "dashboard", "Dashboard")}
                            {listGen(
                                "users",
                                "supervised_user_circle",
                                "Staff"
                            )}
                            {listGen("locations", "location_on", "Locations")}
                            {role !== CUSTOMER_ADMIN
                                ? null
                                : listGen(
                                    "user-permission",
                                    "settings_input_component",
                                    "User Permission"
                                )}
                            {listGen("order", "local_gas_station", "Order")}
                            {role !== CUSTOMER_ADMIN
                                ? null
                                : listGen(
                                    "contracts",
                                    "business_center",
                                    "Contracts"
                                )}
                            {listGen("delivery", "motorcycle", "Delivery")}
                            {role === CUSTOMER_ADMIN ? listGen("audits", "handyman", "Audit Trail") : null}
                            {listGen("reports", "assignment", "Report")}
                        </ul>
                    </div>
                </aside>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ login_reducer, storage_reducer }) => {
    const { decodedData: decodedFromLogin } = login_reducer;
    const { decodedData: decodedFromStorage } = storage_reducer;
    return {
        decodedData:
            decodedFromStorage === undefined
                ? decodedFromLogin
                : decodedFromStorage
    };
};

export default connect(mapStateToProps)(Sidebar);
