import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../../../redux-flow/services/services";
import { CHANGE_USER_ROLE } from "../../../../redux-flow/arsVariables";
import { Actions } from "../../../actions/_index";
import { Notify } from "../../../..//reuse/notify";
import { errorHandler } from "../../../../reuse/utilities";

function* changeUserRole(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: { code, description }
        } = yield call(Services.changeUserRoleService, payload);

        yield put(Actions.uiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        if (code === 1)
            return yield put(
                Actions.getUsers({
                    pageIndex: payload.payload.pageIndex,
                    pageSize: 10
                })
            );
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
    }
}

export default function* changeUserRoleSaga() {
    yield takeLatest(CHANGE_USER_ROLE, changeUserRole);
}
