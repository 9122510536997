import React, { Component } from "react";
import Joi from "joi-browser";
import { InputField as Input } from "./input";
import { Button, IconButton } from "@material-ui/core";
import { Icon } from "antd";
import CustomTextField from "./customInput";

export const _handleInputEnter = (e) => {
  const { keyCode, target } = e;
  if (keyCode === 13) {
    const form = target.form;
    const index = Array.prototype.indexOf.call(form, target);
    e.preventDefault();
    form.elements[index].blur();
    return document.querySelector("[data-buttontarget]").click();
  }
};

class FormLayout extends Component {
  state = {
    data: {},
    data2: {},
    styles: {},
    errors: {},
  };

  validate = (schema2 = false) => {
    const { error } = Joi.validate(
      schema2 ? this.state.data2 : this.state.data,
      schema2 ? this.schema2 : this.schema,
      { abortEarly: false }
    );
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  validateProperty2 = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema2[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleChange2 = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty2(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data2 = { ...this.state.data2 };
    data2[input.name] = input.value;

    this.setState({ data2, errors });
  };

  handleBlur = ({ currentTarget: { value, name } }) => {
    const styles = { ...this.state.styles };
    const style = value === "" ? "" : " form-field--is-filled";
    styles[name] = style;
    this.setState({ styles: styles ? styles : {} });
  };

  handleFocus = ({ currentTarget: input }) => {
    const styles = { ...this.state.styles };
    const style = " form-field--is-active";
    styles[input.name] = style;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({ styles, errors });
  };

  handleFocus2 = ({ currentTarget: input }) => {
    const styles = { ...this.state.styles };
    const style = " form-field--is-active";
    styles[input.name] = style;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty2(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({ styles, errors });
  };

  renderButton = (label, pending, handler, id) => (
    <Button
      id={id}
      data-buttontarget="final-submit-button"
      onClick={pending ? null : handler}
      disabled={pending}
      className={`isw-btn bg-primary text-white w-100 ${
        pending ? "dot false" : "isw-btn--raised"
      }`}
    >
      {pending ? (
        <>
          Loading
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </>
      ) : (
        <span>{label}</span>
      )}
    </Button>
  );

  renderDrawerHeaderButton = (text) => (
    <div>
      <IconButton
        onClick={this._toggleRightSidebar}
        style={{
          cursor: "pointer",
        }}
      >
        <Icon
          type="right"
          style={{
            width: `${16}px`,
            color: "#999",
          }}
        />
      </IconButton>
      {text}
    </div>
  );

  renderDrawerSubmitButton = (loading, handler, activeText, disabledText) => (
    <Button
      onClick={loading ? null : handler}
      disabled={loading}
      data-buttontarget="final-submit-button"
      className={`isw-btn text-blue ${loading ? "dot" : ""}`}
    >
      {loading ? (
        <>
          {disabledText}
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </>
      ) : (
        <span>{activeText}</span>
      )}
    </Button>
  );

  renderInput = (name, label, required, myStyle = "", type = "text") => {
    const { data, errors, styles } = this.state;

    return (
      <Input
        className={`${myStyle} mb-3 ${
          styles !== undefined ? styles[name] : ""
        } ${errors[name] !== "" && errors[name] !== undefined ? "error" : ""}`}
        type={
          name === "email" || name === "password" || name === "number"
            ? name
            : type
        }
        name={name}
        required={required}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onKeyDown={_handleInputEnter}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        error={errors[name]}
      />
    );
  };
  renderInput2 = (name, label, required, myStyle = "", type = "text") => {
    const { data2, errors, styles } = this.state;

    return (
      <Input
        className={`${myStyle} mb-3 ${
          styles !== undefined ? styles[name] : ""
        } ${errors[name] !== "" && errors[name] !== undefined ? "error" : ""}`}
        type={
          name === "email" || name === "password" || name === "number"
            ? name
            : type
        }
        name={name}
        required={required}
        value={data2[name]}
        label={label}
        onChange={this.handleChange2}
        onKeyDown={_handleInputEnter}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus2}
        error={errors[name]}
      />
    );
  };
  renderInput3 = (name, label, required, myStyle = "", type = "text") => {
    const { data, errors, styles } = this.state;

    return (
      <div>
        <label>{label}</label>
        {/* CustomTextField */}
        <CustomTextField
          className={`${myStyle} mb-3 ${
            styles !== undefined ? styles[name] : ""
          } ${
            errors[name] !== "" && errors[name] !== undefined ? "error" : ""
          }`}
          type={
            name === "email" || name === "password" || name === "number"
              ? name
              : type
          }
          name={name}
          required={required}
          value={data[name]}
          label={label}
          onChange={this.handleChange}
          onKeyDown={_handleInputEnter}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          error={errors[name]}
        />
      </div>
    );
  };
}

export default FormLayout;
