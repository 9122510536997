import { getFunc, postFunc, putFunc } from "../httpService";

export const baseurl = window.env.BASE_AUTH_URL;

const client_id = window.env.CUSTOMER_CLIENT_ID;
const client_secret = window.env.CUSTOMER_CLIENT_KEY;

// Reports
export const reportUrl = window.env.BASE_REPORT_URL;

// Signup User
export const onboardSignupService = (payload) => {
  return postFunc(`${baseurl}Auth/CreateNewUser`, payload);
};

// verify-user
export const onboardVerifyUserService = (payload) => {
  return getFunc(
    `${baseurl}Auth/ConfirmEmail?userId=${encodeURIComponent(
      payload?.userId
    )}&token=${encodeURIComponent(payload?.token)}`
  );
};
