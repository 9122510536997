import React from "react";

import { Spin } from "antd";

const LoadingPage = ({ text }) => {
    return (
        <div className="loading-container">
          <span
            style={{
              fontSize: "1rem",
              // color: "dodgerblue",
              fontWeight: "bold",
              color: "#00425F"
            }}
          >
            <Spin style={{marginRight: 10}} /* tip={text} */ spinning={true} />
            { text || 'Getting all assets...'}
          </span>
        </div>
    );
};

export default LoadingPage;
