import React, { useEffect, useState } from "react";
import "./signup.css";
import Static from "../../reuse/Static";
import SignUpForm from "../../reuse/signUpForm";
import { FooterLogoIcon } from "../../reuse/svgIcons";
import axios from "axios";
export const url = "/customer/";

const SignupOnboard = () => {
  const [pingResponse, setPingResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPing = async () => {
      try {
        const response = await axios.get(
          "https://uber-for-diesel-ag-auth-service.k8.isw.la/u4da/api/heart/ping"
        );
        setPingResponse(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPing();

    // fetchPing();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  console.log(pingResponse);

  return (
    <div className="isw-login">
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div className="col-lg-8">
            <div
              className="isw-login--middle"
              style={{
                overflowY: "auto",
                displaY: "flex",
                justifyContent: "center",
              }}
            >
              <div className="isw-login--middle-form">
                <div
                  className="login-form w-100 row form"
                  style={{
                    maxWidth: `${35}rem`,

                    margin: `${0} auto`,
                  }}
                >
                  <div className="col-12">
                    <header>
                      <h1 className="text-primary">Get Started!</h1>
                      <p className="sub-text">
                        Sign up by entering the information below
                      </p>
                    </header>
                  </div>
                  <SignUpForm />
                </div>
                )
              </div>

              <div className="footer-wrapper">
                <p className="footer-text">
                  Powered by <FooterLogoIcon classname={"pb-1 pl-1"} />
                </p>
              </div>
            </div>
          </div>

          <Static />
        </div>
      </div>
    </div>
  );
};

export default SignupOnboard;
