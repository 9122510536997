import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../../../redux-flow/services/services";

import {
    BATCH_UU_PERMISSIONS,
    BATCH_UU_PERMISSIONS_FAILURE,
    BATCH_UU_PERMISSIONS_SUCCESS
} from "../../../../redux-flow/arsVariables";
import { Actions } from "../../../actions/_index";
import { Notify } from "../../../../reuse/notify";
import { errorHandler } from "../../../../reuse/utilities";
function* batchUpdateUserPermissionSaga(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: { code, description }
        } = yield call(Services.batchUpdateUserPermissionsService, payload);

        yield put(Actions.uiStop());
        Notify({
            message: description,
            className: code === 1 ? "success" : "error"
        });
        return yield put({
            type:
                code === 1
                    ? BATCH_UU_PERMISSIONS_SUCCESS
                    : BATCH_UU_PERMISSIONS_FAILURE
        });
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: BATCH_UU_PERMISSIONS_FAILURE
        });
    }
}

export default function* batchUpdateUserPermissionSagaSaga() {
    yield takeLatest(BATCH_UU_PERMISSIONS, batchUpdateUserPermissionSaga);
}
