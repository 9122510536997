import { SIGNUP_SUCCESS } from "../../redux-flow/arsVariables";
import { initialState } from "../initialState";
const { localStorage } = window;

const signup_reducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case SIGNUP_SUCCESS:
            return {
                ...state,
                email: localStorage.getItem("email"),
                phone: localStorage.getItem("phone")
            };

        default:
            return state;
    }
};

export default signup_reducer;
