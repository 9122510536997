import {
    GET_CUSTOMERS_FAILURE,
    GET_CUSTOMERS_SUCCESS
} from "../../redux-flow/arsVariables";
import { initialState } from "../initialState";

const getCustomers_reducer = (state = initialState, action) => {
    const { type,  payloadReturned } = action;
    switch (type) {
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: payloadReturned
            };
        case GET_CUSTOMERS_FAILURE:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default getCustomers_reducer;
