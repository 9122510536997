import React, { Component } from "react";
import { itemRender, NotifyClose } from "./notify";
import { Pagination } from "antd";
import { formatNumber, formatMoney } from "./currencyHelper";
import { NavLink } from "react-router-dom";
import { url } from "../App";
import { PropsContext } from "./PropsContext";
// a base component to reuse methods and some UI i'd rather not import
class BaseComponent extends Component {
    state = {
        pageIndex: 1,
        pageSize: 10,
        SearchKeyword: ""
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({ SearchKeyword: value.trim() });
    };

    getBySearch = (e, empty = false) => {
        e.preventDefault();
        const { pageSize, SearchKeyword } = this.state;
        this.setState({
            pageIndex: 1,
            SearchKeyword: empty ? "" : SearchKeyword
        });
        this.fetch({
            pageIndex: 1,
            pageSize,
            SearchKeyword: empty ? "" : SearchKeyword
        });
    };

    renderTableAbnormality = text => (
        <ul className="isw-table--body">
            <li className="isw-table--bodyLi">
                <div className="row isw-table--bodyA">
                    <div className="col-lg-12 isw-valign--middle">
                        <h3 className="isw-subtitle text-center">
                            {text || "Data not available"}
                        </h3>
                    </div>
                </div>
            </li>
        </ul>
    );

    renderPageTracker = total => {
        const { pageIndex, pageSize } = this.state;
        const indexOfLastUser = pageIndex * pageSize;
        const indexOfFirstUser = indexOfLastUser - pageSize + 1;
        return total > 0 ? (
            <p className="isw-p mb-0">
                List (showing{" "}
                {total > indexOfFirstUser && total > indexOfLastUser
                    ? `${indexOfFirstUser}-${indexOfLastUser}`
                    : `${indexOfFirstUser}-${total}`}
                {` of ${total} records)`}
            </p>
        ) : null;
    };

    _toggler = (e, userId, activationStatus, component) => {
        e.preventDefault();
        this.props._toggleStatus(
            userId,
            activationStatus,
            component,
            this.state.pageIndex
        );
    };

    renderTableDropdown = ({
        activated,
        id,
        component,
        changeUserRole,
        role
    }) => (
        <span className="isw-p2">
            <div className="btn-group">
                <span
                    style={{
                        cursor: "pointer"
                    }}
                    role="button"
                    id="dropdownMenuLink1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <i className="material-icons">more_vert</i>
                </span>

                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink">
                    <NavLink
                        to={`${url}${component}/${id}`}
                        className="dropdown-item">
                        View Info
                    </NavLink>
                    <button
                        onClick={e =>
                            this._toggler(
                                e,
                                id,
                                activated ? false : true,
                                component
                            )
                        }
                        className={`dropdown-item ${activated ? "text-danger" : "text-success"
                            }`}>
                        {activated ? "Disable" : "Enable"}
                    </button>
                    {component === "users" && (
                        <button
                            onClick={e => changeUserRole(e, id, role)}
                            className="dropdown-item">
                            {role === "ADMIN"
                                ? "Demote to User"
                                : "Promote to Admin"}
                        </button>
                    )}
                </div>
            </div>
        </span>
    );
    handleTableChange = page => {
        const { pageSize, SearchKeyword } = this.state;
        NotifyClose();
        this.setState({
            pageIndex: page
        });
        this.fetch({
            pageIndex: page,
            pageSize,
            SearchKeyword
        });
    };
    renderPagination = total => {
        const { pageSize, pageIndex } = this.state;
        return (
            <div className="row">
                <div className="col-11">
                    <nav aria-label="pagination" className="float-right">
                        <Pagination
                            total={Math.ceil(total / pageSize) * 10 || 0}
                            onChange={this.handleTableChange}
                            hideOnSinglePage
                            current={pageIndex}
                            itemRender={itemRender}
                        />
                    </nav>
                </div>
            </div>
        );
    };

    renderDashboardCard1 = ({ header, amount, count, quantity }) => (
        <div className="col-12 mb-4">
            <div className="card shadow-sm border-0">
                <div className="card-body">
                    <div className="isw-card-header mb-2">
                        <h3 className="isw-card-h3 pb-4">{header}</h3>
                        <div className="isw-card-p mb-4" />
                    </div>
                    <div className="d-inline">
                        <h4 className="isw-user-section-title mb-1">{count}</h4>
                        <div className="isw-p2">Total Number</div>
                    </div>
                    <hr className="isw-divider-horizontal" />
                    <div className="d-inline">
                        <h4 className="isw-user-section-title mb-1">
                            {formatNumber(quantity)}
                            <sub
                                className="text-muted"
                                style={{
                                    fontSize: `${0.5}em`
                                }}>
                                Litres
                            </sub>
                        </h4>
                        <div className="isw-p2">Volume</div>
                    </div>
                    <hr className="isw-divider-horizontal" />
                    <div className="d-inline">
                        <h4 className="isw-user-section-title mb-1 text-orange">
                            {formatMoney(amount)}
                        </h4>
                        <div className="isw-p2">Value</div>
                    </div>
                </div>
            </div>
        </div>
    );

    renderDashboardCard2 = ({ icon, amount, label }) => (
        <div className="col-lg-6 col-12">
            <div className="card h-100 shadow-sm border-0">
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <div className="d-inline-flex">
                            <i className="material-icons text-primary">
                                {icon}
                            </i>
                        </div>
                        <div className="d-inline">
                            <h4 className="isw-user-section-title mb-1">
                                {formatNumber(amount)}
                            </h4>
                            <div className="isw-p2">{label}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
BaseComponent.contextType = PropsContext;
export default BaseComponent;
