import { qs } from "./authServices";
import { getFunc } from "./httpService";

const baseurlOrder = window.env.BASE_ORDER_URL;
//: "https://feulorder.azurewebsites.net/api/";

const baseurl = window.env.BASE_AUTH_URL;
//"https://feulapp.azurewebsites.net/api/";

// get customer order stats
export const getCustomerOrderStatsService = ({ payload }) => {
  return getFunc(
    `${baseurlOrder}Statistics/GetCustomerOrderStatistics?${qs.stringify(
      payload
    )}`
  );
};
// get customer profile stats
export const getCustomerProfileStatsService = () => {
  return getFunc(`${baseurl}Statistics/GetCustomerProfileStatistics`);
};
