import { call, put, takeLatest, select } from "redux-saga/effects";
import fileDownload from "js-file-download";

import Services from "../services/services";
import {
  GET_REPORT_LOGS_FAILURE,
  GET_REPORT_LOGS_SUCCESS,
  GET_REPORT_LOGS,
  GET_REPORT_LOGS_EXCEL_DOWNLOAD,
  GET_REPORT_LOGS_PDF_DOWNLOAD,
  GET_REPORT_LOGS_DOWNLOAD_FAILURE,
  GET_REPORT_LOGS_DOWNLOAD_SUCCESS,
} from "../arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";

const {
  getReportsServiceHO,
  getReportsDownloadPDFServiceHO,
  getReportsDownloadExcelServiceHO,
  getReportsServiceUSER,
  getReportsDownloadPDFServiceUSER,
  getReportsDownloadExcelServiceUSER,
} = Services;

const getUserType = (state) => state.storage_reducer.data;

function* getReportLogs(payload) {
  try {
    yield put(Actions.uiStart());

    // check current User role and determine endpoint to call
    let user = yield select(getUserType);
    //let apiService = user.permissions.includes("CUSTOMER_ADMIN")
    let apiService = (user.decoded.CRA_01 || user.decoded.CRA_02 || (user.decoded.role === "CUSTOMER_ADMIN"))
      ? getReportsServiceHO
      : getReportsServiceUSER;

    const {
      data: {
        code,
        description,
        data: payloadReturned,
        total,
        noOfPages,
        sizeOfCurrentPage,
      },
    } = yield call(apiService, payload);

    yield put(Actions.uiStop());

    if (code !== "00") {
      Notify({
        message: description,
        className: "error",
      });
      return yield put({
        type: GET_REPORT_LOGS_FAILURE,
      });
    } else if (code === "00") {
      return yield put({
        type: GET_REPORT_LOGS_SUCCESS,
        payloadReturned,
        noOfPages,
        sizeOfCurrentPage,
        total,
      });
    }
  } catch (err) {
    Notify({
      message: err.response.message,
      className: "error",
    });
    yield put(Actions.uiStop());
    return yield put({
      type: GET_REPORT_LOGS_FAILURE,
    });
  }
}

export default function* getReportsSaga() {
  yield takeLatest(GET_REPORT_LOGS, getReportLogs);
}

// PDF Dowloads
function* getReportsPDFDownload(payload) {
  try {
    yield put(Actions.uiStart());

    let user = yield select(getUserType);
    let apiService = user.permissions.includes("CUSTOMER_ADMIN")
      ? getReportsDownloadPDFServiceHO
      : getReportsDownloadPDFServiceUSER;

    const response = yield call(apiService, payload);
    fileDownload(response.data, "reports.pdf");
    Notify({
      message: 'Download successful',
      className: 'success'
    })
    yield put(Actions.uiStop());

    return yield put({
      type: GET_REPORT_LOGS_DOWNLOAD_SUCCESS,
    });
  } catch (err) {
    Notify({
      message: err.response.message,
      className: "error",
    });
    yield put(Actions.uiStop());
    return yield put({
      type: GET_REPORT_LOGS_DOWNLOAD_FAILURE,
    });
  }
}

export function* getReportsPDFDownloadSaga() {
  yield takeLatest(GET_REPORT_LOGS_PDF_DOWNLOAD, getReportsPDFDownload);
}

// Excel Dowloads
function* getReportsExcelDownload(payload) {
  try {
    yield put(Actions.uiStart());

    let user = yield select(getUserType);
    let apiService = user.permissions.includes("CUSTOMER_ADMIN")
      ? getReportsDownloadExcelServiceHO
      : getReportsDownloadExcelServiceUSER;

    const response = yield call(apiService, payload);
    fileDownload(response.data, "report.xlsx");

    yield put(Actions.uiStop());

    Notify({
      message: 'Download successful',
      className: 'success'
    })
    return yield put({
      type: GET_REPORT_LOGS_DOWNLOAD_SUCCESS,
    });
  } catch (err) {
    Notify({
      message: err.response.message,
      className: "error",
    });
    yield put(Actions.uiStop());
    return yield put({
      type: GET_REPORT_LOGS_DOWNLOAD_FAILURE,
    });
  }
}

export function* getReportsExcelDownloadSaga() {
  yield takeLatest(GET_REPORT_LOGS_EXCEL_DOWNLOAD, getReportsExcelDownload);
}
