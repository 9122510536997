import React, { useState, useEffect, memo } from "react";
import { Chart } from "react-google-charts";
import { Spin } from "antd";
import Services from "../redux-flow/services/services";
import { Notify } from "./notify";
import { connect } from "react-redux";
import { monthNames } from "./datePicker";

const Graph = ({ from, decodedData, to }) => {
    const [graphData, setGraphData] = useState([
        ["Duration", "Location"],
        [monthNames[new Date().getMonth()], 0]
    ]);

    const [loading, setLoading] = useState(false);
    const fixDateFormat = date =>
        `${new Date(date).getFullYear()}-${new Date(date).getMonth() +
            1}-${new Date(date).getDate()}`;

    useEffect(() => {
        setLoading(true);
        Services.getGraphDataService({
            from: fixDateFormat(from),
            to: fixDateFormat(to),
            userId: decodedData.sub,
            limit: 5,
            status: 7
        })
            .then(({ data: { payload, code } }) => {
                setLoading(false);
                if (code === 1) {
                    return payload.length === 1 ? null : setGraphData(payload);
                }
            })
            .catch(error => {
                setLoading(false);
                Notify({
                    message: "Error fetching visualization data",
                    className: "error"
                });
            });
    }, [to, from]);

    return (
        <Spin spinning={loading}>
            <Chart
                height={320}
                width={"100%"}
                chartType="LineChart"
                data={[graphData[0], ...graphData.slice(1)]}
                options={{
                    curveType: "function",
                    legend: "top",
                    vAxis: {
                        title: "Volume",
                        format: "short",
                        viewWindow: {
                            min: 0
                        }
                    },
                    hAxis: {
                        title: "Duration",
                        format: "short"
                    }
                }}
                legendToggle
            />
        </Spin>
    );
};

const mapStateToProps = ({ login_reducer, storage_reducer }) => {
    const { decodedData: decodedFromStorage } = storage_reducer;
    const { decodedData: decodedFromLogin } = login_reducer;
    return {
        decodedData:
            decodedFromStorage === undefined
                ? decodedFromLogin
                : decodedFromStorage
    };
};

export default connect(mapStateToProps)(memo(Graph));
