import React from "react";
import {TextField} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroller";
import PropTypes from "prop-types";
import {Spin} from "antd";

export const LeftView = ({
    searching,
    handleInfiniteOnLoad,
    isLoading,
    hasMore,
    usersAvailable,
    initialLoad
}) => (
        <div className="col-5">
            <div className="isw-permission-name">
                <div className="form-field">
                    <div className="form-field__control">
                        <TextField
                            type="text"
                            className="form-field__input"
                            label="Search Name"
                            id="cssTarget"
                            onChange={searching}
                        />
                    </div>
                </div>

                <div
                    className="list-group list-group-flush"
                    id="list-tab"
                    style={{
                        overflow: "auto",
                        height: `${25}rem`
                    }}
                    role="tablist">
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={1}
                        loadMore={handleInfiniteOnLoad}
                        hasMore={!isLoading && hasMore}
                        useWindow={false}>
                        {!initialLoad ? (
                            <>
                                {usersAvailable}
                                {isLoading ? (
                                    <Spin tip="fetching more..." spinning />
                                ) : null}
                            </>
                        ) : (
                                <Spin tip="Please wait..." spinning />
                            )}
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );

LeftView.propTypes = {
    hasMore: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    initialLoad: PropTypes.bool.isRequired,
    handleInfiniteOnLoad: PropTypes.func.isRequired,
    searching: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    usersAvailable: PropTypes.any.isRequired
};
