import {
    GET_USERS_IN_LOCATION_FAILURE,
    GET_USERS_IN_LOCATION_SUCCESS
} from "../arsVariables";
import { initialState } from "../initialState";

const getUsersInLocation_reducer = (state = initialState, action) => {
    const { type, payload, totalCount } = action;

    switch (type) {
        case GET_USERS_IN_LOCATION_SUCCESS:
            return {
                ...state,
                usersInLocation: payload,
                totalCount
            };
        case GET_USERS_IN_LOCATION_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getUsersInLocation_reducer;
