import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import { RESEND_OTP } from "../../redux-flow/arsVariables";
import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
function* resendOTP({ payload }) {
    try {
        yield put(Actions.miniUiStart());
        const {
            data: { code, description }
        } = yield call(Services.OTPService, payload, RESEND_OTP);
        yield put(Actions.miniUiStop());
        Notify({
            message: description,
            className: code !== 1 ? "error" : "success"
        });
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.miniUiStop());
    }
}

export default function* resendOTPSaga() {
    yield takeLatest(RESEND_OTP, resendOTP);
}
