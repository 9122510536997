import { put, takeLatest } from "redux-saga/effects";
import { clear } from "../../redux-flow/services/localStorageHelper";
import { LOGOUT_USER, LOGOUT_FAILURE, LOGOUT_SUCCESS } from "../arsVariables";
import { FRONT_SSO_URL } from "../../helper";

function* logoutUser() {
    try {
        clear();
        window.location.href = FRONT_SSO_URL + "/logout";
        return yield put({ type: LOGOUT_SUCCESS });
    } catch (error) {
        return yield put({ type: LOGOUT_FAILURE });
    }
}

export default function* logoutSaga() {
    yield takeLatest(LOGOUT_USER, logoutUser);
}
