import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import {
    GET_PENDING_ORDER,
    GET_PENDING_ORDER_FAILURE,
    GET_PENDING_ORDER_SUCCESS
} from "../../arsVariables";

import { Actions } from "../../actions/_index";
import { Notify } from "../../../reuse/notify";
import { errorHandler } from "../../../reuse/utilities";

function* getPendingOrder(payload) {
    try {
        const { error, data, status } = yield call(
            Services.getPendingOrderService,
            payload
        );
        const { code, description, totalCount } = data;
        yield put(Actions.uiStop());
        if (error === false && status === 200 && code === 1) {
            return yield put({
                type: GET_PENDING_ORDER_SUCCESS,

                payloadReturned: data.payload,
                totalCount
            });
        } else if (error === false && status === 200) {
            Notify({ message: description, className: "error" });
            return yield put({
                type: GET_PENDING_ORDER_FAILURE
            });
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_PENDING_ORDER_FAILURE
        });
    }
}

export default function* getPendingOrderSaga() {
    yield takeLatest(GET_PENDING_ORDER, getPendingOrder);
}
