import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../services/services";
import {
    GET_CUSTOMER_PROFILE_STATS,
    GET_CUSTOMER_PROFILE_STATS_SUCCESS
} from "../../arsVariables";
import { Actions } from "../../actions/_index";
import { Notify } from "../../../reuse/notify";
import { errorHandler } from "../../../reuse/utilities";

function* getCustomerProfileStats(payload) {
    try {
        const {
            data: { code, description, totalCount, payload: dataPayload }
        } = yield call(Services.getCustomerProfileStatsService, payload);

        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_CUSTOMER_PROFILE_STATS_SUCCESS,

                payloadReturned: dataPayload,
                totalCount
            });
        } else if (code !== 1) {
            Notify({ message: description, className: "error" });
        }
    } catch (err) {
        let message = errorHandler(err);
        Notify({
            message,
            className: "error"
        });

        yield put(Actions.uiStop());
    }
}

export default function* getCustomerProfileStatsSaga() {
    yield takeLatest(GET_CUSTOMER_PROFILE_STATS, getCustomerProfileStats);
}
