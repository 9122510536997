import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import Sidebar from "../reuse/sidebar";
import { Spin } from "antd";
import Graph from "../reuse/graph";
import DatePicker, { pastDate, currentDate2 } from "../reuse/datePicker";
import BaseComponent from "../reuse/baseComponent";
import MenuBar from "../reuse/MenuBar";

class DashboardUser extends BaseComponent {
    state = {
        pageIndex: 1,
        pageSize: 10,
        from: pastDate,
        to: currentDate2
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(Actions.uiStart());
        const { from, to } = this.state;
        this.fetchByDate({
            from,
            to
        });
    }

    fetchByDate = ({ from, to }) => {
        const { dispatch } = this.props;
        dispatch(Actions.uiStart());
        dispatch(
            Actions.getCustomerOrderStats({
                from,
                to
            })
        );
    };

    dateExtractor = ({ past, today }) => {
        this.setState({
            pending: true,
            errorShown: false,
            loading: true,
            from: past,
            to: today
        });
        this.fetchByDate({
            from: past,
            to: today
        });
    };

    render() {
        const { loading, status, toggleModal } = this.context;

        const {
            orderStatistics: {
                orderCount,
                orderQuantity,
                cancelledOrderCount,
                cancelledOrderQuantity,
                orderAmount,
                cancelledOrderAmount
            }
        } = this.props;

        const { from, to } = this.state;

        return (
            <Fragment>
                <Sidebar
                    search={this.state.SearchKeyword}
                    status={status}
                    toggleModal={toggleModal}
                />
                <div className="isw-mainLayout1">
                    <Spin tip="Please wait..." spinning={loading}>
                        <div className="isw-content--wrapper">
                            <MenuBar />
                            <div>
                                <form className="content" id="content-body">
                                    <div className="container-fluid container-limited">
                                        {/* <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb bg-transparent px-0">
                                                <li className="breadcrumb-item">
                                                    <h1>Dashboard</h1>
                                                </li>
                                            </ol>
                                        </nav> */}

                                        <DatePicker
                                            dateExtractor={this.dateExtractor}
                                        />

                                        <div className="row">
                                            <div
                                                className="col-12 mb-4"
                                                style={{
                                                    minHeight: `${386}px`
                                                }}>
                                                <div className="card h-100 shadow-sm border-0 graph-container">
                                                    <div className="isw-card-header p-3">
                                                        <h3 className="isw-card-h3">
                                                            Total Product
                                                            Purchased
                                                        </h3>
                                                        <div className="isw-card-p">
                                                            By location
                                                        </div>
                                                    </div>

                                                    <Graph
                                                        from={from}
                                                        to={to}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-12 mb-3">
                                                <div className="row">
                                                    {this.renderDashboardCard1({
                                                        header:
                                                            "Orders Fulfilled",

                                                        count: orderCount,
                                                        amount: orderAmount,
                                                        quantity: orderQuantity
                                                    })}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 mb-3">
                                                <div className="row">
                                                    {this.renderDashboardCard1({
                                                        header:
                                                            "Cancelled Orders",

                                                        count: cancelledOrderCount,
                                                        amount: cancelledOrderAmount,
                                                        quantity: cancelledOrderQuantity
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Spin>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ getCustomerOrderStats_reducer }) => {
    const { orderStatistics } = getCustomerOrderStats_reducer;
    return {
        orderStatistics
    };
};

export default connect(mapStateToProps)(DashboardUser);
