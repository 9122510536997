import {
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS
} from "../../redux-flow/arsVariables";
import { initialState } from "../initialState";

const getUsers_reducer = (state = initialState, action) => {
    const { type, payloadReturned, totalCount } = action;
    switch (type) {
        case GET_USERS_SUCCESS:
            return {
                ...state,
                users: payloadReturned,
                usersLength: totalCount
            };
        case GET_USERS_FAILURE:
            return {
                ...state
            };

        default:
            return state;
    }
};

export default getUsers_reducer;
