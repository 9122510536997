import { combineReducers } from "redux";
import storage_reducer from "./storage_reducer";
import login_reducer from "./login_reducer";
import logout_reducer from "./logout_reducer";
import toggleSidebar_reducer from "./toggleSidebar_reducer";
import toggleRightSidebar_reducer from "./toggleRightSidebar_reducer";
import signup_reducer from "./signup_reducer";
import getLocations_reducer from "./getLocations_reducer";
import getLocation_reducer from "./getLocation_reducer";
import getBasicInfo_reducer from "./getBasicInfo_reducer";
import getUsers_reducer from "./getUsers_reducer";
import getCustomers_reducer from "./getCustomers_reducer";
import getUsersInLocation_reducer from "./getUsersInLocation_reducer";
import permissions_reducer from "./user_permissions/GET/permissions_reducer";
import roles_reducer from "./user_permissions/GET/roles_reducer";
import getVendorWithProducts_reducer, {
  getVendorsProduct_reducer,
} from "./orders/getVendorWithProds_reducer";
import getPendingOrder_reducer from "./orders/getPendingOrder_reducer";
import getOrderItems_reducer from "./orders/getOrderItems_reducer";
import getOrders_reducer from "./orders/getOrders_reducer";
import getOrder_reducer from "./orders/getOrder_reducer";
import getVendorContracts_reducer from "./orders/getVendorContracts_reducer";
import getCustomerOrderStats_reducer from "./statistics/getCustomerOrderStats_reducer";
import getCustomerProfileStats_reducer from "./statistics/getCustomerProfileStats_reducer";
import getProdnContDetail_reducer from "./orders/getProdnContDetail_reducer";
import { initialState } from "../initialState";
import getOrderHistory_reducer from "./getOrderHistory_reducer";
import getUserLocations_reducer from "./getUserLocations_reducer";
import getUser_reducer from "./getUser_reducer";
import getReports_reducer from "./getReports_reducer";
import getPendingBulkUploads_reducer from "./getPendingBulkUploads_reducer";
import getAuditLogs_reducer from "./auditLog_reducer";
import getContractList_reducer, {
  getContractDetail_reducer,
  getContractHistory_reducer,
} from "./contracts_reducer";
import getBranchOrder_reducer from "./orders/getBranchOrder_reducer";
import getBranchFulfilledOrder_reducer from "./orders/getBranchFulfilledOrder_reducer";
import getBranchOrderDeliveries_reducer from "./orders/getBranchOrderDelivery_reducer";
import getBranchRecentOrders_reducer from "./orders/getBranchRecentOrders";
import signup_onboard_reducer from "./onboard/signup_onboard_reducer";
import verify_onboard_reducer from "./onboard/verify_email_reducer";

const ui_reducer = (state = initialState, action) => {
  switch (action.type) {
    case "STOP_LOAD":
      return {
        ...state,
        loading: false,
        sucess: false,
      };
    case "LOAD":
      return {
        ...state,
        loading: true,
        sucess: false,
      };
    case "mini_LOAD":
      return {
        ...state,
        isLoading: true,
        sucess: false,
      };
    case "mini_STOP_LOAD":
      return {
        ...state,
        isLoading: false,
        sucess: false,
      };

    default:
      return state;
  }
};
const IndexReducer = combineReducers({
  ui_reducer,
  toggleSidebar_reducer,
  getUserLocations_reducer,
  toggleRightSidebar_reducer,
  storage_reducer,
  login_reducer,
  logout_reducer,
  getProdnContDetail_reducer,
  signup_reducer,
  getBasicInfo_reducer,
  getLocations_reducer,
  getLocation_reducer,
  getUsersInLocation_reducer,
  getUser_reducer,
  getUsers_reducer,
  getCustomers_reducer,
  permissions_reducer,
  roles_reducer,
  getVendorWithProducts_reducer,
  getOrderHistory_reducer,
  getPendingOrder_reducer,
  getOrderItems_reducer,
  getOrders_reducer,
  getOrder_reducer,
  getVendorContracts_reducer,
  getCustomerOrderStats_reducer,
  getCustomerProfileStats_reducer,
  getReports_reducer,
  getPendingBulkUploads_reducer,
  getAuditLogs_reducer,
  getContractList_reducer,
  getContractDetail_reducer,
  getVendorsProduct_reducer,
  getContractHistory_reducer,
  getBranchOrder_reducer,
  getBranchFulfilledOrder_reducer,
  getBranchOrderDeliveries_reducer,
  getBranchRecentOrders_reducer,

  //Onboard
  signup_onboard_reducer,
  verify_onboard_reducer,
});
export default IndexReducer;
