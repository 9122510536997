import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../services/services";
import { GET_USER_FAILURE, GET_USER_SUCCESS, GET_USER } from "../arsVariables";

import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
import { message } from "antd";
import { history } from "../../reuse/history";

function* getUser(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: { code, description, payload: dataPayload }
        } = yield call(Services.getUserService, payload);
        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_USER_SUCCESS,
                payload: dataPayload
            });
        } else if (code !== 1) {
            message.error(description);
            setInterval(() => {
                history.push("/customer/users");
            }, 1500);
            return yield put({
                type: GET_USER_FAILURE
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_USER_FAILURE
        });
    }
}

export default function* getUserSaga() {
    yield takeLatest(GET_USER, getUser);
}
