import { initialState } from "../../initialState";
import {
    GET_ORDER_FAILURE,
    GET_ORDER_SUCCESS,

} from "../../arsVariables";

const getOrder_reducer = (state = initialState, action) => {
    const { type, payloadReturned } = action;
    switch (type) {
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                type: type,
                order: payloadReturned,
            };
        case GET_ORDER_FAILURE:
            return {
                type: type,
                ...state,
            };

        default:
            return state;
    }
};

export default getOrder_reducer;
