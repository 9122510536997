import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { Actions } from "../../redux-flow/actions/_index";
import { SUCCESS, FAILURE, ENCRYPT_USER } from "../../redux-flow/arsVariables";
import { decryptAndRead } from "../../redux-flow/services/localStorageHelper";
import { history } from "../../reuse/history";
// import SmartfuelLogo from "../../assets/images/smartfuel.svg";
import FormLayout from "../../reuse/form";
// import { Link } from "react-router-dom";
import Joi from "joi-browser";
// import { url } from "../../App";
import "./index.css";
import LoadingPage from "../../reuse/loading";
export const url = "/customer/";
class Login extends FormLayout {
    state = {
        redirectToReferrer: false,
        data: {
            email: "",
            password: ""
        },
        errors: {},
        styles: {}
    };

    schema = {
        email: Joi.string()
            .regex(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "email"
            )
            .required()
            .label("Email Address"),
        password: Joi.string()
            .required()
            .label("Password")
    };

    componentDidMount = () => {
        this.props.dispatch(Actions.loginUser(null));
    }

    static getDerivedStateFromProps = (props, state) => {
        const { login, dispatch, fakeAuth } = props;
        // if login is successful
        if (login === SUCCESS || decryptAndRead(ENCRYPT_USER)) {
            fakeAuth.authenticate();
            return {
                redirectToReferrer: true
            };
        }

        // if login fails
        if (login === FAILURE) {
            dispatch(Actions.reset());
            return {
                redirectToReferrer: false
            };
        }

        return null;
    };

    /* onLogin = e => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;
        const { email, password } = this.state.data;
        this.props.dispatch(Actions.loginUser({ email, password }));
    }; */

    render() {
        const { redirectToReferrer } = this.state;
        // const { loading } = this.props;

        /** to redirect when login successful

         if would redirect to the private route user tried to
         if none, it hits the first route in the app which should be dashboard

         * */
        let fromWhere = null;
        if (history.location.state) {
            const { from } = history.location.state.from.state || {
                from: { pathname: `${url}dashboard` }
            };
            fromWhere = from;
        } else {
            fromWhere = { pathname: `${url}dashboard` };
        }

        if (redirectToReferrer === true) {
            return <Navigate push to={fromWhere} />;
        }

        return (
            <>
                <LoadingPage text="Redirecting..." />

                {/* <div className="login-container">
                <div className="header">
                    <div className="logo">
                        <img src={SmartfuelLogo} alt="Smartfuel's logo" />
                    </div>
                </div>
                <form className="customer login-wrap" onSubmit={this.onLogin}>
                    <div className="login-header">
                        Sign in by entering the information below
                    </div>
                    <div className="login-form">
                        <div className="email col-12 form-field">
                            {this.renderInput("email", "Email Address", false)}
                        </div>
                        <div className="password col-12 form-field">
                            {this.renderInput("password", "Password", false)}
                        </div>
                    </div>
                    <div className="forgot-password mt-0 mb-2 col-12">
                        {loading ? (
                            <div className="d-inline-block">
                                Forgot Password?
                            </div>
                        ) : (
                            <div className="d-inline-block csr">
                                <Link
                                    // id for test
                                    id="forgot-btn"
                                    to={`${url}forgotPassword`}
                                    style={{
                                        color: "#00425f"
                                    }}>
                                    Forgot Password?
                                </Link>
                            </div>
                        )}
                    </div>
                    <div className="col-12">
                        {this.renderButton(
                            "Sign in",
                            loading,
                            this.onLogin,
                            "signin"
                        )}
                    </div>
                    <div className="signUp">
                        <span>Don't have an account yet?</span>{" "}
                        <span>
                            {loading ? (
                                <span className="text-primary font-weight-bold">
                                    Sign up
                                </span>
                            ) : (
                                <Link
                                    // leave id, used for testing
                                    id="signup-btn"
                                    className="text-primary font-weight-bold csr"
                                    to={`${url}signup`}>
                                    Sign up
                                </Link>
                            )}
                        </span>
                    </div>
                </form>
            </div> */}
            </>
        );
    }
}

const mapStateToProps = ({ login_reducer }) => {
    const { login } = login_reducer;
    return {
        login
    };
};
export default connect(mapStateToProps)(Login);
