import React, { Fragment, Suspense, lazy, Component } from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { Actions } from "../redux-flow/actions/_index";
import { SUCCESS, FAILURE } from "../redux-flow/arsVariables";
import Header from "./header-ag";
import { history } from "./history";
import { Spin } from "antd";
import { url } from "../App";
import { PropsContext } from "./PropsContext";
const ChangePW = lazy(() => import("../Auth/ChangePW/ChangePW"));

class Layout extends Component {
    state = { status: "close" };

    toggleModal = status => {
        this.setState({ status });
    };

    componentDidMount() {
        this.props.dispatch(Actions.uiStart());
        this.props.dispatch(Actions.onInit());
    }

    logout = () => {
        this.props.dispatch(Actions.logoutUser());
    };

    static getDerivedStateFromProps = (props, state) => {
        const { storage, logout, dispatch, fakeAuth } = props;
        if (storage === SUCCESS && logout === undefined) {
            fakeAuth.authenticate();
            dispatch(Actions.reset());
        }

        if (logout === SUCCESS) {
            dispatch(Actions.reset());
            fakeAuth.signout();
            setTimeout(() => {
                history.replace({ pathname: url });
            }, 1000);
        }
        if (logout === FAILURE) return dispatch(Actions.reset());

        return state;
    };

    render() {
        // component and props passed are accessed
        const {
            data,
            decodedData,
            toggleRightSidebar,
            // children,
            loading,
            success,
            isLoading
        } = this.props;
        const { status } = this.state;

        return (
            <Fragment>
                <Header
                    logout={this.logout}
                    decodedData={decodedData}
                    toggleRightSidebar={toggleRightSidebar}
                    status={status}
                    toggleModal={this.toggleModal}
                    modules={(data || {}).modules}
                />

                <Suspense fallback={<Spin spinning={true} tip="Loading..." />}>
                    <ChangePW toggleRightSidebar={toggleRightSidebar} />
                </Suspense>

                <div>
                    <PropsContext.Provider value={{ decodedData, status, loading, success, isLoading, toggleRightSidebar, toggleModal: this.toggleModal, userId: decodedData.sub, role: decodedData.role, }}>
                        <Outlet />
                    </PropsContext.Provider>
                    {/* {React.cloneElement(children, {
                        decodedData,
                        userId: decodedData.sub,
                        toggleRightSidebar,
                        status,
                        role: decodedData.role,
                        loading,
                        success,
                        isLoading,
                        toggleModal: this.toggleModal
                    })} */}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({
    login_reducer,
    storage_reducer,
    logout_reducer,
    toggleRightSidebar_reducer,
    ui_reducer
}) => {
    const { storage, data, decodedData: decodedFromStorage } = storage_reducer;
    const { decodedData: decodedFromLogin } = login_reducer;
    const { loading, isLoading, success } = ui_reducer;
    const { logout } = logout_reducer;
    const { toggleRightSidebar } = toggleRightSidebar_reducer;
    return {
        loading,
        success,
        isLoading,
        toggleRightSidebar,
        storage,
        logout,
        data,
        decodedData:
            decodedFromStorage === undefined
                ? decodedFromLogin
                : decodedFromStorage
    };
};

export default connect(mapStateToProps)(Layout);
