import React from "react";
import { notification, Icon } from "antd";
import PropTypes from "prop-types";
const key = "updatable";
export const Notify = ({ message, className, duration = 5 }) => {
    return className === "error"
        ? notification.error({
              key,
              message: message,
              duration: duration,
              className: className,
              icon: (
                  <Icon
                      type="close-circle"
                      style={{ color: "#fff", width: `${20}px` }}
                  />
              )
          })
        : className === "success"
        ? notification.success({
              key,
              message: message,
              duration: duration,
              className: className,
              icon: (
                  <Icon
                      type="check-circle"
                      style={{ color: "#fff", width: `${20}px` }}
                  />
              )
          })
        : notification.destroy();
};

Notify.propTypes = {
    message: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired
};

export const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
        return <span>Previous</span>;
    }
    if (type === "next") {
        return <span>Next</span>;
    }
    return originalElement;
};

export const NotifyClose = () => Notify({ className: "close" });
