import { call, put, takeLatest } from "redux-saga/effects";
import Services from "../../redux-flow/services/services";
import {
    GET_LOCATION_FAILURE,
    GET_LOCATION_SUCCESS,
    GET_LOCATION
} from "../../redux-flow/arsVariables";

import { Actions } from "../actions/_index";
import { Notify } from "../../reuse/notify";
import { message } from "antd";
import { history } from "../../reuse/history";

function* getLocation(payload) {
    try {
        yield put(Actions.uiStart());
        const {
            data: { code, description, payload: dataPayload }
        } = yield call(Services.getLocationService, payload);
        yield put(Actions.uiStop());
        if (code === 1) {
            return yield put({
                type: GET_LOCATION_SUCCESS,
                payload: dataPayload
            });
        } else if (code !== 1) {
            message.error(description);
            setInterval(() => {
                history.push("/customer/locations");
            }, 1500);
            return yield put({
                type: GET_LOCATION_FAILURE
            });
        }
    } catch (err) {
        Notify({
            message: err.response.response
                ? err.response.response.data.error_description
                : err.response.message,
            className: "error"
        });
        yield put(Actions.uiStop());
        return yield put({
            type: GET_LOCATION_FAILURE
        });
    }
}

export default function* getLocationSaga() {
    yield takeLatest(GET_LOCATION, getLocation);
}
