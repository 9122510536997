import {
  GET_PENDING_UPLOADS_SUCCESS,
  GET_PENDING_UPLOADS_FAILURE,
} from "../arsVariables";

const initialState = {
  pendingBulkUploads: [],
  total: null,
  verifyPayloadReturned: [],
  verifyTotalCount: null,
};

const getPendingBulkUploads_reducer = (state = initialState, action) => {
  const { type, payloadReturned, totalCount } = action;
  switch (type) {
    case GET_PENDING_UPLOADS_SUCCESS:
      return {
        pendingBulkUploads: payloadReturned,
        total: totalCount,
      };
    case GET_PENDING_UPLOADS_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default getPendingBulkUploads_reducer;
