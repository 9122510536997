import {
  loginService,
  refreshTokenService,
  signupService,
  resetPasswordService,
  OTPService,
  verifyProfileService,
  forgotPasswordService,
  changePasswordService,
} from "./authServices";
import {
  createUserService,
  editBasicInfoService,
  createLocationService,
  getLocationsService,
  getLocationService,
  getUsersService,
  getCustomersService,
  addUserToLocationsService,
  addUsersToLocationService,
  getBasicInfoService,
  getUsersInLocationService,
  deleteUserFromLocationService,
  bulkUploadService,
  changeUserRoleService,
  getUserLocationsService,
  getUserService,
  getReportsServiceHO,
  getReportsDownloadPDFServiceHO,
  getReportsDownloadExcelServiceHO,
  getReportsServiceUSER,
  getReportsDownloadPDFServiceUSER,
  getReportsDownloadExcelServiceUSER,
  getPendingBulkUploadsService,
  verifyPendingBulkUploadsService,
  bulkUploadLocationService,
  getAuditLogService,
  downloadAuditLogService,
} from "./appServices";

import {
  togglerUserStatusService,
  toggleLocationStatusService,
} from "./statusServices";

import {
  rolesService,
  updateUserPermissionsService,
  permissionsService,
  getUserPermissionsService,
  batchUpdateUserPermissionsService,
} from "./permissionServices";

import {
  getVendorWithProductsService,
  createOrderService,
  bulkUploadOrderService,
  getPendingOrderService,
  getOrderItemsService,
  reactToOrderService,
  getOrderService,
  getOrdersService,
  verifyOrderService,
  requestContractService,
  getVendorWithContractsService,
  getOrderHistoryService,
  getProdnContDetailService,
  getVendorWithContractDetailService,
  submitVerificationCodeService,
  getDeliveriesService,
  getGraphDataService,
  getContractList,
  getVendorPrdouctsService,
  getContractHistoryService,
  getBranchOrderService,
  getBranchFulfilledOrderService,
  getCustomerOrderDeliveriesService,
  getBranchRecentOrderService,
} from "./orderServices";

import {
  getCustomerOrderStatsService,
  getCustomerProfileStatsService,
} from "./statistics";
import {
  onboardSignupService,
  onboardVerifyUserService,
} from "./Onboarding/onboardingSignUpService";
const Services = {
  submitVerificationCodeService,
  getDeliveriesService,
  loginService,
  bulkUploadService,
  refreshTokenService,
  signupService,
  resetPasswordService,
  getUserLocationsService,
  OTPService,
  verifyProfileService,
  forgotPasswordService,
  changePasswordService,
  createUserService,
  editBasicInfoService,
  createLocationService,
  getLocationsService,
  getLocationService,
  getUsersService,
  getCustomersService,
  addUserToLocationsService,
  addUsersToLocationService,
  getBasicInfoService,
  togglerUserStatusService,
  toggleLocationStatusService,
  getUsersInLocationService,
  deleteUserFromLocationService,
  changeUserRoleService,
  getUserService,

  // permissions related
  permissionsService,
  getUserPermissionsService,
  batchUpdateUserPermissionsService,
  rolesService,
  updateUserPermissionsService,

  // orders
  getVendorWithProductsService,
  createOrderService,
  getPendingOrderService,
  getOrderItemsService,
  reactToOrderService,
  getVendorWithContractDetailService,
  getOrderService,
  getOrdersService,
  verifyOrderService,
  requestContractService,
  getVendorWithContractsService,
  getOrderHistoryService,
  getProdnContDetailService,
  getGraphDataService,

  // statistics
  getCustomerOrderStatsService,
  getCustomerProfileStatsService,

  //Reports
  getReportsServiceHO,
  getReportsDownloadPDFServiceHO,
  getReportsDownloadExcelServiceHO,

  getReportsServiceUSER,
  getReportsDownloadPDFServiceUSER,
  getReportsDownloadExcelServiceUSER,

  getPendingBulkUploadsService,
  verifyPendingBulkUploadsService,
  bulkUploadLocationService,
  bulkUploadOrderService,

  //audit
  getAuditLogService,
  downloadAuditLogService,

  //Contract
  getContractList,

  //Products
  getVendorPrdouctsService,
  getContractHistoryService,

  //Analytics
  getBranchOrderService,
  getBranchFulfilledOrderService,
  getCustomerOrderDeliveriesService,
  getBranchRecentOrderService,

  //Onboard
  onboardSignupService,
  onboardVerifyUserService,
};
export default Services;
